<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Solicitações De Câmbio</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.requestStatus">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="WAITING"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="CAM_ISSUED"
                           class="select-default"
                           label="CAM Emitida"/>
                <el-option value="SENT"
                           class="select-default"
                           label="Enviada"/>
                <el-option value="PROCESSED"
                           class="select-default"
                           label="Processada"/>
                <el-option value="CANCELED"
                           class="select-default"
                           label="Cancelado"/>
                <el-option value="CANCELED_BY_ERROR"
                           class="select-default"
                           label="Cancelado Por Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [25,50,100]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i class="fa-solid fa-magnifying-glass"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px"
                      @click="generateExcel">
              <i slot="label" class="fas fa-file-invoice"></i>
              Gerar CAM220
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      ref="trTable"
                      @row-click="(row, column, event) => expandRow(row)"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{reqStatusToLabel(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criado em">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="40" label="Moeda">
                <template slot-scope="props">
                    <span>
                      <span>{{(props.row.currency)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Vlr Câmbio">
                <template slot-scope="props">
                    <span>
                      <span>{{toMoney(props.row.foreignCurrencyValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Vlr Real">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.brlValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Cot. Moeda">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.priceQuote)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Cot. Venda">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.exchangePrice)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Lucro">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.profit)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Emitir CAM">
                <template slot-scope="props">
                  <p-switch v-model="props.row.issueCam"></p-switch>
                </template>
              </el-table-column>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <card>
                    <div class="row">
                      <div class="col-md-9">
                        <div>
                          <h5>Dados do Câmbio</h5>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <p>Beneficiário: {{props.row.beneficiary}}</p>
                            <p>Moeda: {{props.row.currency}}</p>
                            <p>Valor em moeda estrangeira: {{toMoney(props.row.foreignCurrencyValue)}}</p>
                            <p>Cotação: R$ {{toMoney(props.row.priceQuote)}}</p>
                            <p>País Destino: {{props.row.country}}</p>
                          </div>
                          <div class="col-md-6">
                            <p>SWIFT/IBC: {{props.row.destinationSwiftCode}}</p>
                            <p>IBAN: {{props.row.destinationIbanCode}}</p>
                            <p>Banco: {{props.row.destinationBankCode}} - {{props.row.destinationBankName}}</p>
                            <p>Agência: {{props.row.destinationAgency}} - {{props.row.destinationAgencyDv}}</p>
                            <p>Conta: {{props.row.destinationAccountNumber}} - {{props.row.destinationAccountNumberDv}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" v-show="props.row.status != 'CANCELED' && props.row.status != 'CANCELED_BY_ERROR' && props.row.status != 'PROCESSED'">
                        <p-button type="info" style="width: 100%; margin: 10px"
                                  @click="updateStatus(props.row.id, 'SENT')">
                          <i class="fa-solid fa-paper-plane"></i>
                          Enviado
                        </p-button>
                        <p-button type="success" style="width: 100%; margin: 10px"
                                  @click="updateStatus(props.row.id, 'PROCESSED')">
                          <i slot="label" class="nc-icon nc-check-2"></i>
                          Sucesso
                        </p-button>
                        <p-button type="warning" style="width: 100%; margin: 10px"
                                  @click="updateStatus(props.row.id, 'CANCELED')">
                          <i class="fa-solid fa-ban"></i>
                          Cancelar
                        </p-button>
                        <p-button type="danger" style="width: 100%; margin: 10px"
                                  @click="updateStatus(props.row.id, 'CANCELED_BY_ERROR')">
                          <i class="fa-solid fa-rectangle-xmark"></i>
                          Cancelar Por Erro
                        </p-button>
                      </div>
                    </div>
                  </card>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info" style="margin-top: 10px">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6" style="margin-top: 10px">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs, callWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney} from "src/util/core.utils"
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import download from "downloadjs";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      PSwitch
    },
    data() {
      return {
        lastExpandedRow: false,
        search: {
          key: '',
          requestStatus: null
        },
        modal: {
          exchangeData: false,
        },
        exchangeData: {
          beneficiary: '',
          country: '',
          destinationBankCode: '',
          destinationBankName: '',
          destinationAgency: '',
          destinationAgencyDv: '',
          destinationAccountNumber: '',
          destinationAccountNumberDv: '',
          currency: '',
          foreignCurrencyValue: '',
          priceQuote: '',
          destinationSwiftCode: '',
          destinationIbanCode: '',
        },
        tableData: [],
        pagination: {
          perPage: 25,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        editApproval: false,
        editShippingData: false,
        editActivation: false,
      }
    },

    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/exchange/list",
          true, null,
          {
            key: this.search.key,
            status: this.search.requestStatus,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        swal({
          title: 'Aviso!',
          text: "Não foi encontrada solicitação para esse usuário.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      successLoadData(response) {
        this.tableData = response.data.requests
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      reqStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING') {
          return 'Aguardando Aprovação'
        } else if (reqStatus == 'CAM_ISSUED') {
          return 'CAM Emitida'
        } else if (reqStatus == 'SENT') {
          return 'Enviado'
        } else if (reqStatus == 'PROCESSED') {
          return 'Processado OK'
        } else if (reqStatus == 'CANCELED') {
          return 'Cancelado'
        } else if (reqStatus == 'CANCELED_BY_ERROR') {
          return 'Cancelado por Erro'
        }
        return '??'
      },
      generateExcel() {
        callWs("/bo/exchange/generate-cam-excel",
          "POST", null, true, null, this.tableData,
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar a CAM, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "CAM220.xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: 'Erro ao gerar a CAM, tente novamente!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      loadExchangeData(data) {
        this.exchangeData.beneficiary = data.beneficiary
        this.exchangeData.country = data.country
        this.exchangeData.destinationBankCode = data.destinationBankCode
        this.exchangeData.destinationBankName = data.destinationBankName
        this.exchangeData.destinationAgency = data.destinationAgency
        this.exchangeData.destinationAgencyDv = data.destinationAgencyDv
        this.exchangeData.destinationAccountNumber = data.destinationAccountNumber
        this.exchangeData.destinationAccountNumberDv = data.destinationAccountNumberDv
        this.exchangeData.currency = data.currency
        this.exchangeData.foreignCurrencyValue = data.foreignCurrencyValue
        this.exchangeData.priceQuote = data.priceQuote
        this.modal.exchangeData = true
      },
      expandRow(row) {
        if (this.lastExpandedRow != null) {
          this.$refs.trTable.toggleRowExpansion(this.lastExpandedRow, false);
          this.value = 0;
        }
        if (this.lastExpandedRow == row) {
          this.$refs.trTable.toggleRowExpansion(row, false);
          this.lastExpandedRow = null
          this.value = 0;
        } else {
          this.lastExpandedRow = row
          this.$refs.trTable.toggleRowExpansion(row, true);
        }
      },
      updateStatus(id, status) {
        postWs("/bo/exchange/update-status",
          true, null,
          {
            id: id,
            status: status
          }, this.successUpdateStatus, this.failUpdateStatus)
      },
      failUpdateStatus(error) {
        swal({
          title: 'Erro',
          text: "Erro ao atualizar a transação.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      successUpdateStatus(response) {
        swal({
          title: 'Sucesso',
          text: "Status atualizado com sucesso!",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'success'
        })
        this.loadData()
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
