<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Extratos de Contas</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Cartão Pré-Pago">
              <el-select
                class="select-default"
                v-model="search.hasCard"
                placeholder="">
                <el-option
                  class="select-default"
                  v-for="item in [true, false, null]"
                  :key="item"
                  :label="getLabelItem(item)"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-1">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [25,50,75,100]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário / E-Mail / CPF">
                <template slot-scope="props">
                    <span><b>@{{props.row.username}}</b></span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>{{props.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Saldo Total Conta">
                <template slot-scope="props">
                    <span>R$ {{toMoney(props.row.availableFunds)}}</span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Saldo Bloqueado Conta">
                <template slot-scope="props">
                    <span>R$ {{toMoney(props.row.blockedFunds)}}</span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Saldo Total Cartões">
                <template slot-scope="props">
                    <span>R$ {{toMoney(props.row.cardFunds)}}</span>
                </template>
              </el-table-column>
              <el-table-column :width="220" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Ver extrato da conta" v-if="isSomeAuthorization(['BO_TRANSACTIONS_HISTORY', 'BO_LIST_STATEMENT_MANAGER'])"
                            @click="openAccountStament(props.row.username)">
                    <i class="fas fa-money-bill-transfer"></i>
                  </p-button>
                  <p-button type="warning" size="sm" icon title="Ver extrato de cartões" v-if="props.row.haveCard && isSomeAuthorization(['BO_TRANSACTIONS_HISTORY', 'BO_LIST_STATEMENT_MANAGER'])"
                            @click="openCreditCardStament(props.row.username)">
                    <i class="fa-regular fa-credit-card"></i>
                  </p-button>
                  <p-button type="success" size="sm" icon title="Ver extrato de Pix de Entrada" v-if="isSomeAuthorization(['BO_TRANSACTIONS_HISTORY', 'BO_LIST_STATEMENT_MANAGER'])"
                            @click="openPixInStatement(props.row.username)">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                  </p-button>
                  <p-button type="danger" size="sm" icon title="Ver extrato de Pix de Saída" v-if="isSomeAuthorization(['BO_TRANSACTIONS_HISTORY', 'BO_LIST_STATEMENT_MANAGER'])"
                            @click="openPixOutStatement(props.row.username)">
                    <i class="fa-solid fa-money-bill-trend-up"></i>
                  </p-button>
                  <p-button type="muted" size="sm" icon title="Ver extrato de Webhook" v-if="isSomeAuthorization(['BO_TRANSACTIONS_HISTORY', 'BO_LIST_STATEMENT_MANAGER'])"
                            @click="openWebhookStatement(props.row.username)">
                    <i class="fa-solid fa-globe"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"
  import {toMoney} from "@/util/core.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          key: '',
          hasCard: null
        },
        tableData: [],
        pagination: {
          perPage: 50,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      isSomeAuthorization,
      resetDefaultValues(){
        this.search.key = ''
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/statement/list-account",
          true, null,
          {
              key: this.search.key,
              hasCard: this.search.hasCard,
              pageNumber: (this.pagination.currentPage - 1),
              pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      getLabelItem(item){
        if(item == null){
          return 'Todos'
        }
        if(item){
          return 'Possui'
        }else{
          return 'Não possui'
        }
      },
      openAccountStament(username){
        this.openWindow('/transactions/byperiod/' + username)
      },
      openCreditCardStament(username){
        this.openWindow('/credit-card/transactions/' + username)
      },
      openPixInStatement(username){
        this.openWindow('/transactions/pix/in/' + username)
      },
      openPixOutStatement(username){
        this.openWindow('/transactions/pix/out/' + username)
      },
      openWebhookStatement(username){
        this.openWindow('/transactions/webhook/' + username)
      },
      docStatusToLabel(documentStatus) {
        if (documentStatus == null) {
          return 'PE*'
        } else if (documentStatus == 'PENDING_DOCUMENT') {
          return 'PE'
        } else if (documentStatus == 'PENDING_VALIDATION') {
          return 'AV'
        } else if (documentStatus == 'APPROVED') {
          return 'OK'
        }
        return '??'
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>

<style lang="scss" scoped>
  .dzs-acc-search .el-table .td-actions button.btn {
    margin-right: 0px;
  }
</style>
