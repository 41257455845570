<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Buscar Região</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Nome</label>
                <fg-input v-model="search.name">
                </fg-input>
              </div>
              <div class="col-md-2">
                <fg-input label="Status">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="search.enabled">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value=true
                               class="select-default"
                               label="Habilitado"/>
                    <el-option value=false
                               class="select-default"
                               label="Desabilitado"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-4">
                <p-button type="primary" style="margin-top: 25px;"
                          @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Filtrar
                </p-button>
                <p-button style="margin-top: 25px;"
                          @click="addRegion = true" type="info">
                  <i slot="label" class="fas fa-plus"></i>
                  Adicionar
                </p-button>
              </div>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="row" v-if="addRegion">
              <div class="col-md-12" style="text-align: center">
                <div class="row">
                  <div class="col-md-6 m-auto" style="text-align: center; width: 50%">
                    <label class="control-label" >Nome</label>
                    <fg-input style="width: 50%" class="m-auto" v-model="regionToAdd.name">
                    </fg-input>
                  </div>
                </div>
                <div class = "row">
                  <div class="col-md-12" style="text-align: center">
                    <p-button type="success" style="margin-top: 25px;"
                              @click="doAddRegion">
                      <i slot="label" class="far fa-floppy-disk"></i>
                      Adicionar
                    </p-button>
                    <p-button type="danger" style="margin-top: 25px;"
                              @click="clearRegionToAdd">
                      <i slot="label" class="nc-icon nc-simple-remove"></i>
                      Cancelar
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 mt-2 m-auto">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%">
                  <el-table-column :min-width="60" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Ativo">
                    <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.enabled?'6bd098':'ef8157')"></i>
                      <span> {{props.row.enabled?'Sim':'Não'}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="230" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="warning" size="sm" icon title="Ativar/Desativar"
                                @click="enableOrDisable(props.row)">
                        <i class="fas fa-check-circle"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Modal
    },
    data() {
      return {
        search: {
          name: '',
          enabled: null,
        },
        tableData: [],
        regionToAdd: {
          name: null,
          enabled: true
        },
        addRegion: false
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      resetDefaultValues(){
        this.search.key = ''
        this.search.enabled = null,
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.loadData()
      },
      loadData() {
        postWs("/bo/commercial/salesperson/regions",
          true, null,
          {
            name: this.search.name,
            enabled: this.search.enabled,
          }, this.successLoadData,
          failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
      },
      clearRegionToAdd(){
        this.addRegion = false;
        this.regionToAdd.name = null
      },
      searchUser() {
        this.clearRegionToAdd()
        postWs("/acc/find",
          true, {username: this.search.key, self: true}, null,
          (response) => {
            this.username = this.search.key
            this.userToAdd.destinationName = response.data.destinationName
            this.userToAdd.destinationSocialNumber = response.data.destinationSocialNumber
            this.userToAdd.email = response.data.email
          }, (error) => {
            this.clearRegionToAdd();
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada para receber transações.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }
          })
      },
      doAddRegion(){
        postWs("/bo/commercial/salesperson/region",
          true, null,
          {
            name: this.regionToAdd.name,
            enabled: true,
          }, response => {
            this.clearRegionToAdd()
            this.loadData()
          },
          failWs)
      },
      enableOrDisable(region) {
        swal({
          title: 'Ativar / Desativar',
          text: "Você deseja ativar ou desativar o vendedor?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ativar',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'Desativar',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callEnabledWs(region, true)
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.callEnabledWs(region, false)
          }
        })
      },
      callEnabledWs(region, status) {
        postWs("/bo/commercial/salesperson/region",
          true, null,
          {name: region.name, enabled: status},
          function () {
            region.enabled = status
          }, failWs)
      },

    }
  }
</script>
<style lang="scss" scoped>

</style>
