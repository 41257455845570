<template>
  <form @submit.prevent="onFormSubmit" class="card edit-card">
    <div class="card-header">
      <div class="row">
        <div class="col-12">
          <h4>Envio de Placa - @{{ plateRequest.username }}</h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <form class="row">
        <div class="col-3">
          <fg-input
            label="CEP"
            v-model="deliveryAddress.zipCode"
            v-mask="['#####-###']"
            v-validate="{required: true}"
            @change="searchZipCode"
            :error="getError('CEP')"
            data-vv-name="CEP"
          ></fg-input>
        </div>
        <div class="col-6">
          <fg-input
            label="Cidade"
            v-model="deliveryAddress.city"
            v-validate="{required: true}"
            :error="getError('Cidade')"
            data-vv-name="Cidade"
            disabled
          ></fg-input>
        </div>
        <div class="col-3">
          <fg-input
            label="Estado"
            v-model="deliveryAddress.state"
            v-validate="{required: true}"
            :error="getError('Estado')"
            data-vv-name="Estado"
            disabled
          ></fg-input>
        </div>
        <div class="col-5">
          <fg-input
            label="Bairro"
            v-model="deliveryAddress.neighborhood"
            v-validate="{required: true}"
            :error="getError('Bairro')"
            data-vv-name="Bairro"
          ></fg-input>
        </div>
        <div class="col-5">
          <fg-input
            label="Rua"
            v-model="deliveryAddress.street"
            v-validate="{required: true}"
            :error="getError('Rua')"
            data-vv-name="Rua"
          ></fg-input>
        </div>
        <div class="col-2">
          <fg-input
            label="Número"
            v-model="deliveryAddress.number"
            v-validate="{required: true}"
            :error="getError('Número')"
            data-vv-name="Número"
          ></fg-input>
        </div>
        <div class="col-6">
          <fg-input
            label="Complemento"
            v-model="deliveryAddress.complement"
            v-validate="{required: true}"
            :error="getError('Complemento')"
            data-vv-name="Complemento"
          ></fg-input>
        </div>
        <div class="col-6">
          <fg-input
            label="Whatsapp"
            v-model="phoneNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-validate="{required: true}"
            :error="getError('Whatsapp')"
            data-vv-name="Whatsapp"
          ></fg-input>
        </div>
      </form>
      <div class="row">
        <div class="col-12">
          <p-button type="success" class="float-right" native-type="submit">Editar</p-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import swal from "sweetalert2";
import {postWs, getWs} from "@/ws.service";

export default {
  name: 'edit-plate-shipment-info-form',
  props: ['plateRequest'],
  emits: ['save'],
  data() {
    return {
      deliveryAddress: {
        zipCode: '',
        codIbge: '',
        cep: '',
        street: '',
        city: '',
        state: '',
        number: '',
        neighborhood: '',
        complement: '',
      },
      phoneNumber: null
    }
  },
  mounted() {
    this.setCurrentAddress()
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onFormSubmit() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.submit()
        }
      })
    },
    submit() {
      postWs('/bo/suitjourney/update-plate-shipment-info', true, null,
        {
          plateRequestId: this.plateRequest.id,
          deliveryAddress: this.deliveryAddress,
          phoneNumber: this.phoneNumber
        },
        this.successSubmit,
        this.failSubmit)
    },
    successSubmit() {
      swal({
        title: 'Sucesso!',
        text: 'Dados de envio atualizados com sucesso!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.$emit('save')
    },
    failSubmit() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao registrar envio da placa. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    setCurrentAddress() {
      this.deliveryAddress.zipCode = this.plateRequest.deliveryAddress.zipCode
      this.deliveryAddress.codIbge = this.plateRequest.deliveryAddress.codIbge
      this.deliveryAddress.cep = this.plateRequest.deliveryAddress.cep
      this.deliveryAddress.street = this.plateRequest.deliveryAddress.street
      this.deliveryAddress.city = this.plateRequest.deliveryAddress.city
      this.deliveryAddress.state = this.plateRequest.deliveryAddress.state
      this.deliveryAddress.number = this.plateRequest.deliveryAddress.number
      this.deliveryAddress.neighborhood = this.plateRequest.deliveryAddress.neighborhood
      this.deliveryAddress.complement = this.plateRequest.deliveryAddress.complement
      this.phoneNumber = this.plateRequest.phoneNumber
    },
    searchZipCode() {
      this.viacep = null
      this.deliveryAddress.codIbge = ""
      this.deliveryAddress.city = ""
      this.deliveryAddress.state = ""
      getWs("/cep/get-cep",
        true, {cep: this.deliveryAddress.zipCode},
        this.successSearchZipCode,
        this.failSearchZipCode)
    },
    successSearchZipCode(response) {
      this.viacep = response.data
      this.deliveryAddress.codIbge = this.viacep.ibge
      if (this.viacep.logradouro.length > 40) {
        this.deliveryAddress.street = this.viacep.logradouro.substr(0, 40)
      } else {
        this.deliveryAddress.street = this.viacep.logradouro
      }
      if (this.viacep.bairro.length > 40) {
        this.deliveryAddress.neighborhood = this.viacep.bairro.substr(0, 40)
      } else {
        this.deliveryAddress.neighborhood = this.viacep.bairro
      }
      this.deliveryAddress.city = this.viacep.localidade
      this.deliveryAddress.state = this.viacep.uf
    },
    failSearchZipCode() {
      swal({
        title: 'Aviso!',
        text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
        buttonsStyling: true,
        timer: 10000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'warning'
      })
    },
  }
}
</script>
<style lang="scss">
.edit-card {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;

  .el-select .el-input input {
    background-color: #FFFFFF !important;
  }
}
</style>
