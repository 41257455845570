<template>
  <div class="dzs-compliance">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">Realizar Upload de Planilha</h4>
          <div class="row">
            <form class="row col-md-12 align-items-end" @submit.prevent="uploadSpreadsheet">
              <div class="col-lg-4">
                <label for="spreadsheetFile">Planilha</label>
                <label for="spreadsheetFile" class="btn spreadsheet-label">
                  <i class="fa-regular fa-image"></i> {{ spreadsheetFileLabelText }}
                </label>
                <input id="spreadsheetFile" ref="spreadsheetFileIpt" type="file" name="spreadsheetFile" hidden
                       @input="onUpdateSpreadsheetFileInput" data-vv-as="Arquivo da Planilha" v-validate="{required: true}"/>
                <fg-input type="file" :error="getError('spreadsheetFile')"></fg-input>
              </div>
              <div class="col-lg-3">
                <fg-input label="Banco Emissor" class="has-danger">
                  <el-select
                    class="select-default"
                    id="issuingBank"
                    name="issuingBank"
                    v-model="uploadMedSpreadsheetRequest.issuingBankId"
                    v-validate="{required: true}"
                    data-vv-as="Banco Emissor"
                  >
                    <el-option class="select-default" v-for="bank in issuingBanks" :key="bank.id" :value="bank.id"
                               :label="bank.name"></el-option>
                  </el-select>
                  <div class="invalid-feedback error-text" v-if="getError('issuingBank')">
                    {{ getError('issuingBank') }}
                  </div>
                </fg-input>
              </div>
              <div class="col-lg-1">
                <button class="btn" type="submit">Enviar</button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="margin: 0 auto">
        <card>
          <div class="card-header row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="search.startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="search.endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input label="Banco Emissor" class="has-danger">
                <el-select
                  class="select-default"
                  id="issuingBank"
                  name="issuingBank"
                  v-model="search.issuingBankId"
                >
                  <el-option class="select-default" value=""
                             label="Todos"></el-option>
                  <el-option class="select-default" v-for="bank in issuingBanks" :key="bank.id" :value="bank.id"
                             :label="bank.name"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [50, 100, 250, 500]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="searchUploadedSpreadsheets" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
            <div class="col-md-12" v-if="this.selectedSpreadsheetIds.length > 0">
              <p-button @click.prevent="exportSelectedSpreadsheetsToPdf" style="margin-top: 24px">
                <i class="fa-regular fa-file-pdf"></i> Exportar PDF
              </p-button>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="uploadedSpreadsheets"
                        @selection-change="handleTableSelectionChange"
                        style="width: 100%">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="Arquivo">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.path }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Banco">
                  <template slot-scope="item">
                    <span>{{ item.row.bankName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Data de Upload">
                  <template slot-scope="item">
                    <span>{{ formatDate(item.row.createdDate, 'DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>
                <el-table-column :width="40" label="">
                  <template slot-scope="item">
                    <span>
                      <p-button type="success" size="sm" icon title="Baixar Planilha"
                                @click="downloadSpreadsheet(item.row)">
                        <i class="fas fa-download"/>
                      </p-button>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.searchUploadedSpreadsheets">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs} from "src/ws.service"
import {toMoney} from 'src/util/core.utils'
import {daysBetween, formatDate} from "@/util/date.utils";
import swal from "sweetalert2";
import download from "downloadjs";
import PPagination from "@/components/UIComponents/Pagination.vue";

export default {
  components: {
    PPagination,
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
  },
  data() {
    return {
      search: {
        startDate: '',
        endDate: '',
        issuingBankId: '',
      },
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      spreadsheetFileLabelText: 'Selecionar Arquivo',
      uploadedSpreadsheets: [],
      uploadMedSpreadsheetRequest: {
        issuingBankId: '',
      },
      issuingBanks: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      selectedSpreadsheetIds: []
    }
  },
  mounted() {
    this.getIssuingBanks()
    this.searchUploadedSpreadsheets()
  },
  methods: {
    formatDate,
    toMoney,
    validatePeriod() {
      this.search.endDate = this.search.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.search.startDate
    },
    uploadSpreadsheet() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          const formData = new FormData()
          const spreadsheetFile = this.$refs.spreadsheetFileIpt.files[0]
          formData.append('spreadsheetFile', spreadsheetFile)

          callWs('/bo/compliance/upload-spreadsheet', 'POST', {'Content-Type': 'multipart/form-data'},
            true,
            this.uploadMedSpreadsheetRequest,
            formData,
            this.successUploadSpreadsheet,
            this.failUploadSpreadsheet
          )
        }
      })
    },
    successUploadSpreadsheet() {
      swal({
        title: 'Sucesso!',
        text: 'A planilha foi carregada com sucesso!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      }).catch(swal.noop)
      this.searchUploadedSpreadsheets()
    },
    failUploadSpreadsheet() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao carregar a planilha. Verifique os dados e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getIssuingBanks() {
      callWs('/bo/compliance/get-issuing-banks', null, null, true, null, null,
        this.successGetIssuingBanks,
        () => null
      )
    },
    successGetIssuingBanks(response) {
      this.issuingBanks = response.data
    },
    searchUploadedSpreadsheets() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      callWs('/bo/compliance/uploaded-spreadsheets', 'POST', null, true, null, {
          startDate: this.search.startDate ? formatDate(this.search.startDate, 'DD/MM/YYYY') : '',
          endDate: this.search.endDate ? formatDate(this.search.endDate, 'DD/MM/YYYY') : '',
          issuingBankId: this.search.issuingBankId,
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage,
        },
        this.successSearchUploadedSpreadsheets,
        this.failSearchUploadedSpreadsheets)
    },
    successSearchUploadedSpreadsheets(response) {
      this.uploadedSpreadsheets = response.data.list
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.uploadedSpreadsheets.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
    },
    failSearchUploadedSpreadsheets() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar por planilhas salvas.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    downloadSpreadsheet(spreadsheetFile) {
      callWs('/bo/compliance/download-spreadsheet', 'POST', null, true, null,
        {
          path: spreadsheetFile.path,
          discToSaveFile: spreadsheetFile.discToSaveFile,
          storageType: spreadsheetFile.storageType,
          bankName: spreadsheetFile.bankName
        },
        (response) => this.successDownloadSpreadsheet(response, spreadsheetFile.path),
        this.failDownloadSpreadsheet
      )
    },
    successDownloadSpreadsheet(response, fileName) {
      try {
        download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response.data, fileName, "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      } catch (ex) {
        swal({
          title: 'Atenção!',
          text: 'Ocorreu um erro ao realizar o download do arquivo. Tente Novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failDownloadSpreadsheet() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao realizar o download do arquivo. Tente Novamente!',
        buttonsStyling: true,
        timer: 10000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    exportSelectedSpreadsheetsToPdf() {
      callWs('/bo/compliance/spreadsheet-med-pdf', 'POST', null, true, null, {
          spreadsheetIds: this.selectedSpreadsheetIds
        },
        this.successExportSelectedSpreadsheetsToPdf,
        this.failExportSelectedSpreadsheetsToPdf)
    },
    successExportSelectedSpreadsheetsToPdf(response) {
      try {
        let pdfWindow = window.open("about:blank");
        pdfWindow.document.write("<html<head><title>SuitPay - Solicitações MED</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
      } catch (ex) {
        swal({
          title: 'Aviso!',
          text: "Para a exibição do pdf, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failExportSelectedSpreadsheetsToPdf(error) {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao exportar PDF. Tente Novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    onUpdateSpreadsheetFileInput(event) {
      this.spreadsheetFileLabelText = event.target.files[0].name
    },
    handleTableSelectionChange(selectedRows) {
      this.selectedSpreadsheetIds = selectedRows.map(el => el.id)
    }
  }
}
</script>
<style lang="scss">
.dzs-compliance {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }

  .spreadsheet-label {
    color: #FFFFFF;
    display: block;
    margin-top: 0px;
  }
}
</style>
