<template>
  <div class="dzs-acc-cancel">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Relatório Analítico de Vendas Online</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-2">
            <div class="row" style="margin-left: 0px">
              <label>Data Início</label>
            </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="search.startDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsStart" @input="validatePeriod"
                              format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row" style="margin-left: 0px">
              <label>Data Fim</label>
            </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="search.endDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsEnd"
                              format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [500, 1000, 2000]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-3">
            <fg-input label="Meio de Vendas">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.type">
                <el-option :value="''"
                           class="select-default"
                           label="Todos"/>
                <el-option value="gateway"
                           class="select-default"
                           label="Gateway/Checkout"/>
                <el-option value="link"
                           class="select-default"
                           label="Link de Pagamento"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-3">
            <fg-input label="Vendedores">
              <el-select
                size="large"
                v-model="search.salePerson"
                class="select-default"
                placeholder="Vendedor">
                <el-option
                  class="select-default"
                  v-for="item in salePersons"
                  :key="item.key"
                  :label="item.key==''?item.username:item.enabled==true?item.username+' (ativo)':item.username+' (inativo)'"
                  :value="item.key">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.username"/>
          </div>
          <div class="col-md-4">
            <label class="control-label">ID Externo</label>
            <fg-input v-model="search.externalId"/>
          </div>
          <div class="col-md-3">
            <fg-input type="text"
                      label="CPF/CNPJ Comprador"
                      v-mask="['###.###.###-##','##.###.###/####-##']"
                      v-model="search.clientDocument">
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span>@{{props.row.username}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="85" label="Data Pag.">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.paidDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Link/Gateway">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.type}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Pagamento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.status}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Vlr Pago">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.paidValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.statusTransaction}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Vendedor">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.salePerson}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="primary" size="sm" icon title="Dados da Venda"
                            @click="loadSaleData(props.row)">
                    <i class="fa-solid fa-search"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal"
           footerClasses="justify-content-center"
           type="notice">
      <h6 style="text-align: center"><b>Dados da Venda</b></h6>
      <p>Nome do Comprador: {{sale.clientName}}</p>
      <p>Documento do Comprador: {{maskDocument(sale.clientDocument)}}</p>
      <p>Telefone do Comprador: {{maskPhone(sale.clientPhone)}}</p>
      <p>E-mail do Comprador: {{sale.clientEmail}}</p>
      <p>Tipo de Pagamento: {{sale.status}}</p>
      <p>Data do Pagamento: {{sale.paidDate}}</p>
      <p>Valor da Venda: R$ {{toMoney(sale.value)}}</p>
      <p>Valor Pago: R$ {{toMoney(sale.paidValue)}}</p>
      <p>Quantidade de Parcelas: {{sale.installments}}</p>
      <p>ID Externo: {{sale.externalId}}</p>
      <p>Produtos: {{sale.products}}</p>
    </modal>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, isNullOrEmpty, notNull} from "src/util/core.utils"
  import {formatDate} from "src/util/date.utils"
  import {DatePicker} from 'element-ui'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
      Modal
    },
    data() {
      return {
        modal: false,
        sale: {
          clientName: '',
          clientDocument: '',
          clientPhone: '',
          clientEmail: '',
          value: 0,
          paidValue: 0,
          installments: '',
          externalId: '',
          products: '',
          status: '',
          paidDate: '',
        },
        search: {
          startDate: new Date(),
          endDate: new Date(),
          username: '',
          salePerson: '',
          clientDocument: '',
          externalId: '',
          type: ''
        },
        cancel: {
          description: '',
        },
        tableData: [],
        pagination: {
          perPage: 500,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        salePersons: [{
          username: '',
          enabled: ''
        }],
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    created() {
      this.loadDataSalePersons()
    },

    methods: {
      toMoney,
      isNullOrEmpty,
      resetDefaultValues(){
        this.search.requestNumber = ''
        this.search.document = ''
        this.search.saleDate = ''
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/gateway/sales-report",
          true, null,
          {
            username: this.search.username,
            salePerson: this.search.salePerson,
            clientDocument: this.search.clientDocument,
            externalId: this.search.externalId,
            type: this.search.type,
            startDate: formatDate(this.search.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.search.endDate, "DD/MM/YYYY"),
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          (error) => {
            let text = 'Erro ao carregar os dados, tente novamente!'
            if(error.response.status == 404) {
              text = "Transação não encontrada para os dados informados!"
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.tableData = []
          })
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      fail(){
        swal('Erro', 'Erro ao carregar os dados, tente novamente!', 'error')
      },
      validatePeriod() {
        this.search.endDate = this.search.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.search.startDate
      },
      maskPhone(phone) {
        if(phone.length == 11) {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 5) + '-' + phone.substr(7, 4)
        } else {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 4) + '-' + phone.substr(6, 4)
        }
      },
      maskDocument(document) {
        if(document.length == 11) {
          return document.substr(0, 3) + '.' + document.substr(3, 3) + '.' + document.substr(6, 3) + '-' + document.substr(9, 2)
        } else {
          return '(' + document.substr(0, 2) + ') ' + document.substr(2, 4) + '-' + document.substr(6, 4)
        }
      },
      loadSaleData(sale) {
        this.sale.clientName = sale.clientName
        this.sale.clientDocument = sale.clientDocument
        this.sale.clientPhone = sale.clientPhone
        this.sale.clientEmail = sale.clientEmail
        this.sale.value = sale.value
        this.sale.paidValue = sale.paidValue
        this.sale.installments = sale.installments
        this.sale.externalId = sale.externalId
        this.sale.products = sale.products
        this.sale.status = sale.status
        this.sale.paidDate = sale.paidDate
        this.modal = true
      },
      loadDataSalePersons() {
        postWs("/bo/gateway/list-sale-persons",
          true, null, {}, this.successLoadSalePersons,
          (error) => {
            let text = 'Erro ao carregar os dados, tente novamente!'
            if(error.response.status == 404) {
              text = "Transação não encontrada para os dados informados!"
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.tableData = []
          })
        return this.pagination.currentPage
      },
      successLoadSalePersons(response) {
        this.salePersons = response.data
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-cancel {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .modal-content {
      width: 600px;
    }
  }
</style>
