<template>
  <div class="dzs-compliance-negative-funds">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Relatório de Saldo Negativo
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <fg-input type="text"
                          label="Usuário"
                          name="usuario"
                          v-model="username"
                          key="username-display">
                </fg-input>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input label="Ordernar por">
                <el-select
                  class="select-default"
                  v-model="sorting"
                  placeholder="Ordernar por">
                  <el-option
                    class="select-default"
                    v-for="item in sortingOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [100,250,500]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
          <div class="row" v-if="tableData.length > 0">
            <div class="col-md-2">
              <p-button @click.prevent="generateExcel" style="margin-top: 24px">
                <i class="fas fa-file-invoice"></i> Gerar excel
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center text-success">
                  <i class="fa-solid fa-user"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category ">Qtd. de usuários negativados</p>
                  <p class="card-title ">{{ qtyUsersWithNegativeFunds }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center text-success">
                  <i class="fa-solid fa-money-bill-wave"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category ">Saldo total negativado</p>
                  <p class="card-title ">R$ {{ toMoney(negativeBalanceValue) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="tableData.length>0" style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%"
                        ref="tableRef"
              >
                <el-table-column :min-width="60" label="Usuário" prop="username" sortable>
                  <template slot-scope="item">
                    <span>
                      <b>@{{ item.row.username }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="45" label="Saldo" prop="availableFunds" sortable>
                  <template slot-scope="item">
                    <span>R$ {{ toMoney(item.row.availableFunds) }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Última Transação" prop="lastTransactionDate" sortable>
                  <template slot-scope="item">
                    <span>
                      <span>{{ formatDate(item.row.lastTransactionDate, 'DD/MM/YYYY') }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="90" label="Dias com saldo negativado" prop="daysWithNegativeBalance" sortable>
                  <template slot-scope="item">
                    <span>
                      {{ item.row.daysWithNegativeBalance }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{ pagination.fromNumber }} AO {{ pagination.toNumber }}, DE
                {{ pagination.totalNumber }} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.search">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs, postWs} from "src/ws.service"
import {daysBetween, formatDate} from "src/util/date.utils"
import {toMoney} from 'src/util/core.utils'
import swal from 'sweetalert2'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import DescribeRequestsForm from "@/components/Dashboard/Views/BackOffice/Compliance/Forms/DescribeRequestsForm.vue";
import download from "downloadjs";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
    PPagination,
    DescribeRequestsForm
  },
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
      tableData: [],
      qtyUsersWithNegativeFunds: 0,
      negativeBalanceValue: 0,
      username: null,
      currentBalance: 0,
      currentActiveModal: undefined,
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
      pagination: {
        perPage: 100,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      sorting: 'available_funds_asc',
      sortingOptions: [
        { label: 'Usuário', value: 'username' },
        { label: 'Saldo - Crescente', value: 'available_funds_asc' },
        { label: 'Saldo - Decrescente', value: 'available_funds_desc' },
        { label: 'Última Transação - Mais antigo', value: 'created_date_asc' },
        { label: 'Última Transação - Mais recente', value: 'created_date_desc' },
      ]
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    formatDate,
    toMoney,
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    disabledEndDate(date) {
      let now = new Date()
      return date > now || date < this.startDate
    },
    search() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      callWs('/bo/compliance/negative-funds', 'POST', null, true, null,
        {
          pageNumber: this.pagination.currentPage - 1,
          pageSize: this.pagination.perPage,
          startDate: this.startDate ? formatDate(this.startDate, 'DD/MM/YYYY') : '',
          endDate: this.endDate ? formatDate(this.endDate, 'DD/MM/YYYY') : '',
          username: this.username,
          sorting: this.sorting
        },
        this.successSearch,
        this.failSearch)
    },
    successSearch(response) {
      this.tableData = response.data.list
      this.qtyUsersWithNegativeFunds = response.data.qtyUsersWithNegativeFunds
      this.negativeBalanceValue = response.data.negativeBalanceValue
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
      if (this.tableData.length <= 0) {
        swal({
          title: 'Não encontrado',
          text: 'No período selecionado não existem usuários com saldo negativo.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failSearch() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar relatório com saldos negativos. Verifique os filtros e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    reversePix(medRequest) {
      swal({
        title: 'Atenção!',
        text: `Deseja mesmo solicitar o estorno da transação (ourNumber: ${medRequest.ourNumber})?`,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        showCancelButton: true
      }).then(result => {
        if (result) {
          postWs('/bo/compliance/reverse-pix', true, null, {
              date: medRequest.date,
              bankName: medRequest.bankName,
              status: medRequest.status,
              ourNumber: medRequest.ourNumber,
              endToEnd: medRequest.endToEnd,
              value: medRequest.value
            },
            this.successReversePix,
            this.failReversePix
          )
        }
      }).catch(swal.noop)
    },
    generateExcel() {
      callWs("/bo/compliance/generate-spreadsheet",
        "POST", null, true, null,
        {
          pageNumber: this.pagination.currentPage - 1,
          pageSize: null,
          startDate: this.startDate ? formatDate(this.startDate, 'DD/MM/YYYY') : '',
          endDate: this.endDate ? formatDate(this.endDate, 'DD/MM/YYYY') : '',
          username: this.username,
          sorting: this.sorting
        },
        this.successGenerateExcel,
        this.failGenerateExcel
      )
    },
    successGenerateExcel(response) {
      try {
        download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
          "SaldoNegativo.xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      } catch (ex) {
        swal({
          title: 'Atenção!',
          text: 'Erro ao gerar arquivo Excel. Tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failGenerateExcel() {
      swal({
        title: 'Atenção!',
        text: 'Erro ao gerar arquivo Excel. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
  }
}
</script>
<style lang="scss">
.dzs-compliance-negative-funds {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }

  .card-header {
    .btn {
      margin: 0;
    }
  }
}
</style>
