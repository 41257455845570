<template>
  <div class="user">
    <div class="photo">
      <img class="avatar border-gray" :src="loadDocSrc()" v-if="this.showAvatar"
           style="background: #2c2c2c !important; border: 0px solid #2c2c2c !important; height: 100%" alt="user avatar">
      <img src="/static/img/faces/user.png" width="" v-else alt="user avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           @{{this.usernameDisplay}}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed" style="margin-left: 10px">
            <li>
              <a @click="$router.push('/user/profile')" href="#">
                <i class="fas fa-cogs"></i>
                <span class="sidebar-normal">Configurar Conta</span>
              </a>
            </li>
            <li>
              <a @click="$router.push('/user/feesandlimits')" href="#">
                <i class="fas fa-file-invoice"></i>
                <span class="sidebar-normal">Taxas e Serviços</span>
              </a>
            </li>
<!--            <li v-if="!verifiedDevice">-->
<!--              <a @click="$router.push('/user/device')" href="#">-->
<!--                <i class="nc-icon nc-mobile"></i>-->
<!--                <span class="sidebar-normal">Cadastrar Dispositivo</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li v-if="personType === 'LEGAL_PERSON'">-->
<!--              <a @click="$router.push('/user/api')" href="#">-->
<!--                <i class="fas fa-terminal"></i>-->
<!--                <span class="sidebar-normal">Integração Via API</span>-->
<!--              </a>-->
<!--            </li>-->
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import {CollapseTransition} from 'vue2-transitions'
  import {isNullOrEmpty} from "../../../util/core.utils";
  import UserProfile from "../../Dashboard/Views/User/UserProfile";

  export default {
    components: {
      UserProfile,
      CollapseTransition
    },
    data() {
      return {
        usernameDisplay: "",
        verifiedDevice: "",
        personType: "",
        isClosed: true,
        avatarBase64: '',
        avatarFileType: '',
        showAvatar: false
      }
    },
    mounted() {
      this.usernameDisplay = localStorage.getItem("usernameDisplay")
      this.verifiedDevice = localStorage.getItem('verifiedDevice') == 'true'
      this.personType = localStorage.getItem('personType')
      if(isNullOrEmpty(localStorage.getItem('name'))){
        this.isClosed = false
      }
      this.avatarBase64 = localStorage.getItem("avatarBase64")
      this.avatarFileType = localStorage.getItem("avatarFileType")
      if(isNullOrEmpty(this.avatarFileType)){
        this.showAvatar = false
      } else {
        this.showAvatar = true
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      loadDocSrc() {
        let fileType = this.avatarFileType.replace(" ", "").toLowerCase()
        if (fileType == 'jpg') {
          return 'data:image/jpeg;base64,' + this.avatarBase64
        }
        return 'data:image/' + fileType + ';base64,' + this.avatarBase64
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
