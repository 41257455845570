<template>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <label class="control-label">Destino</label>
            <p class="form-control-static"><b>{{transfer.destinationName}}</b></p>
        </div>
        <div class="col-md-12">
            <label class="control-label">CPF/CNPJ</label>
            <p class="form-control-static">{{transfer.destinationSocialNumber}}</p>
        </div>
        <div class="col-md-12">
            <label class="control-label">Banco</label>
            <p class="form-control-static">{{transfer.destinationBankName}}</p>
        </div>
        <div class="col-md-12">
            <label class="control-label">Agencia/Conta</label>
            <p class="form-control-static">
                {{transfer.destinationAgency}}/{{transfer.destinationAccountNumber}}-{{transfer.destinationAccountNumberDv}}</p>
        </div>
        <div class="col-md-12">
            <label class="control-label">Valor</label>
            <p class="form-control-static">R$ {{transfer.value}}</p>
        </div>
        <div class="col-md-12">
            <label class="control-label">Descrição</label>
            <p class="form-control-static">{{transfer.description}}</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'dzs-ted-tranfer-details',
        props: {
            transfer: {}
        }
    }
</script>
