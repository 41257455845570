import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'

import Overview from 'src/components/Dashboard/Views/Dashboard/Overview.vue'
import UserProfile from 'src/components/Dashboard/Views/User/UserProfile.vue'
import Device from 'src/components/Dashboard/Views/User/Device.vue'
import Api from 'src/components/Dashboard/Views/User/ApiAccess.vue'
import BoletoDeposit from 'src/components/Dashboard/Views/Deposit/Boleto.vue'
import TevTransfer from 'src/components/Dashboard/Views/Transfer/Tev.vue'
import TedTransfer from 'src/components/Dashboard/Views/Transfer/Ted.vue'
import TransactionsByPeriod from 'src/components/Dashboard/Views/Transactions/ByPeriod.vue'
import TransactionsPixIn from 'src/components/Dashboard/Views/Transactions/PixIn.vue'
import TransactionsPixOut from 'src/components/Dashboard/Views/Transactions/PixOut.vue'
import TransactionsWebhook from 'src/components/Dashboard/Views/Transactions/Webhook.vue'
import TransactionsRequestApi from 'src/components/Dashboard/Views/Transactions/RequestApi.vue'
import TransactionsMovement from 'src/components/Dashboard/Views/Transactions/Movement.vue'
import TransactionsPixPartner from 'src/components/Dashboard/Views/BackOffice/Financial/PixPartner.vue'
import ManageCards from 'src/components/Dashboard/Views/CreditCard/ManageCards.vue'
import ManageCostCenter from 'src/components/Dashboard/Views/CreditCard/ManageCostCenter.vue'
import CardTransactions from 'src/components/Dashboard/Views/CreditCard/CardTransactions.vue'
import RequestCardTerminal from 'src/components/Dashboard/Views/CardTerminal/RequestCardTerminal.vue'
import GenerateRecommendationCode from 'src/components/Dashboard/Views/Recommendation/GenerateRecommendationCode.vue'
import FeesAndLimits from 'src/components/Dashboard/Views/User/FeesAndLimits.vue'
import UploadDoc from 'src/components/Dashboard/Views/User/Profile/UploadDoc'
import CreditTedPage from 'src/components/Dashboard/Views/Transfer/CreditTed.vue'
import PagamentoDeBoleto from 'src/components/Dashboard/Views/Payment/Pay.vue'
import ConsultPlanCardTerminal from 'src/components/Dashboard/Views/CardTerminal/ConsultPlan.vue'
import ConsultTransactions from 'src/components/Dashboard/Views/CardTerminal/ConsultTransactions.vue'
import DepositPix from 'src/components/Dashboard/Views/Pix/DepositPix.vue'
import PaymentPix from 'src/components/Dashboard/Views/Pix/PaymentPix.vue'
import Support from 'src/components/Dashboard/Views/Support/Support.vue'
import ManageNotifications from 'src/components/Dashboard/Views/Notifications/ManageNotifications.vue'
import ManageCollaborators from 'src/components/Dashboard/Views/Collaborators/Manage.vue'
import CollaboratorsArea from 'src/components/Dashboard/Views/Collaborators/Search.vue'


import BoAccountSearchNatural from 'src/components/Dashboard/Views/BackOffice/Account/Search.vue'
import BoAccountSearchLegal from 'src/components/Dashboard/Views/BackOffice/Account/Search.vue'
import BoAccountEdit from 'src/components/Dashboard/Views/BackOffice/Account/Edit.vue'
import BoAccountSearchApproval from 'src/components/Dashboard/Views/BackOffice/Account/Approval.vue'
import BoOperationLimitGlobal from 'src/components/Dashboard/Views/BackOffice/OperationLimit/GlobalOperationLimit.vue'
import BoOperationLimitUser from 'src/components/Dashboard/Views/BackOffice/OperationLimit/UserOperationLimit.vue'
import BoManageCreditTed from 'src/components/Dashboard/Views/BackOffice/CreditTed/ManageCreditTed.vue'
import BoEditCreditTed from 'src/components/Dashboard/Views/BackOffice/CreditTed/EditCreditTed.vue'

import BoCommercialSalesPersonManager from 'src/components/Dashboard/Views/BackOffice/Commercial/ManageSalesPerson.vue'
import BoCommercialRegionManage from 'src/components/Dashboard/Views/BackOffice/Commercial/ManageSalesRegion.vue'
import BoCommercialLevelManage from 'src/components/Dashboard/Views/BackOffice/Commercial/ManageSalePersonLevels.vue'
import BoCommercialManagerManage from 'src/components/Dashboard/Views/BackOffice/Commercial/ManageSalesManager.vue'
import BoCommercialClientDetail from 'src/components/Dashboard/Views/BackOffice/Commercial/ClientDetailsSalePerson.vue'
import BoCommercialSalePersonDashboard
  from 'src/components/Dashboard/Views/BackOffice/Commercial/SalePersonDashboard.vue'
import BoCommercialCommissionsPayment from 'src/components/Dashboard/Views/BackOffice/Commercial/CommissionPayment.vue'
import BoSalesReportBySalePerson from 'src/components/Dashboard/Views/BackOffice/Commercial/SalesReportBySalePerson.vue'
import BoUserOnlineReportBySalePerson
  from 'src/components/Dashboard/Views/BackOffice/Commercial/UserOnlineReportBySalePerson.vue'
import BoCommercialClientSalePerson
  from 'src/components/Dashboard/Views/BackOffice/Commercial/ManageClientSalePerson.vue'
import CommercialClientsLastMonthsTransactions from "@/components/Dashboard/Views/BackOffice/Commercial/SalesPersonClientsLastMonthsTransactions.vue";

import BoComplianceFlaggedUsers from 'src/components/Dashboard/Views/BackOffice/Compliance/FlaggedUsers.vue'
import BoComplianceSpreadsheet from 'src/components/Dashboard/Views/BackOffice/Compliance/Spreadsheet.vue'
import BoComplianceMed from 'src/components/Dashboard/Views/BackOffice/Compliance/Med.vue'
import BoComplianceMedUser from 'src/components/Dashboard/Views/BackOffice/Compliance/MedUser.vue'
import BoComplianceNegativeFunds from 'src/components/Dashboard/Views/BackOffice/Compliance/NegativeFunds.vue'

import BoRegisterRole from 'src/components/Dashboard/Views/BackOffice/Role/RegisterRole.vue'
import BoRegisterRolePack from 'src/components/Dashboard/Views/BackOffice/Role/RegisterRolePack.vue'
import BoManageUsersRole from 'src/components/Dashboard/Views/BackOffice/Role/ManageUsers.vue'
import BoManagePacksRole from 'src/components/Dashboard/Views/BackOffice/Role/ManagePacks.vue'
import BoManageRoles from 'src/components/Dashboard/Views/BackOffice/Role/ManageRoles.vue'

import BoManageBrands from 'src/components/Dashboard/Views/BackOffice/CardTerminal/ManageBrands.vue'
import BoManageModels from 'src/components/Dashboard/Views/BackOffice/CardTerminal/ManageModels.vue'
import BoRegisterCardTerminal from 'src/components/Dashboard/Views/BackOffice/CardTerminal/RegisterCardTerminal.vue'
import BoManageCardTerminal from 'src/components/Dashboard/Views/BackOffice/CardTerminal/ManageCardTerminal.vue'
import BoManagePlans from 'src/components/Dashboard/Views/BackOffice/CardTerminal/ManagePlans.vue'
import BoManageBannerCard from 'src/components/Dashboard/Views/BackOffice/CardTerminal/ManageBanner.vue'
import BoManageRequestTerminal from 'src/components/Dashboard/Views/BackOffice/CardTerminal/ManageRequest.vue'
import BoRequestTerminalEdit
  from 'src/components/Dashboard/Views/BackOffice/CardTerminal/EditRequest/ApproveRequisition.vue'
import BoReqTermPrepareShipment
  from 'src/components/Dashboard/Views/BackOffice/CardTerminal/EditRequest/PrepareShipment.vue'
import BoReqTermSendShipment from 'src/components/Dashboard/Views/BackOffice/CardTerminal/EditRequest/SendShipment.vue'
import BoReqTerminalActivate
  from 'src/components/Dashboard/Views/BackOffice/CardTerminal/EditRequest/ActivateRequest.vue'
import BoReqTerminalConsult from 'src/components/Dashboard/Views/BackOffice/CardTerminal/EditRequest/ConsultRequest.vue'
import BoDistributeValues from 'src/components/Dashboard/Views/BackOffice/CardTerminal/DistributeValues.vue'

import BoCreditCard from 'src/components/Dashboard/Views/BackOffice/CreditCard/BoCreditCard.vue'

import BoStatementSearch from 'src/components/Dashboard/Views/BackOffice/Statement/Search.vue'

import Recovery from 'src/components/Dashboard/Views/Pages/Recovery.vue'
import Forgot from 'src/components/Dashboard/Views/Pages/Forgot.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import RegisterPf from "@/components/Dashboard/Views/Pages/RegisterPf.vue";
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
import Maintenance from 'src/components/Dashboard/Views/Maintenance/Maintenance.vue'

import Wallet from 'src/components/Dashboard/Views/Marketplace/Wallet.vue'
import Bitcoin from 'src/components/Dashboard/Views/Marketplace/Exchange.vue'
import BoManageBuyBtc from 'src/components/Dashboard/Views/BackOffice/Marketplace/ManageBuyBtc.vue'
import BoManageSellBtc from 'src/components/Dashboard/Views/BackOffice/Marketplace/ManageSellBtc.vue'

import BoFinancialOnlineUserReport
  from 'src/components/Dashboard/Views/BackOffice/Financial/Online/UserOnlineReport.vue'
import BoFinancialCardTermUserReport
  from 'src/components/Dashboard/Views/BackOffice/CardTerminal/Financial/UserReport.vue'
import BoFinancialProfitReport from 'src/components/Dashboard/Views/BackOffice/Financial/ProfitReport.vue'
import BoPixCharge from 'src/components/Dashboard/Views/BackOffice/Financial/PixCharge.vue'
import BoPixTransferInternal from 'src/components/Dashboard/Views/BackOffice/Financial/PixTransferInternal.vue'
import BoSalesReport from 'src/components/Dashboard/Views/BackOffice/Financial/Online/SalesReport.vue'

import BoPartnerCardTerminalReport from 'src/components/Dashboard/Views/BackOffice/Partner/CardTerminalReport.vue'

import PaymentLink from 'src/components/Dashboard/Views/PaymentLink/RequestSale.vue'
import FinancialReport from 'src/components/Dashboard/Views/PaymentLink/FinancialReport.vue'
import PaymentsReport from 'src/components/Dashboard/Views/PaymentLink/PaymentsReport.vue'

import CheckoutPaymentLink from 'src/components/Dashboard/Views/Checkout/PaymentLink.vue'

import SuitJourney from 'src/components/Dashboard/Views/SuitJourney/Page.vue'
import PreLaunchSuitJourney from 'src/components/Dashboard/Views/SuitJourney/PreLaunchPage.vue'

import BoDistributeValuesGateway from 'src/components/Dashboard/Views/BackOffice/Gateway/DistributeValues.vue'
import BoCancelSalesPix from 'src/components/Dashboard/Views/BackOffice/Gateway/CancelSalesPix.vue'

import BoManagePlate from '@/components/Dashboard/Views/BackOffice/SuitJourney/ManagePlate'
import BoManagePlateStock from '@/components/Dashboard/Views/BackOffice/SuitJourney/ManagePlateStock'
import BoManagePlateFiscalNote from '@/components/Dashboard/Views/BackOffice/SuitJourney/ManagePlateFiscalNote'
import BoManagePlateRequests from '@/components/Dashboard/Views/BackOffice/SuitJourney/ManagePlateRequests'

import Balances from 'src/components/Dashboard/Views/BI/Balances.vue'

import Exchange from 'src/components/Dashboard/Views/Exchange/RequestExchange.vue'
import ForeignExchangeStatement from 'src/components/Dashboard/Views/Exchange/ForeignExchangeStatement'

import BoExchange from 'src/components/Dashboard/Views/BackOffice/Exchange/ManageExchange.vue'

import BoAccountGatewaySearch from 'src/components/Dashboard/Views/BackOffice/AccountGatewayAcquirer/Search.vue'
import BoAccountGatewayEdit from 'src/components/Dashboard/Views/BackOffice/AccountGatewayAcquirer/Edit.vue'

import GatewayPaymentsReport from 'src/components/Dashboard/Views/Gateway/PaymentsReport.vue'
import AccessCheckout from 'src/components/Dashboard/Views/Gateway/AccessCheckout.vue'
import GatewayIp from 'src/components/Dashboard/Views/Gateway/Ip.vue'
import SuitJourneyLayout from "@/components/Dashboard/Layout/SuitJourneyLayout";
import BoManageClientIp from "@/components/Dashboard/Views/BackOffice/Gateway/ManageClientIp.vue"
import BoConsultPix from "@/components/Dashboard/Views/BackOffice/Gateway/ConsultPix.vue"
import BlockedBankCashOut from "@/components/Dashboard/Views/Gateway/BlockedBankCashOut.vue"
import ManageClientBlock from "@/components/Dashboard/Views/BackOffice/Gateway/ManageClientBlock.vue";


// AUTH VERIFICATION ------------------------------------------
const state = {
  isAuthenticated: localStorage.getItem('isAuth') == 'true'
    && localStorage.getItem('ut') != null && localStorage.getItem('ut').startsWith("$b")
};

const ifNotAuthenticated = (to, from, next) => {
  if (!state.isAuthenticated) {
    next()
    return
  }
  next("/overview");
};

const ifAuthenticated = (to, from, next) => {
  if (state.isAuthenticated) {
    next()
    return
  }
  next("/login")
};
//-------------------------------------------------------------

let overviewPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  props: {buttonBlurValues: true},
  children: [
    {
      path: 'overview',
      name: 'Dashboard',
      component: Overview,
      beforeEnter: ifAuthenticated
    }
  ]
}

let userProfile = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/profile',
  children: [
    {
      path: 'profile',
      name: 'Perfil',
      component: UserProfile,
      beforeEnter: ifAuthenticated
    }
  ]
}

let uploadDoc = {
  path: '/user/profile',
  component: DashboardLayout,
  redirect: '/user/profile/uploaddoc',
  children: [
    {
      path: 'uploaddoc',
      name: 'UploadDoc',
      component: UploadDoc,
      beforeEnter: ifAuthenticated
    }
  ]
}

let feesAndLimits = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/feesandlimits',
  children: [
    {
      path: 'feesandlimits',
      name: 'FeesAndLimits',
      component: FeesAndLimits,
      beforeEnter: ifAuthenticated
    }
  ]
}

let device = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/device',
  children: [
    {
      path: 'device',
      name: 'Device',
      component: Device,
      beforeEnter: ifAuthenticated
    }
  ]
}

let support = {
  path: '/support',
  component: DashboardLayout,
  redirect: '/support',
  children: [
    {
      path: 'support',
      name: 'Support',
      component: Support,
      beforeEnter: ifAuthenticated
    }
  ]
}

let notifications = {
  path: '/notifications',
  component: DashboardLayout,
  redirect: '/notifications',
  children: [
    {
      path: 'manage',
      name: 'manage',
      component: ManageNotifications,
      beforeEnter: ifAuthenticated
    }
  ]
}

let collaborators = {
  path: '/collaborators',
  component: DashboardLayout,
  redirect: '/collaborators',
  children: [
    {
      path: 'manage',
      name: 'manage',
      component: ManageCollaborators,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'area',
      name: 'collaborator-area',
      component: CollaboratorsArea,
      beforeEnter: ifAuthenticated
    }
  ]
}

let suitJourneyPage = {
  path: '/suitjourney',
  component: SuitJourneyLayout,
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: 'page',
      name: 'SuitJourney',
      component: SuitJourney,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'prelaunch',
      name: 'PreLaunchSuitJourney',
      component: PreLaunchSuitJourney,
      beforeEnter: ifAuthenticated
    }
  ]
}

let userApi = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/api',
  children: [
    {
      path: 'api',
      name: 'Api',
      component: Api,
      beforeEnter: ifAuthenticated
    }
  ]
}

let depositBoletoPage = {
  path: '/deposit',
  component: DashboardLayout,
  redirect: '/deposit/boleto',
  children: [
    {
      path: 'boleto',
      name: 'DepositoBoleto',
      component: BoletoDeposit,
      beforeEnter: ifAuthenticated
    }
  ]
}

let depositPix = {
  path: '/pix',
  component: DashboardLayout,
  redirect: '/pix/depositpix',
  children: [
    {
      path: 'depositpix',
      name: 'DepositPix',
      component: DepositPix,
      beforeEnter: ifAuthenticated
    }
  ]
}

let paymentPix = {
  path: '/pix',
  component: DashboardLayout,
  redirect: '/pix/paymentpix',
  children: [
    {
      path: 'paymentpix',
      name: 'PaymentPix',
      component: PaymentPix,
      beforeEnter: ifAuthenticated
    }
  ]
}

let payTicket = {
  path: '/payment',
  component: DashboardLayout,
  redirect: '/payment/pay',
  children: [
    {
      path: 'pay',
      name: 'PagamentoDeBoleto',
      component: PagamentoDeBoleto,
      beforeEnter: ifAuthenticated
    }
  ]
}

let transferTevPage = {
  path: '/transfer',
  component: DashboardLayout,
  redirect: '/deposit/tev',
  children: [
    {
      path: 'tev',
      name: 'Tev',
      component: TevTransfer,
      beforeEnter: ifAuthenticated
    }
  ]
}

let transferTedPage = {
  path: '/transfer',
  component: DashboardLayout,
  redirect: '/deposit/ted',
  children: [
    {
      path: 'ted',
      name: 'Ted',
      component: TedTransfer,
      beforeEnter: ifAuthenticated
    }
  ]
}

let creditTedPage = {
  path: '/transfer',
  component: DashboardLayout,
  redirect: '/credit/ted',
  children: [
    {
      path: 'creditTed',
      name: 'CreditTed',
      component: CreditTedPage,
      beforeEnter: ifAuthenticated
    }
  ]
}

let transactionsPage = {
  path: '/transactions',
  component: DashboardLayout,
  redirect: '/transactions/byperiod',
  children: [
    {
      path: 'byperiod/:username?',
      name: 'TransactionsByPeriod',
      component: TransactionsByPeriod,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'pix/in/:username?',
      name: 'TransactionsPixIn',
      component: TransactionsPixIn,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'pix/out/:username?',
      name: 'TransactionsPixOut',
      component: TransactionsPixOut,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'webhook/:username?',
      name: 'TransactionsWebhook',
      component: TransactionsWebhook,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'request-api',
      name: 'RequestApi',
      component: TransactionsRequestApi,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'pixpartner',
      name: 'TransactionsPixPartner',
      component: TransactionsPixPartner,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'movement',
      name: 'TransactionsMovement',
      component: TransactionsMovement,
      beforeEnter: ifAuthenticated
    }
  ]
}

let maintenancePage = {
  path: '/maintenance',
  component: DashboardLayout,
  redirect: '/maintenance/maintenance',
  meta: {
    reload: true
  },
  children: [
    {
      path: 'maintenance',
      name: 'Maintenance',
      component: Maintenance,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boAccount = {
  path: '/bo/account',
  component: DashboardLayout,
  redirect: '/bo/account/search',
  children: [
    {
      path: 'search/natural',
      name: 'BoAccountSearchNatural',
      component: BoAccountSearchNatural,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'search/legal',
      name: 'BoAccountSearchLegal',
      component: BoAccountSearchLegal,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'edit/:username',
      name: 'BoAccountEdit',
      component: BoAccountEdit,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'search/approval',
      name: 'BoAccountSearchApproval',
      component: BoAccountSearchApproval,
      beforeEnter: ifAuthenticated
    },
  ]
}

let boStatement = {
  path: '/bo/statement',
  component: DashboardLayout,
  redirect: '/bo/statement/search',
  children: [
    {
      path: 'search',
      name: 'BoStatementSearch',
      component: BoStatementSearch,
      beforeEnter: ifAuthenticated
    },
  ]
}

let boAccountGatewayAcquirer = {
  path: '/bo/accountgatewayacquirer',
  component: DashboardLayout,
  redirect: '/bo/accountgatewayacquirer/search',
  children: [
    {
      path: 'search',
      name: 'BoAccountGatewaySearch',
      component: BoAccountGatewaySearch,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'edit/:username',
      name: 'BoAccountGatewayEdit',
      component: BoAccountGatewayEdit,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boOperationLimitGlobal = {
  path: '/bo/operation-limit',
  component: DashboardLayout,
  redirect: '/bo/operation-limit/edit',
  children: [
    {
      path: 'edit',
      name: 'BoOperationLimitGlobal',
      component: BoOperationLimitGlobal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boRegisterRole = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/registerrole',
  children: [
    {
      path: 'registerrole',
      name: 'BoRegisterRole',
      component: BoRegisterRole,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boRegisterRolePack = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/registerrolepack',
  children: [
    {
      path: 'registerrolepack',
      name: 'BoRegisterRolePack',
      component: BoRegisterRolePack,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageUsersRole = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/manageusers',
  children: [
    {
      path: 'manageusers',
      name: 'BoManageUsersRole',
      component: BoManageUsersRole,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManagePacksRole = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/managepacks',
  children: [
    {
      path: 'managepacks',
      name: 'BoManagePacksRole',
      component: BoManagePacksRole,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageRoles = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/manageroles',
  children: [
    {
      path: 'manageroles',
      name: 'BoManageRoles',
      component: BoManageRoles,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boOperationLimitUser = {
  path: '/bo/user-operation-limit',
  component: DashboardLayout,
  redirect: '/bo/user-operation-limit/edit',
  children: [
    {
      path: 'edit/:username',
      name: 'BoOperationLimitUser',
      component: BoOperationLimitUser,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boCommercial = {
  path: '/bo/commercial',
  component: DashboardLayout,
  redirect: '/bo/commercial/manage',
  children: [
    {
      path: 'manage',
      name: 'BoCommercialManage',
      component: BoCommercialSalesPersonManager,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'region/manage',
      name: 'BoCommercialRegionManage',
      component: BoCommercialRegionManage,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'level/manage',
      name: 'BoCommercialLevelManage',
      component: BoCommercialLevelManage,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manager/manage',
      name: 'BoCommercialManagerManage',
      component: BoCommercialManagerManage,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'clientDetail/:username',
      name: 'BoCommercialClientDetail',
      component: BoCommercialClientDetail,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'dashboard/:salePersonUsername?',
      name: 'BoCommercialDashboard',
      component: BoCommercialSalePersonDashboard,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'commissions',
      name: 'BoCommissionPayment',
      component: BoCommercialCommissionsPayment,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'salesreport',
      name: 'BoSalesReportBySalePerson',
      component: BoSalesReportBySalePerson,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'useronlinereport',
      name: 'BoUserOnlineReportBySalePerson',
      component: BoUserOnlineReportBySalePerson,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'comercialportifolio',
      name: 'BoCommercialClientSalePerson',
      component: BoCommercialClientSalePerson,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'clients-last-months-transactions',
      name: 'CommercialClientsLastMonthsTransactions',
      component: CommercialClientsLastMonthsTransactions,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boCompliance = {
  path: '/bo/compliance',
  component: DashboardLayout,
  redirect: '/bo/compliance/med',
  children: [
    {
      path: 'flagged-users',
      name: 'BoComplianceSpreadsheet',
      component: BoComplianceFlaggedUsers,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'spreadsheet',
      name: 'BoComplianceSpreadsheet',
      component: BoComplianceSpreadsheet,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'med',
      name: 'BoComplianceMed',
      component: BoComplianceMed,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'med/:username',
      name: 'BoComplianceMedUser',
      component: BoComplianceMedUser,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'negative-funds',
      name: 'BoComplianceNegativeFunds',
      component: BoComplianceNegativeFunds,
      beforeEnter: ifAuthenticated
    },
  ]
}

let boManageCreditTed = {
  path: '/bo/credit-ted',
  component: DashboardLayout,
  redirect: '/bo/credit-ted/manage',
  children: [
    {
      path: 'manageCreditTed',
      name: 'BoManageCreditTed',
      component: BoManageCreditTed,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'editCreditTed/:id',
      name: 'BoEditCreditTed',
      component: BoEditCreditTed,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageBrands = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/managebrands',
  children: [
    {
      path: 'managebrands',
      name: 'BoManageBrands',
      component: BoManageBrands,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageModels = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/managemodels',
  children: [
    {
      path: 'managemodels',
      name: 'BoManageModels',
      component: BoManageModels,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManagePlans = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/manageplans',
  children: [
    {
      path: 'manageplans',
      name: 'BoManagePlans',
      component: BoManagePlans,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageBannerCard = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/managebanner',
  children: [
    {
      path: 'managebanner',
      name: 'BoManageBannerCard',
      component: BoManageBannerCard,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageRequestTerminal = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/managerequest',
  children: [
    {
      path: 'managerequest',
      name: 'BoManageRequestTerminal',
      component: BoManageRequestTerminal,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'edit/:id',
      name: 'BoRequestTerminalEdit',
      component: BoRequestTerminalEdit,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'prepareshipment/:id',
      name: 'BoReqTermPrepareShipment',
      component: BoReqTermPrepareShipment,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'sendshipment/:id',
      name: 'BoReqTermSendShipment',
      component: BoReqTermSendShipment,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'activerequest/:id',
      name: 'BoReqTerminalActivate',
      component: BoReqTerminalActivate,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'consultrequest/:id',
      name: 'BoReqTerminalConsult',
      component: BoReqTerminalConsult,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boFinancialCardTermUserReport = {
  path: '/bo/cardterminal/financial',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/financial/userreport',
  children: [
    {
      path: 'userreport',
      name: 'BoFinancialCardTermUserReport',
      component: BoFinancialCardTermUserReport,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boFinancialOnlineUserReport = {
  path: '/bo/financial/online',
  component: DashboardLayout,
  redirect: '/bo/financial/online',
  children: [
    {
      path: 'useronlinereport',
      name: 'BoFinancialOnlineUserReport',
      component: BoFinancialOnlineUserReport,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'salesReport',
      name: 'BoSalesReport',
      component: BoSalesReport,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boFinancial = {
  path: '/bo/financial',
  component: DashboardLayout,
  redirect: '/bo/financial/report',
  children: [
    {
      path: 'profitreport',
      name: 'BoFinancialProfitReport',
      component: BoFinancialProfitReport,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boPixCharge = {
  path: '/bo/financial',
  component: DashboardLayout,
  redirect: '/bo/financial/pixcharge',
  children: [
    {
      path: 'pixcharge',
      name: 'BoPixCharge',
      component: BoPixCharge,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boPixTransferInternal = {
  path: '/bo/financial',
  component: DashboardLayout,
  redirect: '/bo/financial/pixtransferinternal',
  children: [
    {
      path: 'pixtransferinternal',
      name: 'BoPixTransferInternal',
      component: BoPixTransferInternal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boDistributeValues = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/distributevalues',
  children: [
    {
      path: 'distributevalues',
      name: 'BoDistributeValues',
      component: BoDistributeValues,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boCreditCard = {
  path: '/bo/credit-card',
  component: DashboardLayout,
  redirect: '/bo/credit-card',
  children: [
    {
      path: '/',
      name: 'BoCreditCard',
      component: BoCreditCard,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boEditRequestTerminal = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/managerequest',
  children: [
    {
      path: 'managerequest',
      name: 'BoManageRequestTerminal',
      component: BoManageRequestTerminal,
      beforeEnter: ifAuthenticated
    },
  ]
}

let boRegisterCardTerminal = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/registercardterminal',
  children: [
    {
      path: 'registercardterminal',
      name: 'BoRegisterCardTerminal',
      component: BoRegisterCardTerminal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageCardTerminal = {
  path: '/bo/cardterminal',
  component: DashboardLayout,
  redirect: '/bo/cardterminal/managecardterminal',
  children: [
    {
      path: 'managecardterminal',
      name: 'BoManageCardTerminal',
      component: BoManageCardTerminal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let manageCreditCard = {
  path: '/credit-card',
  component: DashboardLayout,
  redirect: '/credit-card/manage/virtual',
  children: [
    {
      path: 'manage/:type',
      name: 'ManageCreditCard',
      component: ManageCards,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'transactions/:username?',
      name: 'CreditCardTransactions',
      component: CardTransactions,
      beforeEnter: ifAuthenticated
    }

  ]
}

let manageCostCenter = {
  path: '/cost-center',
  component: DashboardLayout,
  redirect: '/cost-center/manage',
  children: [
    {
      path: 'manage',
      name: 'ManageCostCenter',
      component: ManageCostCenter,
      beforeEnter: ifAuthenticated
    }
  ]
}

let requestCardTerminal = {
  path: '/card-terminal',
  component: DashboardLayout,
  redirect: '/card-terminal/request',
  children: [
    {
      path: 'request',
      name: 'RequestCardTerminal',
      component: RequestCardTerminal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let consultPlanCardTerminal = {
  path: '/card-terminal',
  component: DashboardLayout,
  redirect: '/card-terminal/consultplan',
  children: [
    {
      path: 'consultplan',
      name: 'ConsultPlanCardTerminal',
      component: ConsultPlanCardTerminal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let consultTransactions = {
  path: '/card-terminal',
  component: DashboardLayout,
  redirect: '/card-terminal/consulttransactions',
  children: [
    {
      path: 'consultTransactions',
      name: 'ConsultTransactions',
      component: ConsultTransactions,
      beforeEnter: ifAuthenticated
    }
  ]
}

let generateRecommendationCode = {
  path: '/recommendation',
  component: DashboardLayout,
  redirect: '/recommendation/generaterecommendationcode',
  children: [
    {
      path: 'generaterecommendationcode',
      name: 'GenerateRecommendationCode',
      component: GenerateRecommendationCode,
      beforeEnter: ifAuthenticated
    }
  ]
}

let wallet = {
  path: '/marketplace',
  component: DashboardLayout,
  redirect: '/marketplace/wallet',
  children: [
    {
      path: 'wallet',
      name: 'Wallet',
      component: Wallet,
      beforeEnter: ifAuthenticated
    }
  ]
}

let bitcoin = {
  path: '/marketplace',
  component: DashboardLayout,
  redirect: '/marketplace/exchange',
  children: [
    {
      path: 'bitcoin',
      name: 'Bitcoin',
      component: Bitcoin,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageTransactionBtc = {
  path: '/bo/marketplace',
  component: DashboardLayout,
  redirect: '/bo/marketplace/manage',
  children: [
    {
      path: 'manageBuyBtc',
      name: 'BoManageBuyBtc',
      component: BoManageBuyBtc,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manageSellBtc',
      name: 'BoManageSellBtc',
      component: BoManageSellBtc,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boPartnerCardTerminalReport = {
  path: '/bo/partner',
  component: DashboardLayout,
  redirect: '/bo/partner/cardterminalreport',
  children: [
    {
      path: 'cardterminalreport',
      name: 'BoPartnerCardTerminalReport',
      component: BoPartnerCardTerminalReport,
      beforeEnter: ifAuthenticated
    }
  ]
}

let paymentLink = {
  path: '/paymentlink',
  component: DashboardLayout,
  redirect: '/paymentlink/requestsale',
  children: [
    {
      path: 'requestsale',
      name: 'PaymentLink',
      component: PaymentLink,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'financialreport',
      name: 'FinancialReport',
      component: FinancialReport,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'paymentsreport',
      name: 'PaymentsReport',
      component: PaymentsReport,
      beforeEnter: ifAuthenticated
    }
  ]
}

let gateway = {
  path: '/gateway',
  component: DashboardLayout,
  redirect: '/gateway',
  children: [
    {
      path: 'paymentsreport',
      name: 'GatewayPaymentsReport',
      component: GatewayPaymentsReport,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'accesscheckout',
      name: 'AccessCheckout',
      component: AccessCheckout,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'ip',
      name: 'GatewayIp',
      component: GatewayIp,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'blocked-bank',
      name: 'PixPaymentFilter',
      component: BlockedBankCashOut,
      beforeEnter: ifAuthenticated
    }

  ]
}

let boDistributeValuesGateway = {
  path: '/bo/gateway',
  component: DashboardLayout,
  redirect: '/bo/gateway',
  children: [
    {
      path: 'distributevalues',
      name: 'BoDistributeValuesGateway',
      component: BoDistributeValuesGateway,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'cancelsalepix',
      name: 'BoCancelSalesPix',
      component: BoCancelSalesPix,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manageclientip',
      name: 'BoManageClientIp',
      component: BoManageClientIp,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'consultpix',
      name: 'BoConsultPix',
      component: BoConsultPix,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manage-client-block',
      name: 'BoManageClientBlock',
      component: ManageClientBlock,
      beforeEnter: ifAuthenticated
    },

  ]
}

let boSuitJourney = {
  path: '/bo/suitjourney',
  component: DashboardLayout,
  redirect: '/bo/suitjourney',
  children: [
    {
      path: 'manage/plate',
      name: 'BoManagePlate',
      component: BoManagePlate,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manage/plate-stock',
      name: 'BoManagePlateStock',
      component: BoManagePlateStock,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manage/plate-fiscal-note',
      name: 'BoManagePlateFiscalNote',
      component: BoManagePlateFiscalNote,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manage/request',
      name: 'BoManagePlateRequests',
      component: BoManagePlateRequests,
      beforeEnter: ifAuthenticated
    },
  ]
}

let bi = {
  path: '/bi',
  component: DashboardLayout,
  redirect: '/bi',
  children: [
    {
      path: 'balances',
      name: 'Balances',
      component: Balances,
      beforeEnter: ifAuthenticated
    }
  ]
}

let exchange = {
  path: '/exchange',
  component: DashboardLayout,
  redirect: '/exchange',
  children: [
    {
      path: 'requestexchange',
      name: 'Exchange',
      component: Exchange,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'foreignexchangestatement',
      name: 'ForeignExchangeStatement',
      component: ForeignExchangeStatement,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boExchange = {
  path: '/bo/exchange',
  component: DashboardLayout,
  redirect: '/bo/exchange',
  children: [
    {
      path: 'manageexchange',
      name: 'BoExchange',
      component: BoExchange,
      beforeEnter: ifAuthenticated
    }
  ]
}

const loginPage = {
  path: '/login',
  name: 'Login',
  component: Login,
  beforeEnter: ifNotAuthenticated
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register,
  beforeEnter: ifNotAuthenticated
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock,
  beforeEnter: ifNotAuthenticated
}

let forgotPage = {
  path: '/forgot',
  name: 'Forgot',
  component: Forgot,
  beforeEnter: ifNotAuthenticated
}

let recoveryPage = {
  path: '/recovery/:key',
  name: 'Recovery',
  component: Recovery,
  beforeEnter: ifNotAuthenticated
}

let checkoutPaymentLink = {
  path: '/checkout/paymentlink/:key',
  name: 'PaymentLink',
  component: CheckoutPaymentLink,
  beforeEnter: ifNotAuthenticated
}
let registerNaturalPerson = {
  path: '/register/natural-person',
  name: 'Register',
  component: RegisterPf,
  beforeEnter: ifNotAuthenticated
}


const routes = [
  overviewPage,
  userProfile,
  device,
  userApi,
  depositBoletoPage,
  transferTevPage,
  transferTedPage,
  transactionsPage,
  boAccount,
  boOperationLimitGlobal,
  boOperationLimitUser,
  loginPage,
  registerPage,
  lockPage,
  forgotPage,
  recoveryPage,
  maintenancePage,
  boRegisterRole,
  boRegisterRolePack,
  boManageUsersRole,
  boManagePacksRole,
  boManageRoles,
  manageCreditCard,
  manageCostCenter,
  requestCardTerminal,
  boManageBrands,
  boManageModels,
  boRegisterCardTerminal,
  boManageCardTerminal,
  generateRecommendationCode,
  feesAndLimits,
  uploadDoc,
  creditTedPage,
  boManageCreditTed,
  wallet,
  bitcoin,
  boManageTransactionBtc,
  payTicket,
  support,
  notifications,
  collaborators,
  boManagePlans,
  boManageBannerCard,
  boManageRequestTerminal,
  boCommercial,
  boCompliance,
  consultPlanCardTerminal,
  boDistributeValues,
  boCreditCard,
  boStatement,
  consultTransactions,
  depositPix,
  paymentPix,
  boFinancialCardTermUserReport,
  boFinancial,
  boFinancialOnlineUserReport,
  boPixCharge,
  boPartnerCardTerminalReport,
  paymentLink,
  checkoutPaymentLink,
  boDistributeValuesGateway,
  boSuitJourney,
  bi,
  exchange,
  boExchange,
  boAccountGatewayAcquirer,
  gateway,
  boPixTransferInternal,
  suitJourneyPage,
  registerNaturalPerson,
  {path: '*', component: NotFound}
];

export default routes
