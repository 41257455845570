<template>
  <div class="row dzs-feeslimits">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title" style="text-align: center; color: #17a2b8">
          Taxas e Serviços
        </h4>
        <div class="row" style="margin-bottom: 30px; text-align: center">
          <div class="col-md-12">
            <p>Conheça algumas das funcionalidades que você terá na <b style="color: #000000 !important;">SuitPay</b>.</p>
            <p>Um ecossistema financeiro para você oferecer aos seus clientes de forma transparente, escalável e segura.</p>
          </div>
          <div class="box3" style="text-align: left">
            <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Internet Banking<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Máquinas de Cartão<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Link de Pagamento<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Gateway de Pagamento<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Checkout Transparente</p>
          </div>
          <div class="box3" style="text-align: left">
            <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Câmbio Internacional<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Cartão Virtual<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Cartão Físico<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Multiplos Cartões<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Infraestrutura whitelabel</p>
          </div>
          <div class="box3" style="text-align: left">
            <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Transferências via PIX<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimentos via PIX<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Split de pagamentos<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pagamentos de titulos e convênios<br>
               <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Transferências eletrônicas (TED)</p>
          </div>
          <div class="col-md-12">
            <br>
            <h5>O melhor meio de pagamento com taxas transparentes!</h5>
            <p>Oferecemos tudo o que você precisa para escalar o seu negócio com as melhores taxas e experiência de pagamento.</p>
          </div>
          <div class="card box2" style="text-align: left; text-align: center">
            <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px"><b>Taxas Internet Banking</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">TED (Outros Bancos)<br><b>R$ 7,70</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">TEV (<b style="color: #000000 !important;">SuitPay</b>)<br><b>R$ 0,00</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Boleto Recarga<br><b>R$ 5,00</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Recebimento via PIX<br><b>1,00 %</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Transferência via PIX<br><b>R$ 5,00</b></p>
            <p>Administração de Conta<br><b>Gratuita</b></p>
          </div>
          <div class="col-md-12">
<!--            <p style="font-size: 11px">* A taxa administrativa referente a manutenção de conta só é cobrada, nos meses em que a conta foi utilizada.</p>-->
            <p style="font-size: 11px">* Boletos registrados com recebimento em até dois dias após o pagamento.</p>
            <br>
            <h5>Para quem vende pela internet</h5>
          </div>
          <div class="card box2" style="text-align: left; text-align: center">
            <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px"><b>Taxas Vendas Online</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Cartão de Crédito<br><b>4,99 %</b><br><small> + R$0,99 por transação*</small></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">PIX (Receba na hora)<br><b>4,99 %</b></p>
            <p>Boleto Bancário<br><b>4,99 %</b><br><small> Custo mínimo por boleto R$ 4,99.</small>**</p>
            <br>
          </div>
          <div class="col-md-12" style="text-align: center">
            <p style="font-size: 11px">* Taxa fixa para lojista, recebimento em D+3 na modalidade Cartão de Crédito.<br>
            ** Taxa fixa para lojista, recebimento em D+2 na modalidade Boleto Bancário.</p>
            <br>
            <h5>Como funcionam os meus limites?</h5>
          </div>
          <div class="card box2" style="text-align: left; text-align: center" v-show="listOpLimitLegalPerson.length>0">
            <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px"><b>Pessoa Jurídica</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor mínimo por Boleto<br><b>R$ {{opLimitLegalPerson.boletoMinValue}}
              <span class="customIndication" v-show="opLimitLegalPerson.boletoMinValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo por Boleto<br><b>R$ {{opLimitLegalPerson.boletoMaxValue}}
              <span class="customIndication" v-show="opLimitLegalPerson.boletoMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de recarga mensal via boleto<br><b>R$ {{opLimitLegalPerson.boletoMaxValueMonthly}}
              <span class="customIndication" v-show="opLimitLegalPerson.boletoMaxValueMonthlyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo para contas <b style="color: #000000 !important;">SuitPay</b><br><b>Ilimitado</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de TED por dia<br><b>R$ {{opLimitLegalPerson.tedMaxValueDaily}}
              <span class="customIndication" v-show="opLimitLegalPerson.tedMaxValueDailyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de TED por mês<br><b>R$ {{opLimitLegalPerson.tedMaxValueMonthly}}
              <span class="customIndication" v-show="opLimitLegalPerson.tedMaxValueMonthlyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de TED por operação<br><b>R$ {{opLimitLegalPerson.tedMaxValue}}
              <span class="customIndication" v-show="opLimitLegalPerson.tedMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de PIX por operação<br><b>R$ {{opLimitLegalPerson.pixMaxValue}}
              <span class="customIndication" v-show="opLimitLegalPerson.pixMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de PIX por dia<br><b>R$ {{opLimitLegalPerson.pixMaxValueDaily}}
              <span class="customIndication" v-show="opLimitLegalPerson.pixMaxValueDailyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de Link de Pagamento<br><b>R$ {{opLimitLegalPerson.paymentLinkMaxValue}}
              <span class="customIndication" v-show="opLimitLegalPerson.paymentLinkMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de Pagamento de Boleto por Dia<br><b>R$ {{opLimitLegalPerson.boletoPaymentMaxDaily}}
              <span class="customIndication" v-show="opLimitLegalPerson.boletoPaymentMaxDailyCustom">1</span></b></p>
            <p>Valor máximo de Pagamento de Boleto por Mês<br><b>R$ {{opLimitLegalPerson.boletoPaymentMaxMonthly}}
              <span class="customIndication" v-show="opLimitLegalPerson.boletoPaymentMaxMonthlyCustom">1</span></b></p>
          </div>
          <div class="card box2" style="text-align: left; text-align: center" v-show="listOpLimitNaturalPerson.length>0">
            <p style="margin-top: 15px; padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221); color: #17a2b8; font-size: 20px"><b>Pessoa Física</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor mínimo por Boleto<br><b>R$ {{opLimitNaturalPerson.boletoMinValue}}
              <span class="customIndication" v-show="opLimitNaturalPerson.boletoMinValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo por Boleto<br><b>R$ {{opLimitNaturalPerson.boletoMaxValue}}
              <span class="customIndication" v-show="opLimitNaturalPerson.boletoMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de recarga mensal via boleto<br><b>R$ {{opLimitNaturalPerson.boletoMaxValueMonthly}}
              <span class="customIndication" v-show="opLimitNaturalPerson.boletoMaxValueMonthlyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo para contas <b style="color: #000000 !important;">SuitPay</b><br><b>Ilimitado</b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de TED por dia<br><b>R$ {{opLimitNaturalPerson.tedMaxValueDaily}}
              <span class="customIndication" v-show="opLimitNaturalPerson.tedMaxValueDailyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de TED por mês<br><b>R$ {{opLimitNaturalPerson.tedMaxValueMonthly}}
              <span class="customIndication" v-show="opLimitNaturalPerson.tedMaxValueMonthlyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de TED por operação<br><b>R$ {{opLimitNaturalPerson.tedMaxValue}}
              <span class="customIndication" v-show="opLimitNaturalPerson.tedMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de PIX por operação<br><b>R$ {{opLimitNaturalPerson.pixMaxValue}}
              <span class="customIndication" v-show="opLimitNaturalPerson.pixMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de PIX por dia<br><b>R$ {{opLimitNaturalPerson.pixMaxValueDaily}}
              <span class="customIndication" v-show="opLimitNaturalPerson.pixMaxValueDailyCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo por Link de Pagamento<br><b>R$ {{opLimitNaturalPerson.paymentLinkMaxValue}}
              <span class="customIndication" v-show="opLimitNaturalPerson.paymentLinkMaxValueCustom">1</span></b></p>
            <p style="padding-bottom: 0.8em; border-bottom: 1px solid rgb(221, 221, 221);">Valor máximo de Pagamento de Boleto por Dia<br><b>R$ {{opLimitNaturalPerson.boletoPaymentMaxDaily}}
              <span class="customIndication" v-show="opLimitNaturalPerson.boletoPaymentMaxDailyCustom">1</span></b></p>
            <p>Valor máximo de Pagamento de Boleto por Mês<br><b>R$ {{opLimitNaturalPerson.boletoPaymentMaxMonthly}}
              <span class="customIndication" v-show="opLimitNaturalPerson.boletoPaymentMaxMonthlyCustom">1</span></b></p>
          </div>
          <div class="col-md-12">
            <p style="font-size: 11px">* Todos os limites estão sujeitos a alteração de acordo com a movimentação de cada cliente.</p>
            <p style="font-size: 11px" v-show="customMsg"><b><span class="customIndication">1</span></b> Limite customizado para o usuário.</p>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import {callWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import {isNullOrEmpty, toMoney} from "src/util/core.utils"

  export default {
    components: {},
    data() {
      return {
        personType: '',
        customMsg: false,
        listOpLimitLegalPerson: [],
        listOpLimitNaturalPerson: [],
        opLimitLegalPerson: {
          boletoMinValue: '',
          boletoMaxValue: '',
          boletoMaxValueMonthly: '',
          tedMaxValueDaily: '',
          tedMaxValueMonthly: '',
          tedMaxValue: '',
          tedMaxValueCustom: '',
          boletoMinValueCustom: false,
          boletoMaxValueCustom: false,
          boletoMaxValueMonthlyCustom: false,
          tedMaxValueDailyCustom: false,
          tedMaxValueMonthlyCustom: false,
          boletoPaymentMaxMonthlyCustom: false,
          boletoPaymentMaxDailyCustom: false,
          pixMaxValueDaily: '',
          pixMaxValue: '',
          paymentLinkMaxValue: '',
          pixMaxValueDailyCustom: '',
          pixMaxValueCustom: '',
          paymentLinkMaxValueCustom: '',
          boletoPaymentMaxMonthly: '',
          boletoPaymentMaxDaily: '',
        },
        opLimitNaturalPerson: {
          boletoMinValue: '',
          boletoMaxValue: '',
          boletoMaxValueMonthly: '',
          tedMaxValueDaily: '',
          tedMaxValueMonthly: '',
          tedMaxValue: '',
          tedMaxValueCustom: '',
          boletoMinValueCustom: false,
          boletoMaxValueCustom: false,
          boletoMaxValueMonthlyCustom: false,
          tedMaxValueDailyCustom: false,
          tedMaxValueMonthlyCustom: false,
          boletoPaymentMaxMonthlyCustom: false,
          boletoPaymentMaxDailyCustom: false,
          pixMaxValueDaily: '',
          pixMaxValue: '',
          paymentLinkMaxValue: '',
          pixMaxValueDailyCustom: '',
          pixMaxValueCustom: '',
          paymentLinkMaxValueCustom: '',
          boletoPaymentMaxMonthly: '',
          boletoPaymentMaxDaily: '',
        }
      }
    },
    created() {
      this.getOpLimits()
    },
    methods: {
      isNaturalPerson(personType) {
        return personType === 'NATURAL_PERSON' || personType === 'NATURAL_PERSON_E';
      },
      isLegalPerson(personType) {
        return personType === 'LEGAL_PERSON';
      },
      getOpLimits() {
        callWs("/op-limit/get-user-op-limits", 'GET',
          null, true, null, null, this.success,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao carregar taxas e serviços!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        )
      },
      success(response) {
        if(isNullOrEmpty(response.data.personType)){
          this.listOpLimitLegalPerson = response.data.listLegalPerson
          this.listOpLimitNaturalPerson = response.data.listNaturalPerson
          this.loadDataLegalPerson()
          this.loadDataNaturalPeson()
        }else{
          if(this.isLegalPerson(response.data.personType)){
            this.listOpLimitLegalPerson = response.data.listLegalPerson
            this.loadDataLegalPerson()
          }else{
            this.listOpLimitNaturalPerson = response.data.listNaturalPerson
            this.loadDataNaturalPeson()
          }
        }
      },
      loadDataLegalPerson(){
        this.listOpLimitLegalPerson.forEach(op => {
          if(op.customLimit == true){
            this.customMsg = true
          }
          if(op.limit == 'BOLETO_MAX_VALUE') {
            this.opLimitLegalPerson.boletoMaxValue = toMoney(op.value)
            this.opLimitLegalPerson.boletoMaxValueCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_MIN_VALUE') {
            this.opLimitLegalPerson.boletoMinValue = toMoney(op.value)
            this.opLimitLegalPerson.boletoMinValueCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_MAX_VALUE_MONTHLY') {
            this.opLimitLegalPerson.boletoMaxValueMonthly = toMoney(op.value)
            this.opLimitLegalPerson.boletoMaxValueMonthlyCustom = op.customLimit
          }
          if(op.limit == 'TED_MAX_VALUE_DAILY') {
            this.opLimitLegalPerson.tedMaxValueDaily = toMoney(op.value)
            this.opLimitLegalPerson.tedMaxValueDailyCustom = op.customLimit
          }
          if(op.limit == 'TED_MAX_VALUE_MONTHLY') {
            this.opLimitLegalPerson.tedMaxValueMonthly = toMoney(op.value)
            this.opLimitLegalPerson.tedMaxValueMonthlyCustom = op.customLimit
          }
          if(op.limit == 'TED_MAX_VALUE') {
            this.opLimitLegalPerson.tedMaxValue = toMoney(op.value)
            this.opLimitLegalPerson.tedMaxValueCustom = op.customLimit
          }
          if(op.limit == 'PIX_PAYMENT_MAX_VALUE') {
            this.opLimitLegalPerson.pixMaxValue = toMoney(op.value)
            this.opLimitLegalPerson.pixMaxValueCustom = op.customLimit
          }
          if(op.limit == 'PIX_PAYMENT_MAX_VALUE_DAILY') {
            this.opLimitLegalPerson.pixMaxValueDaily = toMoney(op.value)
            this.opLimitLegalPerson.pixMaxValueDailyCustom = op.customLimit
          }
          if(op.limit == 'PAYMENT_LINK_MAX_VALUE') {
            this.opLimitLegalPerson.paymentLinkMaxValue = toMoney(op.value)
            this.opLimitLegalPerson.paymentLinkMaxValueCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_PAYMENT_MAX_DAILY') {
            this.opLimitLegalPerson.boletoPaymentMaxDaily = toMoney(op.value)
            this.opLimitLegalPerson.boletoPaymentMaxDailyCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_PAYMENT_MAX_MONTHLY') {
            this.opLimitLegalPerson.boletoPaymentMaxMonthly = toMoney(op.value)
            this.opLimitLegalPerson.boletoPaymentMaxMonthlyCustom = op.customLimit
          }
        })
      },
      loadDataNaturalPeson(){
        this.listOpLimitNaturalPerson.forEach(op => {
          if(op.customLimit == true){
            this.customMsg = true
          }
          if(op.limit == 'BOLETO_MAX_VALUE') {
            this.opLimitNaturalPerson.boletoMaxValue = toMoney(op.value)
            this.opLimitNaturalPerson.boletoMaxValueCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_MIN_VALUE') {
            this.opLimitNaturalPerson.boletoMinValue = toMoney(op.value)
            this.opLimitNaturalPerson.boletoMinValueCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_MAX_VALUE_MONTHLY') {
            this.opLimitNaturalPerson.boletoMaxValueMonthly = toMoney(op.value)
            this.opLimitNaturalPerson.boletoMaxValueMonthlyCustom = op.customLimit
          }
          if(op.limit == 'TED_MAX_VALUE_DAILY') {
            this.opLimitNaturalPerson.tedMaxValueDaily = toMoney(op.value)
            this.opLimitNaturalPerson.tedMaxValueDailyCustom = op.customLimit
          }
          if(op.limit == 'TED_MAX_VALUE_MONTHLY') {
            this.opLimitNaturalPerson.tedMaxValueMonthly = toMoney(op.value)
            this.opLimitNaturalPerson.tedMaxValueMonthlyCustom = op.customLimit
          }
          if(op.limit == 'TED_MAX_VALUE') {
            this.opLimitNaturalPerson.tedMaxValue = toMoney(op.value)
            this.opLimitNaturalPerson.tedMaxValueCustom = op.customLimit
          }
          if(op.limit == 'PIX_PAYMENT_MAX_VALUE') {
            this.opLimitNaturalPerson.pixMaxValue = toMoney(op.value)
            this.opLimitNaturalPerson.pixMaxValueCustom = op.customLimit
          }
          if(op.limit == 'PIX_PAYMENT_MAX_VALUE_DAILY') {
            this.opLimitNaturalPerson.pixMaxValueDaily = toMoney(op.value)
            this.opLimitNaturalPerson.pixMaxValueDailyCustom = op.customLimit
          }
          if(op.limit == 'PAYMENT_LINK_MAX_VALUE') {
            this.opLimitNaturalPerson.paymentLinkMaxValue = toMoney(op.value)
            this.opLimitNaturalPerson.paymentLinkMaxValueCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_PAYMENT_MAX_DAILY') {
            this.opLimitNaturalPerson.boletoPaymentMaxDaily = toMoney(op.value)
            this.opLimitNaturalPerson.boletoPaymentMaxDailyCustom = op.customLimit
          }
          if(op.limit == 'BOLETO_PAYMENT_MAX_MONTHLY') {
            this.opLimitNaturalPerson.boletoPaymentMaxMonthly = toMoney(op.value)
            this.opLimitNaturalPerson.boletoPaymentMaxMonthlyCustom = op.customLimit
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-feeslimits {
    .box1 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 350px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
      line-height: 2.0;
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
      line-height: 2.0;
    }

    @media (max-width: 500px) {
      .box2 {
        width: 90%;
      }
    }

    .box3 {
      width: 20%;
      margin-left: 10%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.3%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.3%;
      }
    }

    .customIndication {
      font-size: 8px;
      vertical-align: top;
    }
  }
</style>
