<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Níveis de Executivos</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row" style="text-align: center">
              <div class="col-md-4 m-auto">
                <p-button style="margin-top: 25px;text-align: center"
                          @click="addLevel = true" type="info">
                  Adicionar Nível
                </p-button>
              </div>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="row" v-if="addLevel">
              <div class="col-md-12" style="text-align: center">
                <div class="row">
                  <div class="col-md-6 m-auto" style="text-align: center; width: 50%">
                    <label class="control-label" >Nome</label>
                    <fg-input style="width: 50%" class="m-auto" v-model="levelToAdd.levelName">
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 m-auto" style="text-align: center; width: 50%">
                    <label class="control-label" >Valor máximo comissionamento</label>
                    <money class="form-control input-lg m-auto" style="width: 150px"
                           v-model="levelToAdd.maxCommissionValue"
                           v-bind="money">
                    </money>
                  </div>
                </div>
                <div class = "row">
                  <div class="col-md-12" style="text-align: center">
                    <p-button type="success" style="margin-top: 25px;"
                              @click="doAddLevel">
                      <i slot="label" class="far fa-floppy-disk"></i>
                      Salvar
                    </p-button>
                    <p-button type="danger" style="margin-top: 25px;"
                              @click="clearLevelToAdd">
                      <i slot="label" class="nc-icon nc-check-2"></i>
                      Cancelar
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 mt-2 m-auto">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%">
                  <el-table-column :min-width="90" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.levelName}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="60" label="Comissão Máx.">
                    <template slot-scope="props">
                    <span>
                      <span> R$ {{toMoney(props.row.maxCommissionValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Ativo">
                    <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.enabled?'6bd098':'ef8157')"></i>
                      <span> {{props.row.enabled?'Sim':'Não'}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="230" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="warning" size="sm" icon title="Editar"
                                @click="edit(props.row)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </p-button>
                      <p-button type="danger" size="sm" icon title="Ativar/Desativar"
                                @click="enableOrDisable(props.row)">
                        <i class="fas fa-check-circle"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs, getWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {Modal} from 'src/components/UIComponents'
  import {toMoney} from "@/util/core.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Modal
    },
    data() {
      return {
        search: {
          name: '',
          enabled: null,
        },
        tableData: [],
        levelToAdd: {
          id: null,
          levelName: null,
          maxCommissionValue: 0,
          enabled: true
        },
        addLevel: false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      resetDefaultValues(){
        this.search.key = ''
        this.search.enabled = null,
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.loadData()
      },
      loadData() {
        getWs("/bo/commercial/salesperson/levels",
          true, null, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
      },
      clearLevelToAdd(){
        this.addLevel = false;
        this.levelToAdd.id = null
        this.levelToAdd.levelName = null
        this.levelToAdd.enabled = true
        this.levelToAdd.maxCommissionValue = 0;
      },
      doAddLevel(){
        if(this.levelToAdd.levelName.trim() === ""){
          swal({
            title: 'Nome do nível',
            text: "Informe um nome para o nível!",
            type: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#00c390',
          })
        } else if(this.levelToAdd.maxCommissionValue <= 0){
          swal({
            title: 'Valor máximo da comissão',
            text: "O valor máximo do comissionamento deve ser maior que zero!",
            type: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#00c390',
          })
        } else {
          postWs("/bo/commercial/salesperson/level",
            true, null,
            {
              id: this.levelToAdd.id,
              levelName: this.levelToAdd.levelName,
              maxCommissionValue: this.levelToAdd.maxCommissionValue,
              enabled: true,
            }, response => {
              this.clearLevelToAdd()
              this.loadData()
            },
            failWs)
        }
      },
      enableOrDisable(region) {
        swal({
          title: 'Ativar / Desativar',
          text: "Você deseja ativar ou desativar este nível?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ativar',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'Desativar',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callEnabledWs(region, true)
        }, (dismiss) => {
          if (dismiss === 'cancel') {
            this.callEnabledWs(region, false)
          }
        })
      },
      callEnabledWs(level, maxCommissionValue, enabled) {
        postWs("/bo/commercial/salesperson/level",
          true, null,
          {id: level.id, levelName: level.levelName, enabled: enabled, maxCommissionValue: maxCommissionValue},
          function () {
            this.loadData()
          }, error => {
            swal({
              title: 'Ativar / Desativar',
              text: "Falha ao alterar o nível!",
              type: 'error'
            })
          })
      },
      edit(row) {
        this.levelToAdd.id = row.id
        this.levelToAdd.levelName = row.levelName
        this.levelToAdd.maxCommissionValue = row.maxCommissionValue
        this.addLevel = true
      },

    }
  }
</script>
<style lang="scss" scoped>

</style>
