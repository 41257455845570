<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Planos</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-5">
            <label class="control-label">Nome do Plano</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-3">
            <p-button type="success" style="margin-top: 25px; float: right"
                      @click="insertForm">
              <i slot="label" class="nc-icon nc-simple-add"></i>
              Novo Plano
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="30" label="ID">
                <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.planName}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Bandeira">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.nameCardTerminalBanner}}</span>
                    </span>
                </template>
              </el-table-column>
<!--              <el-table-column :min-width="70" label="Fat. Mínimo">-->
<!--                <template slot-scope="props">-->
<!--                    <span>-->
<!--                      <span>R$ {{toMoney(props.row.minimumBilling)}}</span>-->
<!--                    </span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column :min-width="70" label="Fat. Máximo">-->
<!--                <template slot-scope="props">-->
<!--                    <span>-->
<!--                      <span>R$ {{toMoney(props.row.maximumBilling)}}</span>-->
<!--                    </span>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column :min-width="60" label="Adquirente">
                <template slot-scope="props">
                    <span>
                      <span>
                        <span>{{props.row.acquirerName}}</span>
                      </span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Plano Custo">
                <template slot-scope="props">
                    <span>
                      <span v-if="props.row.costPlan">SIM</span>
                      <span v-if="!props.row.costPlan">NÃO</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Plano Cliente">
                <template slot-scope="props">
                    <span>
                      <span v-if="props.row.customerPlan">SIM</span>
                      <span v-if="!props.row.customerPlan">NÃO</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="80" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="warning" size="sm" icon title="Editar"
                            @click="edit(props.row.id, props.row.planName, props.row.debito, props.row.credito,
                             props.row.credito2x, props.row.credito3x, props.row.credito4x, props.row.credito5x,
                             props.row.credito6x, props.row.credito7x, props.row.credito8x, props.row.credito9x,
                             props.row.credito10x, props.row.credito11x, props.row.credito12x, props.row.customerPlan,
                             props.row.costPlan, props.row.enabled, props.row.minimumBilling, props.row.maximumBilling,
                             props.row.startDate, props.row.endDate, props.row.nameCardTerminalBanner, props.row.acquirerName)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-show="formEditShow" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4 v-if="id!=''">Editar o Plano <b>{{name}}</b></h4>
        <h4 v-if="id==''">Inserir Novo Plano</h4>
        <form>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-8">
                <fg-input type="text"
                          maxlength="100"
                          label="Nome do Plano"
                          name="Nome do Plano"
                          v-validate="{required: true}"
                          :error="getError('Nome do Plano')"
                          v-model="planName">
                </fg-input>
              </div>
              <div class="col-md-4">
                <fg-input label="Adquirente">
                  <el-select size="large"
                             placeholder="Selecione"
                             v-model="acquirerName">
                    <el-option v-for="option in acquirerList"
                               class="select-default"
                               :value="option"
                               :label="option"
                               :key="option">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>
            <div class="row form-group" style="margin-top: 20px">
              <div class="col-sm-4">
                <p-radio label="true" v-model="clientPlan">Plano Cliente</p-radio>
              </div>
              <div class="col-sm-4">
                <p-radio label="false" v-model="clientPlan">Plano Custo</p-radio>
              </div>
              <div class="col-sm-4">
                <p-checkbox class="text-left" v-model="enabled">
                  <b>Plano Ativo</b>
                </p-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <fg-input type="tel"
                          v-mask="['##/##/####']"
                          maxlength="10"
                          label="Data Início"
                          name="Data Início"
                          v-validate="{required: true, min:10}"
                          :error="getError('Data Início')"
                          v-model="startDate">
                </fg-input>
              </div>
              <div class="col-sm-5">
                <fg-input type="tel"
                          v-mask="['##/##/####']"
                          maxlength="10"
                          label="Data Término"
                          name="Data Término"
                          v-validate="{min:10}"
                          :error="getError('Data Término')"
                          v-model="endDate">
                </fg-input>
              </div>
              <div class="col-sm-2">
                <label>Débito</label>
                <money class="form-control input-lg"
                       v-model="debito"
                       v-bind="fee">
                </money>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <label>Crédito à vista</label>
                <money class="form-control input-lg"
                       v-model="credito"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 2x</label>
                </fg-input>
                <money class="form-control input-lg"
                       v-model="credito2x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 3x</label>
                <money class="form-control input-lg"
                       v-model="credito3x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 4x</label>
                <money class="form-control input-lg"
                       v-model="credito4x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 5x</label>
                <money class="form-control input-lg"
                       v-model="credito5x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 6x</label>
                <money class="form-control input-lg"
                       v-model="credito6x"
                       v-bind="fee">
                </money>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <label>Crédito 7x</label>
                <money class="form-control input-lg"
                       v-model="credito7x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 8x</label>
                <money class="form-control input-lg"
                       v-model="credito8x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 9x</label>
                <money class="form-control input-lg"
                       v-model="credito9x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 10x</label>
                <money class="form-control input-lg"
                       v-model="credito10x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 11x</label>
                <money class="form-control input-lg"
                       v-model="credito11x"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Crédito 12x</label>
                <money class="form-control input-lg"
                       v-model="credito12x"
                       v-bind="fee">
                </money>
              </div>
            </div>
            <div class="row" style="margin-top: 15px">
              <div class="col-sm-4" v-show="clientPlan == 'true'">
                <label>Faturamento Mínimo</label>
                <money class="form-control input-lg"
                       v-model="minimumBilling"
                       v-bind="money">
                </money>
              </div>
              <div class="col-sm-4" v-show="clientPlan == 'true'">
                <label>Faturamento Máximo</label>
                <money class="form-control input-lg"
                       v-model="maximumBilling"
                       v-bind="money">
                </money>
              </div>
              <div class="col-md-4">
                <fg-input label="Bandeira">
                  <el-select size="large"
                             placeholder="Selecione uma bandeira"
                             v-model="nameCardTerminalBanner">
                    <el-option v-for="option in tableDataBanners"
                               class="select-default"
                               :value="option.name"
                               :label="option.name"
                               :key="option.name">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" @click.prevent="insertUpdateBrand">Salvar</p-button>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs, getWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, moneyToFloat, isNullOrEmpty} from "../../../../../util/core.utils";
  import {Money} from 'v-money'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Money
    },
    data() {
      return {
        acquirerList: [],
        acquirerName: '',
        search: {
          key: ''
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        fee: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: ' %',
          precision: 2,
          masked: false
        },
        id: '',
        planName: '',
        debito: 0,
        credito: 0,
        credito2x: 0,
        credito3x: 0,
        credito4x: 0,
        credito5x: 0,
        credito6x: 0,
        credito7x: 0,
        credito8x: 0,
        credito9x: 0,
        credito10x: 0,
        credito11x: 0,
        credito12x: 0,
        clientPlan: 'true',
        customerPlan: true,
        costPlan: false,
        enabled: true,
        startDate: '',
        endDate: '',
        minimumBilling: 0,
        maximumBilling: 0,
        nameCardTerminalBanner: '',
        formEditShow: false,
        tableData: [],
        tableDataBanners: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    computed: {
      updateView() {
        this.planBannerCard = true
      }
    },
    mounted() {
      this.loadData()
      this.loadBanners()
    },
    methods: {
      toMoney,
      moneyToFloat,
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.id = ''
        postWs("/bo/card-terminal/list-card-terminal-plans",
          true, null,
          {
            enabled: null,
            key: this.search.key,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)

        callWs("/bo/card-terminal/get-acquirers",
          "GET", null, true, null,null,
          this.loadSuccessAcquirer,
          (error) => {
            swal({
              title: 'Falha!',
              text: 'Falha ao buscar adquirentes!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })

        return this.pagination.currentPage
      },
      loadSuccessAcquirer(response) {
        this.acquirerList = response.data
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      loadBanners() {
        getWs("/bo/card-terminal/list-card-terminal-banner", true, null, this.successLoadBanners, failWs)
      },
      successLoadBanners(response) {
        this.tableDataBanners = response.data
      },
      edit(id, planName, debito, credito, credito2x, credito3x, credito4x, credito5x, credito6x, credito7x,
           credito8x, credito9x, credito10x, credito11x, credito12x, customerPlan, costPlan, enabled,
           minimumBilling, maximumBilling, startDate, endDate, nameCardTerminalBanner, acquirerName){
        this.formEditShow = true
        this.id = id
        this.name = planName
        this.planName = planName
        this.debito = debito
        this.credito = credito
        this.credito2x = credito2x
        this.credito3x = credito3x
        this.credito4x = credito4x
        this.credito5x = credito5x
        this.credito6x = credito6x
        this.credito7x = credito7x
        this.credito8x = credito8x
        this.credito9x = credito9x
        this.credito10x = credito10x
        this.credito11x = credito11x
        this.credito12x = credito12x
        this.acquirerName = acquirerName
        if(customerPlan == true) {
          this.clientPlan = 'true'
        } else {
          this.clientPlan = 'false'
        }
        this.enabled = enabled
        this.minimumBilling = minimumBilling
        this.maximumBilling = maximumBilling
        this.startDate = startDate
        this.endDate = endDate
        this.nameCardTerminalBanner = nameCardTerminalBanner
      },
      insertUpdateBrand(){
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          if(isNullOrEmpty(this.acquirerName)){
            swal({
              title: 'Aviso!',
              text: 'Favor escolher uma Adquirente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          if(this.clientPlan == 'true') {
            this.customerPlan = true;
            this.costPlan = false;
          } else {
            this.customerPlan = false;
            this.costPlan = true;
          }
          callWs("/bo/card-terminal/card-terminal-plans",
            "POST", null, true, null,
            {id: this.id, planName: this.planName, debito: (this.debito), credito: (this.credito),
              credito2x: (this.credito2x), credito3x: (this.credito3x), credito4x: (this.credito4x),
              credito5x: (this.credito5x), credito6x: (this.credito6x), credito7x: (this.credito7x),
              credito8x: (this.credito8x), credito9x: (this.credito9x), credito10x: (this.credito10x),
              credito11x: (this.credito11x), credito12x: (this.credito12x), customerPlan: this.customerPlan,
              costPlan: this.costPlan, enabled: this.enabled, minimumBilling: (this.minimumBilling),
              maximumBilling: (this.maximumBilling), startDate: this.startDate, endDate: this.endDate,
              nameCardTerminalBanner: this.nameCardTerminalBanner, acquirerName: this.acquirerName},
            () => {
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
              this.formEditShow = false
              this.loadData()
            }, (error) => {
              if (error.response.status == 409) {
                swal({
                  title: 'Aviso!',
                  text: 'Nome já existe para outro plano cadastrado!',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Erro!',
                text: 'Erro interno, verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
      insertForm(){
        this.id = ''
        this.planName = ''
        this.debito = 0
        this.credito = 0
        this.credito2x = 0
        this.credito3x = 0
        this.credito4x = 0
        this.credito5x = 0
        this.credito6x = 0
        this.credito7x = 0
        this.credito8x = 0
        this.credito9x = 0
        this.credito10x = 0
        this.credito11x = 0
        this.credito12x = 0
        this.costPlan = true
        this.customerPlan = false
        this.enabled = true
        this.startDate = ''
        this.endDate = ''
        this.minimumBilling = 0
        this.maximumBilling = 0
        this.formEditShow = true
        this.nameCardTerminalBanner = ''
        this.acquirerName = ''
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      updatePlanBannerCard(event) {
        if(event) {
          this.costPlan = false
        } else {
          this.costPlan = true
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .dzs-acc-search {
    .el-input__inner[disabled] {
      background-color: #a8a8a8 !important;
    }

    .el-input__inner[readonly] {
      background-color: #ffffff !important;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .input-group.has-danger .input-group-append .input-group-text {
      border-right: 1px solid #ef8157;
      border-left: none;
      border-radius: 0 5px 5px 0;
      border-top: 1px solid #ef8157;
      border-bottom: 1px solid #ef8157;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
