<template>
  <div class="dzs-boleto">
      <div class="row" style="padding: 15px">
        <div class="col-md-5">
          <card style="padding: 5%;height: 100%">
            <h4 slot="header" class="card-title">
              Pagamentos
            </h4>
            <banner></banner>
            <br>
            <br>
            <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Digite o código de barras e valide o mesmo.<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Após verificar os dados, clique em 'Confirmar Pagamento'.<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> O horário limite para o pagamento de boletos se encerra ás <b>19H00</b> horas.<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> O horário limite para o pagamento de taxas e contas de concessionárias se encerra ás <b>22H00</b> horas.</p>
          </card>
        </div>

        <div class="col-md-7">
          <card style="height: 100%">
            <div class="row">
              <div class="col-md-9">
                <label class="control-label">Código de Barras</label>
                <fg-input v-mask="['#####.##### #####.###### #####.###### # ##############', '###########-# ###########-# ###########-# ###########-#']"
                          type="tel"
                          v-model="validate.digitableLine"
                          v-validate="{required: true}"
                          :error="getError('Código de Barras')"
                          maxlength="55"
                          name="Código de Barras"
                          key="codigo-barras">
                </fg-input>
                <label class="control-label" style="float: right">
                  Saldo: <b>R$ {{toMoney(accountBalance)}}</b>
                  <br>
                  Saldo disponível para saque: <b>R$ {{toMoney(accountAvailableBalance)}}</b>
                </label>
              </div>
              <div class="col-md-3" v-if="boleto.value === '0'">
                <p-button type="info" style="margin-top: 25px;"
                          @click="validateDigitableLine">
                  Validar
                </p-button>
              </div>
              <div class="col-md-3" v-if="boleto.value !== '0'">
                <p-button  style="margin-top: 25px; cursor: default">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Validado
                </p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="boleto.value !== '0' && isNullOrEmpty(qrCode)">
                <boleto-details :boleto=boleto> </boleto-details>
                <div class="col-md-12" style="text-align: right">
                  <p-button type="warning" style="margin-top: 25px;"
                            @click="cancell">
                    <i slot="label" class="nc-icon nc-simple-remove"></i>
                    Cancelar Pagamento
                  </p-button>
                  <p-button type="info" style="margin-top: 25px; margin-left: 25px"
                            @click="confirm">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Confirmar Pagamento
                  </p-button>
                </div>
              </div>
            </div>
            <!-- Bloco Dispositivo Não habilitado -->
            <div class="row" v-if="!isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && !isNullOrEmpty(totpQrCodeUri)">
              <div class="col-md-12">
                <card style="background-color: #00d19a; text-align: center">
                  <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não esta cadastrado!</h4>
                  <card align="center" >
                    <span>Para liberar suas transações siga os passos abaixo:</span>
                    <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
                      <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
                    </div>

                    <div class="row" style="padding: 3%">
                      <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                        <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">SuitPay</b>.</span></p>
                        <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                        <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                        <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                        <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                          <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                        </a>
                        <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                          <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                        </a>

                      </div>

                      <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                        <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                        <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no aplicativo </p>
                        <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                        <p><b>4</b> - Confirme a operação</p>
                        <p><b>5</b> - Pronto!</p>
                      </div>
                    </div>
                    <br>
                    <div style="text-align: center">
                      <p-button @click="()=>{qrCode = ''; clearInterval(validateIntervalLoop)}"
                                class="btn btn-danger" >
                        <i class="fas fa-chevron-left"/> Voltar
                      </p-button>
                    </div>
                  </card>
                </card>
              </div>
            </div>
            <!-- Bloco Dispositivo Habilitado -->
            <div class="row" v-if="!isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
              <div class="col-md-12">
                <card style="background-color: #00d19a; text-align: center; height: 100%">
                  <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
                  <card align="center" style="height: 100%" >

                    <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

                    <div class="row" style="padding: 3%">
                      <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                        <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                        <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                        <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                        <p><b>4</b> - Confirme a operação.</p>
                        <p><b>5</b> - Pronto!</p>
                      </div>
                    </div>

                    <span>Informe o <b>SuitID</b>: </span>
                    <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

                    <div class="row" v-if="!codeValid">
                      <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
                    </div>

                    <div style="text-align: center">
                      <p-button @click="()=>{qrCode = ''; clearInterval(validateIntervalLoop)}"
                                class="btn btn-danger" >
                        <i class="fas fa-chevron-left"/> Voltar
                      </p-button>
                      <p-button @click="()=>{validateCode()}"
                                class="btn btn-primary"
                                :disabled="totpConfirmBtnDisabled"
                      >
                        <i class="fas fa-chevron-right"/> Confirmar
                      </p-button>
                    </div>
                    <hr/>
                    <span>Dados da Transferência</span>
                    <boleto-details :boleto=boleto> </boleto-details>
                  </card>
                </card>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="col-md-12" v-if="tableData!=null && tableData.length > 0">
          <card>
          <div class="card-body row">
            <div class="col-sm-12" align="center">
              <h4 slot="header" class="card-title">
                Relação de Pagamentos
              </h4>
              <hr>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="150" label="Beneficiário">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.beneficiaryName}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="CNPJ/CPF Benef.">
                  <template slot-scope="props">
                    <span>
                      <span>{{returnDocument(props.row.beneficiaryDocument, props.row.beneficiaryDocumentType)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="Valor">
                  <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Data Pagam.">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.created}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Status">
                  <template slot-scope="props">
                    <span>
                      <span>{{props.row.status}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :width="60" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <p-button type="info" size="sm" icon title="Ver Comprovante"
                              @click="printBoleto(props.row.id)">
                      <i class="fas fa-file-invoice"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-success">
              <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                {{pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>

    <input type="hidden" id="digitableLine-copy" :value="boleto.digitableLine">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {postWs, failWs} from "src/ws.service"
  import {
    toMoney,
    moneyToFloat,
    isNullOrEmpty,
    notNull,
    isValidCnpj,
    isValidCpf
  } from "src/util/core.utils"
  import swal from 'sweetalert2'
  import Banner from "../../../UIComponents/Promotion/Banner";
  import BoletoDetails from "@/components/UIComponents/Dzs/Transfer/BoletoDetails";
  import {updateBalance} from "@/util/account.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      BoletoDetails,
      PPagination,
      Banner
    },
    data() {
      return {
        validate: {
          digitableLine: '',
          beneficiaryDocument: '',
          beneficiaryName: '',
          dueDate: '',
        },
        accountBalance: '0,00',
        accountAvailableBalance: '0,00',
        boleto: {
          digitableLine: '',
          barCode: '',
          dueDate: '',
          beneficiaryDocument: '',
          beneficiaryName: '',
          beneficiaryDocumentType: '',
          value: '0',
          type: '',
          discounts: 0,
          interest: 0,
          assessment: 0,
          amountPaid: 0,
          description: '',
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        transactionAuthorizationTotp: 'false',
        qrCode: '',
        validationCode: '',
        totpQrCodeUri : '',
        totpManualCode : '',
        totpConfirmBtnDisabled: false,
        codeValid: true,
        validateIntervalLoop: null
      }
    },
    created() {
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
    },
    mounted() {
      this.getUpdatedBalance()
      this.loadData()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      validateDigitableLine() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.callValidate()
        })
      },
      callValidate(){
        this.registrandoBoleto = true;
        postWs("/boleto/validate-digitable-line",
          true, null, this.validate, this.successValidate, this.failValidate)
      },
      confirm() {
        postWs("/boleto/boleto-payment",
          true, null, this.boleto, this.successConfirm, this.failConfirm)
      },
      cancell() {
        this.validate.digitableLine = ''
        this.boleto.digitableLine = ''
        this.boleto.barCode = ''
        this.boleto.dueDate = ''
        this.boleto.beneficiaryDocument = ''
        this.boleto.beneficiaryName = ''
        this.boleto.beneficiaryDocumentType = ''
        this.boleto.value = '0'
        this.boleto.type = ''
        this.boleto.discounts = 0
        this.boleto.interest = 0
        this.boleto.assessment = 0
        this.boleto.amountPaid = 0
        this.boleto.description = ''
        this.$validator.reset()

      },
      successValidate(response) {
        this.pagination.currentPage = 1;
        if(response.data.value == null || response.data.value == '') {
          swal({
            title: 'Aviso!',
            text: "Boleto não encontrado na CIP, verifique os dados e tente novamente!",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.showDataBoleto(response.data);
          this.loadData();
        }
      },
      failValidate(error){
        try{
          const text = JSON.parse(error.response.request.response);
          swal({
            title: 'Aviso!',
            text: text.description,
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }catch (e){
          swal({
            title: 'Aviso!',
            text: "Ocorreu um problema ao consultar o boleto, entre em contato com o suporte!",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      failConfirm(error) {
        this.registrandoBoleto = false;
        let text = 'Não foi possível realizar o pagamento do boleto! Confira os dados e tente novamente.'
        if (error.response.data == 'NO_FUNDS') {
          text = 'Você não possui saldo suficiente! Verifique o valor do boleto a ser pago, o saldo disponível em sua conta e tente novamente.'
        } else {
          text = error.response.data.message
        }
        swal({
          title: 'Aviso!',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        this.cancell()
      },
      successConfirm(response) {

        if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
          this.qrCode = response.data.qrCode
          this.totpQrCodeUri = response.data.totpQrCodeUri
          this.totpManualCode = response.data.totpManualCode
          return
        } else if(response.data.response == 'OK'){
          this.success()
          return
        }
        swal({
          title: 'Aviso!',
          text: 'Falha ao realizar pagamento! Tente mais tarde.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      success(){
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Pagamento realizado com sucesso!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'success'
        })

        this.boleto.value = '0'
        this.boleto.barcode = ''
        this.boleto.digitableLine = ''
        this.boleto.dueDate = ''
        this.digitableLine = ''
        this.qrCode = ''
        this.validationCode = ''
        this.totpQrCodeUri = ''
        this.totpManualCode = ''
        this.cancell()
        this.loadData()
        this.getUpdatedBalance()
      },
      failConfirmCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Erro!',
          text: 'Erro ao transferir, tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          this.success,
          this.failConfirmCode
        )
      },
      printBoleto(id) {
        if (isNullOrEmpty(id)) {
          id = this.boleto.id
        }
        postWs("/boleto/pdf-boleto-payment",
          true,
          {id: id}, null,
          this.successPrintBoleto,
          (error) => {
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Transação não encontrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return;
            }
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o comprovante, tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successPrintBoleto(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Comprovante De Pagamento</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do comprovante, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      showDataBoleto(boleto) {
        this.boleto.digitableLine = boleto.digitableLine
        this.boleto.barCode = boleto.barCode
        this.boleto.dueDate = boleto.dueDate
        this.boleto.beneficiaryDocument = boleto.beneficiaryDocument
        this.boleto.beneficiaryName = boleto.beneficiaryName
        this.boleto.beneficiaryDocumentType = boleto.beneficiaryDocumentType
        this.boleto.value = boleto.value
        this.boleto.type = boleto.type
        this.boleto.discounts = boleto.discounts
        this.boleto.interest = boleto.interest
        this.boleto.assessment = boleto.assessment
        this.boleto.amountPaid = boleto.amountPaid
        this.boleto.description = boleto.description
      },
      loadData() {
        postWs("/boleto/last-requested-boletos-payment",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      returnDocument(document, documentType) {
        if(documentType == 'J') {
          return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        } else if(documentType == 'F') {
          return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        } else {
          return "-"
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      getUpdatedBalance() {
        updateBalance().then(() => {
          this.accountBalance = moneyToFloat(notNull(localStorage.getItem('accountBalance'), '0,00'))
          this.accountAvailableBalance = moneyToFloat(notNull(localStorage.getItem('accountAvailableBalance'), '0,00'))
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-boleto {
    .vue-barcode-element {
      height: 86px !important;
      display: table;
      margin: 0 auto !important;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    @media (max-width: 800px) {
      .vue-barcode-element {
        height: 50px !important;
        width: 330px !important;
      }
    }
  }
</style>
