<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <img height="1" width="1" style="display:none"
         src="https://www.facebook.com/tr?id=678441956566142&ev=PageView&noscript=1" alt=""/>
    <notifications transition-name="notification-list" transition-mode="out-in">

    </notifications>
    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'
import SHA256 from 'crypto-js/sha256';
import encHex from 'crypto-js/enc-hex';

!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '678441956566142');

function getCookie(cname){
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  methods: {
    async trackPageView() {
      const data = [{
        event_name: 'PageView',
        event_time: Math.floor(Date.now() / 1000),
        user_data: {
          fn: SHA256(localStorage.getItem("name")).toString(encHex).toLowerCase(),
          em: SHA256(localStorage.getItem("email")).toString(encHex).toLowerCase(),
          fbc: getCookie('_fbc'),
          fbp: getCookie('_fbp')
        },
        event_source_url: window.location.href,
        action_source: 'website',
      }];

      try {
        await fetch(`https://graph.facebook.com/v13.0/678441956566142/events?access_token=EABIGJgzrYhUBO3QVMHJHesbHgft7VznMZBxHULjnC7cFugcxgCob0gIjubltNMYPDOgYU6URDn99goEKeFfWVQq82FqgaooKICNSAMXZCRFbD2dAPDqxMlC2uZBDgriC1ZCtJeSScg7mhpZB3ZC86R8WXtyiV5ZCzvKwBZBJZAlaymrxcnOvyvEQj6VeKUBgd7JW4UAZDZD`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({data}),
        });
      } catch (error) {
        console.error('Erro ao enviar o evento PageView', error);
      }
    }
  },
  mounted() {
    this.trackPageView();
    this.$router.afterEach(() => {
      this.trackPageView();
    });
  }
};

</script>
<style lang="scss">

</style>
