<template>
  <div>
    <h2 class="content__headline">A única plataforma de<br>processamento de pagamentos digitais<br>com <span class="card--highlight">Internet Banking 100% integrado.</span> </h2>
    <div class="image-wrapper">
      <img class="image" src="/static/img/login/carousel/elements/grafico_tmp.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "banking-content"
}
</script>
<style scoped>
.content__headline {
  text-align: center;
  padding-left: 20%;
}
.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(9%, -8%);
  padding: -8% 0 0% 9%;
}
.image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
