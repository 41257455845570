<template>
  <div class="dzs-suit-journey">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">Realizar Upload de Nota Fiscal</h4>
          <div class="row">
            <form class="row col-md-12 align-items-end" @submit.prevent="uploadFiscalNote">
              <div class="col-lg-4">
                <label for="fiscalNote">Nota Fiscal</label>
                <label for="fiscalNote" class="btn fiscal-note-label">
                  <i class="fa-regular fa-image"></i> {{ fiscalNoteFileName }}
                </label>
                <input id="fiscalNote" ref="fiscalNoteIpt" type="file" name="fiscalNote" hidden
                       @input="handleFileFiscalNoteChanged" data-vv-as="PDF da Nota Fiscal" v-validate="{required: true}"/>
                <fg-input type="file" :error="getError('fiscalNote')"></fg-input>
              </div>
              <div class="col-lg-1">
                <button class="btn" type="submit">Enviar</button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="margin: 0 auto">
        <card>
          <h4 slot="header" class="card-title">Buscar por Notas Fiscais</h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [5, 25, 50]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="uploadedFiscalNotes"
                        style="width: 100%">
                <el-table-column label="Arquivo">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.name }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Data de Upload">
                  <template slot-scope="item">
                    <span>{{ formatDate(item.row.createdDate, 'DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>
                <el-table-column :width="40" label="">
                  <template slot-scope="item">
                    <span>
                      <p-button type="success" size="sm" icon title="Editar"
                                @click="downloadFiscalNote(item.row)">
                        <i class="fas fa-download"/>
                      </p-button>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                {{pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.search">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs} from "src/ws.service"
import {toMoney} from 'src/util/core.utils'
import {daysBetween, formatDate} from "@/util/date.utils";
import swal from "sweetalert2";
import download from "downloadjs";
import PPagination from "@/components/UIComponents/Pagination.vue";

export default {
  components: {
    PPagination,
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
  },
  data() {
    return {
      fiscalNoteFileName: 'Selecionar Arquivo',
      uploadedFiscalNotes: [],
      startDate: new Date(),
      endDate: new Date(),
      pagination: {
        perPage: 5,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
            this.endDate = date
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
              this.endDate = date
            }
          }]
      },
      dateOptsEnd: {
        disabledDate: this.disabledEndDate
      },
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    formatDate,
    toMoney,
    validatePeriod() {
      this.endDate = this.startDate
    },
    uploadFiscalNote() {
      const formData = new FormData()
      const fiscalNoteFile = this.$refs.fiscalNoteIpt.files[0]
      formData.append('fiscalNoteFile', fiscalNoteFile)

      callWs('/bo/suitjourney/upload-fiscal-note', 'POST', {'Content-Type': 'multipart/form-data'},
        true,
        null,
        formData,
        this.successUploadFiscalNote,
        this.failUploadFiscalNote
      )
    },
    successUploadFiscalNote() {
      swal({
        title: 'Sucesso!',
        text: 'A planilha foi carregada com sucesso!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      }).catch(swal.noop)
      this.search()
    },
    failUploadFiscalNote() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao carregar a planilha. Verifique os dados e tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
      this.search()
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    search() {
      if (daysBetween(this.startDate, this.endDate) > 31) {
        swal({
          title: 'Aviso!',
          text: 'O período selecionado deve ser de no máximo 31 dias!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      callWs('/bo/suitjourney/uploaded-fiscal-notes', 'POST', null, true, null,
        {
          startDate: formatDate(this.startDate, "DD/MM/YYYY"),
          endDate: formatDate(this.endDate, "DD/MM/YYYY"),
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        },
        this.successSearch,
        this.failSearch)
    },
    successSearch(response) {
      this.uploadedFiscalNotes = response.data.list
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.uploadedFiscalNotes.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
    },
    failSearch(error) {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar por Notas Fiscais salvas.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    downloadFiscalNote(fiscalNoteFile) {
      callWs('/bo/suitjourney/download-fiscal-note', 'POST', null, true,
        {
          fiscalNoteId: fiscalNoteFile.id,
        }, null,
        (response) => this.successDownloadFiscalNote(response, fiscalNoteFile.name),
        this.failDownloadFiscalNote
      )
    },
    successDownloadFiscalNote(response, fileName) {
      try {
        let pdfWindow = window.open("about:blank");
        pdfWindow.document.write(`<html<head><title>Suit Journey - Nota Fiscal(${fileName})</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>`);
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'></embed></body></html>");
      } catch (ex) {
        swal({
          title: 'Atenção!',
          text: 'Ocorreu um erro ao realizar o download do arquivo. Tente Novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    failDownloadFiscalNote() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao realizar o download do arquivo. Tente Novamente!',
        buttonsStyling: true,
        timer: 10000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    handleFileFiscalNoteChanged(event) {
      this.fiscalNoteFileName = event.target.files[0].name
    }
  }
}
</script>
<style lang="scss">
.dzs-suit-journey {
  .btn.btn-info.btn-default {
    float: right;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #0a7282 !important;
  }

  .fiscal-note-label {
    color: #FFFFFF;
    display: block;
    margin-top: 0px;
  }
}
</style>
