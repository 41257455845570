<template>
  <div class="dzs-acc-edit">
    <card>
      <div class="row">
        <div class="col-md-12">
          <card>
            <div class="card-header">
              <h6>
                Novo Atendimento
              </h6>
            </div>
            <div class="row card-body">
              <div class="d-flex justify-content-center" style="width: 100%">
                <div class="row col-md-4">
                  <label class="control-label">Descrição do Atendimento</label>
                  <textarea style="width: 100%" rows=5 v-model="description" name="Descrição"
                            v-validate="{required: true}" :error="getError('Descrição')" maxlength="2000" />
                </div>
              </div>

              <div class="d-flex justify-content-center" style="width: 100%">
                <div class="row col-md-2">
                  <p-button style="margin-top: 22px; margin-left: auto!important; margin-right: auto;}" type="primary" round @click.prevent="addHistory()">
                    <i class="fa-solid fa-comment-dots"></i> Adicionar
                  </p-button>
                </div>
              </div>

            </div>
          </card>
          <div class="row" v-if="historyToSave.length > 0">
            <div class="col-md-12">
              <card>
                <div class="card-header">
                  <h6>
                    Históricos a enviar
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <el-table class="table-striped"
                                :data="historyToSave"
                                style="width: 50%; margin: auto">
                        <el-table-column :min-width="90" label="Data">
                          <template v-slot="props">
                            <span>{{props.row.date}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="20" label="Ação">
                          <template v-slot="props">
                            <p-button type="primary" size="sm" icon title="Visualizar" style="margin-right: 5px" @click="viewHistory(props.row, false)">
                              <i class="fa-regular fa-eye"></i>
                            </p-button>
                            <p-button type="danger" size="sm" icon title="Remover" style="margin-right: 5px" @click="remHistory(props.row.date)">
                              <i class="fa-solid fa-xmark"></i>
                            </p-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <card>
                <div class="card-header">
                  <h6>
                    Histórico de Atendimentos
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <el-table class="table-striped"
                                :data="historyList"
                                style="width: 50%; margin: auto">
                        <el-table-column :min-width="90" label="Data">
                          <template v-slot="props">
                            <span>{{props.row.date}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Vendedor">
                          <template v-slot="props">
                            <span>{{props.row.salePersonName}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="20" label="Ação">
                          <template v-slot="props">
                            <p-button type="primary" size="sm" icon title="Visualizar" @click="viewHistory(props.row, true)">
                              <i class="fa-regular fa-eye"></i>
                            </p-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                  <div class="row" style="width: 40%; margin: auto">
                    <div class="col-sm-6 pagination-success">
                      <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                        {{pagination.totalNumber}} REGISTROS.</p>
                    </div>
                    <div class="col-sm-6">
                      <p-pagination class="pull-right"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total="pagination.totalNumber"
                                    :click="loadHistory">
                      </p-pagination>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import {failWs, postWs} from 'src/ws.service'
import swal from "sweetalert2";
import PPagination from "@/components/UIComponents/Pagination";
import {isNullOrEmpty} from "@/util/core.utils";

export default {
  name: 'commercial-client-history',
  props: {
    username: null,
  },
  components: {
    PPagination,
  },
  created() {
    this.$emit("saveInterface", {save: () => this.save()})
    this.loadHistory();
  },
  data() {
    return {
      historyList: [],
      historyToSave: [],
      description: '',
      pagination: {
        perPage: 10,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
    }
  },
  methods: {
    addHistory(){
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return
        }
        if(!isNullOrEmpty(this.description.trim())){
          this.historyToSave.push({date: new Date().toLocaleString(), clientUsername: this.username, description: this.description})
          this.description = null
          this.$validator.reset()
        }
      })
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    loadHistory(){
      postWs("/bo/commercial/dashboard/listHistory", true, null,
        {clientUsername: this.username,
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage,
          },
      response => {
                    this.historyList = response.data.list
                    this.pagination.totalNumber = response.data.totalNumber
                    this.pagination.pageMaxNumber = response.data.pageMaxNumber
                    if (this.pagination.totalNumber > 0) {
                      this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
                      this.pagination.toNumber = ((this.pagination.fromNumber + this.historyList.length) - 1)
                    } else {
                      this.pagination.fromNumber = 0
                      this.pagination.toNumber = 0
                    }
                  },
                failWs)
    },
    save(){
      if(this.historyToSave.length > 0){
        this.doRecursiveSave(0)
      } else {
        this.$emit("saveFinished")
      }
    },
    doRecursiveSave(index) {
      postWs("/bo/commercial/dashboard/addHistory", true, null,
        this.historyToSave[index], response =>{
          if(this.historyToSave.length - 1 === index){
            this.$emit("saveFinished")
            this.historyToSave = []
            this.loadHistory()
          } else {
            this.doRecursiveSave(index + 1)
          }
        }, failWs)
    },
    remHistory(date){
      let indexToRemove = -1
      this.historyToSave.some((history, index) =>{
        if(history.date === date){
          indexToRemove = index
          return true;
        }
      })
      if(indexToRemove >= 0){
        this.historyToSave.splice(indexToRemove, 1)
      }
    },
    viewHistory(row, showSalePersonName){
      let content =
        '<div style="font-size: medium;">'
      if(showSalePersonName){
        content += '   Realizado por: ' + row.salePersonName
      }
      content +=
        '<br>' +
        '   Horário: ' + row.date +
        '   <hr>' +
        '   <br>' +
        '   <div style="text-align: left">' +
        '     <h6> Descrição </h6>'+
        '     <br>'+
        '     <span> ' + row.description.replaceAll("\n", "<br>") +'</span>' +
        '   </div>' +
        '</div>'

      swal({
        title: 'Histórico de Atendimento!',
        html: content,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'info'
      })
    },
  }
}

</script>

<style lang="scss">
</style>
