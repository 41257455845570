<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Marcas</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-5">
            <label class="control-label">Nome da Marca</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-3">
            <p-button type="success" style="margin-top: 25px; float: right"
                      @click="insertForm">
              <i slot="label" class="nc-icon nc-simple-add"></i>
              Nova Marca
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="ID">
                <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Status">
                <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="80" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="warning" size="sm" icon title="Editar"
                            @click="edit(props.row.id, props.row.name, props.row.enabled)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-show="formEditShow" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4 v-if="id!=''">Editar a Marca <b>{{name}}</b></h4>
        <h4 v-if="id==''">Inserir Nova Marca</h4>
        <form>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <fg-input type="text"
                          maxlength="100"
                          label="Nome da Marca"
                          name="Nome da Marca"
                          v-validate="{required: true}"
                          :error="getError('Nome da Marca')"
                          v-model="name">
                </fg-input>
              </div>
            </div>
            <div class="row form-group" style="margin-top: 20px">
              <div class="col-sm-3">
                <p-checkbox class="text-left" v-model="enabled">
                  <b>Marca Ativa</b>
                </p-checkbox>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" @click.prevent="insertUpdateBrand">Salvar</p-button>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          key: ''
        },
        id: '',
        name: '',
        enabled: true,
        formEditShow: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.id = ''
        postWs("/bo/card-terminal/list-card-terminal-brand",
          true, null,
          {
            enabled: null,
            key: this.search.key,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      edit(id, name, enabled){
        this.formEditShow = true
        this.id = id
        this.name = name
        this.enabled = enabled
      },
      insertUpdateBrand(){
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          callWs("/bo/card-terminal/card-terminal-brand",
            "POST", null, true, null,
            {id: this.id, name: this.name, enabled: this.enabled},
            () => {
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
              this.formEditShow = false
              this.loadData()
            }, (error) => {
              if (error.response.status == 409) {
                swal({
                  title: 'Aviso!',
                  text: 'Nome já existe para outra Marca cadastrada!',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Erro!',
                text: 'Erro interno, verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
      insertForm(){
        this.id = ''
        this.enabled = true
        this.name = ''
        this.formEditShow = true
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
