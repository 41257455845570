<template>
  <div class="dzs-paymentlink">
    <div class="row">
      <div class="col-md-5" v-if="bankDetailsForm || noticeForm || gatewayAccountUnderAnalysys.form">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title" style="text-align: center">
            Link de Pagamento <b style="color: #000000 !important;">SuitPay</b>
          </h4>
          <banner></banner>
          <br>
          <br>
          <p>Com o Link de Pagamento <b style="color: #000000 !important;">SuitPay</b> você tem:</p>
          <p>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Tecnologia de ponta que processa milhares de transações por segundo.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Cartão de crédito, boleto e Pix para o seu cliente pagar como quiser e você não perder nenhuma venda.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Dashboard personalizada para acompanhar vendas e monitorar o fluxo financeiro da sua empresa.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Proteção contra fraudes e contestações, os dados dos clientes ficam seguros e criptografados.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimento das vendas pelo CARTÂO em D+3.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimento nas vendas por BOLETO em D+2.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Recebimento nas vendas por PIX na hora.<br>
            <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Links ilimitados.
          </p>
        </card>
      </div>
      <div class="col-md-7" v-show="gatewayAccountUnderAnalysys.form">
        <card style="background-color: #00d19a; padding-bottom: 50px; text-align: center" >
          <h4 slot="header" class="card-title" style="color: #fff; text-align: center">Seus dados bancários estão em análise!</h4>
          <card style=" text-align: center; padding: 5%;">
            <div class="row">
              <div class="col-md-12">
                <p>Os dados abaixo foram submetidos à análise. Poderá levar até <b>72 horas</b> para análise da conta bancária e liberação do serviço.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Banco: {{gatewayAccountUnderAnalysys.bankCode}} - {{gatewayAccountUnderAnalysys.bankName}}</p>
                <p>Agência: {{gatewayAccountUnderAnalysys.agencyNumber}}</p>
                <p>Conta: {{gatewayAccountUnderAnalysys.accountNumber}} - {{gatewayAccountUnderAnalysys.accountNumberDv}}</p>
                <p>Beneficiário: {{gatewayAccountUnderAnalysys.ownerName}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Enquanto isso desfrute de todos os outro serviços que a <b style="color: #000000 !important;">SuitPay</b> fornece a você e a sua empresa, e logo esperamos que você possa utilizar o melhor e mais completo Link de Pagamentos do mercado.</p>
              </div>
            </div>
          </card>
          <br>
          <img style="max-width: 150px" src="/static/img/vue-logo.png" alt="..."  ><br>
          <span style="font-size: 15px; color: #FFF"><b>Anytime, Anywhere</b>!</span>
        </card>
      </div>
      <div class="col-md-7" v-show="noticeForm">
        <card>
          <h5>{{this.noticeTitle}}</h5>
          <p>{{this.noticeText}}</p>
          <div class="row">
            <div class="col-md-12">
              <p>Logo você vai poder utilizar o melhor link de pagamento.</p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-7" v-show="bankDetailsForm">
        <card>
          <h5>{{this.noticeTitle}}</h5>
          <p><b>Seja à vista ou parcelado</b>, a <b style="color: #000000 !important;">SuitPay</b> tem as melhores taxas para sua loja vender mais.<br><br>
            Para iniciar a utilização do link, informe uma conta bancária para recebimento através de cartão de crédito.
            Os valores dessa modalidade serão depositados diretamente nesta conta informada.
            Lembrando que a conta deve ser de mesma titularidade dos dados cadastrados na abertura da conta <b style="color: #000000 !important;">SuitPay</b>.<br><br>
            Após a inserção dos dados pedimos até 24 horas para análise da conta bancária e liberação do serviço.
          </p>
          <p>Os recebimentos através de Boleto e PIX serão depositados normalmente em sua Conta <b style="color: #000000 !important;">SuitPay</b>.</p>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Banco</label>
              <autocomplete ref="bankAc"
                            v-validate="{required: bankDetailsForm}"
                            placeholder="Selecionar"
                            :source="banksCodes"
                            input-class="form-control"
                            results-value="code"
                            :results-display="displayBankName"
                            @selected="selectBank">
              </autocomplete>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-12">
              <label class="control-label">Tipo de Conta</label>
              <el-select size="large" placeholder="Selecionar" style="width: 100%"
                         v-model="bankDetails.accountType">
                <el-option class="select-success"
                           value="CC"
                           label="Corrente"/>
                <el-option class="select-info"
                           value="PP"
                           label="Poupança"/>
              </el-select>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-8">
              <label class="control-label">Agência</label>
              <fg-input v-model="bankDetails.agencyNumber" style="width: 100%"
                        v-validate="{required: bankDetailsForm, agencyBankValid: true}" type="tel" maxlength="5"
                        :error="getError('Agência Destino')"
                        name="Agência Destino" v-mask="['#####']">
              </fg-input>
            </div>
            <div class="col-md-4">
              <label class="control-label">Dígito da Agência</label>
              <fg-input v-model="bankDetails.agencyDv" style="width: 100%"
                        type="tel" maxlength="1"
                        name="Dígito" v-mask="['#']">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <label class="control-label">Número da Conta</label>
              <fg-input v-model="bankDetails.accountNumber" style="width: 100%"
                        v-validate="{required: bankDetailsForm}" type="tel" maxlength="12"
                        :error="getError('Conta Destino')"
                        name="Conta Destino" v-mask="['############']">
              </fg-input>
            </div>
            <div class="col-md-4">
              <label class="control-label">Dígito da Conta</label>
              <fg-input v-model="bankDetails.accountNumberDv" style="width: 100%"
                        v-validate="{required: bankDetailsForm}"
                        type="tel" maxlength="1" :error="getError('Dígito')"
                        name="Dígito" v-mask="['#']">
              </fg-input>
            </div>
          </div>
          <div class="row" v-show="legalPersonOwnerForm">
            <div class="col-md-12">
              <p>Precisamos também dos dados do representante legal da empresa, conforme documentos enviados na abertura da conta.</p>
            </div>
            <div class="col-md-12">
              <fg-input type="text"
                        label="Nome Completo Representante Legal"
                        maxlength="100"
                        name="Nome Completo Representante Legal"
                        v-validate="{required: legalPersonOwnerForm}"
                        :error="getError('Nome Completo Representante Legal')"
                        v-model="bankDetails.ownerName">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="CPF Representante Legal"
                        type="tel"
                        v-mask="['###.###.###-##']"
                        name="CPF Representante Legal"
                        v-validate="{cpfCnpjValid:legalPersonOwnerForm, required: legalPersonOwnerForm, min: 14}"
                        :error="getError('CPF Representante Legal')"
                        v-model="bankDetails.ownerDocument">
              </fg-input>
            </div>
          </div>
          <div class="text-center" style="padding-bottom: 15px">
            <button type="info" @click="saveBankDetails" class="btn btn-info btn-fill btn-wd">
              Salvar Dados Complementares
            </button>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-show="!bankDetailsForm && !noticeForm && !gatewayAccountUnderAnalysys.form">
        <card>
          <h4 slot="header" class="card-title">
            Link de Pagamento <b style="color: #000000 !important;">SuitPay</b>
          </h4>
          <div class="row" style="padding-bottom: 10px">
            <div class="col-md-12" v-show="!disableFormProducts && link == '' && !disableInfo">
              <div class="card-body row">
                <div class="col-md-12">
                  <h6>Inserir Produtos / Serviços</h6>
                </div>
                <div class="col-md-4">
                  <label class="control-label">Descrição</label>
                  <fg-input v-model="products.description" maxlength="40"/>
                </div>
                <div class="col-sm-2">
                  <fg-input label="Quantidade">
                    <el-select
                      size="large"
                      v-model="products.quantity"
                      placeholder="Qtd">
                      <el-option
                        class="select-default"
                        v-for="item in [1,2,3,4,5,6,7,8,9,10]"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <label class="control-label">Valor</label>
                  <money v-model="products.value" v-bind="money" class="form-control input-lg"/>
                </div>
                <div class="col-md-2">
                  <label class="control-label">Total</label>
                  <fg-input v-model="'R$ '+this.total()" disabled="true"/>
                </div>
                <div class="col-md-2">
                  <p-button type="info" style="margin-top: 25px"
                            @click="insertProduct(products)" :disabled = disableInsertProduct>
                    <i slot="label" class="nc-icon nc-simple-add"></i>
                    Inserir
                  </p-button>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-show="link == '' && !disableInfo">
              <div class="card-body row">
                <div class="col-md-12">
                  <h6>Lista de Produtos</h6>
                </div>
                <div class="col-sm-12">
                  <el-table class="table-striped"
                            :data="sale.products"
                            :summary-method="getSummaries"
                            show-summary
                            style="width: 100%">
                    <el-table-column :min-width="20" label="ID">
                      <template slot-scope="props">
                        <span>
                          <span><b>#{{props.row.id}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="140" label="Descrição">
                      <template slot-scope="props">
                        <span>
                          <span>{{props.row.description.toUpperCase()}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Valor">
                      <template slot-scope="props">
                        <span>
                          <span>R$ {{toMoney(props.row.value)}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Qtd.">
                      <template slot-scope="props">
                        <span>
                          <span>{{props.row.quantity}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Total">
                      <template slot-scope="props">
                        <span>
                          <span>R$ {{toMoney(props.row.amount)}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :width="60" fixed="right" class-name="td-actions">
                      <template slot-scope="props">
                        <spam v-if="disableFormProducts == false">
                          <p-button type="danger" size="sm" icon title="Excluir"
                                    @click="deleteList(props.row.id)">
                            <i class="fas fa-trash"></i>
                          </p-button>
                        </spam>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="col-sm-12" style="text-align: right" v-show="!disableFormProducts">
                  <p-button type="info" style="margin-top: 25px"
                            @click="disableFormProducts = true" :disabled = "sale.products.length == 0">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Continuar
                  </p-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-show="disableFormProducts && link == ''">
            <div class="card-body row" style="background-color: #00d19a; border-radius: 25px; margin-right: 1%; margin-left: 1%">
              <div class="col-md-12">
                <p style="color: #fff"><b>Seu Link está quase pronto</b>!<br>
                  Você ainda pode personalizá-lo com informações adicionais:</p>
              </div>
              <div class="col-md-6">
                <div class="row" style="margin-left: 0px">
                  <label style="color: #fff">E-mail do cliente (Opcional)</label>
                </div>
                <fg-input v-model="sale.clientEmail" style="background-color: #fff" addon-left-icon="fas fa-envelope icon"
                          name="email"
                          v-validate="email"
                          :error="getError('email')"
                          data-vv-as="E-mail"></fg-input>
              </div>
              <div class="col-md-6">
                <div class="row" style="margin-left: 0px">
                  <label style="color: #fff">Data de Vencimento</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="sale.dueDate" type="date" placeholder="Escolha uma data"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-12">
                <br>
                <p style="color: #fff">* Ao inserir o e-mail, assim que o link for gerado será enviado automaticamente no e-mail informado.<br>
                * A data de vencimento informa a data limite para que o link seja válido.</p>
              </div>
            </div>
            <div class="card-body row">
              <div class="col-sm-12" style="text-align: right">
                <p-button type="warning" style="margin-top: 25px"
                          @click="disableFormProducts = false">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Voltar
                </p-button>
                <p-button type="success" style="margin-top: 25px"
                          @click="requestPaymentLinkSale(products)">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Criar Link de Pagamento
                </p-button>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-show="link != ''">
            <div class="card-body row">
              <div class="col-md-9 row">
                <div class="col-md-12">
                  <p style="font-size: 16px;"><b>Link criado com sucesso, compartilhe seu Link de Pagamento como quiser!</b></p>
                </div>
                <div class="col-md-12">
                  <fg-input v-model="link" disabled="true"/>
                </div>

                <div class="col-md-6" style="text-align: left">
                  <p-button type="success"
                            @click="copyLink()">
                    <i slot="label" class="fas fa-copy"></i>
                    Copiar Link
                  </p-button>

                  <p-button type="success"
                            @click="openWhatsapp()">
                    <i slot="label" class="fab fa-whatsapp"></i>
                    Compartilhar
                  </p-button>
                </div>
                <div class="col-md-6" style="text-align: right;">
                  <p-button type="info"
                            @click="newLink">
                    <i slot="label" class="nc-icon nc-check-2"></i>
                    Novo Link de Pagamento
                  </p-button>
                </div>
              </div>
              <div class="col-md-3" style="text-align: center">
                <img src="/static/img/paymentlink/suitpay_logo.png" alt="..." style="max-height: 170px">
                <p style="padding-top: 10px; font-size: 16px">Valor: <b>R$ {{toMoney(sale.amount)}}</b></p>
              </div>
            </div>
          </div>
        </card>
        <input type="hidden" id="paymentLink-copy" :value="link">
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import {callWs, getWs, postWs} from "src/ws.service"
  import {toMoney, moneyToFloat, isNullOrEmpty, isValidCnpj, isValidCpf} from "src/util/core.utils"
  import swal from 'sweetalert2'
  import {DatePicker} from 'element-ui'
  import banksCodes from "../Transfer/banks-codes"
  import Autocomplete from 'vuejs-auto-complete'
  import Banner from "../../../UIComponents/Promotion/Banner";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      [DatePicker.name]: DatePicker,
      Autocomplete,
      Banner
    },
    data() {
      return {
        username: '',
        disableInsertProduct: false,
        limitProduct: 5,
        disableFormProducts: false,
        disableInfo: false,
        bankDetailsForm: false,
        legalPersonOwnerForm: false,
        noticeForm: true,
        gatewayAccountUnderAnalysys: {
          form: false,
          bankCode: '',
          bankName: '',
          accountType: '',
          agencyNumber: '',
          agencyDv: '',
          accountNumber: '',
          accountNumberDv: '',
          ownerName: '',
        },
        noticeText: '',
        noticeTitle: '',
        link: '',
        textWhatsapp: 'https://web.whatsapp.com/send?text=Acesse o link, selecione como pagar e pronto! ',
        whatsapp: '',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        products: {
          id: 0,
          description: '',
          quantity: 1,
          value: 0,
          amount: 0,
        },
        sale: {
          amount: 0,
          clientEmail: '',
          dueDate: '',
          products: [],
        },
        email: {
          required: false,
          min: 5,
          email: true,
          ok: false
        },
        bankDetails: {
          username: '',
          bankCode: '',
          accountType: '',
          agencyNumber: '',
          agencyDv: '',
          accountNumber: '',
          accountNumberDv: '',
          ownerName: '',
          ownerDocument: '',
        },
        banksCodes: banksCodes,
      }
    },
    created() {
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })

      this.$validator.extend('agencyBankValid', {
        getMessage: field => ('O ' + field + ' é inválido.'),
        validate: (value) => {
          if (isNullOrEmpty(value)) {
            return false
          }
          if(/^0+$/.test(value)) {
            return false
          }
          return true;
        }
      })
    },
    mounted() {
      this.username = localStorage.getItem("usernameDisplay")
      this.initializeDueDate()
      this.loadData()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      loadData() {
        getWs("/payment-link/validate-user",
          true, {username: this.username},
          this.loadDataSuccess, this.loadDataError)
      },
      loadDataSuccess(reponse) {
        this.noticeForm = false
      },
      loadDataError(error) {
        this.noticeForm = false
        this.bankDetailsForm = false
        this.legalPersonOwnerForm = false
        this.gatewayAccountUnderAnalysys.form = false
        if(error.response.status == '400') {
          if(error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT') {
            this.noticeForm = true
            this.noticeTitle = "Conta faltando documentação."
            this.noticeText = error.response.data.message
          } else if(error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
            this.noticeForm = true
            this.noticeTitle = "Conta em análise."
            this.noticeText = error.response.data.message
          } else if(error.response.data.response == 'GATEWAY_ACCOUNT_UNDER_ANALYSIS') {
            this.gatewayAccountUnderAnalysys.form = true
            this.gatewayAccountUnderAnalysys.bankCode = error.response.data.bankCode
            this.gatewayAccountUnderAnalysys.bankName = error.response.data.bankName
            this.gatewayAccountUnderAnalysys.agencyNumber = error.response.data.agencyNumber
            this.gatewayAccountUnderAnalysys.agencyDv = error.response.data.agencyDv
            this.gatewayAccountUnderAnalysys.accountNumber = error.response.data.accountNumber
            this.gatewayAccountUnderAnalysys.accountNumberDv = error.response.data.accountNumberDv
            this.gatewayAccountUnderAnalysys.accountType = error.response.data.accountType
            this.gatewayAccountUnderAnalysys.ownerName = error.response.data.ownerName
            this.noticeTitle = "Conta em análise."
            this.noticeText = error.response.data.message
          } else if(error.response.data.response == 'USER_WITHOUT_REGISTERED_BANK_DATA_PF') {
            this.bankDetailsForm = true
            this.noticeTitle = "Envio de dados complementares"
            this.noticeText = "Precisamos de mais algumas informações complementares para a realização dos pagamentos de vendas realizadas" +
              " por cartão. Por isso precisamos que você informe uma referência bancária de outra instituição."
          } else if(error.response.data.response == 'USER_WITHOUT_REGISTERED_BANK_DATA_PJ') {
            this.bankDetailsForm = true
            this.legalPersonOwnerForm = true
            this.noticeTitle = "Envio de dados complementares"
            this.noticeText = "Precisamos de mais algumas informações complementares para a realização dos pagamentos de vendas realizadas" +
              " por cartão. Por isso precisamos que você informe uma referência bancária de outra instituição."
          }
        } else {
          this.noticeForm = true
          this.noticeTitle = 'Erro ao carregar link de pagamento.'
          this.noticeText = 'Tente novamente.'
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      openFormLink() {
        this.disableInfo = true
      },
      total() {
        let value = this.products.value
        let total = this.products.quantity*value
        this.products.amount = total
        return toMoney(total)
      },
      insertProduct(products) {
        if(products.description == '') {
          swal({
            title: 'Aviso!',
            text: 'É necessário inserir uma descrição para o produto ou serviço!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if(products.value <= 0) {
          swal({
            title: 'Aviso!',
            text: 'O valor do produto ou serviço não pode ser igual a zero!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if(this.products.description != '') {
          this.products.id++
          this.sale.products.push({
            id: products.id,
            description: products.description,
            quantity: products.quantity,
            value: products.value,
            amount: products.amount,
          })
          if(this.products.id == this.limitProduct) {
            this.disableInsertProduct = true
          }
          this.products.description = ''
          this.products.value = 0
          this.products.quantity = 1
        }
      },
      deleteList(id){
        let index = 0
        this.sale.products.forEach(value => {
          if(value.id == id) {
            this.sale.products.splice(index, 1);
          }
          index++
        })

        index = 0
        this.sale.products.forEach(value => {
          if(value.id > id) {
            value.id = value.id - 1
          }
          index++
        })

        this.products.id--

        if(this.products.id < this.limitProduct) {
          this.disableInsertProduct = false
        }
      },
      getSummaries (param) {
        const { columns } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 3) {
            sums[index] = 'Total'
          } else if (index === 4) {
            let sum = 0
            this.sale.products.forEach((obj) => {
              sum += obj.amount
            })
            sums[index] = 'R$ '+toMoney(sum)
          } else {
            sums[index] = ''
          }
        })
        return sums
      },
      requestPaymentLinkSale() {
        if(this.sale.dueDate <= new Date()) {
          swal({
            title: 'Aviso!',
            text: "Data de vencimento deve ser maior que data de hoje.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/payment-link/request-sale",
          "POST", null, true, null,
          this.sale, (response) => {
            this.link = response.data.urlLink
            this.sale.amount = response.data.amount
            this.whatsapp = this.textWhatsapp.concat(response.data.urlLink)
          },
          (error) => {
            swal({
              title: 'Atenção!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
          })
      },
      initializeDueDate() {
        this.sale.dueDate = new Date();
        this.sale.dueDate.setDate(this.sale.dueDate.getDate() + 10)
        //verifica se sábado ou domingo e coloca a data no próximo dia útil
        if(this.sale.dueDate.getDay() == 6) {
          this.sale.dueDate.setDate(this.sale.dueDate.getDate() + 2)
        }
        if(this.sale.dueDate.getDay() == 0) {
          this.sale.dueDate.setDate(this.sale.dueDate.getDate() + 1)
        }
        return
      },
      copyLink() {
        let paymentLink = document.querySelector('#paymentLink-copy')
        paymentLink.setAttribute('type', 'text')
        paymentLink.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if(msg == 'successful'){
            swal({
              title: 'Sucesso!',
              text: 'Link de Pagamento copiado com sucesso!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }else{
            swal({
              title: 'Erro!',
              text: 'Erro ao copiar Link de Pagamento!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        } catch (err) {
          swal({
            title: 'Erro!',
            text: 'Erro ao copiar Link de Pagamento!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }

        /* unselect the range */
        paymentLink.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      openWhatsapp() {
        try {
          let pdfWindow = window.open(this.whatsapp, "about:blank");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do boleto, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      newLink() {
        this.disableFormProducts = false
        this.disableInsertProduct = false
        this.link = ''
        this.products.quantity = 1
        this.products.description = ''
        this.products.id = 0
        this.sale.amount = 0
        this.sale.clientEmail = ''
        this.sale.products = []
        this.initializeDueDate()
      },
      getBank(code) {
        for (let bank in this.banksCodes) {
          if (this.banksCodes[bank].code == code) {
            return this.banksCodes[bank]
          }
        }
        return null
      },
      selectBank(bank) {
        this.bankDetails.bankCode = bank.selectedObject.code
      },
      displayBankName(bank) {
        return bank.code + ' - ' + bank.name
      },
      saveBankDetails() {
        if (isNullOrEmpty(this.bankDetails.bankCode)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Banco é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        } else if (isNullOrEmpty(this.bankDetails.accountType)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Tipo de Conta é obrigatório.',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.bankDetails.username = localStorage.getItem("usernameDisplay")
          postWs("/acc/save-bank-details", true, null, this.bankDetails,
            () => {
              this.loadData()
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
            }, (error) => {
              swal({
                title: 'Falha!',
                text: 'Erro ao salvar dados bancários, confira os dados e tente novamente.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
    }
  }
</script>
<style lang="scss">
  .dzs-paymentlink {
    .vue-barcode-element {
      height: 86px !important;
      display: table;
      margin: 0 auto !important;
    }

    .el-input__inner {
      background-color: #fff !important;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    @media (max-width: 800px) {
      .vue-barcode-element {
        height: 50px !important;
        width: 330px !important;
      }
    }

    .box2 {
      border-radius: 0px;
      border-color: rgb(187, 187, 187);
      border-style: solid;
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: inset 0 0 1em #ffd70000, 0 0 1em #bbb;
      line-height: 2.0;
    }

    @media (max-width: 500px) {
      .box2 {
        width: 90%;
      }
    }

    .box3 {
      width: 20%;
      margin-left: 10%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.3%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.3%;
      }
    }
  }
</style>
