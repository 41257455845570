<template>
  <div class="dzs-card row">
    <div class="col-lg-12 col-md-7">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body" style="padding-bottom: 30px">
            <form>
              <div>
                <h4>Consulta de Dados da Solicitação</h4>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Usuário"
                            disabled="true"
                            v-model="request.username">
                  </fg-input>
                </div>
                <div class="col-md-7">
                  <fg-input type="email"
                            label="E-mail"
                            disabled="true"
                            v-model="request.email">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="tel"
                            label="Celular"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            v-model="request.phoneNumber1"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="tel"
                            label="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            v-model="request.phoneNumber2"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Nome Completo"
                            v-model="request.name"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row" style="margin-top: 0px">
                <div class="col-md-5">
                  <fg-input label="Tipo de Pessoa"
                            type="text"
                            v-model="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?'Pessoa Física':'Pessoa Jurídica'"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-7">
                  <fg-input :label="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?'CPF':'CNPJ'"
                            type="tel"
                            v-mask="request.personType==='NATURAL_PERSON' || request.personType==='NATURAL_PERSON_E'?['###.###.###-##']:['##.###.###/####-##']"
                            name="CPF/CNPJ"
                            v-model="request.document"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div v-if="request.personType==='LEGAL_PERSON'">
                <div class="row">
                  <div class="col-md-12">
                    <fg-input type="text"
                              label="Razão Social"
                              v-model="request.companyName"
                              disabled="true">
                    </fg-input>
                  </div>
                </div>
              </div>
              <div style="margin-top: 30px">
                <h6>Dados da Entrega</h6>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-5">
                  <fg-input type="tel"
                            v-mask="['#####-###']"
                            label="CEP"
                            v-model="request.deliveryAddress.zipCode"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-7">
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <fg-input type="text"
                            label="Rua/Av."
                            v-model="request.deliveryAddress.street"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input type="tel"
                            v-mask="['######']"
                            label="Número"
                            v-model="request.deliveryAddress.number"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input type="text"
                            label="Complemento"
                            v-model="request.deliveryAddress.complement"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Bairro"
                            v-model="request.deliveryAddress.neighborhood"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Cidade"
                            v-model="request.deliveryAddress.city"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-2">
                  <fg-input type="text"
                            label="Estado"
                            v-model="request.deliveryAddress.state"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <fg-input type="text"
                            label="Código de Rastreio"
                            v-model="request.shipment.dispatchShipmentService.name"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-5">
                  <fg-input type="text"
                            label="Código de Rastreio"
                            v-model="request.shipment.dispatchTrackingCode"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-3">
                  <label>Valor do Envio</label>
                  <money class="form-control input-lg"
                         v-model="request.shipment.shipmentValue"
                         v-bind="money"
                         disabled="true">
                  </money>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Status da Requisição"
                            v-model="request.requestStatus"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Status do Envio"
                            v-model="request.shipment.transitStatus"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <br/><br/>
              <div class="row">
                <div class="col-md-12">
                  <h6>Informação de Máquinas</h6>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Quantidade de Máquinas"
                            v-model="request.terminal.amount"
                            disabled="true">
                  </fg-input>
                </div>
                <div class="col-md-6">
                  <fg-input type="text"
                            label="Máquina Escolhida"
                            v-model="request.terminal.nameModel"
                            disabled="true">
                  </fg-input>
                </div>
              </div>
              <div class="card-body row">
                <div class="col-sm-12">
                  <el-table class="table-striped"
                            :data="tableData"
                            style="width: 100%">
                    <el-table-column :min-width="80" label="Serial">
                      <template slot-scope="props">
                        <span>
                          <span><b>#{{props.row.physicalId}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Modelo">
                      <template slot-scope="props">
                        <span>
                          <span>{{props.row.model.name}}</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="50" label="Customizada">
                      <template slot-scope="props">
                        <span>
                          <span v-if="props.row.themeCustomized">SIM</span>
                          <span v-if="!props.row.themeCustomized">NÃO</span>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <br/>
              <div class="card-body row">
                <div class="col-md-12">
                  <h6>Taxas</h6>
                </div>
                <div class="col-sm-12">
                  <el-table class="table"
                            :data="request.activePlan"
                            style="width: 100%">
                    <el-table-column :min-width="90" label="Bandeira">
                      <template slot-scope="props">
                        <span>
                          <span><b>{{props.row.nameCardTerminalBanner}}</b></span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="70" label="Débito">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.debito)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="1x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="2x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito2x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="3x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito3x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="4x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito4x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="5x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito5x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="6x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito6x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="7x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito7x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="8x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito8x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="9x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito9x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="10x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito10x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="11x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito11x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="65" label="12x">
                      <template slot-scope="props">
                        <span>
                          <span>{{toMoney(props.row.credito12x)}} %</span>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {postWs, getWs, failWs, callWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import {isNullOrEmpty, toMoney} from "../../../../../../util/core.utils";
  import {Table, TableColumn} from "element-ui";

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {},
    data() {
      return {
        idRequest: '',
        request: {
          username: '',
          name: '',
          email: '',
          document: '',
          phoneNumber1: '',
          phoneNumber2: '',
          companyName: '',
          personType: '',
          deliveryAddress: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          plan: {
            carTerminalPlan: '',
            cardTerminalPlanLabel: '',
          },
          terminal: {
            nameModel: '',
            amount: '',
          },
          billingRange: '',
          partner: {
            username: '',
            overprice: 0,
          },
          requestStatus: '',
          shipmentServices: [],
          shipment: {
            transitStatus: '',
            dispatchShipmentService: {
              name: '',
            },
            shipmentValue: 0,
            dispatchTrackingCode: ''
          },
          activePlan: [{
            planName: '',
            debito: 0,
            credito: 0,
            credito2x: 0,
            credito3x: 0,
            credito4x: 0,
            credito5x: 0,
            credito6x: 0,
            credito7x: 0,
            credito8x: 0,
            credito9x: 0,
            credito10x: 0,
            credito11x: 0,
            credito12x: 0,
            nameCardTerminalBanner: ''
          }],
        },
        tableData: [],
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
      }
    },

    created() {
      this.idRequest = this.$route.params.id
    },

    mounted() {
      this.loadData()
    },

    methods: {
      toMoney,
      isNullOrEmpty,
      loadData() {
        getWs('/bo/card-terminal/get-card-terminal-request', true, {idRequest: this.idRequest}, this.loadRequestData, this.failRequestData)
      },
      loadRequestData(response) {
        this.request.requestStatus = this.reqStatusToLabel(response.data.requestStatus)

        this.request.username = response.data.username
        this.request.name = response.data.name
        this.request.email = response.data.email
        this.request.document = response.data.document
        this.request.phoneNumber1 = response.data.phoneNumber1
        this.request.phoneNumber2 = response.data.phoneNumber2
        this.request.companyName = response.data.companyName
        this.request.personType = response.data.personType
        this.request.deliveryAddress.codIbge = response.data.deliveryAddress.codIbge
        this.request.deliveryAddress.street = response.data.deliveryAddress.street
        this.request.deliveryAddress.number = response.data.deliveryAddress.number
        this.request.deliveryAddress.complement = response.data.deliveryAddress.complement
        this.request.deliveryAddress.zipCode = response.data.deliveryAddress.zipCode
        this.request.deliveryAddress.neighborhood = response.data.deliveryAddress.neighborhood
        this.request.deliveryAddress.city = response.data.deliveryAddress.city
        this.request.deliveryAddress.state = response.data.deliveryAddress.state

        if(response.data.shipment != null) {
          this.request.shipment.dispatchTrackingCode = response.data.shipment.dispatchTrackingCode
          this.request.shipment.shipmentValue = response.data.shipment.shipmentValue
          this.request.shipment.transitStatus = this.transitStatusToLabel(response.data.shipment.transitStatus)
          this.request.shipment.dispatchShipmentService.name = response.data.shipment.dispatchShipmentService.name
        } else {
          this.request.shipment.transitStatus = 'Não Enviado'
        }

        this.request.plan.plans = response.data.plans
        this.request.plan.carTerminalPlan = response.data.cardTerminalPlan
        this.request.plan.cardTerminalPlanLabel = response.data.cardTerminalPlanLabel
        this.request.activePlan = response.data.planActive

        this.request.terminal.nameModel = response.data.nameModelRequestedTerminal
        this.request.terminal.amount = response.data.amountRequestedTerminal

        this.request.billingRange = response.data.billingRange

        if(response.data.usernamePartner != null) {
          this.request.partner.username = '@' + response.data.usernamePartner;
          this.request.partner.overprice = response.data.overpricePartner;
        }

        if(response.data.registeredTerminals != null) {
          this.tableData = response.data.registeredTerminals
        }

        if(response.data.shipmentServices != null) {
          this.request.shipmentServices = response.data.shipmentServices
        }
      },
      failRequestData(){
        swal('Erro', 'Erro ao carregar os dados da solicitação!', 'error')
      },
      reqStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING_APPROVAL') {
          return 'Aguardando Aprovação'
        } else if (reqStatus == 'APPROVED') {
          return 'Aprovado'
        } else if (reqStatus == 'PREPARING_SHIPMENT') {
          return 'Aguardando Envio'
        } else if (reqStatus == 'SHIPPED') {
          return 'Enviado'
        } else if (reqStatus == 'PARTIAL_SHIPPING') {
          return 'Envio Parcial'
        } else if (reqStatus == 'NOT_APPROVED') {
          return 'Não Aprovado'
        } else if (reqStatus == 'CANCELED_BY_CUSTOMER') {
          return 'Cancelado Pelo Cliente'
        } else if (reqStatus == 'CANCELED_BY_VENDOR') {
          return 'Cancelado Pelo Vendedor'
        } else if (reqStatus == 'ACTIVE') {
          return 'Ativo'
        }
        return '??'
      },
      transitStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING_SEND') {
          return 'Aguardando Envio'
        } else if (reqStatus == 'SENT') {
          return 'Enviado'
        } else if (reqStatus == 'DELIVERED') {
          return 'Entregue'
        } else if (reqStatus == 'DELIVER_FAIL') {
          return 'Entrega Falhou'
        } else if (reqStatus == 'WAITING_RETURN') {
          return 'Aguardando Devolução'
        } else if (reqStatus == 'RETURN_SENT') {
          return 'Devolução Enviada '
        } else if (reqStatus == 'RETURNED') {
          return 'Devolvido'
        } else if (reqStatus == 'RETURN_FAIL') {
          return 'Falha na Devolução'
        }
        return 'Não Enviado'
      },
    }
  }
</script>

<style lang="scss">
  .dzs-card {
    .card-title {
      margin-top: 0px;
    }
  }

</style>
