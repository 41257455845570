<template>
  <div class="dzs-movement">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Informe de Movimentação
          </h4>
          <div class="row">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="reportDate" :type="filterBy" placeholder="Escolha uma data"
                                :format="filterBy === 'month' ? 'MM/yyyy' : 'yyyy'"
                                :picker-options="dateOptsStart">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <fg-input label="Filtrar por">
                <el-select
                  class="select-default"
                  v-model="filterBy"
                  placeholder="Filtrar por">
                  <el-option value="year" label="Ano" class="select-default"></el-option>
                  <el-option value="month" label="Mês" class="select-default"></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
            <div class="col-md-12" v-show="this.tableDataReport.length > 0">
              <p-button @click.prevent="generateExcel" style="margin-top: 24px">
                <i class="fa-regular fa-file-excel"></i> Exportar Excel
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="timelineItems.length>0"
           style="margin: 0 auto">
        <card>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="timelineItems"
                        style="width: 100%">
                <el-table-column :min-width="50" label="Mês/Ano">
                  <template slot-scope="item">
                    <span>
                      <b>{{ item.row.createdDate }}</b>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Total Entradas">
                  <template slot-scope="item">
                    <span>
                      <span>R$ {{ toMoney(item.row.totalIn) }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Total Saídas">
                  <template slot-scope="item">
                    <span>R$ {{ toMoney(item.row.totalOut) }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Saldo no último dia do Mês">
                  <template slot-scope="item">
                    <span>{{ item.row.lastDayBalance }}</span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Valor (R$)">
                  <template slot-scope="item">
                    <span>
                      <span>
                        R$ {{ toMoney(item.row.value) }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="20" label="" fixed="right" class-name="td-actions">
                  <template slot-scope="item">
                    <div>
                      <p-button @click="sendManually(item.row)"
                                class="btn btn-info" block title="Enviar manualmente">
                        <i class="fa-solid fa-paper-plane fa-2x" style="color: #0c072d;"/>
                      </p-button>
                      <p-button @click="showMoreData(item.row)"
                                class="btn btn-info" block title="Ver Informações de Retorno">
                        <i class="fa-solid fa-magnifying-glass fa-2x" style="color: #0c072d;"/>
                      </p-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableDataReportIn"
                        style="width: 100%"
                        :row-class-name="tableRowClassNameIn">
                <el-table-column :min-width="120" label="Entradas">
                  <template slot-scope="props">
                      <span>
                        <span>{{ getTransactionName(props.row.nameTransaction) }}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Valor">
                  <template slot-scope="props">
                      <span>
                        <span><b>R$</b> {{ toMoney(props.row.value) }}</span>
                      </span>
                  </template>
                </el-table-column>
              </el-table>
              <br>
              <el-table class="table-striped"
                        :data="tableDataReportOut"
                        style="width: 100%"
                        :row-class-name="tableRowClassNameOut">
                <el-table-column :min-width="120" label="Saídas">
                  <template slot-scope="props">
                    <span>
                      <span>{{ getTransactionName(props.row.nameTransaction, props.row.subTypeTransaction) }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="80" label="Valor">
                  <template slot-scope="props">
                    <span>
                      <span><b>R$</b> {{ toMoney(props.row.value) }}</span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <p class="monthly-balance">Saldo do {{ this.footerText }}: R$ {{ toMoney(monthlyBalance) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker} from 'element-ui'
import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
import {callWs, postWs} from "src/ws.service"
import {formatDate} from "src/util/date.utils"
import {toMoney} from 'src/util/core.utils'
import swal from 'sweetalert2'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import ChartCard from "@/components/UIComponents/Cards/ChartCard";
import {getTransactionName} from 'src/components/Dashboard/Views/Transactions/timeline'
import download from "downloadjs";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    TimeLine,
    TimeLineItem,
    Badge,
    PPagination,
    ChartCard
  },
  data() {
    return {
      searchDate: new Date(),
      filterBy: 'year',
      timelineItems: [],
      username: null,
      dateOptsStart: {
        disabledDate: this.disabledStartDate
      },
      reportDate: new Date(),
      reportDateOpts: {
        disabledDate: this.disabledReportMonthDate
      },
      tableDataReport: [],
      tableDataReportIn: [],
      tableDataReportOut: [],
      totalRowsIn: 0,
      totalRowsOut: 0,
      monthlyBalance: 0
    }
  },
  computed: {
    filterPeriod() {
      return this.filterBy === 'month' ? formatDate(this.reportDate, "MM/YYYY") : formatDate(this.reportDate, "YYYY")
    },
    footerText() {
      return this.filterBy === 'month' ? 'Mês' : 'Ano'
    }
  },
  methods: {
    toMoney,
    formatDate,
    getTransactionName,
    search() {
      postWs('/transaction/movement-report', true, null,
        {
          filterBy: this.filterBy,
          reportDate: formatDate(this.reportDate, "DD/MM/YYYY")
        },
        this.successSearch,
        this.failSearch
      )
    },
    successSearch(response) {
      this.resetTableDataReport()
      this.tableDataReport = response.data.monthlyTransactionsReport
      this.monthlyBalance = response.data.monthlyBalance
      this.createReport(this.tableDataReport);
    },
    failSearch() {
      swal({
        title: 'Aviso!',
        text: 'Ocorreu um erro ao filtrar transações. Tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    validatePeriod() {
      this.endDate = this.startDate
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
    tableRowClassNameIn({row, rowIndex}) {
      if (rowIndex + 1 === this.totalRowsIn) {
        return 'table-success'
      }
      return ''
    },
    tableRowClassNameOut({row, rowIndex}) {
      if (rowIndex + 1 === this.totalRowsOut) {
        return 'table-danger'
      }
      return ''
    },
    resetTableDataReport() {
      this.tableDataReport = []
      this.tableDataReportIn = []
      this.tableDataReportOut = []
      this.totalRowsIn = 0
      this.totalRowsOut = 0
      this.monthlyBalance = 0
    },
    createReport(transactions) {
      for (let tr in transactions) {
        if (transactions[tr].typeTransaction == 'IN') {
          this.tableDataReportIn.push(transactions[tr])
          this.totalRowsIn++
        }
        if (transactions[tr].typeTransaction == 'OUT') {
          this.tableDataReportOut.push(transactions[tr])
          this.totalRowsOut++
        }
      }
    },
    generateExcel() {
      callWs("/transaction/generate-movement-report-excel",
        "POST", null, true, null,
        {
          filterBy: this.filterBy,
          reportDate: formatDate(this.reportDate, 'DD/MM/YYYY')
        },
        this.successGenerateExcel,
        () => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao gerar o Excel do relatório, tente novamente!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    successGenerateExcel(response) {
      try {
        download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
          "informe_movimentacao"+"_"+ this.filterPeriod +".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      } catch (ex) {
        swal({
          title: 'Erro!',
          text: 'Erro ao gerar o Excel do relatório, tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
  }
}
</script>
<style lang="scss">
.dzs-movement {
  .btn.btn-info.btn-default {
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn.btn-default {
    background-color: #17a2b8 !important;
  }

  .btn.btn-default:hover {
    background-color: #58adb8 !important;
  }

  .td-actions > .cell div {
    display: flex;
    justify-content: end;
    gap: .2rem;
  }

  .monthly-balance {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 35px 0 15px 0;
    text-align: center;
    color: #0c072d;
  }
}

.swal-wide {
  width: 100% !important;
  max-width: 45rem !important;
}
</style>
