<template>
  <div class="dzs-api">
    <div class="row">
      <div class="col-md-12" v-if="isNullOrEmpty(qrCode)">
        <card>
          <h4 class="card-title" slot="header">
            Acesso à API <b style="color: #000000 !important;">SuitPay</b>
          </h4>
          <div class="row" style="margin-bottom: 10px">
            <div class="col-md-3" style="text-align: center; color: #17a2b8">
              <i class="fa fa-terminal fa-10x"></i>
            </div>
            <div class="col-md-6" style="text-align: left">
              <p>Para integração via Web API, entre em contato conosco para obter o manual de integração e gere abaixo sua chave de acesso.</p>
              <p>Após gerar a chave, <b>não será possivel visualizá-la novamente</b>, apenas gerar uma chave nova.</p>
              <p>Salve a chave em local seguro para evitar transtornos e interrupção na integração.</p>
            </div>
          </div>
          <div class="row" style="margin-bottom: 30px" >
            <div class="col-md-10" style="text-align: center">
              <button class="btn btn-success btn-fill btn-wd"  @click="confirmNewKeyGeneration()">
                Obter Chave
              </button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="!isNullOrEmpty(qrCode) && transactionAuthorizationEmail === 'true'">
        <card>
          <h4 slot="header" class="card-title">
            Confirmação de Transferência
          </h4>
          <hr/>
          <div class="row">
            <i style="margin: 0 auto; margin-top: 14px" class="fas fa-arrow-up fa-5x text-warning"></i>
          </div>
          <div class="row">
            <p style="margin: 0 auto; margin-top: 14px"> Enviamos um código para seu email.</p>
          </div>
          <div class="row">
            <p style="margin: 0 auto; margin-top: 14px"> Informe-o abaixo para confirmar a transação:</p>
          </div>
          <div class="row">
            <input style="margin: 0 auto; margin-top: 14px" v-mask="['######']" v-model="validationCode" size="6"/>
          </div>
          <div class="row" v-if="!codeValid">
            <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
          </div>
          <div class="row" style="text-align: center">
            <div style="display: inline-block; margin: 0 auto; margin-top: 14px">
              <p-button @click="()=>{qrCode = ''}"
                        class="btn btn-primary" style="margin: 0 auto; margin-right: 16px">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
              <p-button @click="()=>{validateCode()}"
                        class="btn btn-primary" style="margin: 0 auto; margin-left: 16px">
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="!isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true'">
        <card>
          <h4 slot="header" class="card-title">
            Confirmação de Transferência
          </h4>
          <hr/>
          <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
            <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
          </div>
          <div class="row">
            <div class="col-md-6" style="text-align: right" v-if="!isNullOrEmpty(totpQrCodeUri)">
              <p style="margin-top: 14px"><b>1</b> - Baixe e instale o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
              <div class="row" style="margin-bottom: 10px">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                    <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                  </a>
                </div>
                <div class="col-md-4">
                  <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                    <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                  </a>
                </div>
              </div>
              <p><b>2</b> - No aplicativo, leia o QR Code ao lado ou informe a chave manualmente</p>
              <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo</p>
              <p><b>4</b> - Confirme a operação</p>
              <p><b>5</b> - Pronto!</p>
            </div>
            <div class="col-md-6" style="text-align: left" v-if="!isNullOrEmpty(totpQrCodeUri)">
              <qrcode :value="totpQrCodeUri" :options="{ width: 200, margin:0 }"></qrcode>
              <p><b>{{this.totpManualCode}}</b></p>
            </div>
            <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
              <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
              <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no aplicativo </p>
              <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
              <p><b>4</b> - Confirme a operação</p>
              <p><b>5</b> - Pronto!</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="text-align: center">
              Código: <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>
            </div>
          </div>
          <div class="row" v-if="!codeValid">
            <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
          </div>
          <div class="row" style="text-align: center">
            <div style="display: inline-block; margin: 0 auto; margin-top: 14px">
              <p-button @click="()=>{qrCode = ''}"
                        class="btn btn-primary" style="margin: 0 auto; margin-right: 16px">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
              <p-button @click="()=>{validateCode()}"
                        class="btn btn-primary" style="margin: 0 auto; margin-left: 16px"
                        :disabled="totpConfirmBtnDisabled"
              >
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {failWs, postWs} from "src/ws.service";
  import {isNullOrEmpty, notNull} from "src/util/core.utils"
  import swal from 'sweetalert2'

  export default {
    components: {},
    data() {
      return {
        transactionAuthorizationEmail: 'false',
        transactionAuthorizationTotp: 'false',
        validationCode: '',
        authorizationId: null,
        codeValid: true,
        qrCode: '',
        totpQrCodeUri: '',
        totpManualCode: '',
        totpConfirmBtnDisabled: false
      }
    },
    created() {
      this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
    },
    methods: {
      isNullOrEmpty,
      confirmNewKeyGeneration(){
        swal({
          title: 'Aviso!',
          text: 'Gerar uma nova chave irá revogar o acesso da chave anterior. Deseja prosseguir?',
          buttonsStyling: true,
          timer: 15000,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'SIM',
          confirmButtonColor: '#17a2b8',
          cancelButtonText: 'CANCELAR'
        }).then(() => {
          this.generateAccess();
        })
      },
      generateAccess(){
        postWs("/api-access-manager/generate-access", true,
          null, {
            authorization: this.authorizationId
          },
          this.showAccessKeys,
          failWs)
      },
      showAccessKeys(response){
        this.totpConfirmBtnDisabled = false
        if (response.data.response === 'EMAIL_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
          this.qrCode = response.data.qrCode
          return
        } else if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
          this.qrCode = response.data.qrCode
          this.totpQrCodeUri = response.data.totpQrCodeUri
          this.totpManualCode = response.data.totpManualCode
          return
        } else {
          this.qrCode = null
          swal({
            title: 'Sucesso',
            html: 'Salve os dados de acesso em um local seguro! Não será possível obtê-los sem gerar uma nova chave.<br><br>' +
              '<div class="form-group">' +
              ' <b>Client Id:</b> <input id="input-field1" style="cursor: pointer" type="text" class="form-control" readonly value="' + response.data.clientId + '" /><br>' +
              ' <b>Client Secret:</b> <textarea rows="3" id="input-field2" style="cursor: pointer" type="text" class="form-control" readonly>' + response.data.clientSecret + '</textarea>' +
              '</div>',
            buttonsStyling: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonClass: 'btn btn-info btn-fill',
            type: 'info',
          })
        }
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      confirmCode(response){
        this.authorizationId = response.data.verifCode
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: this.authorizationId},
          this.generateAccess,
          failWs
        )
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-api{
    .btn.btn-success.btn-fill.btn-wd {
      background-color: #17a2b8 !important;
    }
  }

</style>
