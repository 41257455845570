<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-lg-5">
        <card style=" padding: 5%; height: 100%; text-align: justify">

          <!-- Informativo e Explicação de acesso aos tutoriais -->
          <h4 slot="header" class="card-title">
            <span>Informativos</span>!
          </h4>
          <span style="font-size: 15px">Essa área é destinada ao seu aprendizado!</span><br><br>

          <span style="font-size: 15px">Aqui você encontrará tutoriais em formato de vídeos para que tire todas as suas dúvidas em relação a usabilidade da plataforma.</span><br><br>
          <span
            style="font-size: 15px;">Para acessar basta clicar no tema que possui dúvidas na utilização.</span><br><br>
          <span style="font-size: 15px;">Ahh! E pensando em facilitar sua fonte de informação, abaixo vamos deixar todos os nossos canais oficiais. Neles você irá encontrar uma grande variedade de conteúdo que são postados todos os dias.</span><br><br>

          <div class="row" style="font-size: 50px">
            <div class="col-md-3">
              <a href="https://www.instagram.com/suit.pay/" target="_blank"><i class="fab fa-instagram"></i></a>
            </div>
            <div class="col-md-3">
              <a href="https://www.facebook.com/suitpay.br" target="_blank"><i class="fa-brands fa-facebook"></i></a>
            </div>
            <div class="col-md-3">
              <a href="https://www.linkedin.com/company/suitpay/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
            </div>
            <div class="col-md-3">
              <a href="https://www.youtube.com/@suitpay4134" target="_blank"><i class="fab fa-youtube"></i></a>
            </div>
          </div>

          <!-- Atualizações e Updates -->
          <h4 class="card-title">
            <span>Atualizações</span>!
          </h4>
          <span style="font-size: 15px;">Confira as últimas atualizações:</span><br><br>
          <div style="text-align: left">
            <span style="font-size: 15px;"><i class="fa-solid fa-check"></i> Anexo comprovante SuitCard.</span><br>
            <span style="font-size: 15px;"><i
              class="fa-solid fa-check"></i> Melhoria no sistema de antifraude.</span><br>
            <span style="font-size: 15px;"><i class="fa-solid fa-check"></i> Melhoria na ferramenta OrderBump checkout.</span><br>
            <span style="font-size: 15px;"><i
              class="fa-solid fa-check"></i> Melhoria na ferramenta Pixel checkout.</span><br>
            <span style="font-size: 15px;"><i
              class="fa-solid fa-check"></i> Formas de pagamento checkout.</span><br><br>


            <span style="font-size: 15px;"><i
              class="fa-regular fa-clock"></i> Sistema de verificação de compra 3DS.</span><br>
            <span style="font-size: 15px;"><i class="fa-regular fa-clock"></i> Abertura de conta com verificação biométrica.</span><br>
            <span style="font-size: 15px;"><i class="fa-regular fa-clock"></i> Informe de movimentação.</span><br>
            <span style="font-size: 15px;"><i class="fa-regular fa-clock"></i> Link de Pagamento no app.</span><br>
            <span style="font-size: 15px;"><i class="fa-regular fa-clock"></i> Recarga automática SuitCard.</span><br>
            <span style="font-size: 15px;"><i class="fa-regular fa-clock"></i> SuitCard no app.</span><br>
            <span style="font-size: 15px;"><i class="fa-regular fa-clock"></i> SuitCard físico.</span><br>
            <span style="font-size: 15px;"><i
              class="fa-regular fa-clock"></i> SuitCard SamsungPay e GooglePay.</span><br>
          </div>


          <!-- Contatos e Suporte ao Cliente -->
          <h4 class="card-title">
            <span>Contatos</span>!
          </h4>
          <div style="text-align: left">
            <span style="font-size: 15px;"><b>Whatsapp</b>: 0800 302 0202</span><br>
            <span style="font-size: 15px;"><b>E-mail</b>: contato@suitpay.app</span><br>
            <span style="font-size: 15px;"><b>SAC</b>: sac@suitpay.app</span><br>
            <span style="font-size: 15px;"><b>Horário de Atendimento</b>: Seg à Sex - 08H00 às 18H00</span>
          </div>
          <br><br>
          <div class="row">
            <div class="col-md-6">
              <a href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                <img src="/static/img/mobile/apple_store.png" alt="...">
              </a>
            </div>
            <div class="col-md-6">
              <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                <img src="/static/img/mobile/google_play.png" alt="...">
              </a>
            </div>
          </div>
          <br><br>

          <div style="text-align: center">
            <span style="font-size: 15px">"A arte é muito importante para não compartilhar."</span><br>
            <span style="font-size: 15px"><b>Romero Britto</b></span>
          </div>
        </card>
      </div>


      <!-- Listagem de Vídeos Tutoriais YouTube -->
      <div class="col-lg-7">
        <card style="height: 100%;">
          <div style="margin: 2%">

            <a href="https://www.youtube.com/watch?v=2BPuFAAyBqw" target="_blank">
              <img src="/static/img/support/support-link-de-pagamentos.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=t0ipviPQeFE" target="_blank">
              <img src="/static/img/support/support-painel-unificado.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=YLo1GfVkn5M" target="_blank">
              <img src="/static/img/support/support-ted.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=EOsrQpKinmo" target="_blank">
              <img src="/static/img/support/support-pagamento-de-titulos.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=eBy7ujUpGWA" target="_blank">
              <img src="/static/img/support/support-transferencia-entre-contas.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=MwY1_S8dz8k" target="_blank">
              <img src="/static/img/support/support-suitcard.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=ambfALrWuUI" target="_blank">
              <img src="/static/img/support/support-deposito-por-boleto.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=ew0U6cPjhuU" target="_blank">
              <img src="/static/img/support/support-transferencia-por-pix.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=fBk0wg22vE0" target="_blank">
              <img src="/static/img/support/support-habilitar-aplicativo.png" alt="...">
            </a>

            <a href="https://www.youtube.com/watch?v=oXH24XkjF6Q" target="_blank">
              <img src="/static/img/support/support-extrato.png" alt="...">
            </a>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    components: {
    },

    data() {
      return {}
    },
    methods: {}
  }
</script>
<style lang="scss">
</style>
