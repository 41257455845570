<template>
  <div class="dzs-manage-lessons">
    <div class="row">
      <div class="col-md-6">
        <card style="padding: 5%;">
          <h4 slot="header" class="card-title" style="text-align: center">Por que cadastrar um <b>IP</b>?</h4>
          <p>Para garantir a segurança das suas transações pelo Gateway e proteger suas operações financeiras, é
            obrigatório cadastrar o endereço de IP associado ao seu servidor para utilizar a SuitPay.</p>

            <p>Essa medida é uma parte essencial das nossas práticas de segurança, permitindo que verifiquemos e
            autentiquemos suas transações com maior precisão. O registro do seu IP fortalece a proteção contra
            atividades não autorizadas e salvaguarda suas transações contra possíveis ameaças, e assim manter a
            integridade das suas operações financeiras, principalmente durante as transações de cashout, e assegura uma
            experiência tranquila e protegida ao utilizar nossa plataforma.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Segurança Reforçada:</strong> O IP
            autorizado serve como um selo de segurança, garantindo que apenas dispositivos com o IP registrado possam
            realizar transações na plataforma.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Redução de Riscos:</strong>
            Limitar as transações a IPs específicos reduz as chances de acesso não autorizado, minimizando os riscos de
            fraude ou atividades maliciosas.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Validação da Identidade:</strong>
            O IP cadastrado ajuda na validação da identidade do usuário, adicionando uma camada extra de autenticação
            para garantir a legitimidade das transações.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Controle de Acesso:</strong>
            Permite um controle mais preciso sobre quem pode realizar transações na plataforma, proporcionando maior
            controle e monitoramento das atividades.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Rastreabilidade
            Aprimorada:</strong> Ao vincular uma transação a um IP específico, facilita-se o rastreamento e a análise em
            caso de investigação de atividades suspeitas ou problemas de segurança.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Proteção contra Fraudes:</strong>
            A autorização por IP ajuda a mitigar possíveis ameaças, uma vez que dificulta ações fraudulentas de
            terceiros que tentem acessar a conta de um usuário.</p>

          <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Experiência do Usuário:</strong>
            Embora seja uma medida de segurança, quando implementada de forma adequada, não interfere significativamente
            na experiência do usuário, pois os acessos autorizados continuam ocorrendo sem interrupções.</p>
        </card>
      </div>
      <!-- Bloco Dispositivo Não habilitado -->
      <div class="col-md-6"
           v-if="!hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && !isNullOrEmpty(totpQrCodeUri)">
        <card style="background-color: #00d19a; text-align: center">
          <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não está cadastrado!</h4>
          <card align="center">
            <span>Para liberar suas transações siga os passos abaixo:</span>
            <div class="row" v-if="isNullOrEmpty(totpQrCodeUri)">
              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            </div>

            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b
                  style="color: #000000 !important;">SuitPay</b>.</span></p>
                <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                   target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                </a>
                <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                </a>

              </div>

              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span>
                </p>
                <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no
                  aplicativo </p>
                <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                <p><b>4</b> - Confirme a operação</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>
            <br>
            <div style="text-align: center">
              <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                        class="btn btn-danger">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
            </div>
          </card>
        </card>
      </div>

      <!-- Bloco Dispositivo Habilitado -->
      <div class="col-md-6"
           v-if=" !hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
        <card style="background-color: #00d19a; text-align: center;">
          <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
          <card align="center" style="height: 100%">
            <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                <p><b>4</b> - Confirme a operação.</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>

            <span>Informe o <b>SuitID</b>: </span>
            <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

            <div class="row" v-if="!codeValid">
              <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
            </div>

            <div style="text-align: center">
              <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                        class="btn btn-danger">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
              <p-button @click="()=>{validateCode()}"
                        class="btn btn-primary"
                        :disabled="totpConfirmBtnDisabled"
              >
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
          </card>
        </card>
      </div>
      <div v-else class="col-md-6">
        <div class="row">
          <div class="col">
            <card>
              <div>
                <div class="card-header">
                  <h4>Inserir novo IP</h4>
                </div>
                <div class="card-body row">
                  <div class="col">
                    <fg-input label="IP" placeholder="Ex.: 127.0.0.1" type="text" v-model="newIp.value"></fg-input>
                  </div>
                  <div class="col">
                    <p-button type="success" style="margin-top: 25px;" @click="insertNewIp">
                      <i slot="label" class="nc-icon nc-check-2"></i>
                      Enviar
                    </p-button>
                  </div>
                </div>
                <div class="card-footer"></div>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <card>
              <div class="card-header">
                <h4>Gerenciar IPs</h4>
              </div>
              <div class="row card-body">
                <div class="col-md-12 mt-2">
                  <el-table class="table-striped" :data="tableData" style="width: 100%">
                    <el-table-column :min-width="50" label="IP">
                      <template slot-scope="props">
                        <span>{{ props.row.ip }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="50" label="Cadastrado Em">
                      <template slot-scope="props">
                        <span>{{ props.row.createdDate }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="20" fixed="right" class-name="td-actions">
                      <template slot-scope="props">
                        <p-button type="danger" size="sm" icon title="Excluir Ip" @click="removeIp(props.row)">
                          <i class="fas fa-trash"></i>
                        </p-button>
                      </template>
                    </el-table-column>
                    <template slot="empty">Não existem IP's cadastrados</template>
                  </el-table>
                </div>
              </div>
              <div class="card-footer"></div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn} from 'element-ui'
import {callWs, postWs} from "@/ws.service";
import swal from "sweetalert2";
import {isNullOrEmpty, notNull} from "@/util/core.utils";

Vue.use(Table)
Vue.use(TableColumn)

export default {
  data() {
    return {
      newIp: {
        value: ''
      },
      search: {
        key: '',
      },
      tableData: [],
      transactionAuthorizationTotp: 'false',
      validationCode: '',
      qrCode: '',
      totpQrCodeUri: '',
      totpManualCode: '',
      totpConfirmBtnDisabled: false,
      codeValid: true,
      hideTOTP: true,
      verifCode: null,
      totpCallback: null,
    }
  },
  created() {
    this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
  },
  mounted() {
    this.loadData()
  },
  methods: {
    isNullOrEmpty,
    insertNewIp() {
      if (!this.newIp.value.trim()) {
        swal({
          title: 'Atenção',
          text: 'Informe um endereço de IP válido para prosseguir.',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      postWs('/gateway/user/ip/add', true, null,
        {
          ip: this.newIp.value,
          verifCode: this.verifCode
        },
        response => {
          if (!this.showTOTP(response, function () {
            return this.insertNewIp()
          })) {
            this.clearTOTP()
            this.loadData()
            this.clearInput()
          }
        },
        error => {
          if(error.response.status === 403) {
            swal({
              title: 'Aviso!',
              text: 'Sua sessão expirou, por favor, faça login novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
            return
          }
          swal({
            title: 'Aviso!',
            text: 'Ocorreu um erro ao inserir novo endereço de IP. Verifique os dados e tente novamente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
          this.clearTOTP()
        }
      )
    },
    loadData() {
      callWs('/gateway/user/ip/list',
        'GET',
        null,
        true,
        null,
        null,
        this.successLoadData,
        this.failLoadData
      )
    },
    successLoadData(response) {
      this.tableData = response.data.list
    },
    failLoadData() {
      swal({
        title: 'Atenção',
        text: 'Ocorreu um erro ao buscar os IPs cadastrados. Atualize a página e tente novamente.',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    removeIp(row) {
      swal({
        title: 'Atenção',
        text: `Tem certeza que deseja remover o IP "${row.ip}"?`,
        buttonsStyling: true,
        showCancelButton: true,
        cancelButtonClass: 'btn btn-info btn-fill',
        confirmButtonClass: 'btn btn-warning btn-fill',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
        reverseButtons: true,
        type: 'warning'
      })
        .then((result) => this.doRemoveIp(row))
        .catch(() => {
        })
    },
    doRemoveIp(row) {
      postWs('/gateway/user/ip/delete', true, null,
        {
          ip: row.ip,
          verifCode: this.verifCode
        },
        response => {
          if (!this.showTOTP(response, function () {
            return this.doRemoveIp(row)
          })) {
            this.clearTOTP()
            this.loadData()
          }
        },
        error => {
          swal({
            title: 'Aviso!',
            text: 'Ocorreu um erro ao remover o IP. Tente novamente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
        }
      )
    },
    clearTOTP() {
      this.qrCode = null
      this.totpQrCodeUri = null
      this.totpManualCode = null
      this.totpCallback = null
      this.verifCode = null
      this.validationCode = null
    },
    showTOTP(response, callback) {
      if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.code)) {
        this.hideTOTP = false;
        this.qrCode = response.data.code
        this.totpQrCodeUri = response.data.totpQRCodeURI
        this.totpManualCode = response.data.totpManualCode
        this.totpCallback = callback
        return true
      }
      return false
    },
    validateCode() {
      this.totpConfirmBtnDisabled = true;
      postWs("/verifcode/validate",
        true,
        null,
        {verifCode: this.qrCode, hashCode: this.validationCode},
        this.successValidateCode,
        this.failValidateCode
      )
    },
    successValidateCode(response) {
      this.totpConfirmBtnDisabled = false
      this.verifCode = response.data.verifCode
      this.hideTOTP = true
      this.qrCode = ''
      this.totpCallback()
    },
    failValidateCode() {
      this.totpConfirmBtnDisabled = false
      swal({
        title: 'Aviso!',
        text: 'Código inválido!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    clearInput() {
      this.newIp.value = ''
    }
  },
}
</script>
