<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12 card">
        <div class="row card">
          <div class="col-md-12" style="margin-bottom: 15px; text-align: left">
            <h4 slot="header" class="card-title">
              Faturamento de Máquinas por Usuário
            </h4>
            <div class="row">
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Início</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsStart" @input="validatePeriod"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Fim</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsEnd"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <fg-input label="Adquirente">
                  <el-select size="large"
                             placeholder="Selecione"
                             v-model="acquirerName">
                    <el-option v-for="option in acquirerList"
                               class="select-default"
                               :value="option"
                               :label="option"
                               :key="option">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-2">
                <p-button @click.prevent="search" style="margin-top: 24px">
                  <i class="fas fa-search-dollar"></i> BUSCAR
                </p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row card">
          <div class="row col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Vendas: R$ {{toMoney(summary.saleValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Usuário: R$ {{toMoney(summary.userValue)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Lucro: R$ {{toMoney(summary.profitValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Parceiro: R$ {{toMoney(summary.partnerValue)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Pago: R$ {{toMoney(summary.amountPaid)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor à Receber: R$ {{toMoney(summary.amountUnpaid)}}</p>
            </div>
          </div>
        </div>
        <div class="row card" v-if="timelineItems.length>0">
          <div class="col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="card-body row">
              <div class="col-sm-12">
                <el-table class="table-striped"
                          :data="timelineItems"
                          style="width: 100%">
                  <el-table-column :min-width="130" label="Usuário">
                    <template slot-scope="item">
                    <span>
                      <span>{{item.row.username}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vlr. Vendas">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.saleValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vlr. Usuário">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.userValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vlr. Lucro">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.profitValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vlr. Parceiro">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.partnerValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vlr. Pago">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.amountPaid)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vlr. à Receber">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.amountUnpaid)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {callWs} from "src/ws.service"
  import {formatDate} from "src/util/date.utils"
  import {isNullOrEmpty, toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'

  export default {
    components: {
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        timelineItems: [],
        acquirerList: [],
        acquirerName: '',
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        summary: {
          saleValue: '',
          userValue: '',
          profitValue: '',
          partnerValue: '',
          amountPaid: '',
          amountUnpaid: '',
        },
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      toMoney,
      loadData() {
        callWs("/bo/card-terminal/get-acquirers",
          "GET", null, true, null,null,
          this.loadDataSuccess,
          (error) => {
            swal({
              title: 'Falha!',
              text: 'Falha ao buscar adquirentes!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      },
      loadDataSuccess(response) {
        this.acquirerList = response.data
      },
      search() {
        if(isNullOrEmpty(this.acquirerName)){
          swal({
            title: 'Aviso!',
            text: 'Favor escolher uma Adquirente!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/bo/financial/card-terminal/user-report",
          "POST", null, true, null,
          {
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            acquirerName: this.acquirerName
          },
          this.loadTimeline,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Dados não encontrados para o período selecionado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
          })
      },
      loadTimeline(response) {
        this.timelineItems = response.data.list
        this.summary.saleValue = response.data.saleValue
        this.summary.userValue = response.data.userValue
        this.summary.profitValue = response.data.profitValue
        this.summary.partnerValue = response.data.partnerValue
        this.summary.amountPaid = response.data.amountPaid
        this.summary.amountUnpaid = response.data.amountUnpaid
        if (this.timelineItems.length <= 0) {
          swal({
            title: 'Não encontrado',
            text: 'No período selecionado não existem transações.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
    }
  }
</script>
<style lang="scss">
  .dzs-transactions {
    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .btn.btn-default{
      background-color: #17a2b8 !important;
    }
    .btn.btn-default:hover{
      background-color: #0a7282 !important;
    }
    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }
  }
</style>
