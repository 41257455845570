<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12 card">
        <div class="row card">
          <div class="col-md-12" style="margin-bottom: 15px; text-align: left">
            <h4 slot="header" class="card-title">
              Relatório de Vendas por Usuário
            </h4>
            <div class="row">
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Início</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsStart" @input="validatePeriod"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Data Fim</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                  :picker-options="dateOptsEnd"
                                  format="dd/MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <fg-input label="Meio de Vendas">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="saleSource">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value="gateway"
                               class="select-default"
                               label="Gateway"/>
                    <el-option value="link"
                               class="select-default"
                               label="Link de Pagamento"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-sm-3">
                <fg-input label="Vendedores">
                  <el-select
                    size="large"
                    v-model="salePerson"
                    class="select-default"
                    placeholder="Vendedor">
                    <el-option
                      class="select-default"
                      v-for="item in salePersons"
                      :key="item.key"
                      :label="item.key==''?item.username:item.enabled==true?item.username+' (ativo)':item.username+' (inativo)'"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-2">
                <p-button @click.prevent="search" style="margin-top: 24px">
                  <i class="fas fa-search-dollar"></i> BUSCAR
                </p-button>
              </div>
              <div class="col-md-3" v-show="this.timelineItems.length > 0">
                <p-button @click.prevent="generateExcel" style="margin-top: 24px">
                  <i class="fa-regular fa-file-excel"></i> Exportar Excel
                </p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row card">
          <div class="row col-md-12" style="margin-bottom: 15px; text-align: center">
            <div class="box3" style="text-align: left; margin: auto">
              <p>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Total Transacionado: R$ {{toMoney(summary.totalTransacted)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Total Vendas: R$ {{toMoney(summary.salesValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas Gateway: R$ {{toMoney(summary.gatewaySale)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas Link: R$ {{toMoney(summary.linkSale)}}
              </p>
            </div>
            <div class="box3" style="text-align: left; margin: auto">
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas Cartão: R$ {{toMoney(summary.cardValue)}}<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas Boleto: R$ {{toMoney(summary.boletoValue)}}<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas Pix: R$ {{toMoney(summary.pixValue)}} ({{summary.pixAmount}})<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pix Saída: R$ {{toMoney(summary.pixOutValue)}} ({{summary.pixOutAmount}})
            </div>
            <div class="box3" style="text-align: left; margin: auto">
              <p>
                <span v-if="hasAnyAuthorization(['BO_CARD_TERM_USER_REPORT', 'BO_FINANCIAL_ONLINE'])"><i style="color: #17a2b8" class="fas fa-arrow-circle-right" ></i> Valor Lucro: R$ {{toMoney(summary.profitValue)}}<br></span>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Canceladas: R$ {{toMoney(summary.valueCanceled)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Chargeback: R$ {{toMoney(summary.chargebackValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Quantidade de usuários: {{summary.qtyUsers}} <br>
              </p>
            </div>
          </div>
        </div>
        <div class="row card" v-if="timelineItems.length>0">
          <div class="col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="card-body row">
              <div class="col-sm-12">
                <el-table class="table-striped"
                          :data="timelineItems"
                          style="width: 100%">
                  <el-table-column :min-width="130" label="Usuário" :sortable="true" prop="username">
                    <template slot-scope="item">
                    <span>
                      <span>{{item.row.username}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="160" label="Transacionado" :sortable="true" prop="totalTransacted">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.totalTransacted)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Vendas" :sortable="true" prop="salesValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.salesValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Gateway" :sortable="true" prop="gatewaySale">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.gatewaySale)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Link" :sortable="true" prop="linkSale">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.linkSale)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Cartão" :sortable="true" prop="cardValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.cardValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Boleto" :sortable="true" prop="boletoValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.boletoValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="130" label="PIX (in)" :sortable="true" prop="pixValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.pixValue)}} ({{item.row.pixAmount}})</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Lucro" :sortable="true" prop="profitValue" v-if="hasAnyAuthorization(['BO_CARD_TERM_USER_REPORT', 'BO_FINANCIAL_ONLINE'])">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.profitValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Cbk" :sortable="true" prop="chargebackValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.chargebackValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="130" label="PIX (out)" :sortable="true" prop="pixOutValue">
                    <template slot-scope="item">
                    <span>
                      <span>R$ {{toMoney(item.row.pixOutValue)}} ({{item.row.pixOutAmount}})</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="120" label="Vendedor" :sortable="true" prop="salePerson">
                    <template slot-scope="item">
                    <span>
                      <span>{{item.row.salePerson}}</span>
                    </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {callWs, postWs} from "src/ws.service"
  import {formatDate, daysBetween} from "src/util/date.utils"
  import {isNullOrEmpty, toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'
  import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"
  import download from "downloadjs";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        saleSource: null,
        salePerson: '',
        timelineItems: [],
        acquirerList: [],
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        summary: {
          salesValue: 0,
          cardValue: 0,
          valueCanceled: 0,
          valueFinancialReserve: 0,
          valuePaid: 0,
          valueUnpaid: 0,
          waitingApproval: 0,
          profitValue: 0,
          boletoValue: 0,
          pixValue: 0,
          pixAmount: 0,
          gatewaySale: 0,
          linkSale: 0,
          totalTransacted: 0,
          pixOutValue: 0,
          pixOutAmount: 0,
          qtyUsers: 0
        },
        salePersons: [{
          username: '',
          enabled: ''
        }],
      }
    },
    created() {
      this.loadDataSalePersons()
    },
    methods: {
      toMoney,
      search() {
        this.timelineItems = []
        callWs("/bo/financial/online/user-report",
          "POST", null, true, null,
          {
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            source: this.saleSource,
            salePerson: this.salePerson
          },
          this.loadTimeline,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Dados não encontrados para o período selecionado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'warning'
            })
          })
      },
      loadTimeline(response) {
        this.timelineItems = response.data.list
        this.summary.salesValue = response.data.salesValue
        this.summary.cardValue = response.data.cardValue
        this.summary.valueCanceled = response.data.valueCanceled
        this.summary.valueFinancialReserve = response.data.valueFinancialReserve
        this.summary.valuePaid = response.data.valuePaid
        this.summary.valueUnpaid = response.data.valueUnpaid
        this.summary.waitingApproval = response.data.waitingApproval
        this.summary.profitValue = response.data.profitValue
        this.summary.boletoValue = response.data.boletoValue
        this.summary.pixValue = response.data.pixValue
        this.summary.pixAmount = response.data.pixAmount
        this.summary.totalTransacted = response.data.totalTransacted
        this.summary.pixOutValue = response.data.pixOutValue
        this.summary.pixOutAmount = response.data.pixOutAmount
        this.summary.gatewaySale = response.data.gatewaySale
        this.summary.linkSale = response.data.linkSale
        this.summary.qtyUsers = response.data.list.length
        if (this.timelineItems.length <= 0) {
          swal({
            title: 'Não encontrado',
            text: 'No período selecionado não existem transações.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      hasAnyAuthorization(roles){
        return isSomeAuthorization(roles);
      },
      loadDataSalePersons() {
        postWs("/bo/gateway/list-sale-persons",
          true, null, {}, this.successLoadSalePersons,
          (error) => {
            let text = 'Erro ao carregar os dados, tente novamente!'
            if(error.response.status == 404) {
              text = "Transação não encontrada para os dados informados!"
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.tableData = []
          })
      },
      successLoadSalePersons(response) {
        this.salePersons = response.data
      },
      generateExcel() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/bo/financial/online/user-report-excel",
          "POST", null, true, null,
          {
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            source: this.saleSource,
            salePerson: this.salePerson
          },
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o Excel do relatório, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "transacoes_online_por_usuario"+"_"+formatDate(this.startDate, "DD/MM/YYYY")+"_a_"+formatDate(this.endDate, "DD/MM/YYYY")+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: 'Erro ao gerar o Excel do relatório, tente novamente!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-transactions {
    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .btn.btn-default{
      background-color: #17a2b8 !important;
    }
    .btn.btn-default:hover{
      background-color: #0a7282 !important;
    }
    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }

    .el-table__cell {
      font-size: 12px !important;

    }
  }
</style>
