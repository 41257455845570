import VMasker from 'vanilla-masker'

export function toMoney(value) {
    if (value == null) {
        value = 0;
    }
    value = parseFloat(value)

    if(value<0) {
      return '-' + VMasker.toMoney(parseFloat(value).toFixed(2));
    } else {
      return VMasker.toMoney(parseFloat(value).toFixed(2));
    }
}

export function moneyToFloat(value) {
    if (value.includes(',')) {
        return parseFloat(value.trim().replace(/\./g, '').replace(',', '.'));
    }
    return parseFloat(value.trim())
}

export function notNull(value, defaultValue) {
    if (isNull(value)) {
        return defaultValue
    }
    return value
}

export function notNullOrEmpty(value, defaultValue) {
    if (isNullOrEmpty(value)) {
        return defaultValue
    }
    return value
}

export function isNull(value) {
    try {
        if (value == undefined || value == null || value == 'undefined' || value == 'null') {
            throw 'Value is undefined or null'
        }
        return false
    } catch (e) {
        return true
    }
}

export function isNullOrEmpty(value) {
    if (isNull(value) || value.trim() == '') {
        return true
    }
    return false
}

export function isValidCpf(cpf) {
    if (!cpf || cpf.length != 11
        || cpf == "00000000000"
        || cpf == "11111111111"
        || cpf == "22222222222"
        || cpf == "33333333333"
        || cpf == "44444444444"
        || cpf == "55555555555"
        || cpf == "66666666666"
        || cpf == "77777777777"
        || cpf == "88888888888"
        || cpf == "99999999999")
        return false
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
}

export function isValidCnpj(cnpj) {
    if (isNullOrEmpty(cnpj)
        || cnpj.length != 14
        || cnpj == "00000000000000"
        || cnpj == "11111111111111"
        || cnpj == "22222222222222"
        || cnpj == "33333333333333"
        || cnpj == "44444444444444"
        || cnpj == "55555555555555"
        || cnpj == "66666666666666"
        || cnpj == "77777777777777"
        || cnpj == "88888888888888"
        || cnpj == "99999999999999")
        return false
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho)
    var digitos = cnpj.substring(tamanho)
    var soma = 0
    var pos = tamanho - 7
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado != digitos.charAt(0)) return false;
    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado != digitos.charAt(1)) return false
    return true;
}

export function isValidBeforeDate(dateStr) {
    if (isNullOrEmpty(dateStr)
        || dateStr.length != 10) {
        return false
    }

    let pattern = /(\d{2})\/(\d{2})\/(\d{4})/
    let date = new Date(dateStr.replace(pattern, '$3-$2-$1') + 'T12:00:00')
    let now = new Date()

    if (date > now) {
        return false
    }

    date.setFullYear(date.getFullYear() + 120)
    if (date < now) {
        return false
    }

    return true
}

export function containsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}

export function getValidUsername(username){
  return username == null ? null : username.replace(/[\W_]+/g,"")
}

export function headScript(src) {
  let headScript = document.createElement('script');
  headScript.setAttribute('src', src);
  headScript.setAttribute('type', 'text/javascript');
  headScript.setAttribute('name', 'antifraud');
  document.head.appendChild(headScript)
}

export function bodyScript(src) {
  let bodyScript = document.createElement('noscript');
  let iframe = document.createElement("iframe");
  iframe.setAttribute('src', src);
  iframe.setAttribute('style', 'width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;');
  bodyScript.append(iframe);
  document.body.appendChild(bodyScript);
}
