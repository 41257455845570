<template>
  <form @submit.prevent="onFormSubmit" class="card edit-card">
    <div class="card-header">
      <h4>Remover Placas do Estoque - ({{ plate.name }})</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-9">
          <label for="quantity">Quantidade a remover <b>(Em estoque: {{ plate.quantityInStock }})</b></label>
          <fg-input v-mask="'######'"
                    id="quantity"
                    name="Quantidade a remover"
                    v-validate="{required: true, min_value: 1, max_value: this.plate.quantityInStock, }"
                    v-model="quantity"
                    :error="getError('Quantidade a remover')"
          ></fg-input>
        </div>
        <div class="col-md-3">
          <p-button type="success" native-type="submit">Remover</p-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import swal from "sweetalert2";
import {postWs} from "@/ws.service";

export default {
  name: "remove-plate-in-stock-form",
  props: ['plate'],
  emits: ['save'],
  data() {
    return {
      quantity: 0
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onFormSubmit() {
      this.$validator.validateAll().then(isValid => {
        if(isValid) {
          this.showSubmitConfirmation()
        }
      })
    },
    showSubmitConfirmation() {
      swal({
        title: 'Atenção!',
        text: `Deseja mesmo remover ${this.quantity} unidade(s) da placa "${this.plate.name}"?`,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Cancelar',
      }).then(() => {
        this.doSubmit()
      })
    },
    doSubmit() {
      postWs('/bo/suitjourney/remove-plate-stock', true, null,
        { plateId: this.plate.id, quantity: this.quantity},
        this.successSubmit,
        this.failSubmit)
    },
    successSubmit() {
      swal({
        title: 'Sucesso!',
        text: 'Quantiade remover com sucesso do estoque!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.$emit('save')
    },
    failSubmit() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao remover a quantidade informada. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-card {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
}
.btn {
  margin-top: 24px;
}
</style>
