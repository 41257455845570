<template>
  <div class="dzs-acc-edit">
    <div class="row">
      <div class="col-md-12" v-for="image in images">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Aprovar Recarga Via TED</h4>
          </div>
          <div class="row" style="margin: 0px 10px 0px 10px;">
            <div class="col-md-4">
              <fg-input type="text"
                        label="Username"
                        disabled="true"
                        v-model="username">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="Valor"
                        disabled="true"
                        v-model="value">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="Status"
                        disabled="true"
                        v-model="status">
              </fg-input>
            </div>
          </div>
          <div class="row" style="margin: 0px 10px 0px 10px;">
            <div class="col-md-4">
              <fg-input type="text"
                        label="Nome Depositante"
                        disabled="true"
                        v-model="sourceAccountOwnerName">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="CPF/CNPJ Depositante"
                        v-mask="['###.###.###-##','##.###.###/####-##']"
                        disabled="true"
                        v-model="sourceAccountOwnerSocialNumber">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="Data do TED"
                        disabled="true"
                        v-model="tedDate">
              </fg-input>
            </div>
          </div>
          <div class="row" style="margin: 0px 10px 0px 10px;" v-show="!isNullOrEmpty(alert)">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Motivo da Rejeição"
                        disabled="true"
                        v-model="alert">
              </fg-input>
            </div>
          </div>
          <div class="row" style="margin: 0px 10px 0px 10px;" v-show="!isNullOrEmpty(tedDocNumberSaved)">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Número do Comprovante do TED"
                        disabled="true"
                        v-model="tedDocNumberSaved">
              </fg-input>
            </div>
          </div>
          <div class="card-body" style="margin: 0 auto">
            <div class="row">
              <div class="col-md-12 doc-image">
                <a href="#" @click="openDoc(image)">
                  <embed width="900" height="900" :src='loadDocSrc(image)'
                         v-if="'pdf'==image.imageFileType.replace(' ', '').toLowerCase()"/>
                  <img :src="loadDocSrc(image)" v-else/>
                </a>

                <fg-input type="text" disabled="true"
                          v-model="image.documentName"/>

              </div>
            </div>
            <div class="row">
              <button class="btn btn-info btn-fill btn-wd" style="margin: 20px auto"
                      @click="openDoc(image)">
                <i class="fas fa-search-plus"></i>
                VISUALIZAR DOCUMENTO EM NOVA JANELA
              </button>
            </div>
            <div class="row" v-show="status=='AGUARDANDO APROVAÇÃO'">
              <button class="btn btn-success btn-fill btn-wd" style="margin: 20px auto"
                      type="submit" @click="approveCredit()">
                <i class="fa fa-check-circle"></i>
                Aprovar Recarga
              </button>
              <button class="btn btn-danger btn-fill btn-wd" style="margin: 20px auto"
                      type="submit" @click="rejectCredit()">
                <i class="fa fa-times-circle"></i>
                Reprovar Recarga
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {postWs, getWs, failWs} from 'src/ws.service'
  import {isNullOrEmpty, toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'

  export default {
    components: {},
    data() {
      return {
        id: '',
        username: '',
        value: '',
        sourceAccountOwnerName: '',
        sourceAccountOwnerSocialNumber: '',
        tedDate: '',
        status: '',
        alert: '',
        tedDocNumber: '',
        tedDocNumberSaved: '',
        images: []
      }
    },
    created() {
      this.id = this.$route.params.id
      this.loadData()
    },
    methods: {
      isNullOrEmpty,
      loadData() {
        postWs("/credit-ted/get-credit-ted", true,
          {idCreditTed: this.id}, null,
          this.loadCrediTed, failWs)
      },
      loadCrediTed(response) {
        this.value = 'R$ ' + toMoney(response.data.value)
        this.sourceAccountOwnerName = response.data.sourceAccountOwnerName
        this.sourceAccountOwnerSocialNumber = response.data.sourceAccountOwnerSocialNumber
        this.status = this.statusToLabel(response.data.status)
        this.id = response.data.id
        this.username = '@'+response.data.username
        this.alert = response.data.alert
        this.tedDate = response.data.tedDate
        this.tedDocNumberSaved = response.data.numberDocumentTed
        this.loadDoc(response)
      },
      loadDoc(response) {
        this.images = []
        this.loadDocData(response.data.idReceipt)
      },
      loadDocData(idReceipt) {
        getWs("/bo/document/image", true,
          {id: idReceipt},
          (response) => {
            this.images.push(response.data)
          }, failWs)
      },
      loadDocSrc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        if (fileType == 'pdf') {
          return 'data:application/pdf;base64,' + image.imageBase64
        } else if (fileType == 'jpg') {
          return 'data:image/jpeg;base64,' + image.imageBase64
        }
        return 'data:image/' + fileType + ';base64,' + image.imageBase64
      },
      openDoc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        let imageDimension = " width='100%' "
        if (fileType == 'pdf') {
          imageDimension += " height='100%' "
        }
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Validação de Documento</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed" + imageDimension + "src='" + this.loadDocSrc(image) + "'></embed></body></html>"
          )
          ;
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do documento, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      approveCredit() {
        swal({
          title: 'Aprovar Recarga',
          text: "Você deseja realmente aprovar a recarga de "+this.value+
            " na conta do usuário "+this.username+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'SIM',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'NÃO',
          cancelButtonColor: '#ef8157'
        }).then((result) => {
          if(result) {
            swal({
              title: 'Número do Comprovante',
              text: 'Digite o número do comprovante de TED que está no extrato:',
              input: 'text',
              buttonsStyling: true,
              confirmButtonClass: 'btn-success btn-fill',
              showCancelButton: true,
              confirmButtonColor: '#00c390',
              cancelButtonColor: '#ef8157',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              width: 500,
              preConfirm: (tedDocNumber) => {
                if(isNullOrEmpty(tedDocNumber)){
                  swal({
                    title: 'Erro!',
                    text: 'Favor preencher o número do comprovante.',
                    buttonsStyling: true,
                    timer: 15000,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'error'
                  })
                }else {
                  this.tedDocNumber = tedDocNumber
                  this.callApproveCreditWs(this.id)
                }
              }
            })
          }
        })
      },
      callApproveCreditWs(id) {
        postWs("/credit-ted/update-status", true,
          null, {id: id, status: "PROCESSED", tedDocumentNumber: this.tedDocNumber},
          () => {
            swal({
              title: 'Sucesso!',
              text: "Recarga realizada com sucesso.",
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
            this.loadData()
          }, function (error) {
            if(error.response.status == '409'){
              swal({
                title: 'Comprovante Duplicado!',
                text: 'O número de documento do comprovante de TED está registrado em outra TED!',
                buttonsStyling: true,
                timer: 15000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'warning'
              })
              return
            }
            swal({
              title: 'Erro!',
              text: 'Erro ao autorizar recarga!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'error'
            })
          })
      },
      rejectCredit() {
        swal({
          title: 'Rejeitar Recarga',
          text: "Você deseja realmente rejeitar a recarga de "+this.value+
            " do usuário "+this.username+"?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'SIM',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'NÃO',
          cancelButtonColor: '#ef8157'
        }).then((result) => {
          if(result) {
            swal({
              title: 'Escolha um motivo:',
              input: 'select',
              inputOptions: {
                TED_NOT_FOUND: 'TED não encontrado',
                INCOMPATIBLE_VALUE: 'Valor incompatível',
                OTHERS: 'Outros'
              },
              showCancelButton: true,
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value != null) {
                    this.callRejectCreditWs(this.id, value)
                  }
                })
              }
            })
          }
        })
      },
      callRejectCreditWs(id, alert) {
        postWs("/credit-ted/update-status", true,
          null, {id: id, status: "REJECTED", alert: alert},
          () => {
            swal({
              title: 'Sucesso!',
              text: "Recarga rejeitada com sucesso.",
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'success'
            })
            this.loadData()
          }, function (error) {
            swal({
              title: 'Erro!',
              text: 'Erro ao rejeitar recarga!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'error'
            })
          })
      },
      statusToLabel(status) {
        if (status == 'WAITING_APPROVAL') {
          return 'AGUARDANDO APROVAÇÃO'
        } else if (status == 'PROCESSED') {
          return 'PROCESSADO'
        } else if (status == 'REJECTED') {
          return 'REJEITADO'
        } else if (status == 'FAIL') {
          return 'ERRO'
        }
        return '??'
      }
    }
  }

</script>
<style lang="scss">
  .dzs-acc-edit {
    .doc-image input {
      min-width: 400px;
      text-align: center;
      color: #000000 !important;
    }

    .autocomplete__icon {
      display: none;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
