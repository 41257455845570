<template>
  <div class="dzs-acc-search">
    <div class="row" v-show="formPrincipal">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4 class="card-title">
            Cadastrar Máquina de Cartão
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <fg-input label="Adquirente">
                <el-select size="large"
                           placeholder="Selecione"
                           v-model="terminal.acquirerName">
                  <el-option v-for="option in acquirerList"
                             class="select-default"
                             :value="option"
                             :label="option"
                             :key="option">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-6">
              <div style="float: left; width: 86%">
                <label class="control-label">Modelo</label>
                <label class="control-label" style="float: right">
                  <a href="#" @click="loadNewDataModels">
                    <i slot="label" class="fas fa-history"></i> Consultar Lista de Modelos</a>
                </label>
                <fg-input v-model="terminal.modelName"
                          style="width: 100%"
                          name="Modelo"
                          v-validate="{required: true}"
                          :error="getError('Modelo')">
                  <template slot="addonRight"><i class="fas fa-check" style="color: #156800" v-show="modelCheck"></i>
                    <i class="fas fa-times" style="color: #156800" v-show="modelNotFound"></i></template>
                </fg-input>
              </div>
              <p-button type="primary" style="margin-top: 25px; float: left" @click="searchDataModel">
                <i slot="label" class="fas fa-search"></i>
              </p-button>
            </div>
          </div>
<!--          <div class="row form-group" style="margin-top: 20px">-->
<!--            <div class="col-sm-6">-->
<!--              <p-checkbox class="text-left" v-model="terminal.themeCustomized">-->
<!--                <b>Marca Da Máquina Customizada</b>-->
<!--              </p-checkbox>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="card-footer text-right">
          <p-button type="info" @click.prevent="openFormInsertTerminal">Inserir Máquinas</p-button>
        </div>
      </div>
    </div>
    <div class="row card" v-show="formInsertTerminals">
      <div class="card-body row">
        <div class="col-md-12">
          <h4 class="card-title">Cadastrar Máquinas da Adquirente {{terminal.acquirerName}}, Modelo {{terminal.modelName}}</h4>
        </div>
        <div class="col-md-4">
          <label class="control-label">Serial da máquina</label>
          <fg-input v-model="search.physicalId" @keydown.enter.prevent="loadTerminal"/>
        </div>
        <div class="col-md-2">
          <p-button type="success" style="margin-top: 25px"
                    @click="loadTerminal">
            <i slot="label" class="nc-icon nc-simple-add"></i>
            Inserir
          </p-button>
        </div>
        <div class="card-body row">
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="listTerminals"
                      style="width: 100%">
              <el-table-column :min-width="150" label="Serial">
                <template slot-scope="props">
                          <span>
                            <span><b>#{{props.row.physicalId}}</b></span>
                          </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="200" label="Adquirente">
                <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.acquirerName}}</b></span>
                          </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Modelo">
                <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.modelName}}</b></span>
                          </span>
                </template>
              </el-table-column>
              <el-table-column :width="60" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Excluir"
                            @click="deleteList(props.row.physicalId)">
                    <i class="fas fa-minus"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-footer text-left">
            <p-button type="warning" @click.prevent="returnFormPrincipal">Voltar</p-button>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" @click.prevent="insertTerminal">Salvar Máquinas</p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="formSearchModel">
      <div class="col-md-12 card">
      <div class="card-header">
        <h4>Selecionar Modelo</h4>
      </div>
      <div class="card-body row">
        <div class="col-md-6">
          <label class="control-label">Nome do Modelo</label>
          <fg-input v-model="searchModel.key"/>
        </div>
        <div class="col-sm-3">
          <fg-input label="Exibir">
            <el-select
              class="select-default"
              v-model="paginationModels.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in [5,10,25,50]"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-3">
          <p-button type="success" style="margin-top: 25px"
                    @click="findModels">
            <i slot="label" class="nc-icon nc-check-2"></i>
            Buscar
          </p-button>
        </div>
        <div class="col-sm-12 mt-2 clickable-rows">
          <el-table class="table-striped"
                    :data="tableDataModels"
                    @row-click="selectDestination"
                    style="width: 100%">
            <el-table-column :min-width="30" label="Serial">
              <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" label="Nome">
              <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="50" label="Status">
              <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="70" label="Valor de Aluguel">
              <template slot-scope="props">
                    <span>
                      <span>R$ {{props.row.leaseValue}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="70" label="Valor de Compra">
              <template slot-scope="props">
                    <span>
                      <span>R$ {{props.row.purchaseValue}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" label="Marca">
              <template slot-scope="props">
                    <span>
                      <span>{{props.row.brand.name}}</span>
                    </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">EXIBINDO DO {{paginationModels.fromNumber}} AO {{paginationModels.toNumber}}, DE
            {{paginationModels.totalNumber}} REGISTROS.</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="paginationModels.currentPage"
                        :per-page="paginationModels.perPage"
                        :total="paginationModels.totalNumber"
                        :click="this.loadDataModels">
          </p-pagination>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {callWs, failWs, getWs, postWs} from '../../../../../ws.service'
  import swal from 'sweetalert2'
  import {isNullOrEmpty} from "../../../../../util/core.utils";

  export default {
    components: {
    },
    data () {
      return {
        formPrincipal: true,
        formSearchModel: false,
        formInsertTerminals: false,
        modelCheck: false,
        modelNotFound: false,
        tableDataModels: [],
        acquirerList: [],
        listTerminals: [],
        searchModel:{
          key: ''
        },
        terminal:{
          physicalId: '',
          status: 'STORED',
          returnStatus: 'OK',
          themeCustomized: false,
          modelId: '',
          modelName: '',
          acquirerName: '',
        },
        paginationModels: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        search: {
          physicalId: '',
          active: '',
        }
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      loadData() {
        callWs("/bo/card-terminal/get-acquirers",
          "GET", null, true, null,null,
          this.loadDataSuccess,
          (error) => {
            swal({
              title: 'Falha!',
              text: 'Falha ao buscar adquirentes!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      },
      loadDataSuccess(response) {
        this.acquirerList = response.data
      },
      openFormInsertTerminal() {
        if(!this.modelCheck){
          swal({
            title: 'Aviso!',
            text: 'Favor validar o Modelo da Máquina!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        if(isNullOrEmpty(this.terminal.acquirerName)){
          swal({
            title: 'Aviso!',
            text: 'Favor escolher uma Adquirente!',
            buttonsStyling: true,
            timer: 10000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.formInsertTerminals = true
        this.formPrincipal = false
      },
      returnFormPrincipal() {
        this.listTerminals = []
        this.formInsertTerminals = false
        this.formPrincipal = true
      },
      insertTerminal() {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          if(this.listTerminals.length == 0){
            swal({
              title: 'Aviso!',
              text: 'Favor inserir ao menos um terminal na lista!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          if(!this.modelCheck){
            swal({
              title: 'Aviso!',
              text: 'Favor validar o Modelo da Máquina!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          callWs("/bo/card-terminal/card-terminal",
            "POST", null, true, null, this.listTerminals,
            () => {
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
              this.returnFormPrincipal()
            }, (error) => {
              this.buttonDisabled = false
              if (error.response.status == 409) {
                swal({
                  title: 'Aviso!',
                  text: 'Já existe uma máquina cadastrada com o mesmo ID',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Falha!',
                text: 'Falha ao salvar a máquina, verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
      findModels() {
        this.paginationModels.currentPage = 1
        this.loadDataModels()
      },
      loadNewDataModels(){
        this.terminal.modelName = ''
        this.searchModel.key = ''
        this.loadDataModels()
      },
      loadDataModels() {
        postWs("/bo/card-terminal/list-card-terminal-model",
          true, null,
          {
            enabled: null,
            key: this.searchModel.key,
            pageNumber: this.paginationModels.currentPage - 1,
            pageSize: this.paginationModels.perPage
          }, this.successLoadDataModels,
          failWs)
        return this.paginationModels.currentPage
      },
      successLoadDataModels(response) {
        this.formPrincipal = false
        this.formSearchModel = true
        this.tableDataModels = response.data.list
        this.paginationModels.totalNumber = response.data.totalNumber
        this.paginationModels.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationModels.totalNumber > 0) {
          this.paginationModels.fromNumber = ((this.paginationModels.perPage * (this.paginationModels.currentPage - 1)) + 1)
          this.paginationModels.toNumber = ((this.paginationModels.fromNumber + this.tableDataModels.length) - 1)
        } else {
          this.paginationModels.fromNumber = 0
          this.paginationModels.toNumber = 0
        }
      },
      searchDataModel() {
        postWs("/bo/card-terminal/search-card-terminal-model",
          true, {name: this.terminal.modelName},
          null, this.successSearchModel,
          () => {
            swal({
              title: 'Aviso!',
              text: 'Nenhum modelo encontrado com esse nome!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.modelNotFound = true
            this.modelCheck = false
          })
      },
      successSearchModel(response) {
        this.tableDataModels = response.data
        this.terminal.modelId = this.tableDataModels.id
        this.terminal.modelName = this.tableDataModels.name
        this.modelNotFound = false
        this.modelCheck = true
      },
      selectDestination(destination) {
        this.terminal.modelId = destination.id
        this.terminal.modelName = destination.name
        this.modelNotFound = false
        this.modelCheck = true
        this.formPrincipal = true
        this.formSearchModel = false
      },
      loadTerminal(){
        this.search.active = true
        this.listTerminals.forEach(value => {
          if(value.physicalId == this.search.physicalId) {
            swal('Aviso', 'Terminal já foi incluído na lista!', 'warning')
            this.search.active = false
          }
        })
        if(this.search.active) {
          getWs('/bo/card-terminal/search-card-terminal', true, {physicalId: this.search.physicalId}, this.successLoadTerminal, this.failLoadTerminal)
        }
      },
      successLoadTerminal(response){
        swal({
          title: 'Aviso!',
          text: 'Terminal já cadastrado na base de dados',
          buttonsStyling: true,
          timer: 10000,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        this.search.physicalId = ''
      },
      failLoadTerminal(error){
        this.terminal.physicalId = this.search.physicalId
        this.listTerminals.push({physicalId: this.terminal.physicalId,
          status: this.terminal.status,
          returnStatus: this.terminal.returnStatus,
          themeCustomized: this.terminal.themeCustomized,
          modelId: this.terminal.modelId,
          modelName: this.terminal.modelName,
          acquirerName: this.terminal.acquirerName})
        this.search.physicalId = ''
      },
      deleteList(physicalId){
        let index = 0
        this.listTerminals.forEach(value => {
          if(value.physicalId == physicalId) {
            this.listTerminals.splice(index, 1);
          }
          index++
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .el-input__inner[readonly] {
      background-color: #ffffff !important;
    }
  }

  .clickable-rows {
    tbody tr td {
      cursor: pointer;
    }

    .el-table__expanded-cell {
      cursor: default;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
