<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Buscar Usuário</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Usuário</label>
                <fg-input v-model="search.name">
                  <span slot="addonLeft">@</span>
                </fg-input>
              </div>
              <div class="col-md-4">
                <p-button style="margin-top: 25px;"
                          @click="searchUser" type="info">
                   Alterar Gerente de Conta
                </p-button>
              </div>
            </div>
            <div class="row" v-if="userToChange.username != null">
              <div class="col-md-12" style="text-align: center">
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Username</label>
                    <p class="form-control-static">@{{userToChange.username}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Gerente de Contas Atual</label>
                    <p class="form-control-static">@{{userToChange.actualSalePersonUsername}}</p>
                  </div>
                </div>
                <div  class="row">
                  <div  class="col-md-3 m-auto" style="text-align: center">
                    <fg-input label="Novo Gerente de Conta">
                      <el-select size="s"
                                 placeholder="Selecionar"
                                 class="select-default"
                                 v-model="userToChange.salePersonToAdd">
                        <el-option v-for="item in listSalePersonUsernames"
                                   class="select-danger"
                                   :value="item.username"
                                   :label="item.name"
                                   :key="item.username">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <p-button type="success" style="margin-top: 25px;"
                              @click="saveSalePerson">
                      <i slot="label" class="far fa-floppy-disk"></i>
                      Salvar
                    </p-button>
                    <p-button type="danger" style="margin-top: 25px;"
                              @click="clearSalePersonToAdd">
                      <i slot="label" class="nc-icon nc-simple-remove"></i>
                      Cancelar
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {failWs, postWs, getWs} from "src/ws.service"
import swal from 'sweetalert2'
export default {
  data() {
    return {
      search: {
        name: '',
      },
      userToChange: {
        username: null,
        actualSalePersonUsername: null,
        salePersonToAdd: null
      },
      listSalePersonUsernames: [],
    }
  },
  watch:{
    '$route': 'resetDefaultValues'
  },

  mounted() {
    this.loadData()
  },
  methods: {
    resetDefaultValues(){
      this.search.name = ''
      this.loadData()
    },
    find() {
      this.loadData()
    },
    loadData() {
      postWs("/bo/commercial/salesperson/list",
        true, null, {},
        this.successLoadData,
        failWs)
    },
    successLoadData(response) {
      this.listSalePersonUsernames = response.data.list
    },
    clearSalePersonToAdd(){
      this.userToChange.username = null
      this.userToChange.actualSalePersonUsername = null
      this.userToChange.salePersonToAdd = null
    },
    searchUser() {
      this.clearSalePersonToAdd()
      getWs("/bo/commercial/salesperson/user-salesperson",
        true, {username: this.search.name},
        (response) => {
          this.userToChange.username = this.search.name
          this.userToChange.actualSalePersonUsername = response.data.salePersonUsername
        }, (error) => {
          this.clearSalePersonToAdd();
          if (error.response.status === 404) {
            swal({
              title: 'Aviso!',
              text: 'Conta não existe, está bloqueada ou não possui gerente de vendas.',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        })
    },
    saveSalePerson() {
      postWs("/bo/commercial/salesperson/save-saleperson-user", true, null, {
          username: this.userToChange.username,
          salePersonUsername:  this.userToChange.salePersonToAdd
      }, response => {
          this.search.name = ''
          this.clearSalePersonToAdd()
          this.loadData()
         swal({
            title: 'Sucesso!',
            text: 'Gerente de Conta Alterado!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          })
        },
        failWs)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
