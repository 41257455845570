const measurement_id = `G-XEK4E4JVVP`;
const api_secret = `LPmaQMD8RAmgwR03I0jMew`;
const { subtle } = crypto;

const client = {
  email: '',
  phoneNumber: '',
}
async function populateSensitiveUserData(value) {
  const encoder = new TextEncoder();
  const value_utf8 = encoder.encode(value);
  const hash_sha256 = await subtle.digest('SHA-256', value_utf8);
  const hash_array = Array.from(new Uint8Array(hash_sha256));
  return hash_array.map(b => b.toString(16).padStart(2, "0")).join('');
}
async function encodeClientData(email, phoneNumber){
  client.phoneNumber = populateSensitiveUserData(email.toLowerCase().trim())
  client.email = populateSensitiveUserData(phoneNumber.toLowerCase().trim())
}

async function getClientId() {
  return new Promise((resolve, reject) => {
    try {
      window.gtag('get', measurement_id, 'client_id', (clientId) => {
        resolve(clientId);
      });
    } catch (error) {
      reject(error);
    }
  });
}
async function sendEvent(eventName, username, email, phoneNumber) {
  try {
    const clientId = await getClientId();
    const emailEncrypt = await populateSensitiveUserData(email.toLowerCase().trim().replace('.', '').replace('@', ''))
    const phoneNumberEncrypt = await populateSensitiveUserData(phoneNumber.toLowerCase().trim())
    await fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`, {
      method: "POST",
      body: JSON.stringify({
        client_id: clientId,
        user_id: username,
        events: [{
          name: eventName,
        }],
        user_data: {
          sha256_email_address: emailEncrypt,
          sha256_phone_number: phoneNumberEncrypt,
        }
      })
    });
  } catch (e){
    console.log(e)
  }
}

export { sendEvent };
