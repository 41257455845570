<template>
  <form @submit.prevent="onFormSubmit" class="card edit-card">
    <div class="card-header">
      <h4>Editar Placa - ({{ plate.name }})</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-8">
          <label for="platePhoto" style="display: block">Imagem da placa</label>
          <label for="platePhoto" class="btn plate-photo-label">
            <i class="fa-regular fa-image"></i> {{ platePhotoFileName }}
          </label>
          <input id="platePhoto" ref="platePhotoIpt" type="file" name="platePhoto" hidden
                 @input="handleFilePlateChange" />
          <fg-input type="file"></fg-input>
        </div>
        <div class="col-sm-4">
          <div class="img-wrapper">
            <img :src="getPlateImage(plate.platePhotoBase64)" alt="">
          </div>
        </div>
        <div class="col-md-12">
          <fg-input label="Nome"
                    name="name"
                    data-vv-as="Nome"
                    v-validate="{required: true}"
                    v-model="edit.name"
                    :error="getError('name')"
          ></fg-input>
        </div>
        <div class="col-md-12">
          <label for="revenue">Faturamento</label>
          <div class="form-group" :class="[{'has-danger': getError('revenue')}]">
            <input id="revenue"
                   name="revenue"
                   v-money="'money'"
                   v-validate="'revenueNotEmpty'"
                   data-vv-as="Faturamento"
                   v-model.lazy="edit.revenue"
                   class="form-control"
                   :class="[{'is-valid': !getError('revenue')}, {'is-invalid': getError('revenue')}]"
            />
            <div class="invalid-feedback error-text" v-if="getError('revenue')">
              {{ getError('revenue') }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <p-button type="success" native-type="submit" class="float-right">Editar</p-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import swal from "sweetalert2";
import {callWs} from "@/ws.service";
import {moneyToFloat, toMoney} from "@/util/core.utils";

export default {
  name: "edit-plate-form",
  props: ['plate'],
  emits: ['save'],
  data() {
    return {
      platePhotoFileName: 'Selecionar Arquivo',
      quantity: 0,
      edit: {
        id: '',
        name: '',
        revenue: 0,
      },
    }
  },
  created() {
    this.$validator.extend('revenueNotEmpty', {
      getMessage: field => 'O campo ' + field + ' deve ser maior que R$ 0,00',
      validate: (value) => {
        const number = moneyToFloat(value)
        return number > 0
      }
    })
  },
  beforeMount() {
    this.loadOriginalData()
  },
  watch: {
    plate() {
      this.loadOriginalData()
    },
  },
  methods: {
    toMoney,
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onFormSubmit() {
      this.$validator.validateAll().then(isValid => {
        const formData = new FormData()
        formData.set('platePhoto', this.$refs.platePhotoIpt.files[0])

        if (isValid) {
          callWs('/bo/suitjourney/update-plate-data', 'POST', null, true,
            {
              plateId: this.edit.id,
              name: this.edit.name,
              revenue: moneyToFloat(this.edit.revenue)
            },
            formData,
            this.successSubmit,
            this.failSubmit)
        }
      })
    },
    successSubmit() {
      swal({
        title: 'Sucesso!',
        text: 'Dados atualizados com sucesso!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.$emit('save')
    },
    failSubmit() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao editar a placa. Tente novamente!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    loadOriginalData() {
      this.edit.id = this.plate.id
      this.edit.name = this.plate.name
      this.edit.revenue = toMoney(this.plate.revenue)
    },
    handleFilePlateChange(event) {
      this.platePhotoFileName = event.target.files[0].name
    },
    getPlateImage(platePhotoBase64) {
      return 'data:image/jpeg;base64,' + platePhotoBase64
    }
  }

}
</script>
<style lang="scss">
.edit-card {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;

  .img-wrapper {
    height: 100%;
    max-height: 7rem;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
