<template>
  <div class="manage-plate">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header row aligin-items-center">
          <div class="col-lg-9">
            <h4>Buscar Placa</h4>
          </div>
          <div v-if="isAuthorized('BO_SUIT_JOURNEY_PLATE_ACTIONS')" class="col-lg-3 d-flex align-items-center">
            <p-button @click="insertNewPlate">Inserir Nova Placa</p-button>
          </div>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Nome</label>
                <fg-input v-model="search.name"/>
              </div>
              <div class="col-md-2">
                <label for="revenue">Faturamento</label>
                <fg-input id="revenue"
                          v-money="'money'"
                          v-model="search.revenue"
                          class="input-revenue"
                          data-vv-as="Faturamento"
                          name="revenue"
                          :error="getError('revenue')"
                >
                  <template slot="addonLeft">R$</template>
                </fg-input>
              </div>
              <div class="col-md-4">
                <p-button type="success" style="margin-top: 25px;"
                          @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2 pb-3">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%">
                  <el-table-column :min-width="90" label="Nome">
                    <template slot-scope="props">
                      <div class="plate-name">
                        <div class="img-wrapper">
                          <img :src="getPlateImage(props.row.platePhotoBase64)" alt="">
                        </div>
                        <span>
                          <span>{{ props.row.name }}</span>
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="60" label="Faturamento">
                    <template slot-scope="props">
                    <span>
                      <span>R$ {{ toMoney(props.row.revenue) }}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="isAuthorized('BO_SUIT_JOURNEY_PLATE_ACTIONS')" label="Ações" :min-width="20"
                                   fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="info" size="sm" icon title="Editar" class="float-right"
                                @click="edit(props.row)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <component
              :plate="selectedPlate"
              :is="currentActiveModal"
              @save="loadData"
            ></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {postWs} from "src/ws.service"
import swal from 'sweetalert2'
import {Modal} from 'src/components/UIComponents'
import {moneyToFloat, toMoney} from "@/util/core.utils";
import InsertNewPlateForm from "@/components/Dashboard/Views/BackOffice/SuitJourney/Forms/InsertNewPlateForm.vue";
import EditPlateForm from "@/components/Dashboard/Views/BackOffice/SuitJourney/Forms/EditPlateForm";
import {isAuthorized} from "@/util/role.utils"

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  components: {
    PPagination,
    Modal,
    EditPlateForm,
    InsertNewPlateForm
  },
  data() {
    return {
      search: {
        name: '',
        revenue: '',
      },
      tableData: [],
      selectedPlate: null,
      currentActiveModal: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    isAuthorized,
    toMoney,
    find() {
      this.loadData()
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    loadData() {
      postWs('/bo/suitjourney/search-plate',
        true, null,
        {
          name: this.search.name,
          revenue: moneyToFloat(this.search.revenue) == 0 ? '' : moneyToFloat(this.search.revenue)
        },
        this.successLoadData,
        this.failLoadData)
    },
    successLoadData(response) {
      this.tableData = response.data
      this.currentActiveModal = undefined
    },
    failLoadData() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao buscar pelas Placas. Tente novamente!',
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    insertNewPlate() {
      this.currentActiveModal = 'insert-new-plate-form'
    },
    edit(plate) {
      this.selectedPlate = plate
      this.currentActiveModal = 'edit-plate-form'
    },
    getPlateImage(platePhotoBase64) {
      return 'data:image/jpeg;base64,' + platePhotoBase64
    }
  }
}
</script>
<style lang="scss">
.manage-plate {
  h4 {
    margin-top: 15px;
  }

  .form-group .input-group-prepend {
    padding: 0 !important;
  }

  .input-group-text {
    padding: .375rem .75rem !important;
  }

  .input-revenue .form-control {
    padding: 0 !important;
  }

  .table-striped {
    .plate-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;
    }

    .img-wrapper {
      height: 100%;
      max-height: 7rem;
      border-radius: 0 !important;
      margin: 0 !important;

      img {
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
</style>
