<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Extrato por Período <span v-if="username != null">(<span v-if="username != null" style="font-weight: bolder"> @{{username}}</span> )</span>
          </h4>
          <div class="row">
            <div class="col-xl-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-xl-2">
              <fg-input label="Exibir">
                <el-select
                  class="select-primary"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-primary"
                    v-for="item in [10, 100, 500, 1000, 5000, 10000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-xl-4">
              <div style="display: flex; margin-bottom: -13px">
                <p-radio v-model="searchType" label="CARD_V">Cartão Virtual</p-radio>
                <p-radio v-model="searchType" label="CARD_P" class="ml-2">Cartão Físico</p-radio>
                <p-radio v-model="searchType" label="COST_CENTER" class="ml-2">Centros de Custo</p-radio>
              </div>
              <div class="autocomplete-wrapper">
                <autocomplete ref="filter"
                              placeholder="Selecionar"
                              :source="searchOptions"
                              input-class="form-control"
                              results-value="id"
                              name="filtro"
                              :results-display="displaySearchOption"
                              @selected="selectFilter"
                              @clear="()=> {this.searchFilter = null}"
                >
                </autocomplete>
                <label v-show="showSelectedFilter" ref="selectedFilter"></label>
              </div>
              <div class="error-text" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">{{ errors.first('filtro') }}</div>
            </div>
            <div class="col-md-2">
              <p-button @click.prevent="search(false)" style="margin-top: 24px;" wide>
                <i class="fas fa-search-dollar"></i> BUSCAR
              </p-button>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-10" style="height: 0px"></div>
            <div class="col-xl-2" v-show="this.timelineItems.length > 0">
              <p-button @click.prevent="exportStatement" style="margin-top: 0px;" wide>
                <i class="fas fa-file-invoice"></i> Exportar
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="timelineItems.length > 0" style="margin: 0 auto">
        <card>
          <div class="card-body row" v-for="cardTr in timelineItems">
            <div class="col-sm-12">
              <card>
                <div class="card-body row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <h5>Cartão: {{getCardNumber(cardTr)}} / {{getCardNickname(cardTr)}} / (@{{getCardUser(cardTr)}} - {{getCardUserName(cardTr)}})</h5>
                        <hr/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="text-muted m-auto row">
                          <div class="col-lg-3">
                            <stats-card
                              type="primary"
                              :title="'R$ ' + toMoney(statistics[cardTr[0].card.cardId].amountIn)"
                              small-title="Entradas"
                              icon="fa-solid fa-money-check-dollar">
                            </stats-card>
                          </div>
                          <div class="col-lg-3">
                            <stats-card
                              type="danger"
                              :title="'R$ ' + toMoney(statistics[cardTr[0].card.cardId].amountOut)"
                              small-title="Compras"
                              icon="fa-solid fa-arrow-up">
                            </stats-card>
                          </div>
                          <div class="col-lg-3">
                            <stats-card
                              type="danger"
                              :title="'R$ ' + toMoney(statistics[cardTr[0].card.cardId].averageAmountOut)"
                              small-title="Média de Compras"
                              icon="nc-icon nc-sound-wave">
                            </stats-card>
                          </div>
                          <div class="col-lg-3">
                            <stats-card
                              type="info"
                              :title="'R$ ' + toMoney(statistics[cardTr[0].card.cardId].availableFunds)"
                              small-title="Saldo "
                              icon="nc-icon nc-refresh-69">
                            </stats-card>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <el-table class="table-striped"
                                  :data="cardTr"
                                  style="width: 100%"
                                  :row-class-name="tableRowClassName">
                          <el-table-column :min-width="80" label="Final do Cartão">
                            <template slot-scope="item">
                              <span>
                                <span>
                                  {{item.row.card.number.slice(-4)}}
                                </span>
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="130" label="Data/Hora">
                            <template slot-scope="item">
                              <span>
                                <span>
                                  {{item.row.date}}
                                </span>
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="160" label="Tipo">
                            <template slot-scope="item">
                              <span>
                                <span>
                                  <i :class="getCardTransactionLogo(item.row.transactionType)" :style="item.row.transactionDirection === 'IN'?'color:#00f000':'color:#f70000'"/>&nbsp;
                                  {{(getCardTransactionName(item.row.transactionType)) + (item.row.chargedBack ? ' (Cancelado)' : '')}}
                                </span>
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="100" label="Valor (R$)">
                            <template slot-scope="item">
                              <span>
                                <span v-if="item.row.transactionType != 'CARD_TRANSFER'"><b>{{item.row.transactionDirection == "IN" ? '+' :'-'}} {{toMoney(item.row.amount)}}</b></span>
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="150" label="Estabelecimento">
                            <template slot-scope="item">
                              <span>
                                <span style="font-weight: normal" v-if="item.row.transactionType == 'CARD_TRANSFER'">De @{{item.row.previousCardUser}} para @{{item.row.card.cardUser}}</span>
                                <span v-if="item.row.transactionType == 'PURCHASE' || item.row.transactionType == 'FUNDS_ADJUSTMENT'">
                                  {{item.row.merchant}}
                                </span>
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="90" label="Saldo">
                            <template slot-scope="item">
                              <span>
                                <span>R$ {{toMoney(item.row.availableFunds)}}</span>
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column :min-width="40" label="">
                            <template slot-scope="item">
                              <span>
                                <span>
                                  <div class="invoice">
                                    <p-button v-if="item.row.transactionType === 'PURCHASE' && item.row.justification == null" @click="addJustification(item.row.id)"
                                              class="btn btn-info " title="Incluir justificativa">
                                      <i class="fas fa-receipt fa-2x" style="color: #0c072d;"/>
                                      <i class="fas fa-plus plus" style="color: #0c072d;"/>
                                    </p-button>
                                  </div>
                                  <p-button v-if="item.row.transactionType === 'PURCHASE' && item.row.justification != null" @click="viewJustification(item.row.justification.id)"
                                            class="btn btn-info" title="Ver justificativa">
                                    <i class="fas fa-receipt fa-2x" style="color: #0c072d;"/>
                                  </p-button>
                                </span>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="search">
            </p-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {Badge, TimeLine, TimeLineItem, StatsCard} from 'src/components/UIComponents'
  import {getCardTransactionName, getCardTransactionLogo} from 'src/components/Dashboard/Views/Transactions/timeline'
  import {callWs, failWs, postWs} from "src/ws.service"
  import {daysBetween, formatDate} from "src/util/date.utils"
  import {toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import Autocomplete from 'vuejs-auto-complete'
  import download from "downloadjs";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      TimeLine,
      TimeLineItem,
      Badge,
      PPagination,
      Autocomplete,
      StatsCard
    },
    data() {
      return {
        fileMaxSize: 5,
        startDate: new Date(),
        endDate: new Date(),
        timelineItems: [],
        statistics: [],
        status: null,
        username: null,
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        searchType: 'CARD_V',
        searchFilter: null,
        showSelectedFilter: false,
        searchOptions:[],
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    created() {
      this.username = this.$route.params.username

      this.$watch( () => this.searchType, (toParams, previousParams) => {
        this.$refs.filter.clear()
        this.populateSearchItems()
      })
      this.populateSearchItems()

      this.search(true)
    },
    mounted() {
      this.$watch( () => this.$refs.filter.value, (value) => {
        if(!value) {
          this.showSelectedFilter = false
        }
      })

      this.$watch(() => this.$refs.filter.$data.display, (value) => {
        if(value === null) return

        if(value === "") {
          this.$refs.filter.clear()
          this.searchFilter = null
        }

        if(value.trim() || value === "") {
          this.showSelectedFilter = false
          this.searchFilter = null
        }
      })
    },
    methods: {
      toMoney,
      getCardTransactionName,
      getCardTransactionLogo,
      displaySearchOption(option){
        if(option.id !== 'TODOS'){
          let prefix = '';
          let suffix = ''
          if(this.searchType === 'CARD_V' || this.searchType === 'CARD_P'){
            prefix = '**** '
            let detail2Color;
            if(option.detail2 == 'FÍSICO'){
              detail2Color = '#13d519'
            } else {
              detail2Color = '#00b6e1'
            }
            suffix = ' - ' + option.detail1 + ' - <span style="color: ' + detail2Color + ' ">' + option.detail2 + '</span>'
          }
          return prefix + option.label + ' - <span class="' +  option.statusColor + '">'+ option.status + '</span>' + suffix
        } else {
          return option.label
        }
      },
      selectFilter(optionAutoComplete){
        this.$refs.selectedFilter.innerHTML = optionAutoComplete.display
        this.showSelectedFilter = true
        this.$refs.filter.$data.selectedDisplay = " "
        this.searchFilter = optionAutoComplete.value
      },
      statusToText(status) {
        switch (status) {
          case 'ACTIVE':
            return 'Ativo'
          case 'BLOCKED':
            return 'Bloqueado'
          case 'CANCELED':
            return 'Cancelado'
        }
      },
      statusToColor(status) {
        switch (status) {
          case 'ACTIVE':
            return 'text-primary'
          case 'BLOCKED':
            return 'text-warning'
          case 'CANCELED':
            return 'text-danger'
        }
      },
      getCardNumber(cardTr){
        return cardTr[0].card.number
      },
      getCardNickname(cardTr){
        return cardTr[0].card.nickname
      },
      getCardUser(cardTr){
        return cardTr[0].card.cardUser
      },
      getCardUserName(cardTr){
        return cardTr[0].card.cardUserName
      },
      populateSearchItems(){
        this.searchOptions=[]
        if(this.searchType === 'CARD_V' || this.searchType === 'CARD_P'){
          postWs("/card/list", true, null,
            {username: this.username, type: this.searchType === 'CARD_V' ? 'VIRTUAL' : 'PHYSICAL'},
          response => {

            this.searchOptions.push({
              id: 'TODOS',
              label: 'TODOS',
              detail1: '',
              detail2: '',
              status: '',
              statusColor: ''
            })

            response.data.list.forEach(item => {
              this.searchOptions.push({
                id: item.cardId,
                label: item.number.slice(-4),
                detail1: (item.nickname != null ? item.nickname.toUpperCase() : ''),
                detail2: (item.cardType === 'PHYSICAL' ? 'FÍSICO' : 'VIRTUAL'),
                status: this.statusToText(item.status),
                statusColor: this.statusToColor(item.status)
              })
            })
          }, error => {

          })
        }
        if(this.searchType === 'COST_CENTER'){
          postWs("/costcenter/list", true, null,
            {username: this.username},
            response => {

              this.searchOptions.push({
                id: 'TODOS',
                label: 'TODOS',
                detail1: '',
                detail2: '',
                status: '',
                statusColor: ''
              })

              response.data.list.forEach(item => {
                this.searchOptions.push({
                  id: item.id,
                  label: (item.name != null ? item.name.toUpperCase() : ''),
                  status: (item.enabled ? 'Habilitado' : 'Desabilitado'),
                  statusColor: (item.enabled ? 'text-primary' : 'text-danger')
                })
              })
            }, error => {

            })
        }
      },
      search(ignoreNoDataSwal) {
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }

          if(daysBetween(this.startDate, this.endDate) > 31){
            swal({
              title: 'Aviso!',
              text: 'O período selecionado deve ser de no máximo 31 dias!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          } else {
            let searchParams = null
            if (this.searchType === 'CARD_V' || this.searchType === 'CARD_P') {
              searchParams = {
                username: this.username,
                cardId: this.searchFilter,
                startDate: formatDate(this.startDate, "DD/MM/YYYY"),
                endDate: formatDate(this.endDate, "DD/MM/YYYY"),
                pageNumber: this.pagination.currentPage - 1,
                pageSize: this.pagination.perPage,
                type: this.searchType === 'CARD_V' ? 'VIRTUAL' : 'PHYSICAL'
              }
            }
            if (this.searchType === 'COST_CENTER') {
              searchParams = {
                username: this.username,
                costCenter: {
                  id: this.searchFilter
                },
                startDate: formatDate(this.startDate, "DD/MM/YYYY"),
                endDate: formatDate(this.endDate, "DD/MM/YYYY"),
                pageNumber: this.pagination.currentPage - 1,
                pageSize: this.pagination.perPage,
              }
            }
            callWs("/cardtransaction/list",
              "POST", null, true, null,
              searchParams, (response) => this.loadTimeline(ignoreNoDataSwal, response), failWs)
          }
        })
      },
      loadTimeline(ignoreNoDataSwal, response) {
        this.timelineItems = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        this.statistics = response.data.statistics;
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.timelineItems.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
        if (this.timelineItems.length <= 0 && !ignoreNoDataSwal) {
          swal({
            title: 'Não encontrado',
            text: 'No período selecionado não existem transações.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      viewJustification(justificationId){
        postWs('/cardtransaction/get-justification', true, null, {id: justificationId},
        response => {
          swal({
            title: 'Justificativa!',
            html:
              '<div style="font-size: medium;">' +
              '   Comprovante desta compra!' +
              '   <hr>' +
              '   </br>' +
              '   <div style="text-align: left">' +
              '     <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A;">Imagem: </span>' +
              '     <img src="data:image/' + response.data.filetype + ';base64, ' + response.data.image64 + '" />' +
              '     </br>' +
              '     </br>' +
              '     <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A;">Descrição do Gasto: </span>' +
              '     </br>' +
              '     <textarea style="width: 100%; font-size: smaller" maxlength="100" id="justificationText">' + response.data.justification + '</textarea>' +
              '   </div>' +
              '</div>' ,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'info'
          })
        },
        error => {
          swal({
            title: 'Ops!',
            text: error.response.data.message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'error'
          })
        })
      },
      addJustification(id){
        swal({
          title: 'Justificativa!',
          html:
            '<div style="font-size: medium;">' +
            '   Adicione um comprovante ou faça uma breve descrição deste gasto!' +
            '   <hr>' +
            '   </br>' +
            '   <div style="text-align: left">' +
            '     <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A;">Escolha um arquivo: </span>' +
            '     <input type="file" id="justificationFile" accept=".png, .jpg, .jpeg, .pdf"/>' +
            '     </br>' +
            '     </br>' +
            '     <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A;">Descrição do gasto: </span>' +
            '     </br>' +
            '     <textarea style="width: 100%; font-size: smaller" maxlength="100" id="justificationText"></textarea>' +
            '   </div>' +
            '</div>' ,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass:'btn btn-danger btn-fill',
          showCancelButton: true,
          type: 'info'
        }).then((result) =>{
          if(result){
            let formData = new FormData()
            let file = document.getElementById('justificationFile').files[0]
            if (file.size > this.fileMaxSize * 1024 * 1024) {
              swal({
                title: 'Aviso!',
                text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }

            formData.append("file", file)
            callWs("/cardtransaction/justify","POST",{'Content-Type': 'multipart/form-data'}, true,
              {id: id, text: document.getElementById('justificationText').value}, formData,
              (response) => {
                let breakLoop = false;
                this.timelineItems.some((trList) =>{
                  trList.some((tr) =>{
                    if(tr.id === id){
                      tr.justification = {
                        id: response.data.id
                      }
                      breakLoop = true;
                      return true;
                    }
                  })
                  if(breakLoop){
                    return true
                  }
                })
              },
              (error) => {
                swal({
                  title: 'Ops!',
                  text: error.response.data.message,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'error'
                })
              })
          }
        }).catch((rejected) => {

        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      tableRowClassName({row, rowIndex}){
         if(row.chargedBack){
          return "table-warning"
         }
         return ''
      },
      exportStatement() {
        let type = swal({
          title: 'Exportar Extrato!',
          text: "Escolha o formato do arquivo.",
          input: "select",
          inputOptions:{
            PDF: 'PDF', CSV: 'CSV', XLS: 'XLS'
          },
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          showCancelButton: true,
          buttonsStyling: false,
          type: 'warning'
        }).then(value => {
          let searchParams = null
          if (this.searchType === 'CARD_V' || this.searchType === 'CARD_P') {
            searchParams = {
              cardId: this.searchFilter,
              startDate: formatDate(this.startDate, "DD/MM/YYYY"),
              endDate: formatDate(this.endDate, "DD/MM/YYYY"),
              reportType: value,
              type: this.searchType === 'CARD_V' ? 'VIRTUAL' : 'PHYSICAL',
              username: this.username
            }
          }
          if (this.searchType === 'COST_CENTER') {
            searchParams = {
              costCenter: {
                id: this.searchFilter
              },
              startDate: formatDate(this.startDate, "DD/MM/YYYY"),
              endDate: formatDate(this.endDate, "DD/MM/YYYY"),
              reportType: value,
              username: this.username
            }
          }
          callWs("/cardtransaction/list",
            "POST", null, true, null,
            searchParams,
            response =>{
              if(value === 'XLS'){
                download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
                  "transacoes_cartao_credito_"+formatDate(this.startDate, 'DD/MM/YYYY')+"_a_"+formatDate(this.endDate, 'DD/MM/YYYY')+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
              }
              if(value === 'CSV'){
                download("data:application/csv;base64,"+response.data, "transacoes_cartao_credito_"+formatDate(this.startDate, 'DD/MM/YYYY')+"_a_"+formatDate(this.endDate, 'DD/MM/YYYY')+".csv", "text/csv")
              }
              if(value === 'PDF'){
                this.successGeneratePdf(response)
              }
            }, failWs)
        }).catch(cause => {

        })
      },
      successGeneratePdf(response) {
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>SuitPay - Extrato de Cartões</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + encodeURI(response.data) + "'/></body></html>");
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do extrato, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .invoice {
    position: relative;
    display: inline-block;
  }

  .invoice .plus {
    position: absolute;
    top: 1px;
    right: -12px;
    padding: 2px 2px;
    border-radius: 50%;
    font-size: xx-small;
    background: black;
    color: white !important;
  }

  .statsCardBody{
    max-height: 105px;
  }

  p.card-title{
    font-size: large;
  }
  .card-footer{
    padding-bottom: 0px!important;
  }

  .autocomplete-wrapper {
    position: relative;

    label {
      position: absolute;
      top: 50%;
      left: 2rem;
      transform: translateY(-50%);
      background: #f4f4f4;
      padding: .2rem .75rem;
      border-radius: .5rem;
    }
  }
</style>
