<template>
  <div class="dzs-payments-report">
    <div class="row">
      <div :class="'col-lg-6 col-md-12'" v-for="stats in {saleValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Valor Líquido: R$ {{toMoney(saleValue.netValue)}}<br><br>
          </div>
        </stats-card>
      </div>
      <div :class="'col-lg-6 col-md-12'" v-for="stats in {paidNetValue}">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="'R$ ' + toMoney(stats.value)">
          <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
            Valor a Receber: R$ {{toMoney(paidNetValue.receivableNetValue)}}<br><br>
          </div>
        </stats-card>
      </div>
      <div class="col-lg-4 col-md12">
        <div class="row">
          <div :class="'col-lg-12 col-md-12'" v-for="stats in {saleCardAmount}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)" style="height: 97%">
              <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
                <b>Processamento:</b><br>
                Aprovadas: R$ {{toMoney(saleCardAmount.approvedCardAmount)}}<br>
                Aguardando Aprovação: R$ {{toMoney(saleCardAmount.waitingApprovalCardAmount)}}<br>
                Canceladas: R$ {{toMoney(saleCardAmount.canceledCardAmount)}}<br><br>
                Valor Líquido Aprovadas: R$ {{toMoney(saleCardAmount.approvedCardNetValue)}}<br>
                Valor Líquido Ag. Aprovação: R$ {{toMoney(saleCardAmount.waitingApprovalCardNetValue)}}<br>
                Valor Líquido Canceladas: R$ {{toMoney(saleCardAmount.canceledCardNetValue)}}<br><br>
                <b>Recebimento:</b><br>
                Valor Recebido: R$ {{toMoney(saleCardAmount.paidCardNetValue)}}<br>
                A Receber: R$ {{toMoney(saleCardAmount.receivableCardNetValue)}}<br><br><br>
              </div>
            </stats-card>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div :class="'col-lg-12 col-md-12'" v-for="stats in {salePixAmount}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
              <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
                Valor Líquido: R$ {{toMoney(salePixAmount.salePixNetValue)}}<br>
                Valor Recebido: R$ {{toMoney(salePixAmount.paidPixNetValue)}}<br><br><br>
              </div>
            </stats-card>
          </div>
          <div :class="'col-lg-12 col-md-12'" v-for="stats in {saleBoletoAmount}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
              <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
                Valor Líquido: R$ {{toMoney(saleBoletoAmount.saleBoletoNetValue)}}<br>
                Valor Recebido: R$ {{toMoney(saleBoletoAmount.paidBoletoNetValue)}}<br><br><br>
              </div>
            </stats-card>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div :class="'col-lg-12 col-md-12'" v-for="stats in {chargebackAmount}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
              <div class="stats" slot="footer" style="color: #0c072d; font-size: 13px;">
                Chargeback Cartão: R$ {{toMoney(chargebackAmount.chargebackCardAmount)}}<br>
                Chargeback Pix: R$ {{toMoney(chargebackAmount.chargebackPixAmount)}}<br>
                Chargeback Boleto: R$ {{toMoney(chargebackAmount.chargebackBoletoAmount)}}<br><br>
              </div>
            </stats-card>
          </div>
          <div :class="'col-lg-12 col-md-12'" v-for="stats in {financialReserveAmount}">
            <stats-card :type="stats.type"
                        :icon="stats.icon"
                        :small-title="stats.title"
                        :title="'R$ ' + toMoney(stats.value)">
            </stats-card>
          </div>
        </div>
      </div>

      <div class="col-md-12 card">
        <div class="row card">
          <div class="card-header">
            <h4 style="margin-top: 0px">Relatório de Pagamentos do Gateway/Checkout</h4>
          </div>
          <div class="card-body row" style="padding-left: 25px;">
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-sm-2">
              <p-radio v-model="enabledRadio" label="saleDate">Data da Venda</p-radio>
              <p-radio v-model="enabledRadio" label="balanceReceiptDate">Data do Recebimento</p-radio>
            </div>
            <div class="col-md-2">
              <fg-input label="Tipo de Pagamento">
                <el-select size="large"
                           placeholder="Selecionar"
                           v-model="typePayment">
                  <el-option class="select-success"
                             value=""
                             label="TODOS"/>
                  <el-option class="select-success"
                             value="CARTÃO"
                             label="CARTÃO"/>
                  <el-option class="select-success"
                             value="PIX"
                             label="PIX"/>
                  <el-option class="select-info"
                             value="BOLETO"
                             label="BOLETO"/>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input label="Status">
                <el-select size="large"
                           placeholder="Selecionar"
                           v-model="status">
                  <el-option class="select-success"
                             value=""
                             label="TODOS"/>
                  <el-option class="select-success"
                             value="PAGO"
                             label="PAGO"/>
                  <el-option class="select-success"
                             value="PAGAMENTO APROVADO"
                             label="PAGAMENTO APROVADO"/>
                  <el-option class="select-info"
                             value="AGUARDANDO APROVAÇÃO"
                             label="AGUARDANDO APROVAÇÃO"/>
                  <el-option class="select-info"
                             value="CANCELADA"
                             label="CANCELADA"/>
                  <el-option class="select-info"
                             value="CHARGEBACK"
                             label="CHARGEBACK"/>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-1">
              <fg-input label="Exibir">
                <el-select
                  size="large"
                  v-model="pagination.perPage"
                  placeholder="Por Página">
                  <el-option
                    class="select-default"
                    v-for="item in [100,500,1000,5000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="success" style="margin-top: 10px"
                        @click="find">
                <i slot="label" class="fas fa-search-dollar"></i>
                Buscar
              </p-button>
            </div>
            <!--            <div class="col-md-2" v-if="this.requestExcel.enabled">-->
            <!--              <p-button type="success" style="margin-top: 25px"-->
            <!--                        @click="generateExcel">-->
            <!--                <i slot="label" class="fas fa-file-invoice"></i>-->
            <!--                Excel-->
            <!--              </p-button>-->
            <!--            </div>-->
          </div>
        </div>

        <div class="row card">
          <div class="row col-md-12" style="margin-bottom: 15px; margin-top: 20px; text-align: center">
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor de Vendas: R$ {{toMoney(survey.saleValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Líquido: R$ {{toMoney(survey.netValue)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor Pago: R$ {{toMoney(survey.paidNetValue)}}<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Valor a Receber: R$ {{toMoney(survey.receivableNetValue)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas no Cartão: R$ {{toMoney(survey.saleCardAmount)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas no PIX: R$ {{toMoney(survey.salePixAmount)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Vendas no Boleto: R$ {{toMoney(survey.saleBoletoAmount)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Reserva Financeira: R$ {{toMoney(survey.financialReserveAmount)}}</p>
            </div>
            <div class="box3" style="text-align: left">
              <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pag. Aprovados Cartão: R$ {{toMoney(survey.approvedCardAmount)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Ag. Aprovação Cartão: R$ {{toMoney(survey.waitingApprovalCardAmount)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Pag. Cancelados Cartão: R$ {{toMoney(survey.canceledCardAmount)}}<br>
                <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Chargeback: R$ {{toMoney(survey.chargebackAmount)}}</p>
            </div>
          </div>
        </div>

        <div class="row card">
          <div class="col-sm-12">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="50" label="ID Venda">
                <template slot-scope="props">
                    <span>
                      <span><b>{{props.row.requestNumber}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Data Venda">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createdDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Venda">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.saleValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Valor Líquido">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.netValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Tipo Pagamento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.typePayment}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.status}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Data do Pgt.">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.datePayment}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Data do Recebimento">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.balanceReceiptDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="40" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="primary" size="sm" icon title="Dados do Cliente"
                            @click="loadClientData(props.row.clientName, props.row.clientSocialNumber, props.row.clientPhoneNumber)">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row" style="padding: 10px 10px 10px 10px">
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal.client"
           footerClasses="justify-content-center"
           type="notice">
      <h6 style="text-align: center"><b>Dados do Cliente</b></h6>
      <p>Nome do Cliente: {{client.name}}</p>
      <p>Documento: {{maskDocument(client.socialNumber)}}</p>
      <p>Telefone: {{maskPhone(client.phoneNumber)}}</p>
    </modal>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney, moneyToFloat} from "../../../../util/core.utils";
  import {DatePicker} from 'element-ui'
  import {daysBetween, formatDate} from "../../../../util/date.utils";
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import download from 'downloadjs'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
      StatsCard,
      ChartCard,
      Modal,
    },
    data() {
      return {
        saleValue: {
          type: 'success',
          icon: 'fa-solid fa-money-bill-trend-up',
          title: 'Valor de Vendas',
          value: 0,
          netValue: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        paidNetValue: {
          type: 'success',
          icon: 'fa-solid fa-hand-holding-dollar',
          title: 'Valor Recebido',
          value: 0,
          receivableNetValue: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        saleCardAmount: {
          type: 'success',
          icon: 'fa-regular fa-credit-card',
          title: 'Vendas no Cartão',
          value: 0,
          saleCardNetValue: 0,
          approvedCardAmount: 0,
          waitingApprovalCardAmount: 0,
          canceledCardAmount: 0,
          approvedCardNetValue: 0,
          paidCardNetValue: 0,
          receivableCardNetValue: 0,
          waitingApprovalCardNetValue: 0,
          canceledCardNetValue: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        salePixAmount: {
          type: 'success',
          icon: 'fa-brands fa-pix',
          title: 'Vendas no Pix',
          value: 0,
          salePixNetValue: 0,
          paidPixNetValue: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        saleBoletoAmount: {
          type: 'success',
          icon: 'fa-solid fa-file-circle-check',
          title: 'Vendas no Boleto',
          value: 0,
          saleBoletoNetValue: 0,
          paidBoletoNetValue: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        financialReserveAmount: {
          type: 'success',
          icon: 'fa-solid fa-sack-dollar',
          title: 'Reserva Financeira',
          value: 0,
          footerText: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        chargebackAmount: {
          type: 'success',
          icon: 'fa-solid fa-rotate-left',
          title: 'Chargeback',
          value: 0,
          chargebackCardAmount: 0,
          chargebackPixAmount: 0,
          chargebackBoletoAmount: 0,
          action: '',
          footerIcon: 'nc-icon nc-refresh-69'
        },
        startDate: new Date(),
        endDate: new Date(),
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        search: {
          statusTransaction: null,
          bannerCard: null,
          transactionType: null,
          terminalNumber: null,
          nsu: null,
        },
        survey: {
          saleValue: 0,
          netValue: 0,
          paidNetValue: 0,
          receivableNetValue: 0,
          saleCardAmount: 0,
          salePixAmount: 0,
          saleBoletoAmount: 0,
          financialReserveAmount: 0,
          approvedCardAmount: 0,
          waitingApprovalCardAmount: 0,
          canceledCardAmount: 0,
          chargebackAmount: 0,
        },
        tableData: [],
        statusList: [null],
        status: '',
        typePayment: '',
        enabledRadio: 'saleDate',
        pagination: {
          perPage: 100,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        formRequestNotFound: false,
        requestExcel: {
          listTr: [],
          startDate: '',
          endDate: '',
          enabled: false,
        },
        modal: {
          client: false,
          products: false,
        },
        client: {
          name: '',
          socialNumber: '',
          phoneNumber: '',
        },
        username: '',
      }
    },
    created() {
      this.username = localStorage.getItem("usernameDisplay")
      this.$validator.extend('leaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.$validator.extend('purchaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.loadData()
    },
    methods: {
      toMoney,
      find() {

        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.pagination.currentPage = 1
          this.loadData()
        }
      },
      loadData() {
        postWs("/gateway/last-requested-sale",
          true, null,
          {
            status: this.status,
            typePayment: this.typePayment,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
            searchDateType: this.enabledRadio,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        this.formRequestNotFound = true
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.requestExcel.listTr = response.data.list
        if(this.tableData != null && response.data.list.length > 0) {
          this.requestExcel.enabled = true
        } else {
          this.requestExcel.enabled = false
        }
        this.requestExcel.startDate = formatDate(this.startDate, "DD_MM_YYYY")
        this.requestExcel.endDate = formatDate(this.endDate, "DD_MM_YYYY")
        this.saleValue.value = response.data.summary.saleValue
        this.saleValue.netValue = response.data.summary.netValue

        this.paidNetValue.value = response.data.summary.paidNetValue
        this.paidNetValue.receivableNetValue = response.data.summary.receivableNetValue

        this.saleCardAmount.value = response.data.summary.saleCardAmount
        this.saleCardAmount.saleCardNetValue = response.data.summary.saleCardNetValue
        this.saleCardAmount.approvedCardAmount = response.data.summary.approvedCardAmount
        this.saleCardAmount.waitingApprovalCardAmount = response.data.summary.waitingApprovalCardAmount
        this.saleCardAmount.waitingApprovalCardNetValue = response.data.summary.waitingApprovalCardNetValue
        this.saleCardAmount.canceledCardAmount = response.data.summary.canceledCardAmount
        this.saleCardAmount.canceledCardNetValue = response.data.summary.canceledCardNetValue
        this.saleCardAmount.approvedCardNetValue = response.data.summary.approvedCardNetValue
        this.saleCardAmount.paidCardNetValue = response.data.summary.paidCardNetValue
        this.saleCardAmount.receivableCardNetValue = response.data.summary.receivableCardNetValue

        this.salePixAmount.value = response.data.summary.salePixAmount
        this.salePixAmount.salePixNetValue = response.data.summary.salePixNetValue
        this.salePixAmount.paidPixNetValue = response.data.summary.paidPixNetValue

        this.saleBoletoAmount.value = response.data.summary.saleBoletoAmount
        this.saleBoletoAmount.saleBoletoNetValue = response.data.summary.saleBoletoNetValue
        this.saleBoletoAmount.paidBoletoNetValue = response.data.summary.paidBoletoNetValue

        this.chargebackAmount.value = response.data.summary.chargebackAmount
        this.chargebackAmount.chargebackCardAmount = response.data.summary.chargebackCardAmount
        this.chargebackAmount.chargebackPixAmount = response.data.summary.chargebackPixAmount
        this.chargebackAmount.chargebackBoletoAmount = response.data.summary.chargebackBoletoAmount

        this.financialReserveAmount.value = response.data.summary.financialReserveAmount

        this.survey.saleValue = response.data.searchSummary.saleValue
        this.survey.netValue = response.data.searchSummary.netValue
        this.survey.paidNetValue = response.data.searchSummary.paidNetValue
        this.survey.receivableNetValue = response.data.searchSummary.receivableNetValue
        this.survey.saleCardAmount = response.data.searchSummary.saleCardAmount
        this.survey.salePixAmount = response.data.searchSummary.salePixAmount
        this.survey.saleBoletoAmount = response.data.searchSummary.saleBoletoAmount
        this.survey.financialReserveAmount = response.data.searchSummary.financialReserveAmount
        this.survey.approvedCardAmount = response.data.searchSummary.approvedCardAmount
        this.survey.waitingApprovalCardAmount = response.data.searchSummary.waitingApprovalCardAmount
        this.survey.canceledCardAmount = response.data.searchSummary.canceledCardAmount
        this.survey.chargebackAmount = response.data.searchSummary.chargebackAmount

        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      statusToColor(status){
        let retorno = ''
        switch (status) {
          case 'WAITING':
            retorno = '#ffef00';
            break;
          case 'CANCELED':
            retorno = '#ff3c00';
            break;
          case 'PAID_PIX':
            retorno = '#52d019';
            break;
          case 'PAID_BOLETO':
            retorno = '#52d019';
            break;
          case 'PAID_CARD':
            retorno = '#52d019';
            break;
          case 'CHARGEDBACK':
            retorno = '#f40606';
            break;
          case 'EXPIRED':
            retorno = '#ff3c00';
            break;
        }
        return retorno
      },
      generateExcel() {
        callWs("/payment-link/sales-report-excel",
          "POST", null, true, null, this.requestExcel.listTr,
          this.successGenerateExcel,
          (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao gerar o Excel do extrato, tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          })
      },
      successGenerateExcel(response) {
        try {
          download("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+response.data,
            "vendas_link_de_pagamento_"+this.username+"_"+this.requestExcel.startDate+"_a_"+this.requestExcel.endDate+".xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        } catch (ex) {
          swal({
            title: 'Erro!',
            text: "Erro ao gerar o relatório em Excel, favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      loadClientData(name, socialNumber, phoneNumber) {
        this.client.name = name
        this.client.socialNumber = socialNumber
        this.client.phoneNumber = phoneNumber
        this.modal.client = true
      },
      maskPhone(phone) {
        if(phone.length == 11) {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 5) + '-' + phone.substr(7, 4)
        } else {
          return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 4) + '-' + phone.substr(6, 4)
        }
      },
      maskDocument(document) {
        if(document.length == 11) {
          return document.substr(0, 3) + '.' + document.substr(3, 3) + '.' + document.substr(6, 3) + '-' + document.substr(9, 2)
        } else {
          return '(' + document.substr(0, 2) + ') ' + document.substr(2, 4) + '-' + document.substr(6, 4)
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-payments-report {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .card-stats .card-body .numbers {
      font-size: 18px !important;
    }

    .box3 {
      width: 31%;
      margin-left: 2.1%;
    }

    @media (max-width: 1366px) {
      .box3 {
        width: 31%;
        margin-left: 2.1%;
      }
    }

    @media (max-width: 500px) {
      .box3 {
        width: 90%;
        margin-left: 2.1%;
      }
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }

    .btn.btn-primary{
      margin-right: 10px;
    }

    .el-input__inner {
      background-color: white !important;
    }
  }
</style>
