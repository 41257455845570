import {callWs} from "@/ws.service";
import swal from "sweetalert2";

export function updateBalance() {
  return new Promise((resolve, reject) => {
    callWs('/balance/get-current-available', 'POST', null, true, null, {},
      (response) => {
      localStorage.setItem('accountBalance', response.data.current)
      localStorage.setItem('accountAvailableBalance', response.data.available)
      resolve()
    }, (error) => {
      failUpdateBalance(error, reject);
    })
  })
}

function failUpdateBalance(error, reject) {
  swal({
    title: 'Aviso!',
    text: 'Ocorreu um erro inesperado ao buscar seu saldo atual. Atualize a página e tente novamente!',
    buttonsStyling: true,
    timer: 5000,
    confirmButtonClass: 'btn btn-warning btn-fill',
    type: 'warning'
  })
  reject()
}
