<template>
    <div class="row">
      <div class="col-lg-12">
          <developmentFunction></developmentFunction>
      </div>
  </div>
</template>


<script>

  import developmentFunction from "../../../UIComponents/Promotion/DevelopmentFunction";

  export default {
    components: {
      developmentFunction
    }
  }
</script>

<style scoped>

</style>
