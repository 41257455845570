<template>
  <div class="dzs-btc">
    <div class="row">
      <div class="col-md-12" v-show="!listBuy && !listSell">
        <card>
          <h4 slot="header" class="card-title">
            Exchange
          </h4>
          <div class="row">
            <div class="col-md-6" v-if="!isNullOrEmpty(qrCode) || viewWallet || viewQrCodeWallet">
              <card>
                <p style="color: #667eea">Olá {{usernameDisplay}}!</p>
                <p>A <b style="color: black">Bank<span style="color: #667eea">4</span>u</b> oferta a você uma Exchange para comprar e vender seus criptoativos diretamente da sua conta.</p>
                <p>Toda operação pode ser realizada no menu ao lado, onde disponibilizamos duas ações: Comprar e Vender.</p>
                <p>Para realizar a operação siga os passos:</p>
                <ul>
                  <p><i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Selecione a ação desejada: Compra ou Venda.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Verifique a cotação antes de executar a ordem.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Informe o Valor em reais do montante que gostaria comprar. Ao informar o valor em reais será preenchida automaticamente a fração ou a quantidade de moedas desejadas.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Informe a carteira para envio ou recebimentos dos ativos.<br>
                    <i style="color: #667eea" class="fas fa-arrow-circle-right"></i> Execute a ordem clicando no botão.</p>
                </ul>
                <p>Limite para Compras: R$ 500,00 diários.</p>
                <p>Ao executar a ordem de compra, o valor será automaticamente congelado em sua conta <b style="color: black">Bank<span style="color: #667eea">4</span>u</b> e pedimos um prazo de 2 a 24 horas úteis para a entrega dos ativos em carteira para transações realizadas ate as 15H30. Para ordens de compra realizadas após as 15H30 pode levar mais de 24 horas úteis para que os ativos sejam creditados.</p>
                <p>É valido lembrar que para as ordens de compra e venda o tempo de validade da cotação é de 5 segundos e serve de base para a compra. A cotação é travada após a primeira confirmação na blockchain. Sendo assim poderá sofrer ou não variação.</p>
              </card>
            </div>
            <div class="col-md-6" v-if="viewWallet && !viewQrCodeWallet">
              <card>
                <p>Cotação 1BTC para compra: R$ {{toMoney(viewPrice.btcBrlBuyTotal)}}</p>
                <p>Cotação 1BTC para venda: R$ {{toMoney(viewPrice.btcBrlSellTotal)}}</p>
                <tabs pills type="primary" icons centered tab-content-classes="tab-space">
                  <tab-pane id="buy" key="buy">
                    <span slot="title" @click="getPrice">
                      <i class="fa fa-long-arrow-down"></i> Comprar
                    </span>
                    <label class="control-label">Valor em Real</label>
                    <label class="control-label" style="float: right">
                      <a href="#" @click="loadHistoryBuy">
                        <i slot="label" class="fas fa-history"></i> Histórico de Compras</a>
                    </label>
                    <fg-input
                      ref="amountBrlBuy"
                      v-model.lazy="viewPrice.amountBrlBuy"
                      v-money="real"
                      @keyup="updateBtcBuyValue"><template slot="addonLeft">R$</template>
                    </fg-input>
                    <label class="control-label" style="float: left">
                      Saldo: <b>R$ {{toMoney(accountBalance)}}</b>
                    </label>
                    <br><br>
                    <label class="control-label" style="float: left">Valor em BTC</label>
                    <fg-input
                      ref="amountBtcBuy"
                      v-model.lazy="viewPrice.amountBtcBuy"
                      v-money="btc"
                      @keyup="updateBrlBuyValue"><template slot="addonLeft"><i class="fa fa-btc"></i></template>
                    </fg-input>
                    <fg-input type="text"
                              label="Endereço da Carteira de Bitcoin"
                              maxlength="200"
                              name="Código da Carteira"
                              v-validate="{required: true}"
                              :error="getError('Endereço da Carteira de Bitcoin')"
                              v-model="viewPrice.bitcoinWallet">
                    </fg-input>
                    <br>
                    <p-button type="info" @click="confirmTrasaction('BUY')"
                              style="margin: 0 auto; display: table; min-width: 260px; height: 60px">
                      <i slot="label" class="fa fa-long-arrow-down"></i> Comprar
                    </p-button>
                  </tab-pane>
                  <tab-pane id="sell" key="sell">
                    <span slot="title" @click="getPrice">
                      <i class="fa fa-long-arrow-up"></i> Vender
                    </span>
                    <label class="control-label">Valor em Real</label>
                    <label class="control-label" style="float: right">
                      <a href="#" @click="loadHistorySell">
                        <i slot="label" class="fas fa-history"></i> Histórico de Vendas</a>
                    </label>
                    <fg-input
                      ref="amountBrlSell"
                      v-model.lazy="viewPrice.amountBrlSell"
                      v-money="real"
                      @keyup="updateBtcSellValue"><template slot="addonLeft">R$</template></fg-input>
                    <label class="control-label">Valor em BTC</label>
                    <fg-input
                      ref="amountBtcSell"
                      v-model.lazy="viewPrice.amountBtcSell"
                      v-money="btc"
                      @keyup="updateBrlSellValue"><template slot="addonLeft"><i class="fa fa-btc"></i></template></fg-input>
                    <br>
                    <p-button type="info" @click="confirmTrasaction('SELL')"
                              style="margin: 0 auto; display: table; min-width: 260px; height: 60px">
                      <i slot="label" class="fa fa-long-arrow-up"></i> Vender
                    </p-button>
                  </tab-pane>
                </tabs>
              </card>
            </div>
            <div class="col-md-6 card" v-show="viewQrCodeWallet" style="text-align: center; padding-bottom: 10px; margin-top: -22px">
              <h4 slot="header" class="card-title" style="color:#12a500">
                Sucesso!
              </h4>
              <p>Transação realizada com sucesso, envie o valor de <b>{{this.viewPrice.amountBtcSell}} BTC</b> para a carteira abaixo,
                após a confirmação do envio a venda será finalizada:</p>
              <div class="row" style="padding-top: 20px">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <qrcode :value='this.qrCodeWalletExchange' :options='{ width: 200, margin:0 }' style="text-align: center"></qrcode>
                  <p>{{this.walletExchange}}</p>
                </div>
                <div class="col-md-3"></div>
              </div>
              <p-button type="info" style="margin: 0 auto; margin-top: 20px; display: table"
                        @click="viewQrCodeWallet = false">
                <i slot="label" class="fas fa-undo"></i> Voltar
              </p-button>
            </div>
            <div class="col-md-6" v-if="approvedAccount == 'false'">
              <card style="text-align: center">
                <h5>Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no botão abaixo:</h5>
                <p-button @click="$router.push('/user/profile')" href="#"
                          class="btn btn-primary" style="margin: 0 auto; margin-top: 14px">
                  <i class="fas fa-cogs"/> Verificar Conta
                </p-button>
              </card>
            </div>
            <div class="col-md-6" v-if="!isNullOrEmpty(qrCode) && approvedAccount == 'true' && !viewQrCodeWallet">
              <div class="row" v-if="isNullOrEmpty(totpQrCodeUri)">
                <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Baixe e instale o aplicativo <span
                    style="color: black; font-weight: bold">Google Authenticator</span></p>
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col-md-6">
                      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                         target="_blank">
                        <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                      </a>
                    </div>
                    <div class="col-md-6">
                      <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                        <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                      </a>
                    </div>
                  </div>
                  <p><b>2</b> - No aplicativo, leia o QR Code ao lado ou informe a chave manualmente</p>
                  <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo</p>
                  <p><b>4</b> - Confirme a operação</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
                <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                  <qrcode :value="totpQrCodeUri" :options="{ width: 200, margin:0 }"></qrcode>
                  <p><b>{{this.totpManualCode}}</b></p>
                </div>
                <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span
                    style="color: black; font-weight: bold">Google Authenticator</span></p>
                  <p><b>2</b> - Procure por Bank<b style="color: #667eea !important;">4</b>u e seu nome de usuário no
                    aplicativo </p>
                  <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                  <p><b>4</b> - Confirme a operação</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center">
                  Código: <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode"
                                 size="6"/>
                </div>
              </div>
              <div class="row" v-if="!codeValid">
                <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
              </div>
              <div class="row">
                <p-button @click="()=>{validateCode()}"
                          class="btn btn-primary" style="margin: 0 auto; margin-top: 14px"
                          :disabled="totpConfirmBtnDisabled"
                >
                  <i class="fas fa-chevron-right"/> Acessar Carteira
                </p-button>
              </div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md-12 card" v-show="listBuy">
        <div class="card-header">
          <h4>Suas compras de BTC</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="searchBuy.status">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="NOT_PROCESSED"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="PROCESSED"
                           class="select-default"
                           label="Processados"/>
                <el-option value="REJECTED"
                           class="select-default"
                           label="Rejeitados"/>
                <el-option value="ERROR"
                           class="select-default"
                           label="Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="paginationBuy.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="loadHistoryBuy">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Cotação BTC">
                <template slot-scope="props">
                      <span>
                        <span>R$ {{toMoney(props.row.btcBrlBuyTotal)}}</span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Data">
                <template slot-scope="props">
                      <span>
                        <span>{{props.row.date}}</span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="BTC">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.amountBtcBuy}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="BRL">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.amountBrlBuy)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Wallet">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.bitcoinWalletUser}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Order_Id">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.orderId}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusToLabel(props.row.statusTransactionBtc)}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{paginationBuy.fromNumber}} AO {{paginationBuy.toNumber}}, DE
              {{paginationBuy.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationBuy.currentPage"
                          :per-page="paginationBuy.perPage"
                          :total="paginationBuy.totalNumber"
                          :click="this.loadHistoryBuy">
            </p-pagination>
          </div>
          <p-button type="info" style="margin: 0 auto; margin-top: 40px; display: table"
                    @click="listBuy = false">
            <i slot="label" class="fas fa-undo"></i> Voltar
          </p-button>
        </div>
      </div>

      <div class="col-md-12 card" v-show="listSell">
        <div class="card-header">
          <h4>Suas vendas de BTC</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="searchSell.status">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="NOT_PROCESSED"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="PROCESSED"
                           class="select-default"
                           label="Processados"/>
                <el-option value="WAITING_BTC"
                           class="select-default"
                           label="Aguardando Bitcoin"/>
                <el-option value="CANCELED"
                           class="select-default"
                           label="Cancelado"/>
                <el-option value="REJECTED"
                           class="select-default"
                           label="Rejeitados"/>
                <el-option value="ERROR"
                           class="select-default"
                           label="Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="paginationSell.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="loadHistorySell">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Cotação BTC">
                <template slot-scope="props">
                      <span>
                        <span>R$ {{toMoney(props.row.btcBrlSellTotal)}}</span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="60" label="Data">
                <template slot-scope="props">
                      <span>
                        <span>{{props.row.date}}</span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="BTC">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.amountBtcSell}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="BRL">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.amountBrlSell)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Wallet Destino">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.bitcoinWalletExchange}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Order_Id">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.orderId}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusToLabel(props.row.statusTransactionBtc)}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{paginationSell.fromNumber}} AO {{paginationSell.toNumber}}, DE
              {{paginationSell.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationSell.currentPage"
                          :per-page="paginationSell.perPage"
                          :total="paginationSell.totalNumber"
                          :click="this.loadHistorySell">
            </p-pagination>
          </div>
          <p-button type="info" style="margin: 0 auto; margin-top: 40px; display: table"
                    @click="listSell = false">
            <i slot="label" class="fas fa-undo"></i> Voltar
          </p-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {callWs, failWs, postWs} from "../../../../ws.service";
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from "src/util/core.utils";
  import swal from 'sweetalert2'
  import {Tabs, TabPane} from 'src/components/UIComponents'
  import PPagination from "../../../UIComponents/Pagination";

  export default {
    components: {
      TabPane,
      Tabs,
      PPagination
    },
    data() {
      return {
        minValueBtc: 0.001,
        validationCode: '',
        totpQrCodeUri: '',
        totpManualCode: '',
        totpConfirmBtnDisabled: false,
        qrCode: '',
        codeValid: true,
        viewWallet: false,
        tokenWallet: '',
        urlWallet: '',
        usernameDisplay: '',
        approvedAccount: false,
        accountBalance: '',
        walletExchange: '',
        qrCodeWalletExchange: '',
        viewQrCodeWallet: false,
        viewPrice: {
          btcBrlBuy : '',
          btcBrlSell: '',
          btcBrlBuyTotal: '',
          btcBrlSellTotal: '',
          amountBtcSell: '',
          amountBtcBuy: '',
          amountBrlSell: '',
          amountBrlBuy: '',
          overPrice: '',
          hashId: '',
          bitcoinWallet: '',
          transactionBtcType: '',
        },
        listBuy: false,
        listSell: false,
        tableData: [],
        paginationBuy: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        paginationSell: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        searchBuy: {
          username: '',
          status: 'PROCESSED',
          type: 'BUY'
        },
        searchSell: {
          username: '',
          status: null,
          type: 'SELL'
        },
        real: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        btc: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 6,
          masked: false /* doesn't work with directive */
        }
      }
    },
    created() {
      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.approvedAccount = localStorage.getItem("approvedAccount")
      this.getPrice()
      this.getWalletAccessCode()
    },
    methods: {
      toMoney,
      isNullOrEmpty,
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      getWalletAccessCode() {
        postWs("/bitcoin/new-wallet-access-code",
          true, null, null,
          (response) => {
            this.qrCode = response.data.qrCode
            this.totpQrCodeUri = response.data.totpQrCodeUri
            this.totpManualCode = response.data.totpManualCode
            return
          }, (error) => {
            swal({
              title: 'Aviso!',
              text: 'Erro ao acessar a sua conta de Bitcoin. Tente novamente!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        )
      },
      validateCode() {
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          this.successConfirmCode,
          this.failConfirmCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      successConfirmCode(){
        this.totpConfirmBtnDisabled = false
        this.viewWallet = true
        this.qrCode = ''
        return
      },
      failConfirmCode() {
        this.validationCode = ''
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Erro!',
          text: 'Erro ao acessar a sua conta de Bitcoin. Tente novamente!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        this.getWalletAccessCode()
      },
      backView(){
        this.viewWallet = false
        this.tokenWallet = ''
        this.validationCode = ''
        this.getWalletAccessCode()
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      getPrice(){
        callWs("/bitcoin/get-trading-view-price",
        'GET', null, true, null, null,
        this.successGetPrice,
        this.errorGetPrice)
      },
      successGetPrice(response){
        this.viewPrice.btcBrlBuy = response.data.BTC_BRL_BUY
        this.viewPrice.btcBrlSell = response.data.BTC_BRL_SELL
        this.viewPrice.overPrice = response.data.overPrice
        this.viewPrice.btcBrlBuyTotal = (this.viewPrice.btcBrlBuy+(this.viewPrice.btcBrlBuy*this.viewPrice.overPrice/100)).toFixed(2)
        this.viewPrice.btcBrlSellTotal = (this.viewPrice.btcBrlSell-(this.viewPrice.btcBrlSell*this.viewPrice.overPrice/100)).toFixed(2)
        this.viewPrice.hashId = response.data.HASH_ID
        this.viewPrice.amountBtcSell = 0
        this.viewPrice.amountBtcBuy = 0
        this.viewPrice.amountBrlSell = '0'
        this.viewPrice.amountBrlBuy = 0
        this.viewPrice.bitcoinWallet = ''
      },
      errorGetPrice(error){
        this.viewPrice.btcBrlBuy = 0
        this.viewPrice.btcBrlSell= 0
        this.viewPrice.hashId = 0
      },
      updateBtcBuyValue(){
        if(isNullOrEmpty(this.viewPrice.amountBrlBuy) || this.viewPrice.amountBrlBuy == 0){
          this.viewPrice.amountBtcBuy = 0.0
        } else {
          this.viewPrice.amountBtcBuy = (moneyToFloat(this.viewPrice.amountBrlBuy) / this.viewPrice.btcBrlBuyTotal).toFixed(6)
          this.$refs.amountBtcBuy.$el.getElementsByTagName('input')[0].value = this.viewPrice.amountBtcBuy
        }
      },
      updateBrlBuyValue(){
        if(isNullOrEmpty(this.viewPrice.amountBtcBuy) || this.viewPrice.amountBtcBuy == 0){
          this.viewPrice.amountBrlBuy = '0'
        } else {
          this.viewPrice.amountBrlBuy = (moneyToFloat(this.viewPrice.amountBtcBuy) * moneyToFloat(this.viewPrice.btcBrlBuyTotal)).toFixed(2)
          this.$refs.amountBrlBuy.$el.getElementsByTagName('input')[0].value = this.viewPrice.amountBrlBuy
        }
      },
      updateBtcSellValue(){
        if(isNullOrEmpty(this.viewPrice.amountBrlSell) || this.viewPrice.amountBrlSell == 0){
          this.viewPrice.amountBtcSell = 0.0
        } else {
          this.viewPrice.amountBtcSell = (moneyToFloat(this.viewPrice.amountBrlSell) / this.viewPrice.btcBrlSellTotal).toFixed(6)
          this.$refs.amountBtcSell.$el.getElementsByTagName('input')[0].value = this.viewPrice.amountBtcSell
        }
      },
      updateBrlSellValue(){
        if(isNullOrEmpty(this.viewPrice.amountBtcSell) || this.viewPrice.amountBtcSell == 0){
          this.viewPrice.amountBrlSell = '0'
        } else {
          this.viewPrice.amountBrlSell = (moneyToFloat(this.viewPrice.amountBtcSell) * moneyToFloat(this.viewPrice.btcBrlSellTotal)).toFixed(2)
          this.$refs.amountBrlSell.$el.getElementsByTagName('input')[0].value = this.viewPrice.amountBrlSell
        }
      },
      confirmTrasaction(typeOperation){
        this.$validator.validateAll().then(isValid => {
          if (!isValid && typeOperation == 'BUY') {
            return
          }
          if(typeOperation == 'BUY') {
            if (moneyToFloat(this.viewPrice.amountBrlBuy) > moneyToFloat(this.accountBalance)) {
              swal({
                title: 'Aviso!',
                text: 'Você não possui saldo suficiente! Altere o valor e tente novamente.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return
            } else if (moneyToFloat(this.viewPrice.amountBrlBuy) <= 0 || moneyToFloat(this.viewPrice.amountBtcBuy) < this.minValueBtc) {
              swal({
                title: 'Aviso!',
                text: 'O valor de BTC tem que ser maior que '+this.minValueBtc+' BTC!',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return
            }
            this.viewPrice.transactionBtcType = 'BUY'
            swal({
              title: 'Termos de Uso',
              html: "<div style='text-align: left;'>A Bank4u é apenas uma facilitadora de pagamentos, regulamentada pela Lei 12.865/13. Portanto, sempre confira a legalidade da negociação realizada.<br><br>" +
                "Não nos responsabilizamos pelo endereço (carteira digital) informado pelo usuário para a transação de Biticoin. Portanto confira sempre o endereço para o qual será realizada a transação, pois o Bank4u não se responsabiliza por endereço e dados que fora informados erroneamente, ou seja, as informações são de total responsabilidade do usuário.<br><br>" +
                "Informamos ainda que, conforme a Lei 12.865/13, a Bank4u, caso notificada pela Polícia Federal tem o dever de lhes apresentar as informações solicitadas.<br><br>" +
                "Para sua segurança, confira sempre as informações antes de finalizar a transação.<br><br>Você aceita os termos?</div>",
              showCancelButton: true,
              confirmButtonText: 'SIM',
              confirmButtonColor: '#00c390',
              cancelButtonText: 'NÃO',
              cancelButtonColor: '#ef8157',
              width: 800
            }).then((result) => {
              if (result) {
                swal({
                  title: 'Aprovar Compra',
                  text: "Você deseja realmente realizar a compra de BTC no valor de R$ "+this.viewPrice.amountBrlBuy+"?",
                  showCancelButton: true,
                  confirmButtonText: 'SIM',
                  confirmButtonColor: '#00c390',
                  cancelButtonText: 'NÃO',
                  cancelButtonColor: '#ef8157'
                }).then((result) => {
                  if (result) {
                    this.callConfirmTransaction()
                  }
                })
              }
            })
          } else if(typeOperation == 'SELL') {
            if (moneyToFloat(this.viewPrice.amountBrlSell) <= 0 || moneyToFloat(this.viewPrice.amountBtcSell) < this.minValueBtc) {
              swal({
                title: 'Aviso!',
                text: 'O valor de BTC tem que ser maior que '+this.minValueBtc+' BTC!',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return
            }
            this.viewPrice.transactionBtcType = 'SELL'
            swal({
              title: 'Termos de Uso',
              html: "<div style='text-align: left;'>A Bank4u é apenas uma facilitadora de pagamentos, regulamentada pela Lei 12.865/13. Portanto, sempre confira a legalidade da negociação realizada.<br><br>" +
                "Não nos responsabilizamos pelo endereço (carteira digital) informado pelo usuário para a transação de Biticoin. Portanto confira sempre o endereço para o qual será realizada a transação, pois o Bank4u não se responsabiliza por endereço e dados que fora informados erroneamente, ou seja, as informações são de total responsabilidade do usuário.<br><br>" +
                "Informamos ainda que, conforme a Lei 12.865/13, a Bank4u, caso notificada pela Polícia Federal tem o dever de lhes apresentar as informações solicitadas.<br><br>" +
                "Para sua segurança, confira sempre as informações antes de finalizar a transação.<br><br>Você aceita os termos?</div>",
              showCancelButton: true,
              confirmButtonText: 'SIM',
              confirmButtonColor: '#00c390',
              cancelButtonText: 'NÃO',
              cancelButtonColor: '#ef8157',
              width: 800
            }).then((result) => {
              if (result) {
                swal({
                  title: 'Aprovar Venda',
                  text: "Você deseja realmente realizar a venda de BTC no valor de R$ "+this.viewPrice.amountBrlSell+"?",
                  showCancelButton: true,
                  confirmButtonText: 'SIM',
                  confirmButtonColor: '#00c390',
                  cancelButtonText: 'NÃO',
                  cancelButtonColor: '#ef8157'
                }).then((result) => {
                  if (result) {
                    this.callConfirmTransaction()
                  }
                })
              }
            })
          }
        })
      },
      callConfirmTransaction(){
        postWs("/bitcoin/request-transaction",
          true,
          null,
          {transactionBtcType: this.viewPrice.transactionBtcType,
            btcBrlBuy: this.viewPrice.btcBrlBuy,
            btcBrlSell: this.viewPrice.btcBrlSell,
            btcBrlBuyTotal: this.viewPrice.btcBrlBuyTotal,
            btcBrlSellTotal: this.viewPrice.btcBrlSellTotal,
            amountBtcSell: moneyToFloat(this.viewPrice.amountBtcSell),
            amountBtcBuy: moneyToFloat(this.viewPrice.amountBtcBuy),
            amountBrlSell: moneyToFloat(this.viewPrice.amountBrlSell),
            amountBrlBuy: moneyToFloat(this.viewPrice.amountBrlBuy),
            overPrice: this.viewPrice.overPrice,
            hashId: this.viewPrice.hashId,
            bitcoinWalletUser: this.viewPrice.bitcoinWallet},
          (response) => {
            this.callSuccess(response);
          }, (error) => {
            if(error.response.status == '406') {
              swal({
                title: 'Erro!',
                text: 'Erro de comunicação com a Exchange, verifique os dados e tente novamente!',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            } else if(error.response.status == '429'){
              swal({
                title: 'Erro!',
                text: 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            } else if(error.response.status == '412'){
              swal({
                title: 'Erro!',
                text: 'Você não possui saldo suficiente! Altere o valor e tente novamente.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            } else {
              swal({
                title: 'Erro!',
                text: 'Erro ao solicitar transação!',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }
            this.getWalletAccessCode()
          }
        )
      },
      callSuccess(response){
        if(this.viewPrice.transactionBtcType == 'BUY'){
          swal({
            title: 'Sucesso!',
            text: 'Sua trancação foi realizada com sucesso!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'success'
          })
        }else{
          this.walletExchange = response.data.btcAddress
          this.qrCodeWalletExchange = response.data.qrCode
          this.viewQrCodeWallet = true
        }
      },
      loadHistorySell(){
        this.listSell = true
        postWs("/bitcoin/get-list-user",
          true, null,
          {
            username: this.searchSell.username,
            statusTransactionBtc: this.searchSell.status,
            transactionBtcType: this.searchSell.type,
            pageNumber: this.paginationSell.currentPage,
            pageSize: this.paginationSell.perPage
          }, this.successLoadDataSell,
          failWs)
        return this.paginationSell.currentPage
      },
      successLoadDataSell(response) {
        this.tableData = response.data.list
        this.paginationSell.totalNumber = response.data.totalNumber
        this.paginationSell.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationSell.totalNumber > 0) {
          this.paginationSell.fromNumber = ((this.paginationSell.perPage * (this.paginationSell.currentPage - 1)) + 1)
          this.paginationSell.toNumber = ((this.paginationSell.fromNumber + this.tableData.length) - 1)
        } else {
          this.paginationSell.fromNumber = 0
          this.paginationSell.toNumber = 0
        }
      },
      loadHistoryBuy(){
        this.listBuy = true
        postWs("/bitcoin/get-list-user",
          true, null,
          {
            username: this.searchBuy.username,
            statusTransactionBtc: this.searchBuy.status,
            transactionBtcType: this.searchBuy.type,
            pageNumber: this.paginationBuy.currentPage,
            pageSize: this.paginationBuy.perPage
          }, this.successLoadDataBuy,
          failWs)
        return this.paginationBuy.currentPage
      },
      successLoadDataBuy(response) {
        this.tableData = response.data.list
        this.paginationBuy.totalNumber = response.data.totalNumber
        this.paginationBuy.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationBuy.totalNumber > 0) {
          this.paginationBuy.fromNumber = ((this.paginationBuy.perPage * (this.paginationBuy.currentPage - 1)) + 1)
          this.paginationBuy.toNumber = ((this.paginationBuy.fromNumber + this.tableData.length) - 1)
        } else {
          this.paginationBuy.fromNumber = 0
          this.paginationBuy.toNumber = 0
        }
      },
      statusToLabel(status) {
        if (status == 'NOT_PROCESSED') {
          return 'AG. APROVAÇÃO'
        } else if (status == 'PROCESSED') {
          return 'PROCESSADO'
        } else if (status == 'REJECTED') {
          return 'REJEITADO'
        } else if (status == 'WAITING_BTC') {
          return 'AG. BITCOIN'
        } else if (status == 'CANCELED') {
          return 'CANCELADO'
        } else if (status == 'ERROR') {
          return 'ERRO'
        }
        return '??'
      }
    }
  }
</script>

<style lang="scss">
  .dzs-btc {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }
  }

</style>
