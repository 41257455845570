<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Alterar Senha</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="Senha Atual"
                      name="Senha Atual"
                      v-validate="{required: true, min: 6}"
                      :error="getError('Senha Atual')"
                      v-model="password.oldPassword">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="Nova Senha"
                      name="Nova Senha" ref="password"
                      v-validate="{required: true, min: 8}"
                      :error="getError('Nova Senha')"
                      v-model="password.newPassword">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="Confirme a Senha"
                      name="Confirme a Senha"
                      v-validate="{required: true, confirmed: 'password'}"
                      :error="getError('Confirme a Senha')"
                      v-model="confirmPassword">
            </fg-input>
          </div>
        </div>

        <br/>
        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="resetPassword">
            Salvar Senha
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import swal from 'sweetalert2'
  import {postWs} from "src/ws.service";

  export default {
    components: {},
    data() {
      return {
        confirmPassword: '',
        password: {
          oldPassword: '',
          newPassword: '',
        }
      }
    },
    methods: {
      resetPassword() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            postWs("/acc/reset-password", true, null, this.password,
              this.changeSuccess, function () {
                swal({
                  title: 'Falha!',
                  text: 'Falha ao salvar nova senha, favor tentar novamente mais tarde.',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  type: 'error'
                })
              })
          }
        })
      },
      changeSuccess() {
        swal({
          title: 'Sucesso!',
          text: 'Senha alterada com sucesso.',
          buttonsStyling: true,
          timer: 5000,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        this.password.oldPassword = ''
        this.password.newPassword = ''
        this.confirmPassword = ''
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
    }
  }

</script>
<style>

</style>
