<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">
          Cadastrar Role
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label>Autorização</label>
            <input type="text" class="form-control" maxlength="30" v-model="role.authority" style="text-transform: uppercase"></input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4 class="card-title">Descrição da Role</h4>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-6">
            <label>Descrição</label>
            <textarea class="form-control" rows="5" maxlength="255" v-model="description.description"></textarea>
          </div>
          <div class="col-sm-6">
            <label>Detalhes Internos</label>
            <textarea class="form-control" rows="5" maxlength="1000" v-model="description.internalDetails"></textarea>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <p-button type="info" @click.prevent="saveRole">Salvar</p-button>
      </div>
    </form>
  </div>
</template>
<script>
  import {Option} from 'element-ui'
  import {callWs, failWs} from '../../../../../ws.service'
  import swal from 'sweetalert2'

  export default {
    components: {
      [Option.name]: Option
    },
    data () {
      return {
        role: {
          authority: '',
        },
        description: {
          description: '',
          internalDetails: ''
        },
        selects: {
          packsItems: [],
          simple: '',
          multiple: ''
        }
      }
    },
    methods: {
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.registerForm, isValid)
        })
      },
      saveRole(){
        if(this.role.authority == ''){
          swal({
            title: 'Aviso!',
            text: 'O campo Autorização é obrigatório!',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        callWs("/role/insert-role",
          "POST", null, true, null,
          {authority: this.role.authority.toUpperCase(), idDescription: null, listIdRolePack: null,
            newDescription:{description: this.description.description, internalDetails: this.description.internalDetails}},
          () => {
            swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'success'
            })
          }, (error) => {
            this.buttonDisabled = false
            if (error.response.status == 409) {
              swal({
                title: 'Aviso!',
                text: 'Role já cadastrada!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
              return
            }
            swal({
              title: 'Falha!',
              text: 'Falha ao salvar a role, verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-danger btn-fill',
              type: 'error'
            })
          })
      }
    }
  }
</script>
<style>
  .uppercase{
    text-transform: uppercase;
  }
</style>
