<template>
  <div style="border: 1px solid; border-color: #00d19a; border-radius: 25px; background-image: url(/static/img/background_s.png); background-repeat: no-repeat; padding: 5%">
    <div class="row">
      <div class="col-md-4" align="center">
        <img style="max-height: 100%; position: absolute; display: block" src="/static/img/card-dash.png" alt="..."  >
      </div>
      <div class="col-md-8">
        <div style="text-align: center">
          <span style="font-size: 18px;">SuitCard, o cartão pré-pago da sua empresa.</span><br>
          <span style="font-size: 12px">Gerencie orçamentos de um ou vários cartões pela plataforma.</span>
          <br>
          <br>
          <div class="row">
            <div class="col-md-6">
              <a  href="https://apps.apple.com/br/app/suitpay/id1632130123" target="_blank">
                <img src="/static/img/mobile/apple_store.png" alt="..."  >

              </a>
            </div>
            <div class="col-md-6">
              <a href="https://play.google.com/store/apps/details?id=com.suitpay" target="_blank">
                <img src="/static/img/mobile/google_play.png" alt="..."  >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: "BannerCard"
  }
</script>

<style scoped>

</style>
