<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Roles Por Usuários</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário / E-Mail / CPF</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-md-3">
            <fg-input label="Documentação Status">
              <el-select size="large"
                         class="select-default"
                         placeholder="Selecionar"
                         v-model="search.documentStatus">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="PENDING_DOCUMENT"
                           class="select-default"
                           label="Pendente (PE)"/>
                <el-option value="PENDING_VALIDATION"
                           class="select-default"
                           label="A Validar (AV)"/>
                <el-option value="APPROVED"
                           class="select-default"
                           label="Validado (OK)"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <fg-input label="Ordenação Data">
              <el-select size="large"
                         class="select-default"
                         placeholder="Selecionar"
                         v-model="search.dateOrder">
                <el-option value="ASC"
                           class="select-default"
                           label="Crescente"/>
                <el-option value="DESC"
                           class="select-default"
                           label="Decrescente"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="E-mail">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.email}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="24" label="Doc">
                <template slot-scope="props">
                    <span>
                      <span>{{docStatusToLabel(props.row.documentStatus)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createdDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="110" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Roles"
                            @click="loadDataRoles(props.row.username)">
                    <i class="far fa-copy"></i>
                  </p-button>
                  <p-button type="danger" size="sm" icon title="Packs"
                            @click="loadDataPacks(props.row.username)">
                    <i class="fas fa-box-open"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="role.tableData.length>0" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4>Roles do Usuário <b>@{{role.username}}</b></h4>
        <div class="card-body row">
          <div class="col-md-4">
            <label class="control-label">Nome da Role</label>
            <fg-input v-model="role.authority"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="role.pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="itemRole in [5,10,25,50]"
                  :key="itemRole"
                  :label="itemRole"
                  :value="itemRole">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         class="select-default"
                         v-model="role.status">
                <el-option value="ALL"
                           class="select-default"
                           label="Todos"/>
                <el-option value="TRUE"
                           class="select-default"
                           label="Ativo"/>
                <el-option value="FALSE"
                           class="select-default"
                           label="Inativo"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-3">
            <p-button type="success" style="margin-top: 25px;"
                      @click="findRoles(role.username)">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12">
            <el-table class="table-striped"
                      header-row-class-name="text-primary"
                      :data="role.tableData">
              <el-table-column label="Role">
                <template slot-scope="props">
                      <span>
                        <span><b>{{props.row.authority}}</b></span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="Ativo">
                <template slot-scope="props">
                  <input type="checkbox" v-model="props.row.isAssociateRole" @change="updateAssociateRoles(props.row.id, props.row.isAssociateRole)">
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{role.pagination.fromNumber}} AO {{role.pagination.toNumber}}, DE
              {{role.pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="role.pagination.currentPage"
                          :per-page="role.pagination.perPage"
                          :total="role.pagination.totalNumber"
                          :click="findRolePagination">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-12" v-if="pack.tableData.length>0" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4>Packs do Usuário <b>@{{pack.username}}</b></h4>
        <div class="card-body row">
          <div class="col-md-6">
            <label class="control-label">Nome da Pack</label>
            <fg-input v-model="pack.name"/>
          </div>
          <div class="col-sm-3">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pack.pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="itemPack in [5,10,25,50]"
                  :key="itemPack"
                  :label="itemPack"
                  :value="itemPack">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-3">
            <p-button type="success" style="margin-top: 25px;"
                      @click="findPacks(pack.username)">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12">
            <el-table class="table-striped"
                      header-row-class-name="text-primary"
                      :data="pack.tableData">
              <el-table-column label="Pack">
                <template slot-scope="props">
                      <span>
                        <span><b>{{props.row.name}}</b></span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="Ativo">
                <template slot-scope="props">
                  <input type="checkbox" v-model="props.row.isAssociatePack" @change="updateAssociatePack(props.row.id, props.row.isAssociatePack)">
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pack.pagination.fromNumber}} AO {{pack.pagination.toNumber}}, DE
              {{pack.pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pack.pagination.currentPage"
                          :per-page="pack.pagination.perPage"
                          :total="pack.pagination.totalNumber"
                          :click="findPackPagination">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        roleSelect: [],
        search: {
          key: '',
          documentStatus: null,
          dateOrder: 'ASC'
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        role: {
          authority: '',
          username: '',
          status: 'ALL',
          tableData: [],
          listIncludeIdRoles: [],
          listDeleteIdRoles: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          }
        },
        pack: {
          name: '',
          username: '',
          tableData: [],
          listIncludeIdPacks: [],
          listDeleteIdPacks: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          }
        }
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.role.tableData = []
        this.pack.tableData = []
        postWs("/bo/acc/list-account",
          true, null,
          {
            key: this.search.key,
            documentStatus: this.search.documentStatus,
            dateOrder: this.search.dateOrder,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.accounts
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      docStatusToLabel(documentStatus) {
        if (documentStatus == null) {
          return 'PE*'
        } else if (documentStatus == 'PENDING_DOCUMENT') {
          return 'PE'
        } else if (documentStatus == 'PENDING_VALIDATION') {
          return 'AV'
        } else if (documentStatus == 'APPROVED') {
          return 'OK'
        }
        return '??'
      },
      findRoles(username) {
        this.role.pagination.currentPage = 1
        this.loadDataRoles(username)
      },
      findRolePagination() {
        this.loadDataRoles(this.role.username)
      },
      loadDataRoles(username) {
        this.pack.tableData = []
        if(username != this.role.username) {
          this.role.pagination.currentPage = 1
        }
        this.role.username = username
        postWs("/role/get-user-roles",
          true, null,
          {
            username: username,
            name: this.role.authority,
            active: this.role.status,
            pageNumber: this.role.pagination.currentPage,
            pageSize: this.role.pagination.perPage
          }, this.successLoadDataRole,
          failWs)
        return this.role.pagination.currentPage
      },
      successLoadDataRole(response) {
        if(this.role.authority != '' && response.data.list.length == 0){
          this.role.authority = ''
          swal({
            title: 'Aviso!',
            text: "Nenhuma role encontrada com esse nome!",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.role.tableData = response.data.list
          this.role.pagination.totalNumber = response.data.totalNumber
          this.role.pagination.pageMaxNumber = response.data.pageMaxNumber
          if (this.role.pagination.totalNumber > 0) {
            this.role.pagination.fromNumber = ((this.role.pagination.perPage * (this.role.pagination.currentPage - 1)) + 1)
            this.role.pagination.toNumber = ((this.role.pagination.fromNumber + this.role.tableData.length) - 1)
          } else {
            this.role.pagination.fromNumber = 0
            this.role.pagination.toNumber = 0
          }
        }
      },
      updateAssociateRoles(id, active){
        if(active){
          this.role.listIncludeIdRoles = [id];
          this.role.listDeleteIdRoles = [];
        }else{
          this.role.listDeleteIdRoles = [id];
          this.role.listIncludeIdRoles = [];
        }

        postWs("/role/associate-user-roles",
          true, null,
          {
            username: this.role.username,
            listIncludeIdRoles: this.role.listIncludeIdRoles,
            listDeleteIdRoles: this.role.listDeleteIdRoles
          }, this.wsOk,
          failWs)
      },
      findPacks(username) {
        this.pack.pagination.currentPage = 1
        this.loadDataPacks(username)
      },
      findPackPagination() {
        this.loadDataPacks(this.pack.username)
      },
      loadDataPacks(username) {
        this.role.tableData = []
        if(username != this.pack.username){
          this.pack.pagination.currentPage = 1
        }
        this.pack.username = username
        postWs("/role/get-user-packs",
          true, null,
          {
            username: username,
            name: this.pack.name,
            pageNumber: this.pack.pagination.currentPage,
            pageSize: this.pack.pagination.perPage
          }, this.successLoadDataPack,
          failWs)
        return this.pack.pagination.currentPage
      },
      successLoadDataPack(response) {
        if(this.pack.name != '' && response.data.list.length == 0){
          this.pack.name = ''
          swal({
            title: 'Aviso!',
            text: "Nenhuma pack encontrada com esse nome!",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.pack.tableData = response.data.list
          this.pack.pagination.totalNumber = response.data.totalNumber
          this.pack.pagination.pageMaxNumber = response.data.pageMaxNumber
          if (this.pack.pagination.totalNumber > 0) {
            this.pack.pagination.fromNumber = ((this.pack.pagination.perPage * (this.pack.pagination.currentPage - 1)) + 1)
            this.pack.pagination.toNumber = ((this.pack.pagination.fromNumber + this.pack.tableData.length) - 1)
          } else {
            this.pack.pagination.fromNumber = 0
            this.pack.pagination.toNumber = 0
          }
        }
      },
      updateAssociatePack(id, active){
        if(active){
          this.pack.listIncludeIdPacks = [id];
          this.pack.listDeleteIdPacks = [];
        }else{
          this.pack.listDeleteIdPacks = [id];
          this.pack.listIncludeIdPacks = [];
        }

        postWs("/role/associate-user-packs",
          true, null,
          {
            username: this.pack.username,
            listIncludeIdPacks: this.pack.listIncludeIdPacks,
            listDeleteIdPacks: this.pack.listDeleteIdPacks
          }, this.wsOk,
          failWs)
      },
      wsOk(response){
        return true;
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
