<template>
  <form @submit.prevent="onFormSubmit" class="card edit-card">
    <div class="card-header">
      <h4>Definir Motivo de Negação</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <fg-input label="Motivo"
                    name="motivo"
                    data-vv-as="Motivo"
                    v-validate="{required: true}"
                    v-model="reason"
                    :error="getError('name')"
          ></fg-input>
        </div>
        <div class="col-md-12">
          <p-button type="success" native-type="submit" class="float-right">Salvar</p-button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import {postWs} from "@/ws.service";
import swal from "sweetalert2";

export default {
  name: 'describe-requests-form',
  props: {
    selectedRequests: {
      required: true,
      default: []
    }
  },
  emits: ['save'],
  data() {
    return {
      reason: ''
    }
  },
  methods: {
    onFormSubmit() {
      postWs('/bo/compliance/describe-requests-status', true, null,
        {
          selectedRequests: this.selectedRequests.map(el => el.endToEnd),
          reason: this.reason
        },
        this.successSubmit, this.failSubmit
      )
    },
    successSubmit() {
      swal({
        title: 'Sucesso!',
        text: 'Os status foram atualizados com sucesso!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.$emit('save')
    },
    failSubmit() {
      swal({
        title: 'Atenção!',
        text: 'Ocorreu um erro ao atualizar status. Tente Novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    }
  }
}
</script>
<style scoped lang="scss">
.edit-card {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
}
</style>
