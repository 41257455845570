<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Recargas Via TED</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário</label>
            <fg-input v-model="search.username"/>
          </div>
          <div class="col-md-3">
            <label class="control-label">Número do documento</label>
            <fg-input v-model="search.tedDocumentNumber"/>
          </div>
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.status">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="WAITING_APPROVAL"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="PROCESSED"
                           class="select-default"
                           label="Processados"/>
                <el-option value="REJECTED"
                           class="select-default"
                           label="Rejeitados"/>
                <el-option value="FAIL"
                           class="select-default"
                           label="Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-1">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Data do TED">
                <template slot-scope="props">
                      <span>
                        <span>{{props.row.tedDate}}</span>
                      </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="CPF/CNPJ Depositante">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.sourceAccountOwnerSocialNumber}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome do Depositante">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.sourceAccountOwnerName}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Núm. Comprovante">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.numberDocumentTed}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{statusToLabel(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="60" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="info" size="sm" icon title="Editar"
                            @click="editCreditTed(props.row.id)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"
  import {toMoney} from "../../../../../util/core.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        search: {
          username: '',
          status: 'WAITING_APPROVAL',
          tedDocumentNumber: ''
        },
        statusDefault: 'WAITING_APPROVAL',
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      resetDefaultValues(){
        this.search.username = ''
        this.search.status = 'WAITING_APPROVAL',
        this.pagination.currentPage = 1
        this.pagination.perPage = 5
        this.pagination.pageMaxNumber = 1
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
        this.pagination.totalNumber = 0
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/credit-ted/get-list",
          true, null,
          {
            username: this.search.username,
            status: this.search.status,
            tedDocumentNumber: this.search.tedDocumentNumber,
            pageNumber: this.pagination.currentPage,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusToLabel(status) {
        if (status == 'WAITING_APPROVAL') {
          return 'AG. APROVAÇÃO'
        } else if (status == 'PROCESSED') {
          return 'PROCESSADO'
        } else if (status == 'REJECTED') {
          return 'REJEITADO'
        } else if (status == 'FAIL') {
          return 'ERRO'
        }
        return '??'
      },
      hasAuthorization(role){
        return isAuthorized(role);
      },
      hasAnyAuthorization(roles){
        return isSomeAuthorization(roles);
      },
      editCreditTed(id) {
        this.openWindow('/bo/credit-ted/editCreditTed/' + id)
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
