<template>
  <div class="dzs-ted">
    <div class="row" style="padding: 15px">

      <div class="col-md-5" v-if="!showHistory">
        <card style="padding: 5%; height: 100%">
          <h4 slot="header" class="card-title" style="text-align: center">
            Transferência TED (Outros Bancos)
          </h4>

          <banner></banner>
          <br>
          <br>
          <p>
            As solicitações de TED podem ser realizadas de segunda a sexta das 08h00 à 16h00.
          </p>
          <p>
            Ao fazer uma transferência interbancária (transferência entre bancos diferentes), fique atento ao prazo de processamento, que pode variar de alguns segundos a até 1 dia útil.
          </p>
          <p>
            Caso a TED seja estornada por erro nos dados informados, a taxa de TED não será devolvida.
          </p>
          <p>
            Abaixo alguns fatores que podem impactar no tempo de efetivação das transferências entre bancos distintos:
          </p>

            <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Prazo de efetivação do banco emissor da transferência.<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Prazo de efetivação do banco que recebe a transferência.<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Data e horário no qual a transferência foi efetivada - dia útil ou não.<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> Registro da transação em orgãos reguladores (Banco Central e, dependendo do valor, no Coaf).<br>
              <i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> O setor de compliance pode segurar a ordem por questões de segurança. </p>
        </card>
      </div>


      <div class="col-md-7" v-if="!showHistory && isNullOrEmpty(transfer.qrCode)">
        <card style="height: 100%">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <label class="control-label">Valor</label>
                  <fg-input placeholder="0,00" v-money="'money'" type="tel"
                            v-model="transfer.value">
                    <template slot="addonLeft">R$</template>
                  </fg-input>
                  <label class="control-label" style="float: left">
                    Saldo: <b>R$ {{toMoney(accountBalance)}}</b>
                    <br>
                    Saldo disponível para saque: <b>R$ {{toMoney(accountAvailableBalance)}}</b>
                  </label>
                  <label class="control-label" style="float: right">
                    Taxa Serviço: <b>R$ {{serviceFee}}</b>
                  </label>
                  <br>
                  <br>
                  <label class="control-label">Banco Destino</label>
                  <label class="control-label" style="float: right">
                    <a href="#" @click="loadHistory">
                      <i slot="label" class="fas fa-history"></i> Histórico de Favorecidos</a>
                  </label>
                  <autocomplete ref="bankAc"
                                v-validate="{required: true}"
                                placeholder="Selecionar"
                                :source="banksCodes"
                                input-class="form-control"
                                results-value="code"
                                :results-display="displayBankName"
                                @selected="selectBank">
                  </autocomplete>
                </div>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-12">
                  <label class="control-label">Tipo de Conta Destino</label>
                  <el-select size="large" placeholder="Selecionar" style="width: 100%"
                             v-model="transfer.destinationAccountType">
                    <el-option class="select-success"
                               value="CC"
                               label="Corrente"/>
                    <el-option class="select-info"
                               value="PP"
                               label="Poupança"/>
                  </el-select>
                </div>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-12">
                  <label class="control-label">Agência Destino (Sem o Dígito)</label>
                  <fg-input v-model="transfer.destinationAgency" style="width: 100%"
                            v-validate="{required: true}" type="tel" maxlength="5"
                            :error="getError('Agência Destino')"
                            name="Agência Destino" v-mask="['#####']">
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <label class="control-label">Conta Destino</label>
                  <fg-input v-model="transfer.destinationAccountNumber" style="width: 100%"
                            v-validate="{required: true}" type="tel" maxlength="12"
                            :error="getError('Conta Destino')"
                            name="Conta Destino" v-mask="['############']">
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <label class="control-label">Dígito</label>
                  <fg-input v-model="transfer.destinationAccountNumberDv" style="width: 100%"
                            type="tel" maxlength="1" :error="getError('Dígito')"
                            name="Dígito" v-mask="['#']">
                  </fg-input>
                </div>
              </div>
              <fg-input type="text"
                        label="Nome Destino"
                        maxlength="30"
                        name="Nome Destino"
                        v-validate="{required: true}"
                        :error="getError('Nome Destino')"
                        v-model="transfer.destinationName">
              </fg-input>

              <fg-input label="CPF/CNPJ Destino"
                        type="tel"
                        v-mask="['###.###.###-##','##.###.###/####-##']"
                        name="CPF/CNPJ Destino"
                        v-validate="{cpfCnpjValid:true, required: true, min: 14}"
                        :error="getError('CPF/CNPJ Destino')"
                        v-model="transfer.destinationSocialNumber">
              </fg-input>
            </div>
          <hr v-if="!isNullOrEmpty(transfer.destinationSocialNumber)"/>
          <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationSocialNumber)" style="text-align: center">
            <div class="col-sm-12">
              <label class="control-label">Descrição</label>
              <fg-input v-model="transfer.description" style="max-width: 200px; margin: 0 auto" maxlength="20"/>
            </div>
          </div>
          <br>
          <hr/>
          <br>
          <div class="row">
            <div class="col-md-12">
              <p-button type="info" @click="doTransaction"
                        style="margin: 0 auto; display: table; min-width: 260px; height: 80px">
                <i slot="label" class="fas fa-exchange-alt"></i> Transferir
              </p-button>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md-12" v-if="showHistory">
        <card>
          <h4 slot="header" class="card-title">
            Selecione um Favorecido
          </h4>
          <div class="row clickable-rows">
            <div class="col-md-12">
              <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="tableData"
                          @row-click="onBankReuseSelected"
                          style="width: 100%">
                  <el-table-column :min-width="130" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span><b>{{props.row.destinationName}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Banco">
                    <template slot-scope="props">
                    <span>
                      <span><b>{{getBank(props.row.destinationBankCode).name}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="110" label="Agência/Conta">
                    <template slot-scope="props">
                    <span>
                      <span><b>{{props.row.destinationAgency}}/{{props.row.destinationAccountNumber}}-{{props.row.destinationAccountNumberDv}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-12">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.totalNumber"
                              :click="this.loadData">
                </p-pagination>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p-button type="info" style="margin: 0 auto; margin-top: 40px; display: table"
                        @click="showHistory = false">
                <i slot="label" class="fas fa-undo"></i> Voltar
              </p-button>
            </div>
          </div>
        </card>
      </div>


      <!-- Bloco Dispositivo Não habilitado -->
      <div class="col-md-7"  v-if="!isNullOrEmpty(totpQrCodeUri) && !isNullOrEmpty(transfer.qrCode) && transactionAuthorizationTotp==='true'">
        <card style="background-color: #00d19a; text-align: center">
          <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não esta cadastrado!</h4>
          <card align="center" >
            <span>Para liberar suas transações siga os passos abaixo:</span>
            <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            </div>

            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">SuitPay</b>.</span></p>
                <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                </a>
                <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                </a>

              </div>

              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no aplicativo </p>
                <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                <p><b>4</b> - Confirme a operação</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>
            <br>
            <div style="text-align: center">
              <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                        class="btn btn-danger" >
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
            </div>
          </card>
        </card>
      </div>

      <!-- Bloco Dispositivo Habilitado -->
      <div class="col-md-7"  v-if="!isNullOrEmpty(transfer.qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
        <card style="background-color: #00d19a; text-align: center; height: 100%">
          <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
          <card align="center" style="height: 100%" >

            <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                <p><b>4</b> - Confirme a operação.</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>

            <span>Informe o <b>SuitID</b>: </span>
            <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

            <div class="row" v-if="!codeValid">
              <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
            </div>

            <div style="text-align: center">
              <p-button @click="()=>{transfer.qrCode = ''; clearInterval(validateIntervalLoop)}"
                        class="btn btn-danger" >
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
              <p-button @click="()=>{validateCode()}"
                        class="btn btn-primary"
                        :disabled="totpConfirmBtnDisabled"
              >
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
            <hr/>
            <span>Dados da Transferência</span>
            <dzs-ted-tranfer-details :transfer="transfer"/>
          </card>
        </card>
      </div>
    </div>

    <div class="col-md-12" >
      <card>
        <div class="card-body row">
          <div class="col-sm-12" align="center">
            <h4 slot="header" class="card-title">
              Relação de TED's enviadas
            </h4>
            <hr>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="timelineItems"
                      style="width: 100%">
              <el-table-column :min-width="130" label="Data">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.date}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="130" label="Destinatário">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.to}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Banco">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.toBank}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Valor">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="110" label="Descrição">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.userDescription}}</span>
                    </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-success">
            <p class="category">EXIBINDO DO {{paginationItems.fromNumber}} AO {{paginationItems.toNumber}}, DE
              {{paginationItems.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="paginationItems.currentPage"
                          :per-page="paginationItems.perPage"
                          :total="paginationItems.totalNumber"
                          :click="this.loadHistoryTed">
            </p-pagination>
          </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {getTransactionName, getTransactionLogo} from 'src/components/Dashboard/Views/Transactions/timeline'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {isNullOrEmpty, isValidCnpj, isValidCpf, moneyToFloat, notNull, toMoney} from "src/util/core.utils"
  import {failWs, postWs} from 'src/ws.service'
  import swal from 'sweetalert2'
  import nprogress from 'nprogress'
  import banksCodes from './banks-codes'
  import Autocomplete from 'vuejs-auto-complete'
  import DzsTedTranferDetails from "../../../UIComponents/Dzs/Transfer/DzsTedTranferDetails"
  import Banner from "../../../UIComponents/Promotion/Banner";
  import {updateBalance} from "@/util/account.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      DzsTedTranferDetails,
      Autocomplete,
      PPagination,
      Banner
    },
    data() {
      return {
        timelineItems: [],
        paginationItems: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        startDate: new Date(),
        endDate: new Date(),
        serviceFee: '7,70',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        accountAvailableBalance: '0,00',
        transfer: {
          qrCode: '',
          destination: '',
          destinationName: '',
          destinationSocialNumber: '',
          value: '',
          description: '',
          destinationBankCode: '',
          destinationBankName: '',
          destinationAccountType: '',
          destinationAgency: '',
          destinationAccountNumber: '',
          destinationAccountNumberDv: ''
        },
        showHistory: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        propsToSearch: ['destinationName', 'destinationSocialNumber', 'destinationAgency', 'destinationAccountNumber'],
        banksCodes: banksCodes,
        transactionAuthorizationEmail: 'false',
        transactionAuthorizationTotp: 'false',
        validationCode: '',
        totpQrCodeUri : '',
        totpManualCode : '',
        totpConfirmBtnDisabled: false,
        codeValid: true
      }
    },
    created() {
      this.getUpdatedBalance()
      this.$validator.extend('cpfCnpjValid', {
        getMessage: field => 'O ' + field + ' é inválido.',
        validate: (value) => {
          value = value.replace(/(\.)|(\/)|(-)/g, '')
          return isValidCnpj(value) || isValidCpf(value)
        }
      })
      this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      this.loadHistoryTed()
    },
    methods: {
      toMoney,
      getTransactionName,
      getTransactionLogo,
      moneyToFloat,
      isNullOrEmpty,
      doTransaction() {
        let valueAndFee = (moneyToFloat(this.transfer.value) + moneyToFloat(this.serviceFee)).toFixed(2)
        if (valueAndFee > moneyToFloat(this.accountBalance)) {
          swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        } else if (isNullOrEmpty(this.transfer.destinationBankCode)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Banco Destino é obrigatório.',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        } else if (isNullOrEmpty(this.transfer.destinationAccountType)) {
          swal({
            title: 'Aviso!',
            text: 'O campo Tipo de Conta Destino é obrigatório.',
            buttonsStyling: true,
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          this.callTedWs()
        })
      },
      callTedWs() {
        if (moneyToFloat(this.transfer.value) <= 0) {
          swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          return
        }
        this.transfer.description = this.transfer.description.toUpperCase()
        this.transfer.destinationName = this.transfer.destinationName.toUpperCase()
        postWs("/transaction/ted",
          true, null,
          {
            value: moneyToFloat(this.transfer.value),
            destinationBankCode: this.transfer.destinationBankCode,
            destinationAccountType: this.transfer.destinationAccountType,
            destinationAgency: this.transfer.destinationAgency,
            destinationAccountNumber: this.transfer.destinationAccountNumber,
            destinationAccountNumberDv: this.transfer.destinationAccountNumberDv,
            destinationName: this.transfer.destinationName,
            destinationSocialNumber: this.transfer.destinationSocialNumber,
            description: this.transfer.description
          },
          (response) => {
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.response === 'QR_CODE_VALIDATION_REQUIRED'
              && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              this.validateIntervalLoop = setInterval(
                this.callStatusWs, 5 * 1000)
              return
            } else if (response.data.response === 'EMAIL_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              return
            } else if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.qrCode)) {
              this.transfer.qrCode = response.data.qrCode
              this.totpQrCodeUri = response.data.totpQrCodeUri
              this.totpManualCode = response.data.totpManualCode
              return
            } else if(response.data.response == 'OK'){
              this.success()
              return
            }
            swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }, (error) => {
            let text = null
            if (error.response.data.response == 'NO_MOBILE_DEVICE_REGISTERED') {
              text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
            } else if (error.response.data.response == 'NO_FUNDS') {
              text = 'Você não possui saldo suficiente! Altere o valor e tente novamente.'
            } else if (error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
              text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
            } else if (error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' ||
              error.response.data.response == 'BLOCKED') {
              text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
              text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
            } else if (error.response.data.response == 'SOURCE_USER_INACTIVE') {
              text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_TIME_LIMIT') {
              text = 'As solicitações de TED podem ser realizadas de segunda a sexta das 09h à 15h00, tente novamente no horário permitido.'
            } else if (error.response.data.response == 'BLOCKED_OUT_TRANSACTIONS'){
              text = 'As transações de saída desta conta estão em análise!'
            }
            if (!isNullOrEmpty(text)) {
              swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            } else {
              swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            }
          })
      },
      callStatusWs() {
        if (this.$router.currentRoute.name != 'Ted') {
          clearInterval(this.validateIntervalLoop)
          return
        }
        postWs("/verifcode/status",
          true,
          null,
          {verifCode: this.transfer.qrCode},
          this.transferSuccess,
          function (error) {
          })
      },
      transferSuccess(response) {
        if (response.data.status == 'USED') {
          this.success()
        }
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.transfer.qrCode,
            hashCode: this.validationCode},
          this.confirmCode,
          this.failValidateCode)
      },
      failValidateCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      confirmCode(response){
        postWs("/verifcode/confirm",
          true,
          null,
          {verifCode: response.data.verifCode},
          this.success,
          this.failConfirmCode
        )
      },
      success(){
        this.totpConfirmBtnDisabled = false
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = (moneyToFloat(this.accountBalance) - moneyToFloat(this.transfer.value)) - moneyToFloat(this.serviceFee)
        localStorage.setItem('accountBalance', this.accountBalance)

        this.transfer.value = ''
        this.transfer.destination = ''
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        this.transfer.description = ''
        this.transfer.qrCode = ''

        this.destinationBankName = ''
        this.destinationBankCode = ''
        this.destinationAccountType = ''
        this.destinationAgency = ''
        this.destinationAccountNumber = ''
        this.destinationAccountNumberDv = ''
        swal({
          title: 'Sucesso!',
          text: 'Valor transferido com sucesso!',
          buttonsStyling: true,
          timer: 5000,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        })
        nprogress.done()
        this.getUpdatedBalance()
      },
      failConfirmCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Erro!',
          text: 'Erro ao transferir, tente novamente!',
          buttonsStyling: true,
          timer: 5000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          type: 'error'
        })
      },
      loadHistory() {
        this.showHistory = true
        postWs("/transaction/ted-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          },
          this.successLoadData
          , () => {
            this.tableData = []
          })
      },
      onBankReuseSelected(destination) {
        this.showHistory = false
        nprogress.start()
        setTimeout(function (vueComponent, destination, progress) {
          vueComponent.selectDestination(destination)
          nprogress.done()
        }, 500, this, destination)
      },
      selectDestination(destination) {
        this.transfer.destination = destination.destination
        this.transfer.destinationName = destination.destinationName
        this.transfer.destinationSocialNumber = destination.destinationSocialNumber
        this.transfer.destinationBankCode = destination.destinationBankCode
        this.transfer.destinationBankName = this.getBank(destination.destinationBankCode).name
        this.transfer.destinationAccountType = destination.destinationAccountType
        this.transfer.destinationAgency = destination.destinationAgency
        this.transfer.destinationAccountNumber = destination.destinationAccountNumber
        this.transfer.destinationAccountNumberDv = destination.destinationAccountNumberDv
        this.$refs.bankAc.display = this.displayBankName(this.getBank(destination.destinationBankCode))
        this.$refs.bankAc.value = destination.destinationBankCode
      },
      getBank(code) {
        for (let bank in this.banksCodes) {
          if (this.banksCodes[bank].code == code) {
            return this.banksCodes[bank]
          }
        }
        return null
      },
      selectBank(bank) {
        this.transfer.destinationBankCode = bank.selectedObject.code
        this.transfer.destinationBankName = bank.selectedObject.name
      },
      displayBankName(bank) {
        return bank.code + ' - ' + bank.name
      },
      loadData() {
        postWs("/transaction/ted-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      loadHistoryTed() {
        postWs("/transaction/ted-history",
          true, null,
          {
            pageNumber: (this.paginationItems.currentPage - 1),
            pageSize: this.paginationItems.perPage
          }, this.successLoadHistoryTed, this.failLoadHistoryTed)
      },
      successLoadHistoryTed(response) {
        this.timelineItems = response.data.list
        this.paginationItems.totalNumber = response.data.totalNumber
        this.paginationItems.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationItems.totalNumber > 0) {
          this.paginationItems.fromNumber = ((this.paginationItems.perPage * (this.paginationItems.currentPage - 1)) + 1)
          this.paginationItems.toNumber = ((this.paginationItems.fromNumber + this.timelineItems.length) - 1)
        } else {
          this.paginationItems.fromNumber = 0
          this.paginationItems.toNumber = 0
        }
      },
      failLoadHistoryTed(error) {
        this.timelineItems = []
        return
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      getUpdatedBalance() {
        updateBalance().then(() => {
          this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
          this.accountAvailableBalance = notNull(localStorage.getItem('accountAvailableBalance'), '0,00')
        })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-ted {
    .autocomplete__icon {
      display: none;
    }

    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .picture-row {
      margin-top: 20px;
      background-color: #5e5e5e;
    }

    .picture-container {
      margin: 5px auto;
      display: table;
    }

    .picture {
      float: left;
      width: 80px;
      height: 80px;
      background-color: #999;
      border: 1px solid #e3e3e3;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      transition: all .2s;
      -webkit-transition: all .2s;
    }

    .picture-text {
      float: right;
      margin-top: 20px;
      margin-left: 6px;
    }

    .clickable-rows {
      tbody tr td {
        cursor: pointer;
      }

      .el-table__expanded-cell {
        cursor: default;
      }
    }
  }
</style>
