<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Relatório de PLR</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <div class="row" style="margin-left: 0px">
                  <label>Mês</label>
                </div>
                <div class="row" style="margin-left: 0px">
                  <el-date-picker v-model="search.month" placeholder="Escolha um mês" type="month" format="MM/yyyy">
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-2">
                <fg-input label="Status">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             v-model="search.payed">
                    <el-option :value="null"
                               class="select-default"
                               label="Todos"/>
                    <el-option value=true
                               class="select-default"
                               label="Pagos"/>
                    <el-option value=false
                               class="select-default"
                               label="Não Pagos"/>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-sm-2">
                <fg-input label="Exibir">
                  <el-select
                    class="select-default"
                    v-model="pagination.perPage"
                    placeholder="Per page">
                    <el-option
                      class="select-default"
                      v-for="item in [5,10,25,50]"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-2">
                <p-button type="primary" style="margin-top: 25px;"
                          @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Filtrar
                </p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%">
                  <el-table-column :min-width="80" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.salePersonName}}</span>
                    </span>
                    </template>
                  </el-table-column>
<!--                  <el-table-column :min-width="30" label="1º PLR. %">-->
<!--                    <template slot-scope="props">-->
<!--                    <span>-->
<!--                      <span>{{props.row.firstCommissionPercent * 100}} %</span>-->
<!--                    </span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column :min-width="30" label="1º PLR. Total">-->
<!--                    <template slot-scope="props">-->
<!--                    <span>-->
<!--                      <span>R$ {{toMoney(props.row.firstCommissionValue)}}</span>-->
<!--                    </span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
                  <el-table-column :min-width="30" label="PLR. Gateway %">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.secondCommissionPercent * 100}} %</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="PLR. Gateway">
                    <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.secondCommissionValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="PLR. PP %">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.fixedCommissionPercent * 100}} %</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="PLR. PP">
                    <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.fixedCommissionValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Valor Total">
                    <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.totalValue)}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Pago">
                    <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+(props.row.payed?'6bd098':'ef8157')"></i>
                      <span> {{props.row.payed?'Sim': props.row.totalValue > 0 ? 'Não' : "N.A."}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="65" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="danger" size="sm" icon title="Pagar PLR"
                                @click="pay(props.row)" v-if="!props.row.payed &&  props.row.totalValue > 0">
                        <i class="fa-solid fa-file-import"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6 pagination-info">
                <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE {{pagination.totalNumber}} REGISTROS.</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                              :total="pagination.totalNumber" :click="this.loadData">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {DatePicker} from 'element-ui'
  import {toMoney} from "@/util/core.utils";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        search: {
          key: '',
          month: new Date(),
          payed: null
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        tableData: [],
        pagination: {
          perPage: 50,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        postWs("/bo/commercial/salesperson/listCommissions",
          true, null,
          {
            key: this.search.key,
            payed: this.search.payed,
            month: this.search.month.getMonth() + 1,
            year: this.search.month.getFullYear(),
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      pay(row){
        swal({
          title: 'Aviso!',
          text: "Deseja pagar R$ " + toMoney(row.totalValue) + " para "  + row.salePersonName + "? O valor será debitado desta conta!",
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-warning btn-fill',
          showCancelButton: true,
          confirmButtonText: 'Pagar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(result => {
          if(result){
            postWs("/bo/commercial/salesperson/payCommission",
              true, null,
              {
                id: row.id
              }, this.loadData,
              failWs)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
