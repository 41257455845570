export function isNotAuthorized(role) {
  return !isAuthorized(role)
}

export function isAuthorized(role) {
  let roles = JSON.parse(localStorage.getItem('roles'));
  for (let current in roles) {
    if (role.toLowerCase() === roles[current].replace(' ', '').toLowerCase()) {
      return true
    }
  }
  return false
}

export function isNotSomeAuthorization(roles) {
  return !isSomeAuthorization(roles)
}

export function isSomeAuthorization(roles) {
  for (let current in roles) {
    if (isAuthorized(roles[current])) {
      return true
    }
  }
  return false
}
