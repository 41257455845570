<template>
  <div class="dzs-acc-cancel">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Consultar por qrCode</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-2">
            <fg-input type="text"
                      label="Código Copia e Cola"
                      v-model="search.brCode">
            </fg-input>
          </div>
          <div class="col-md-2">
            <div class="row" style="margin-left: 0px">
              <label>De</label>
            </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="search.startDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsStart" format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
            <div class="col-md-2">
              <div class="row" style="margin-left: 0px">
                <label>Até</label>
              </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="search.endDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsStart" format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-12">
            <div class="row" v-if="data !== '' ">
              <div class="col-md-12" style="text-align: center">
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Usuário</label>
                    <p class="form-control-static">@{{data.username}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Valor</label>
                    <p class="form-control-static">R${{toMoney(data.value)}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Gerente de Contas</label>
                    <p class="form-control-static">@{{data.salePersonUsername}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {failWs, postWs} from "src/ws.service"
import swal from 'sweetalert2'
import {toMoney, isNullOrEmpty, notNull} from "src/util/core.utils"
import {formatDate} from "src/util/date.utils"
import {DatePicker} from 'element-ui'
import {Modal} from 'src/components/UIComponents'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  components: {
    PPagination,
    [DatePicker.name]: DatePicker,
    Modal
  },
  data() {
    return {
      modal: false,
      search: {
        brCode: '',
        startDate: '',
        endDate: '',
      },
      data: '',
      dateOptsStart: {
        disabledDate: this.disabledStartDate,
        shortcuts: [{
          text: 'Hoje',
          onClick(picker) {
            const date = new Date()
            picker.$emit('pick', date)
          }
        },
          {
            text: 'Ontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: 'Anteontem',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 48)
              picker.$emit('pick', date)
            }
          }]
      },
    }
  },
  watch:{
    '$route': 'resetDefaultValues'
  },

  methods: {
    toMoney,
    isNullOrEmpty,
    resetDefaultValues(){
      this.search.requestNumber = ''
      this.search.brCode = ''
      this.modal = false
      this.loadData()
    },
    find() {
      this.loadData()
    },
    loadData() {
      postWs("/bo/gateway/consult/created-pix",
        true, null,
        {
          brCode: this.search.brCode.trim(),
          startDate: notNull(this.search.startDate, '')===''?'':formatDate(this.search.startDate, "YYYYMMDD"),
          endDate: notNull(this.search.endDate, '')===''?'':formatDate(this.search.endDate, "YYYYMMDD"),
        }, this.successLoadData,
        (error) => {
          let text = 'Erro ao carregar os dados, tente novamente!'
          if(error.response.status === 404) {
            text = "Transação não encontrada para os dados informados!"
          }
          swal({
            title: 'Aviso!',
            text: text,
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
          this.data = ''
        })
      return this.pagination.currentPage
    },
    successLoadData(response) {
      this.data = response.data
      this.modal = true
    },
    fail(){
      swal('Erro', 'Erro ao carregar os dados, tente novamente!', 'error')
    },
    disabledStartDate(date) {
      let now = new Date()
      return date > now
    },
  }
}
</script>
<style lang="scss">
.dzs-acc-cancel {
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .input-group .form-control {
    padding-left: 2px !important;
  }

  .input-group .input-group-prepend .input-group-text {
    padding: 5px 0 5px 10px !important;
    font-size: 14px !important;
  }

  .cell {
    font-size: 12px !important;
  }

  th {
    color: black !important;
  }
}
</style>
