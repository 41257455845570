<template>
  <div class="dzs-transactions">
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 slot="header" class="card-title">
            Realizar Distribuição dos Valores do Online
          </h4>
          <div class="row">
            <div class="col-md-3">
              <div class="row" style="margin-left: 0px">
                <label>Data Início</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsStart" @input="validatePeriod"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row" style="margin-left: 0px">
                <label>Data Fim</label>
              </div>
              <div class="row" style="margin-left: 0px">
                <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                                :picker-options="dateOptsEnd"
                                format="dd/MM/yyyy">
                </el-date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <p-button @click.prevent="requestTransactions" style="margin-top: 24px">
                <i class="fas fa-search-dollar"></i> BUSCAR TRANSAÇÕES
              </p-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-show="showTransactions">
        <card>
          <div class="row">
            <div class="col-md-6">
              <p><b>Valor à Pagar da Primeira Parcela</b></p>
              <p>Valor Usuários: R$ {{toMoney(listTransactionsFirstInstallment.valueUser)}}</p>
              <p>Valor Lucro: R$ {{toMoney(listTransactionsFirstInstallment.valueProfit)}}</p>
              <p>Valor Profit Share: R$ {{toMoney(listTransactionsFirstInstallment.valueProfitShare)}}</p>
              <p>Valor Parceiros: R$ {{toMoney(listTransactionsFirstInstallment.valuePartner)}}</p>
              <p>Valor Custo Transação: R$ {{toMoney(listTransactionsFirstInstallment.valueCost)}}</p>
              <p>Valor Total à Pagar: R$ {{toMoney(listTransactionsFirstInstallment.valueTotal)}}</p>
            </div>
            <div class="col-md-6">
              <p><b>Valor à Pagar da Reserva Financeira</b></p>
              <p>Valor Usuários: R$ {{toMoney(listTransactionsSecondInstallment.valueUser)}}</p>
              <p>Valor Lucro: R$ {{toMoney(listTransactionsSecondInstallment.valueProfit)}}</p>
              <p>Valor Profit Share: R$ {{toMoney(listTransactionsSecondInstallment.valueProfitShare)}}</p>
              <p>Valor Parceiros: R$ {{toMoney(listTransactionsSecondInstallment.valuePartner)}}</p>
              <p>Valor Custo Transação: R$ {{toMoney(listTransactionsSecondInstallment.valueCost)}}</p>
              <p>Valor Total à Pagar: R$ {{toMoney(listTransactionsSecondInstallment.valueTotal)}}</p>
            </div>
            <div class="col-md-12" style="text-align: center">
              <p-button @click.prevent="distributeValues" style="margin-top: 24px">
                <i class="fas fa-arrow-up"></i> DISTRIBUIR VALORES
              </p-button>
            </div>
            <div class="col-md-12" v-show="showDistributeValues">
              <card>
                <div class="row">
                  <div class="col-md-6">
                    <p><b>Valores Pagos da Primeira Parcela</b></p>
                    <p>Número total de transações: {{distributeValuesFirstInstallment.numberOfTransactions}}</p>
                    <p>Valor Usuários: R$ {{toMoney(distributeValuesFirstInstallment.valueUser)}}</p>
                    <p>Valor Lucro: R$ {{toMoney(distributeValuesFirstInstallment.valueProfit)}}</p>
                    <p>Valor Profit Share: R$ {{toMoney(distributeValuesFirstInstallment.valueProfitShare)}}</p>
                    <p>Valor Parceiros: R$ {{toMoney(distributeValuesFirstInstallment.valuePartner)}}</p>
                    <p>Valor Custo Transação: R$ {{toMoney(distributeValuesFirstInstallment.valueCost)}}</p>
                    <p>Valor Total Pago: R$ {{toMoney(distributeValuesFirstInstallment.valueTotal)}}</p>
                  </div>
                  <div class="col-md-6">
                    <p><b>Valores Pagos da Reserva Financeira</b></p>
                    <p>Número total de transações: {{distributeValuesSecondInstallment.numberOfTransactions}}</p>
                    <p>Valor Usuários: R$ {{toMoney(distributeValuesSecondInstallment.valueUser)}}</p>
                    <p>Valor Lucro: R$ {{toMoney(distributeValuesSecondInstallment.valueProfit)}}</p>
                    <p>Valor Profit Share: R$ {{toMoney(distributeValuesSecondInstallment.valueProfitShare)}}</p>
                    <p>Valor Parceiros: R$ {{toMoney(distributeValuesSecondInstallment.valuePartner)}}</p>
                    <p>Valor Custo Transação: R$ {{toMoney(distributeValuesSecondInstallment.valueCost)}}</p>
                    <p>Valor Total Pago: R$ {{toMoney(distributeValuesSecondInstallment.valueTotal)}}</p>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import {Badge, TimeLine, TimeLineItem} from 'src/components/UIComponents'
  import {getTransactionName, getTransactionLogo} from 'src/components/Dashboard/Views/Transactions/timeline'
  import {callWs, failWs, postWs} from "src/ws.service"
  import {formatDate} from "src/util/date.utils"
  import {isNullOrEmpty, toMoney} from 'src/util/core.utils'
  import swal from 'sweetalert2'

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      TimeLine,
      TimeLineItem,
      Badge
    },
    data() {
      return {
        startDate: new Date(),
        endDate: new Date(),
        timelineItems: [],
        listPayments: [],
        listTransactionsFirstInstallment: {
          valueUser: 0,
          valueProfit: 0,
          valuePartner: 0,
          valueProfitShare: 0,
          valueCost: 0,
          valueTotal: 0,
          numberOfTransactions: 0,
        },
        distributeValuesFirstInstallment: {
          valueUser: 0,
          valueProfit: 0,
          valuePartner: 0,
          valueProfitShare: 0,
          valueCost: 0,
          valueTotal: 0,
          numberOfTransactions: 0,
        },
        listTransactionsSecondInstallment: {
          valueUser: 0,
          valueProfit: 0,
          valuePartner: 0,
          valueProfitShare: 0,
          valueCost: 0,
          valueTotal: 0,
          numberOfTransactions: 0,
        },
        distributeValuesSecondInstallment: {
          valueUser: 0,
          valueProfit: 0,
          valuePartner: 0,
          valueProfitShare: 0,
          valueCost: 0,
          valueTotal: 0,
          numberOfTransactions: 0,
        },
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        showTransactions: false,
        showDistributeValues: false,
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      toMoney,
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
      requestTransactions() {
        callWs("/bo-gateway/request-payment-transactions",
          "POST", null, true, null,null,
          this.loadTransactions,
          this.failTransactions)
      },
      loadTransactions(response) {
        this.listTransactionsFirstInstallment = response.data.firstInstallment
        this.listTransactionsSecondInstallment = response.data.secondInstallment
        this.showTransactions = true
      },
      failTransactions(error) {
        let text = 'Erro ao carregar dados das transações!'
        if(error.response.status == '412') {
          text = 'Erro de comunicação com a adquirente!'
        }
        swal({
          title: 'Erro',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'error'
        })
      },
      distributeValues() {
        callWs("/bo-gateway/distributed-values",
          "POST", null, true, null, null,
          this.loadDistributeValuess,
          this.failDistributeValues)
      },
      loadDistributeValuess(response) {
        this.distributeValuesFirstInstallment = response.data.firstInstallment
        this.distributeValuesSecondInstallment = response.data.secondInstallment
        this.showDistributeValues = true
      },
      failDistributeValues(error) {
        let text = 'Erro ao distribuir valores!'
        swal({
          title: 'Erro',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'error'
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .dzs-transactions {
    .btn.btn-info.btn-default {
      float: right;
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .btn.btn-default{
      background-color: #17a2b8 !important;
    }
    .btn.btn-default:hover{
      background-color: #0a7282 !important;
    }
    .el-input__inner[readonly] {
      background-color: #ffffff !important;
    }
  }
</style>
