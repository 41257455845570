<template>
  <div class="dzs-acc-edit">
    <card>
      <div class="row">
        <div class="col-md-12">
          <card>
            <div class="card-header">
              <h6>
                Enviar novo Documento
              </h6>
            </div>
            <div class="row card-body">
              <div class="col-md-2">
                <fg-input label="Identificação do Documento" name="Identificação" v-model="newDocName" type="text"
                          data-vv-scope="addDocScope" v-validate="{required: true}" :error="getError('addDocScope.Identificação')"/>
              </div>
              <div class="col-md-2">
                <p-button style="margin-top: 22px" @click.prevent="function (){$refs['selectFile'].click()}">
                  <i class="fa-regular fa-image"></i> {{ newFileName }}
                </p-button>
                <input v-validate="{required: true}" data-vv-scope="addDocScope" :error="getError('addDocScope.Arquivo')" name="Arquivo" type="file" ref="selectFile" style="display: none" accept=".png, .jpg, .jpeg, .pdf" @change="function (event){validateFile(event)}"/>
              </div>
              <div class="col-md-2">
                <p-button style="margin-top: 22px" type="primary" round @click.prevent="addFile()">
                  <i class="fa fa-upload"/> Adicionar
                </p-button>
              </div>
              <div class="col-md-6" v-if="fileToSendList.length > 0">
                <card>
                  <div class="card-header">
                    <h6>
                      Documentos a enviar
                    </h6>
                  </div>
                  <div class="row card-body" style="width: 100%">
                    <div class="col-md-12">
                      <el-table class="table-striped"
                                :data="fileToSendList"
                                style="width: 100%">
                        <el-table-column :min-width="90" label="Identificação">
                          <template v-slot="props">
                            <span>{{props.row.docName}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Nome do Arquivo">
                          <template v-slot="props">
                            <span>{{props.row.fileName}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Ação">
                          <template v-slot="props">
                            <p-button type="danger" size="sm" icon title="Remover arquivo" @click="remFile(props.row.id)">
                              <i class="fa-solid fa-xmark"></i>
                            </p-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </card>
          <div class="row">
            <div class="col-md-12">
              <card>
                <div class="card-header">
                  <h6>
                    Documentos Salvos
                  </h6>
                </div>
                <div class="row card-body">
                  <div class="col-md-12">
                    <el-table class="table-striped"
                              :data="fileList"
                              style="width: 50%; margin: auto">
                      <el-table-column :min-width="90" label="Documento">
                        <template v-slot="props">
                          <span>{{props.row.identification}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="20" label="Ação">
                        <template v-slot="props">
                          <p-button type="primary" size="sm" icon title="Visualizar" @click="viewDoc(props.row.id)">
                            <i class="fa-regular fa-eye"></i>
                          </p-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import {callWs, getWs, failWs, postWs} from 'src/ws.service'
import swal from "sweetalert2";

export default {
  name: 'commercial-client-extra-docs',
  props: {
    username: null,
  },
  components: {
  },
  created() {
    this.$emit("saveInterface", {save: () => this.save()})
    this.loadFiles();
  },
  data() {
    return {
      newDocName:'',
      newFileName:'Escolha um Arquivo',
      newFile: null,
      fileMaxSize: 5,
      fileToSendList:[],
      fileList: []
    }
  },
  methods: {
    validateFile(event) {
      if (event.target.files.length <= 0) {
        return
      } else if (event.target.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
        swal({
          title: 'Aviso!',
          text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
        return
      }
      this.newFile = event.target.files[0]
      this.newFileName = event.target.files[0].name
    },
    addFile(){
      this.$validator.validateAll('addDocScope').then(isValid => {
        if (!isValid) {
          return
        }
        this.fileToSendList.push({id: Date.now(), docName: this.newDocName, fileName: this.newFileName, file: this.newFile})
        this.newDocName = ''
        this.newFile = null
        this.newFileName = 'Escolha um Arquivo'
        this.$validator.reset({scope:'addDocScope'})
      })
    },
    remFile(fileId){
      let indexToRemove = -1
      this.fileToSendList.some((file, index) =>{
        if(file.id === fileId){
          indexToRemove = index
          return true;
        }
      })
      if(indexToRemove >= 0){
        this.fileToSendList.splice(indexToRemove, 1)
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    loadFiles(){
      getWs("/bo/commercial/dashboard/getDocs", true, {username: this.username},
      response => {
        if(typeof (response.data.response) == "undefined" ){
          this.fileList = response.data
        }
      }, failWs)
    },
    save(){
      if(this.fileToSendList.length > 0){
        this.doRecursiveSave(0)
      } else {
        this.$emit("saveFinished")
      }
    },
    doRecursiveSave(index){
      let formData = new FormData()
      formData.append("file", this.fileToSendList[index].file)
      callWs("/bo/commercial/dashboard/saveDoc", 'POST', {'Content-Type': 'multipart/form-data'}, true,
        {username: this.username, identification: this.fileToSendList[index].docName},
        formData,
        response => {
          if(this.fileToSendList.length - 1 === index){
            this.$emit("saveFinished")
            this.loadFiles()
            this.fileToSendList = []
          } else {
            this.doRecursiveSave(index+1)
          }
        },error => console.log(error))
    },
    viewDoc(idDoc){
      getWs('/bo/commercial/dashboard/getDoc', true, {id: idDoc},
        response => {
          let fileType = response.data.fileType.replace(" ", "").toLowerCase()
          let fileData = null
          if (fileType === 'pdf') {
            fileData = '<embed width="900" height="900" src="data:application/pdf;base64,' + response.data.image64 + '" />'
          } else if (fileType === 'jpg') {
            fileData = '<img src="data:image/jpeg;base64,' + response.data.image64 +'" />'
          } else {
            fileData = '<img src="data:image/' + fileType + ';base64,' + response.data.image64 + '" />'
          }

          swal({
            title: 'Documento!',
            html:
              '<div style="font-size: medium;">' +
              '   ' + response.data.identification +
              '   <hr>' +
              '   </br>' +
              '   <div style="text-align: center">' +
              '     <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A">Imagem: </span><br>' +
              '     <span style="overflow: auto"> ' + fileData + '</span>' +
              '   </div>' +
              '</div>' ,
            buttonsStyling: false,
            width: '50%',
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'info'
          })
        },
        error => {
          swal({
            title: 'Ops!',
            text: error.response.data.message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'error'
          })
        })
    },
  }
}

</script>

<style lang="scss">
</style>
