<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-lg-6">
        <card>
          <div class="card-header">
            <h4 style="text-align: center;">Colaboradores</h4>
          </div>
          <div class="card-body">
            <p>
              Nesta tela, você pode adicionar usuários como colaboradores da sua conta. Isso permitirá que eles
              consultem os extratos, mas apenas para visualização, sem qualquer permissão para realizar transações. Você
              pode adicionar quantos colaboradores precisar, garantindo que todos os envolvidos tenham acesso às
              informações financeiras de maneira segura e transparente.
            </p>
          </div>
        </card>
      </div>
      <!-- Bloco Dispositivo Não habilitado -->
      <div class="col-lg-6"
           v-if="!hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && !isNullOrEmpty(totpQrCodeUri)">
        <card style="background-color: #00d19a; text-align: center">
          <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não está cadastrado!</h4>
          <card align="center">
            <span>Para liberar suas transações siga os passos abaixo:</span>
            <div class="row" v-if="isNullOrEmpty(totpQrCodeUri)">
              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            </div>

            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b
                  style="color: #000000 !important;">SuitPay</b>.</span></p>
                <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                   target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                </a>
                <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                </a>

              </div>

              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span>
                </p>
                <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no
                  aplicativo </p>
                <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                <p><b>4</b> - Confirme a operação</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>
            <br>
            <div style="text-align: center">
              <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                        class="btn btn-danger">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
            </div>
          </card>
        </card>
      </div>

      <!-- Bloco Dispositivo Habilitado -->
      <div class="col-lg-6"
           v-if=" !hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
        <card style="background-color: #00d19a; text-align: center;">
          <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
          <card align="center" style="height: 100%">
            <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                <p><b>4</b> - Confirme a operação.</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>

            <span>Informe o <b>SuitID</b>: </span>
            <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

            <div class="row" v-if="!codeValid">
              <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
            </div>

            <div style="text-align: center">
              <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                        class="btn btn-danger">
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
              <p-button @click="()=>{validateCode()}"
                        class="btn btn-primary"
                        :disabled="totpConfirmBtnDisabled"
              >
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
          </card>
        </card>
      </div>
      <div v-if="hideTOTP" class="col-lg-6">
        <card>
          <div class="card-header">
            <h4 style="text-align: center;">Gerenciar Colaboradores</h4>
          </div>
          <div class="card-body">
            <form class="row" @submit.prevent="addCollaborator">
              <div class="col-md-7">
                <fg-input label="Usuário"
                          name="Usuário"
                          v-validate="{required: true}"
                          v-model="insert.username"
                ></fg-input>
              </div>
              <div class="col-md-5 d-flex align-items-end">
                <p-button type="success" native-type="submit">Adicionar</p-button>
              </div>
            </form>
            <div class="row">
              <div class="col-md-12">
                <el-table class="table-striped" :data="collaborators" style="margin: auto; width: 100%">
                  <el-table-column :min-width="35" label="Usuário">
                    <template v-slot="props">
                      <span>{{props.row.collaborator}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="50" label="Adicionado Em">
                    <template v-slot="props">
                      <span>{{formatDate(props.row.createdDate, "DD/MM/YYYY HH:mm")}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="Ação" class-name="td-actions">
                    <template v-slot="props">
                      <p-button type="primary" size="sm" icon title="Gerenciar Permissões" @click="openModal(props.row)">
                        <i class="fa-regular fa-eye"></i>
                      </p-button>
                      <p-button type="warning" size="sm" icon title="Remover da Lista de Colaboradores" @click="removeCollaborator(props.row.collaborator)">
                        <i class="fa-solid fa-trash"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </card>
        <card v-show="showPermissionsModal">
          <div class="card-header">
            <h4 style="text-align: center;">Permissões - @{{selectedCollaborator}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <el-table class="table-striped" :data="permissions" style="margin: auto; width: 100%">
                  <el-table-column :min-width="60" label="Relatório">
                    <template v-slot="props">
                      <span>{{permissionNames[props.row.permission]}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="20" fixed="right" align="center" label="Ativo">
                    <template v-slot="props">
                      <input type="checkbox" v-model="props.row.isActive" @click.prevent="updateCollaboratorPermission(props.row.permission, props.row.isActive)">
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {callWs, postWs} from "@/ws.service";
import swal from "sweetalert2";
import {formatDate} from "@/util/date.utils";
import {failWs} from "@/ws.service";
import {isNullOrEmpty, notNull} from "@/util/core.utils";

export default {
  name: 'manage-collaborators',
  data() {
    return {
      search: {
        username: ''
      },
      insert: {
        username: ''
      },
      collaborators: [],
      permissions: [],
      showPermissionsModal: false,
      selectedCollaborator: '',
      transactionAuthorizationTotp: 'false',
      validationCode: '',
      qrCode: '',
      totpQrCodeUri: '',
      totpManualCode: '',
      totpConfirmBtnDisabled: false,
      codeValid: true,
      hideTOTP: true,
      verifCode: null,
      totpCallback: null,
    }
  },
  created() {
    this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
  },
  mounted() {
    this.getCollaborators()
  },
  computed: {
    permissionNames() {
      return {
        "STATEMENT": "Extrato",
        "PIX_IN_GATEWAY": "Pix de Entrada Gateway",
        "PIX_OUT_GATEWAY": "Pix de Saída Gateway",
        "WEBHOOK": "Webhook",
        "CREDIT_CARD": "Cartão de Crédito"
      }
    }
  },
  methods: {
    isNullOrEmpty,
    formatDate,
    getCollaborators() {
      callWs('/collaborator/list',
        'POST',
        null,
        true,
        null,
        this.search,
        this.successGetCollaborators,
        this.failGetCollaborators
      )
    },
    successGetCollaborators(response) {
      this.collaborators = response.data
      this.clearSelectedCollaborator()
    },
    failGetCollaborators() {
      swal({
        title: 'Aviso!',
        text: 'Ocorreu um erro inesperado ao buscar colaboradores. Tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    addCollaborator() {
      if (this.insert.username.trim().length === 0) return
      callWs('/collaborator/insert',
        'POST',
        null,
        true,
        null,
        {
          username: this.insert.username,
          verifCode: this.verifCode
        },
        this.handlerThenAddCollaborator,
        this.failAddCollaborator
      )
    },
    handlerThenAddCollaborator(response) {
      if (!this.showTOTP(response, function () {
        return this.addCollaborator()
      })) {
        this.clearTOTP()
        this.successAddCollaborator()
        this.clearInput()
      }
    },
    successAddCollaborator(authentication) {
      this.authentication = authentication
      clearInterval(this.validateIntervalLoop)

      swal({
        title: 'Sucesso!',
        text: 'Colaborador adicionado com sucesso!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      }).catch(swal.noop)

      this.getCollaborators()
    },
    failAddCollaborator(error) {
      let text = null;
      if (error.response.data.response === 'NO_MOBILE_DEVICE_REGISTERED') {
        text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
      } else if (error.response.data.response === 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
        text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
      } else if (error.response.data.response === 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' || error.response.data.response === 'BLOCKED') {
        text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
      } else if (error.response.data.response === 'SOURCE_USER_INACTIVE') {
        text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
      } else if (error.response.status === 409) {
        text = 'Este usuário já está cadastrado como um colaborador!'
      } else if (error.response.status === 404) {
        text = 'Não foi possível encontrar um usuário com este nome!'
      }
      if (!isNullOrEmpty(text)) {
        swal({
          title: 'Aviso!',
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    getPermissions(collaborator) {
      callWs('/collaborator/list-permissions',
        'POST',
        null,
        true,
        null,
        {
          collaborator: collaborator
        },
        this.successGetPermissions,
        failWs
      )
    },
    successGetPermissions(response) {
      this.permissions = response.data
    },
    removeCollaborator(username) {
      callWs('/collaborator/remove',
        'POST',
        null,
        true,
        null,
        {
          username: username,
        },
        this.successRemoveCollaborator,
        this.failRemoveCollaborator
      )
    },
    successRemoveCollaborator() {
      this.getCollaborators()
    },
    failRemoveCollaborator() {
      swal({
        title: 'Aviso!',
        text: 'Ocorreu um erro inesperado ao remover colaborador. Atualize a página e tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    updateCollaboratorPermission(permission, isActive) {
      const username = localStorage.getItem('username')
      if (isActive) {
        this.doUpdateCollaboratorPermission(username, permission)
        return
      }

      swal({
        title: 'Termo de Condição',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: ` Estou ciente e concordo com o acesso do usuário @${username} ao relatório de ${this.permissionNames[permission]}.`,
        confirmButtonText: 'Confirmar',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-success btn-fill',
      }).then(result => {
        if (!result) {
          swal({
            type: 'warning',
            title: 'Atenção!',
            text: 'Você deve concordar com o termo para prosseguir!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            timer: 5000,
          }).catch(swal.noop)
          return
        }
          this.doUpdateCollaboratorPermission(username, permission)
      }).catch(swal.noop)
    },
    doUpdateCollaboratorPermission(username, permission) {
      callWs('/collaborator/update',
        'POST',
        null,
        true,
        null,
        {
          username,
          collaborator: this.selectedCollaborator,
          permission
        },
        this.successUpdateCollaboratorPermission,
        this.failUpdateCollaboratorPermission
      )
    },
    successUpdateCollaboratorPermission() {
      swal({
        title: 'Sucesso!',
        text: 'As permissões foram atualizadas com sucesso!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      }).catch(swal.noop)
      this.getPermissions(this.selectedCollaborator)
    },
    failUpdateCollaboratorPermission() {
      swal({
        title: 'Aviso!',
        text: 'Ocorreu um erro inesperado ao atualizar permissão. Tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      }).catch(swal.noop)
    },
    openModal(userCollaborator) {
      this.showPermissionsModal = true
      this.selectedCollaborator = userCollaborator.collaborator
      this.getPermissions(userCollaborator.collaborator)
    },
    clearTOTP() {
      this.qrCode = null
      this.totpQrCodeUri = null
      this.totpManualCode = null
      this.totpCallback = null
      this.verifCode = null
      this.validationCode = null
    },
    showTOTP(response, callback) {
      if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.code)) {
        this.hideTOTP = false;
        this.qrCode = response.data.code
        this.totpQrCodeUri = response.data.totpQRCodeURI
        this.totpManualCode = response.data.totpManualCode
        this.totpCallback = callback
        return true
      }
      return false
    },
    validateCode() {
      this.totpConfirmBtnDisabled = true;
      postWs("/verifcode/validate",
        true,
        null,
        {verifCode: this.qrCode, hashCode: this.validationCode},
        this.successValidateCode,
        this.failValidateCode
      )
    },
    successValidateCode(response) {
      this.totpConfirmBtnDisabled = false
      this.verifCode = response.data.verifCode
      this.hideTOTP = true
      this.qrCode = ''
      this.totpCallback()
    },
    failValidateCode() {
      this.totpConfirmBtnDisabled = false
      swal({
        title: 'Aviso!',
        text: 'Código inválido!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    clearInput() {
      this.insert.username= ''
    },
    clearSelectedCollaborator() {
      this.selectedCollaborator = ''
      this.showPermissionsModal = false
    }
  }
}
</script>

