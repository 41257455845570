<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Buscar Gestor</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Usuário</label>
                <fg-input v-model="search.name">
                  <span slot="addonLeft">@</span>
                </fg-input>
              </div>
              <div class="col-md-2">
                <fg-input label="Região">
                  <el-select size="large"
                             placeholder="Selecionar"
                             class="select-default"
                             filterable
                             v-model="search.region">
                    <el-option
                      v-for="item in regions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-4">
                <p-button type="primary" style="margin-top: 25px;"
                          @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Filtrar
                </p-button>
                <p-button style="margin-top: 25px;"
                          @click="searchUser" type="info">
                  <i slot="label" class="fas fa-plus"></i>
                  Adicionar
                </p-button>
              </div>
            </div>
            <div class="row" v-if="userToAdd.destinationName != null">
              <div class="col-md-12" style="text-align: center">
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">Nome</label>
                    <p class="form-control-static">{{userToAdd.destinationName}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">CPF/CNPJ</label>
                    <p class="form-control-static">{{userToAdd.destinationSocialNumber}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <label class="control-label">E-mail</label>
                    <p class="form-control-static">{{userToAdd.email}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 m-auto" style="text-align: center">
                    <fg-input label="Regiões Ativas">
                      <el-select size="large"
                                 placeholder="Selecionar"
                                 class="select-default"
                                 multiple
                                 v-model="userToAdd.regions">
                        <el-option
                          v-for="item in activeRegions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12" style="text-align: center">
                    <p-button type="success" style="margin-top: 25px;"
                              @click="addManager">
                      <i slot="label" class="far fa-floppy-disk"></i>
                      Salvar
                    </p-button>
                    <p-button type="danger" style="margin-top: 25px;"
                              @click="clearManagerToAdd">
                      <i slot="label" class="nc-icon nc-simple-remove"></i>
                      Cancelar
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%">
                  <el-table-column :min-width="60" label="Usuário">
                    <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Nome">
                    <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="70" label="Regiões Ativas">
                    <template slot-scope="props">
                    <span>
                      <span v-for="region in props.row.regions">{{region.name }} - </span>
                    </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Ações" :width="230" fixed="right" class-name="td-actions">
                    <template slot-scope="props">
                      <p-button type="warning" size="sm" icon title="Editar"
                                @click="edit(props.row)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </p-button>

                      <p-button type="danger" size="sm" icon title="Remover Gerente"
                                @click="deleteManager(props.row)">
                        <i class="fa-regular fa-trash-can"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Modal
    },
    data() {
      return {
        search: {
          name: '',
          region: '',
          enabled: null,
        },

        tableData: [],
        regions:[],
        activeRegions:[],
        userToAdd: {
          username: null,
          destinationName : null,
          destinationSocialNumber : null,
          email : null,
          regions: []
        }
      }
    },
    watch:{
      '$route': 'resetDefaultValues'
    },

    mounted() {
      this.loadData()
    },
    methods: {
      resetDefaultValues(){
        this.search.name = ''
        this.search.region = null
        this.search.enabled = null
        this.tableData = [];
        this.loadData()
      },
      find() {
        this.loadData()
      },
      loadData() {
        postWs("/bo/commercial/salesperson/managers",
          true, null,
          {
            username: this.search.name,
            region: this.search.region,
          }, this.successLoadData,
          failWs)

        postWs("/bo/commercial/salesperson/regions",
          true, null,
          {}, response => this.regions = this.loadRegions(response, true),
        failWs)

        postWs("/bo/commercial/salesperson/regions",
          true, null,
          {enabled:true}, response => this.activeRegions = this.loadRegions(response),
          failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
      },
      loadRegions(response, addNull){
        let regionList = []
        if(addNull){
          regionList.push({value: null, label: "Todas"})
        }
        for(let i = 0; i < response.data.list.length; i++){
          regionList.push({value:response.data.list[i].name, label:response.data.list[i].name})
        }
        return regionList
      },
      clearManagerToAdd(){
        this.userToAdd.username = null
        this.userToAdd.destinationName = null
        this.userToAdd.destinationSocialNumber = null
        this.userToAdd.email = null
        this.userToAdd.regions = []
      },
      searchUser() {
        this.clearManagerToAdd()
        postWs("/acc/find",
          true, {username: this.search.name, self: true}, null,
          (response) => {
            this.userToAdd.username = this.search.name
            this.userToAdd.destinationName = response.data.destinationName
            this.userToAdd.destinationSocialNumber = response.data.destinationSocialNumber
            this.userToAdd.email = response.data.email
          }, (error) => {
            this.clearManagerToAdd();
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-warning btn-fill',
                type: 'warning'
              })
            }
          })
      },
      addManager(){
        postWs("/bo/commercial/salesperson/manager",
          true, null,
          {
            username: this.userToAdd.username,
            regions: this.userToAdd.regions,
          }, response => {
            this.search.name = ''
            this.clearManagerToAdd()
            this.loadData()
          },
          failWs)
      },
      deleteManager(account) {
        swal({
          title: 'Remover Gestor',
          text: "Você realmente remover o gestor?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Remover',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          this.callRemoveWs(account)
        }, (dismiss) => {

        })
      },
      callRemoveWs(account) {
        postWs("/bo/commercial/salesperson/delete-manager",
          true, null,
          {username: account.username},
            this.loadData, failWs)
      },
      edit(row) {
        this.search.name = row.username;
        this.searchUser()
        for(let i = 0 ; i < row.regions.length; i++){
          this.userToAdd.regions.push(row.regions[i].name);
        }
      },
    }
  }
</script>
<style lang="scss" scoped>

</style>
