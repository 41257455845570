<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Extrato De Câmbio</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-2">
            <div class="row" style="margin-left: 0px">
              <label>Data Início</label>
            </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="startDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsStart" @input="validatePeriod"
                              format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row" style="margin-left: 0px">
              <label>Data Fim</label>
            </div>
            <div class="row" style="margin-left: 0px">
              <el-date-picker v-model="endDate" type="date" placeholder="Escolha uma data"
                              :picker-options="dateOptsEnd"
                              format="dd/MM/yyyy">
              </el-date-picker>
            </div>
          </div>
          <div class="col-md-3">
            <fg-input label="Status">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.requestStatus">
                <el-option :value="null"
                           class="select-default"
                           label="Todos"/>
                <el-option value="WAITING"
                           class="select-default"
                           label="Aguardando Aprovação"/>
                <el-option value="SENT"
                           class="select-default"
                           label="Enviado"/>
                <el-option value="PROCESSED"
                           class="select-default"
                           label="Processado"/>
                <el-option value="CANCELED"
                           class="select-default"
                           label="Cancelado"/>
                <el-option value="CANCELED_BY_ERROR"
                           class="select-default"
                           label="Cancelado Por Erro"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [25,50,100]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px;"
                      @click="find">
              <i class="fa-solid fa-magnifying-glass"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      ref="trTable"
                      @row-click="(row, column, event) => expandRow(row)"
                      style="width: 100%">
              <el-table-column :min-width="80" label="Status">
                <template slot-scope="props">
                    <span>
                      <span>{{reqStatusToLabel(props.row.status)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Solicitado em">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="40" label="Moeda">
                <template slot-scope="props">
                    <span>
                      <span>{{(props.row.currency)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Vlr Câmbio">
                <template slot-scope="props">
                    <span>
                      <span>{{toMoney(props.row.foreignCurrencyValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Vlr Real">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.brlValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Cot. Moeda">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.priceQuote)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Taxa">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.profit)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <card>
                    <div class="row">
                      <div class="col-md-9">
                        <div>
                          <h5>Dados do Câmbio</h5>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <p>Beneficiário: {{props.row.beneficiary}}</p>
                            <p>Moeda: {{props.row.currency}}</p>
                            <p>Valor em moeda estrangeira: {{toMoney(props.row.foreignCurrencyValue)}}</p>
                            <p>Cotação: R$ {{toMoney(props.row.priceQuote)}}</p>
                            <p>Descrição: {{props.row.description}}</p>
                            <p>País de Destino: {{props.row.country}}</p>
                          </div>
                          <div class="col-md-6">
                            <p>SWIFT/IBC: {{props.row.destinationSwiftCode}}</p>
                            <p>IBAN: {{props.row.destinationIbanCode}}</p>
                            <p>Banco: {{props.row.destinationBankCode}} - {{props.row.destinationBankName}}</p>
                            <p>Agência: {{props.row.destinationAgency}} - {{props.row.destinationAgencyDv}}</p>
                            <p>Conta: {{props.row.destinationAccountNumber}} - {{props.row.destinationAccountNumberDv}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info" style="margin-top: 10px">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6" style="margin-top: 10px">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {toMoney} from "src/util/core.utils"
  import {DatePicker} from 'element-ui'
  import {daysBetween, formatDate} from "src/util/date.utils"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        username: '',
        startDate: new Date(),
        endDate: new Date(),
        dateOptsStart: {
          disabledDate: this.disabledStartDate,
          shortcuts: [{
            text: 'Hoje',
            onClick(picker) {
              const date = new Date()
              picker.$emit('pick', date)
              this.endDate = date
            }
          },
            {
              text: 'Ontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
                this.endDate = date
              }
            },
            {
              text: 'Anteontem',
              onClick(picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 48)
                picker.$emit('pick', date)
                this.endDate = date
              }
            }]
        },
        dateOptsEnd: {
          disabledDate: this.disabledEndDate
        },
        lastExpandedRow: false,
        search: {
          requestStatus: null
        },
        exchangeData: {
          beneficiary: '',
          country: '',
          destinationBankCode: '',
          destinationBankName: '',
          destinationAgency: '',
          destinationAgencyDv: '',
          destinationAccountNumber: '',
          destinationAccountNumberDv: '',
          currency: '',
          foreignCurrencyValue: '',
          priceQuote: '',
          destinationSwiftCode: '',
          destinationIbanCode: '',
        },
        tableData: [],
        pagination: {
          perPage: 25,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
      }
    },

    created() {
      this.username = localStorage.getItem("usernameDisplay")
      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        if(daysBetween(this.startDate, this.endDate) > 31){
          swal({
            title: 'Aviso!',
            text: 'O período selecionado deve ser de no máximo 31 dias!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        } else {
          this.pagination.currentPage = 1
          this.loadData()
        }
      },
      loadData() {
        postWs("/exchange/last-requested-exchange",
          true, null,
          {
            username: this.username,
            status: this.search.requestStatus,
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage,
            startDate: formatDate(this.startDate, "DD/MM/YYYY"),
            endDate: formatDate(this.endDate, "DD/MM/YYYY"),
          }, this.successLoadData, this.failLoadData)
        return this.pagination.currentPage
      },
      failLoadData(error) {
        swal({
          title: 'Aviso!',
          text: "Não foi encontrada solicitação para esse usuário.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      successLoadData(response) {
        this.tableData = response.data.requests
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      reqStatusToLabel(reqStatus) {
        if (reqStatus == 'WAITING') {
          return 'Aguardando Aprovação'
        } else if (reqStatus == 'CAM_ISSUED') {
          return 'Aguardando Aprovação'
        } else if (reqStatus == 'SENT') {
          return 'Enviado'
        } else if (reqStatus == 'PROCESSED') {
          return 'Processado OK'
        } else if (reqStatus == 'CANCELED') {
          return 'Cancelado'
        } else if (reqStatus == 'CANCELED_BY_ERROR') {
          return 'Cancelado por Erro'
        }
        return '??'
      },
      expandRow(row) {
        if (this.lastExpandedRow != null) {
          this.$refs.trTable.toggleRowExpansion(this.lastExpandedRow, false);
          this.value = 0;
        }
        if (this.lastExpandedRow == row) {
          this.$refs.trTable.toggleRowExpansion(row, false);
          this.lastExpandedRow = null
          this.value = 0;
        } else {
          this.lastExpandedRow = row
          this.$refs.trTable.toggleRowExpansion(row, true);
        }
      },
      validatePeriod() {
        this.endDate = this.startDate
      },
      disabledStartDate(date) {
        let now = new Date()
        return date > now
      },
      disabledEndDate(date) {
        let now = new Date()
        return date > now || date < this.startDate
      },
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }
</style>
