<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-6">
        <card>
          <div class="card-header">
            <h4>Bloqueio de Destinatários <b>Cash-Out</b> </h4>
          </div>
          <p style="padding-left: 50px">
              Com a funcionalidade de bloqueios para Pix de saída, você ganha mais controle e segurança sobre as transações financeiras da sua empresa.<br>
              Agora, é possível bloquear transferências de saída via Pix para determinadas contas bancárias de sua escolha, oferecendo uma camada adicional de proteção para o seu negócio.<br>
            Como funciona?
            <br>
            Você pode adicionar filtros baseados em:
            <div class="row" style="padding-left: 70px">
                <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Instituição Financeira</strong> Cada instituição possui um código único. Ao incluir um código específico,
                  você estará restringindo qualquer Pix de saída para todas as contas daquele banco.</p>
                <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Agência</strong> Se preferir, você pode refinar ainda mais suas restrições bloqueando transações para também uma agência bancária específica.</p>
                <p><i style="color: #17a2b8" class="fas fa-arrow-circle-right"></i> <strong>Número da Conta</strong> Para maior precisão, é possível bloquear transferências diretamente para um número de conta específico.</p>
            </div>
          </p>
        </card>
      </div>
      <div class="col-md-6">
        <div class="row">
          <card class="col-sm-12">
            <div class="card-header">
              <h4>Inserir Bloqueio</h4>
            </div>
            <div class="card-body row">
              <div class="col-sm-12">
                <label class="control-label">Instituição Financeira</label>
                <autocomplete v-validate="{required: true}"
                              name="Banco"
                              placeholder="Selecionar"
                              :source="bankIspb"
                              input-class="form-control"
                              results-value="code"
                              :results-display="displayBankName"
                              @selected="selectBank">
                </autocomplete>
                <b v-show="ispbIsNull" style="color: #b23e3e"> *A instituição financeira é obrigatória*</b>
              </div>
              <div class="col-sm-6" style="padding-top: 5px">
                <label class="control-label">Agência</label>
                <fg-input v-model="filter.agencyCode" style="width: 100%"
                          v-validate="{required: false, agencyBankValid: true}" type="tel" maxlength="5"
                          :error="getError('Agência Destino')"
                          name="Agência Destino" v-mask="['#####']">
                </fg-input>
              </div>
              <div class="col-sm-6" style="padding-top: 5px">
                <label class="control-label">Número da Conta</label>
                <fg-input v-model="filter.accountNumber" style="width: 100%"
                          v-validate="{required: false}" type="tel"
                          :error="getError('Conta Destino')"
                          name="Conta Destino" v-mask="['############']">
                </fg-input>
              </div>
              <div class="col-md-4 ml-auto mr-auto">
                <p-button style="margin-top: 10px;"
                          type="info"
                          @click="createFilter">
                  Adicionar Filtro
                </p-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div class="row">
      <card class="col-sm-12">
        <div class="card-header">
          <h3>Bloqueios</h3>
        </div>
        <div class="card-body">
          <div class="row col-md-12">
            <el-table class="table-striped" :data="filters" style="margin: auto; width: 100%">
              <el-table-column :min-width="90" label="Ativo" align="center">
                <template v-slot="props">
                  <input type="checkbox" v-model="props.row.enabled" @change="updateFilter(props.row)">
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Instituição Financeira">
                <template v-slot="props">
                  <span>{{getBank(props.row.ispb)}}</span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Agência">
                <template v-slot="props">
                  <span>{{props.row.agencyCode}}</span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Número da Conta">
                <template v-slot="props">
                  <span>{{props.row.accountNumber}}</span>
                </template>
              </el-table-column>
              <el-table-column :min-width="90" label="Data de Criação">
                <template v-slot="props">
                  <span>{{props.row.createdDate}}</span>
                </template>
              </el-table-column>
              <el-table-column :width="240" fixed="right" class-name="td-actions" label="Deletar">
                <template slot-scope="props">
                  <p-button type="danger" size="sm" icon title="Deletar"
                            @click="deleteFilter(props.row.filterId)">
                    <i class="fas fa-trash"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>

import {isNullOrEmpty} from "@/util/core.utils";
import {postWs, getWs} from "src/ws.service"
import swal from 'sweetalert2'
import Autocomplete from "vuejs-auto-complete";

export default {
  components: {Autocomplete},
  data() {
    return {
      filter: {
        ispb: '',
        agencyCode: '',
        accountNumber: ''
      },
      filters: [],
      bankIspb: [],
      ispbIsNull: false
    }
  },
  mounted() {
    this.getFilters()
  },
  created() {
    this.$validator.extend('agencyBankValid', {
      getMessage: field => ('O ' + field + ' é inválido.'),
      validate: (value) => {
        if (isNullOrEmpty(value)) {
          return false
        }
        if(/^0+$/.test(value)) {
          return false
        }
        return true;
      }
    })
    this.getBanksIspb()
  },
  methods: {
    getBanksIspb() {
      getWs("/transaction/get-bacen-institutions", true, null,
        (response) => {
          this.bankIspb = response.data
        },(error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao buscar códigos ISPB',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    displayBankName(bank) {
      return bank.ispbCode + ' - ' + bank.name
    },
    getBank(ispb){
      return this.bankIspb.find(objeto => objeto.ispbCode === ispb).ispbCode + " - " + this.bankIspb.find(objeto => objeto.ispbCode === ispb).name;
    },
    selectBank(bank) {
      this.filter.ispb = bank.selectedObject.ispbCode
    },
    resetDefaultValues(){
      this.filter.agencyCode = ''
      this.filter.accountNumber = ''
      this.ispbIsNull = false
    },
    getFilters(){
      getWs("/pix-bank-blacklist/list",
        true,
        {
          username: localStorage.getItem("username"),
        },
        (response) => {
          this.filters = response.data
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao buscar filtros! Entre em contato com o suporte!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    createFilter() {
      if(this.filter.ispb === ''){
        this.ispbIsNull = true
        return
      }
      postWs("/pix-bank-blacklist/create",
        true, null,
        {
          username: localStorage.getItem("username"),
          ispb: this.filter.ispb,
          agencyCode: this.filter.agencyCode,
          accountNumber: this.filter.accountNumber
        },
        (response) => {
          swal({
            title: 'Sucesso!',
            text: 'Filtro adicionado com sucesso',
            type:'success',
          })
          this.getFilters()
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao criar filtro! Entre em contato com o suporte!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
      this.resetDefaultValues()
    },
    updateFilter(filter){
      postWs("/pix-bank-blacklist/update",
        true, null,
        {
          filterId: filter.filterId,
          enabled: filter.enabled
        },
        (response) => {
          this.getFilters()
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao atualizar filtro!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    deleteFilter(filterId){
      postWs("/pix-bank-blacklist/delete",
        true, null,
        {
          filterId: filterId
        },
        (response) => {
          this.getFilters()
        }, (error) => {
          swal({
            title: 'Aviso!',
            text: 'Erro ao deletar filtro!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        })
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
