<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Gerenciar Modelos</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-5">
            <label class="control-label">Nome do Modelo</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-sm-2">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="success" style="margin-top: 25px"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
          <div class="col-md-3">
            <p-button type="success" style="margin-top: 25px; float: right"
                      @click="insertForm">
              <i slot="label" class="nc-icon nc-simple-add"></i>
              Novo Modelo
            </p-button>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="30" label="ID">
                <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Status">
                <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Valor de Aluguel">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.leaseValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="Valor de Compra">
                <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.purchaseValue)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="80" label="Marca">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.brand.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="70" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="warning" size="sm" icon title="Editar"
                            @click="edit(props.row.id, props.row.name, props.row.enabled, props.row.leaseValue,
                            props.row.purchaseValue, props.row.brand)">
                    <i class="fas fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-show="formEditShow" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4 v-if="id!=''">Editar o Modelo <b>{{name}}</b></h4>
        <h4 v-if="id==''">Inserir Novo Modelo</h4>
        <form>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <fg-input type="text"
                          maxlength="100"
                          label="Nome do Modelo"
                          name="Nome do Modelo"
                          v-validate="{required: true}"
                          :error="getError('Nome do Modelo')"
                          v-model="name">
                </fg-input>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div style="float: left; width: 80%">
                      <label class="control-label">Marca</label>
                      <label class="control-label" style="float: right">
                        <a href="#" @click="loadNewDataBrands">
                          <i slot="label" class="fas fa-history"></i> Consultar Lista de Marcas</a>
                      </label>
                      <fg-input v-model="brandName"
                                style="width: 100%"
                                name="Marca"
                                v-validate="{required: true}"
                                :error="getError('Marca')">
                        <template slot="addonRight"><i class="fas fa-check" style="color: #156800" v-show="brandCheck"></i>
                          <i class="fas fa-times" style="color: #156800" v-show="brandNotFound"></i></template>
                      </fg-input>
                    </div>
                    <p-button type="primary" style="margin-top: 25px; float: left" @click="searchDataBrand">
                      <i slot="label" class="fas fa-search"></i>
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 20px">
              <div class="col-sm-6">
                <label>Valor do Aluguel</label>
                <money class="form-control input-lg"
                       v-model="leaseValue"
                       v-bind="money">
                </money>
              </div>
              <div class="col-sm-6">
                <label>Valor de Compra</label>
                <money class="form-control input-lg"
                       v-model="purchaseValue"
                       v-bind="money">
                </money>
              </div>
            </div>
            <div class="row form-group" style="margin-top: 20px">
              <div class="col-sm-3">
                <p-checkbox class="text-left" v-model="enabled">
                  <b>Modelo Ativo</b>
                </p-checkbox>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" @click.prevent="insertUpdateBrand">Salvar</p-button>
          </div>
        </form>
      </card>
    </div>
    <div class="col-md-12 card" v-show="formSearchBrand" style="max-width: 800px; margin: 0 auto">
      <div class="card-header">
        <h4>Selecionar Marca</h4>
      </div>
      <div class="card-body row">
        <div class="col-md-6">
          <label class="control-label">Nome da Marca</label>
          <fg-input v-model="searchBrand.key"/>
        </div>
        <div class="col-sm-3">
          <fg-input label="Exibir">
            <el-select
              class="select-default"
              v-model="paginationBrands.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in [5,10,25,50]"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-md-3">
          <p-button type="success" style="margin-top: 25px"
                    @click="findBrands">
            <i slot="label" class="nc-icon nc-check-2"></i>
            Buscar
          </p-button>
        </div>
        <div class="col-sm-12 mt-2 clickable-rows">
          <el-table class="table-striped"
                    :data="tableDataBrands"
                    @row-click="selectDestination"
                    style="width: 100%">
            <el-table-column :min-width="60" label="ID">
              <template slot-scope="props">
                    <span>
                      <span><b>#{{props.row.id}}</b></span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="100" label="Nome">
              <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" label="Status">
              <template slot-scope="props">
                    <span>
                      <span v-if="props.row.enabled">ATIVO</span>
                      <span v-if="!props.row.enabled">INATIVO</span>
                    </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">EXIBINDO DO {{paginationBrands.fromNumber}} AO {{paginationBrands.toNumber}}, DE
            {{paginationBrands.totalNumber}} REGISTROS.</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="paginationBrands.currentPage"
                        :per-page="paginationBrands.perPage"
                        :total="paginationBrands.totalNumber"
                        :click="this.loadDataBrands">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {moneyToFloat, toMoney} from "../../../../../util/core.utils"
  import {Money} from 'v-money'


  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination,
      Money
    },
    data() {
      return {
        tableDataBrands: [],
        search: {
          key: ''
        },
        searchBrand: {
          key: ''
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        id: '',
        name: '',
        enabled: true,
        leaseValue: 0,
        purchaseValue: 0,
        leaseValueForm: '',
        purchaseValueForm: '',
        brandId: '',
        brandName: '',
        brandEnabled: false,
        brandCheck: false,
        brandNotFound: false,
        formSearchBrand: false,
        formEditShow: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        paginationBrands: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        }
      }
    },
    created() {
      this.$validator.extend('leaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.$validator.extend('purchaseValueValid', {
        getMessage: field => (field + ' informada é inválida, informe um valor maior que Zero.'),
        validate: (value) => {
          if (moneyToFloat(value) == 0) {
            return false
          } else {
            return true
          }
        }
      })

      this.loadData()
    },
    methods: {
      toMoney,
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.id = ''
        postWs("/bo/card-terminal/list-card-terminal-model",
          true, null,
          {
            enabled: true,
            key: this.search.key,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      edit(id, name, enabled, leaseValue, purchaseValue, brand){
        this.formEditShow = true
        this.id = id
        this.name = name
        this.enabled = enabled
        this.leaseValue = leaseValue
        this.purchaseValue = purchaseValue
        this.brandName = brand.name
        this.brandId = brand.id
        this.brandEnabled = brand.enabled
        this.brandCheck = true
      },
      insertUpdateBrand(){
        this.leaseValueForm = (this.leaseValue)
        this.purchaseValueForm = (this.purchaseValue)
        this.$validator.validateAll().then(isValid => {
          if (!isValid) {
            return
          }
          if(!this.brandCheck){
            swal({
              title: 'Aviso!',
              text: 'Favor validar a Marca!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            return
          }
          callWs("/bo/card-terminal/card-terminal-model",
            "POST", null, true, null,
            {id: this.id, name: this.name, enabled: this.enabled, leaseValue: this.leaseValueForm,
              purchaseValue: this.purchaseValueForm, brand:{id: this.brandId, name: this.brandName, enabled: this.brandEnabled}},
            () => {
              swal({
                title: 'Sucesso!',
                text: 'Dados salvos com sucesso.',
                buttonsStyling: true,
                timer: 10000,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'success'
              })
              this.formEditShow = false
              this.loadData()
            }, (error) => {
              if (error.response.status == 409) {
                swal({
                  title: 'Aviso!',
                  text: 'Nome já existe para outro Modelo cadastrado!',
                  buttonsStyling: true,
                  timer: 5000,
                  confirmButtonClass: 'btn btn-warning btn-fill',
                  type: 'warning'
                })
                return
              }
              swal({
                title: 'Erro!',
                text: 'Erro interno, verifique os dados e tente novamente!',
                buttonsStyling: true,
                timer: 5000,
                confirmButtonClass: 'btn btn-danger btn-fill',
                type: 'error'
              })
            })
        })
      },
      insertForm(){
        this.id = ''
        this.enabled = true
        this.name = ''
        this.brandName = ''
        this.brandId = ''
        this.brandCheck = false
        this.leaseValueForm = ''
        this.purchaseValueForm = ''
        this.leaseValue = 0
        this.purchaseValue = 0
        this.formEditShow = true
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      findBrands() {
        this.paginationBrands.currentPage = 1
        this.loadDataBrands()
      },
      loadNewDataBrands(){
        this.brandName = ''
        this.searchBrand.key = ''
        this.loadDataBrands()
      },
      loadDataBrands() {
        postWs("/bo/card-terminal/list-card-terminal-brand",
          true, null,
          {
            enabled: null,
            key: this.searchBrand.key,
            pageNumber: this.paginationBrands.currentPage - 1,
            pageSize: this.paginationBrands.perPage
          }, this.successLoadDataBrands,
          failWs)
        return this.paginationBrands.currentPage
      },
      successLoadDataBrands(response) {
        this.formEditShow = false
        this.formSearchBrand = true
        this.tableDataBrands = response.data.list
        this.paginationBrands.totalNumber = response.data.totalNumber
        this.paginationBrands.pageMaxNumber = response.data.pageMaxNumber
        if (this.paginationBrands.totalNumber > 0) {
          this.paginationBrands.fromNumber = ((this.paginationBrands.perPage * (this.paginationBrands.currentPage - 1)) + 1)
          this.paginationBrands.toNumber = ((this.paginationBrands.fromNumber + this.tableDataBrands.length) - 1)
        } else {
          this.paginationBrands.fromNumber = 0
          this.paginationBrands.toNumber = 0
        }
      },
      searchDataBrand() {
        postWs("/bo/card-terminal/search-card-terminal-brand",
          true, {name: this.brandName},
          null, this.successSearchBrand,
          () => {
            swal({
              title: 'Aviso!',
              text: 'Nenhuma marca encontrada com esse nome!',
              buttonsStyling: true,
              timer: 5000,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
            this.brandNotFound = true
            this.brandCheck = false
          })
      },
      successSearchBrand(response) {
        this.tableDataBrands = response.data
        this.brandId = this.tableDataBrands.id
        this.brandName = this.tableDataBrands.name
        this.brandEnabled = this.tableDataBrands.enabled
        this.brandNotFound = false
        this.brandCheck = true
      },
      selectDestination(destination) {
        this.brandId = destination.id
        this.brandName = destination.name
        this.brandEnabled = destination.enabled
        this.formEditShow = true
        this.formSearchBrand = false
        this.brandNotFound = false
        this.brandCheck = true
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 5px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .input-group-text {
      padding: 5px 5px 5px 5px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .clickable-rows {
    tbody tr td {
      cursor: pointer;
    }

    .el-table__expanded-cell {
      cursor: default;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
