<template>
  <div class="dzs-card row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-xl-3">
          <card style="height: 100%">
            <h3 slot="header" class="card-title text-center font-weight-bold">
              Cartão {{cardType === 'virtual' ? 'Virtual' : 'Físico'}}
            </h3>
              <div class="row">
                <div class="card-body" style="margin-top: -30px;  padding-top: 0">
                  <div class="row">
                    <div class="col-lg-12">
                      <img class="rounded mx-auto d-block w-50 p-3" v-if="cardType === 'virtual'" src="/static/img/creditcard/BlackCardSuitPay.png">
                      <img class="rounded mx-auto d-block w-50 p-3" v-if="cardType === 'physical'" src="/static/img/creditcard/BlackCardSuitPay.png">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-10 pt-3 mx-auto d-block">
                      <p>
                        <p-progress :value="getProportionalPercent(maxCardLimit, currentCardCount)" type="primary" style="height: 18px; border-radius: 15px;"></p-progress>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <p class="text-center" style="font-size: 11px">
                        Cartões emitidos: {{currentCardCount}}
                        <br>
                        Cartões disponíveis: {{maxCardLimit}}
                        <span v-if="cardType=='physical'" style="font-weight: bold;"><br>Custo por cartão físico: R$ 29,90 </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!requestNewCard">
                <div class="row">
                  <div class="card-body">
                    <h5 class="text-center"> Mais benefícios para você</h5>
                    <div class="row">
                      <div class="col-sm-12 align-items-center">
                        <div class="row justify-content-sm-center align-items-center" style="min-height: 30px">
                          <div class="col-sm-auto">
                            <i class="fa-solid fa-check text-primary"></i>
                          </div>
                          <div class="col-sm-8" style="font-size: x-small; font-weight: bold">
                            Use em qualquer lugar do mundo que aceite Mastercard online
                          </div>
                        </div>

                        <div class="row justify-content-sm-center align-items-center" style="min-height: 30px">
                          <div class="col-sm-auto">
                            <i class="fa-solid fa-check text-primary"></i>
                          </div>
                          <div class="col-sm-8" style="font-size: x-small; font-weight: bold">
                            Sem taxas escondidas e taxas de transação
                          </div>
                        </div>

                        <div class="row justify-content-sm-center align-items-center" style="min-height: 30px">
                          <div class="col-sm-auto">
                            <i class="fa-solid fa-check text-primary"></i>
                          </div>
                          <div class="col-sm-8" style="font-size: x-small; font-weight: bold">
                            Simples, transparente e seguro.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <hr>
                  <span v-if="maxCardLimit > currentCardCount && hideTOTP">
                    <p-button type="primary" class="p-3 w-75" @click="requestNewCard=true">
                      Solicitar
                    </p-button>
                  </span>
                  <span v-if="maxCardLimit <= currentCardCount">
                    Você já possui o número máximo de cartões disponíveis para sua conta. Caso deseje solicitar mais, entre em contato com o nosso canal de atendimento.
                  </span>
                </div>
              </div>
              <div v-if="requestNewCard">
                <h5 slot="header" class="card-title text-center font-weight-bold">
                  Solicitar Novo Cartão
                </h5>
                <hr>
                <br>
                <div class="row">
                  <div class="card-body" style="margin-top: -30px;  padding-top: 0">
                    <div v-show="!showAddressFields">
                      <div class="row">
                        <div class="col-lg-12">
                          <fg-input type="text"
                                    v-model="formRequest.nickname"
                                    maxlength="150"
                                    label="Nome do Cartão"
                                    :error="getError('request.nome')"
                                    name="nome"
                                    v-validate="{required: true, max:50}"
                                    data-vv-scope="request"
                                    key="nickname">
                          </fg-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div style="display: flex">
                            <label class="control-label">Limite Mínimo</label>
                            <el-popover
                              placement="top-start"
                              title="Limite Mínimo"
                              width="300"
                              trigger="hover">
                              <hr>
                              <div style="word-break: break-word; font-size: small">
                                Você receberá uma notificação sempre que o saldo do cartão for inferior ao valor definido neste parâmetro.
                              </div>
                              <div style="float:right; font-size: x-small; font-weight: bold" slot="reference">&nbsp;<i class="fa-solid fa-circle-info"></i></div>
                            </el-popover>
                          </div>
                          <money class="form-control input-lg mb-2" v-model="formRequest.minFundAlert" v-bind="money"> </money>
                        </div>
                        <div class="col-lg-6">
                          <div style="display: flex">
                            <label class="control-label">Limite Máximo</label>
                            <el-popover
                              placement="top-start"
                              title="Limite Máximo"
                              width="300"
                              trigger="hover">
                              <hr>
                              <div style="word-break: break-word; font-size: small">
                                Este limite define o valor máximo que pode ser gasto mensalmente por este cartão.<br>Você não poderá adicionar valores acima deste limite no cartão pelo período de um mês.
                              </div>
                              <div style="float:right; font-size: x-small; font-weight: bold" slot="reference">&nbsp;<i class="fa-solid fa-circle-info"></i></div>
                            </el-popover>
                          </div>
                          <money class="form-control input-lg mb-2" v-model="formRequest.maxFundAlert" v-bind="money"> </money>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div style="display: flex">
                            <label class="control-label">Centro de Custo</label>
                            <el-popover
                              placement="top-start"
                              title="Centro de Custo"
                              width="300"
                              trigger="hover">
                              <hr>
                              <div style="word-break: break-word; font-size: small">
                                Organize seus gastos por categorias de despesas.
                              </div>
                              <div style="float:right; font-size: x-small; font-weight: bold" slot="reference">&nbsp;<i class="fa-solid fa-circle-info"></i></div>
                            </el-popover>
                          </div>
                          <el-select class="select-primary"
                                     placeholder="Centro de Custo"
                                     style="width: 100%"
                                     v-model="requestCostCenter">
                            <el-option v-for="option in costCenters"
                                       class="select-success"
                                       :value="option.id"
                                       :label="option.name"
                                       :key="option.id"/>
                          </el-select>
                        </div>
                      </div>
                      <div class="row" v-if="cardType=='physical'">
                        <div class="col-lg-12">
                          <div style="display: flex">
                            <label class="control-label">Titular do Cartão</label>
                            <el-popover
                              placement="top-start"
                              title="Titular do Cartão"
                              width="300"
                              trigger="hover">
                              <hr>
                              <div style="word-break: break-word; font-size: small">
                                Este será o usuário que usará este cartão físico. O nome deste usuário será impresso no cartão e não será possível alterar após a solicitação.
                                <br>
                                Deixe em branco caso o usuário do cartão seja você mesmo.
                              </div>
                              <div style="float:right; font-size: x-small; font-weight: bold" slot="reference">&nbsp;<i class="fa-solid fa-circle-info"></i></div>
                            </el-popover>
                          </div>
                          <div style="display: flex">
                            <fg-input v-model="requestCardUser" style="width: 100%">
                              <template slot="addonLeft">@</template>
                            </fg-input>
                            <el-popover
                              placement="left-start"
                              width="260"
                              v-model="userInfoRequestVisible"
                              v-on:hide="userInfoRequestHidden()">
                              <div class="col-md-12" v-if="!isNullOrEmpty(searchUser.destinationName)" style="text-align: center">
                                <div class="col-sm-12">
                                  <label class="control-label">Nome Destino</label>
                                  <p class="form-control-static">{{searchUser.destinationName}}</p>
                                </div>
                              </div>
                              <div class="col-md-12" v-if="!isNullOrEmpty(searchUser.destinationSocialNumber)" style="text-align: center">
                                <div class="col-sm-12">
                                  <label class="control-label">CPF/CNPJ</label>
                                  <p class="form-control-static">{{searchUser.destinationSocialNumber}}</p>
                                  <input v-mask="['###.***.***-##', '##.###.###/####-##']" v-model="searchUser.destinationSocialNumber"
                                         style="display: none"/>
                                </div>
                              </div>
                              <div class="col-md-12" v-if="!isNullOrEmpty(searchUser.email)" style="text-align: center">
                                <div class="col-sm-12">
                                  <label class="control-label">E-mail Destinatário</label>
                                  <p class="form-control-static">{{searchUser.email}}</p>
                                </div>
                              </div>
                              <div style="text-align: right; margin: 0">
                                <p-button size="sm" type="primary" @click="selectUserRequest()">
                                  Confirmar
                                </p-button>
                                <p-button size="sm" type="danger" @click="userInfoRequestVisible = false">
                                  Cancelar
                                </p-button>
                              </div>
                              <p-button slot="reference" class="search mb-auto mt-auto pl-2 pr-2" @click="search(requestCardUser)">
                                <i slot="label" class="fas fa-search"></i>
                              </p-button>
                            </el-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="showAddressFields">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <p-checkbox class="text-left" v-model="formRequest.mainAddressSameShippingAddress">
                              <b>Utilizar o mesmo endereço como endereço de entrega.</b>
                            </p-checkbox>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 pl-1 pr-1">
                            <fg-input type="tel"
                                      v-mask="['#####-###']"
                                      label="CEP"
                                      name="CEP"
                                      v-validate="{required: true, min: 9}"
                                      data-vv-scope="request"
                                      :error="getError('request.CEP')"
                                      v-on:blur="this.searchZipCode"
                                      v-model="formRequest.deliveryAddress.zipCode"
                                      :disabled="formRequest.mainAddressSameShippingAddress"
                                      key="cep">
                            </fg-input>
                          </div>
                          <div class="col-md-8 pl-1 pr-1">
                            <fg-input type="text"
                                      maxlength="40"
                                      :disabled="formRequest.mainAddressSameShippingAddress"
                                      label="Rua/Av."
                                      name="Rua/Av."
                                      v-validate="{required: true}"
                                      data-vv-scope="request"
                                      :error="getError('request.Rua/Av.')"
                                      v-model="formRequest.deliveryAddress.street"
                                      key="rua">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 pl-1 pr-1">
                            <fg-input type="tel"
                                      v-mask="['######']"
                                      label="Número"
                                      name="Número"
                                      :disabled="formRequest.mainAddressSameShippingAddress"
                                      v-model="formRequest.deliveryAddress.number"
                                      key="numero">
                            </fg-input>
                          </div>
                          <div class="col-md-8 pl-1 pr-1">
                            <fg-input type="text"
                                      maxlength="40"
                                      :disabled="formRequest.mainAddressSameShippingAddress"
                                      label="Complemento"
                                      v-model="formRequest.deliveryAddress.complement"
                                      key="complemento">
                            </fg-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 pl-1 pr-1">
                            <fg-input type="text"
                                      maxlength="40"
                                      :disabled="formRequest.mainAddressSameShippingAddress"
                                      label="Bairro"
                                      name="Bairro"
                                      v-validate="{required: true}"
                                      data-vv-scope="request"
                                      :error="getError('Bairro')"
                                      v-model="formRequest.deliveryAddress.neighborhood"
                                      key="bairro">
                            </fg-input>
                          </div>
                          <div class="col-md-5 pl-1 pr-1">
                            <fg-input type="text"
                                      label="Cidade"
                                      disabled="true"
                                      v-model="formRequest.deliveryAddress.city"
                                      key="cidade">
                            </fg-input>
                          </div>
                          <div class="col-md-2 pl-1 pr-1">
                            <fg-input type="text"
                                      label="Estado"
                                      disabled="true"
                                      placeholder=""
                                      v-model="formRequest.deliveryAddress.state"
                                      key="estado">
                            </fg-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 mt-auto mb-auto" v-if="cardType === 'virtual' || showAddressFields">
                        <p-button type="primary" class="mx-auto d-block p-2 mt-3 w-100" @click="requestCard()">
                          Confirmar
                        </p-button>
                      </div>
                      <div class="col-md-6 mt-auto mb-auto" v-if="cardType === 'physical' && !showAddressFields">
                        <p-button type="primary" class="mx-auto d-block p-2 mt-3 w-100" @click.prevent="openShippingAddress()">
                          Proximo
                        </p-button>
                      </div>
                      <div class="col-md-6 mt-auto mb-auto">
                        <p-button type="danger" class="mx-auto d-block p-2 mt-3 w-100" @click="backButtonRequest()">
                          Voltar
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </card>
        </div>
        <!-- Bloco Dispositivo Não habilitado -->
        <div class="col-xl-9" v-if=" !hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && !isNullOrEmpty(totpQrCodeUri)">
          <card style="background-color: #00d19a; text-align: center">
            <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não esta cadastrado!</h4>
            <card align="center" >
              <span>Para liberar suas transações siga os passos abaixo:</span>
              <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
                <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
              </div>

              <div class="row" style="padding: 3%">
                <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">SuitPay</b>.</span></p>
                  <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                  <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                  <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                  <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                    <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                  </a>
                  <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                    <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                  </a>

                </div>

                <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                  <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no aplicativo </p>
                  <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                  <p><b>4</b> - Confirme a operação</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
              </div>
              <br>
              <div style="text-align: center">
                <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                          class="btn btn-danger" >
                  <i class="fas fa-chevron-left"/> Voltar
                </p-button>
              </div>
            </card>
          </card>
        </div>

        <!-- Bloco Dispositivo Habilitado -->
        <div class="col-xl-9" v-if=" !hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
          <card style="background-color: #00d19a; text-align: center; height: 100%">
            <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
            <card align="center" style="height: 100%" >

              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

              <div class="row" style="padding: 3%">
                <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                  <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                  <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                  <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                  <p><b>4</b> - Confirme a operação.</p>
                  <p><b>5</b> - Pronto!</p>
                </div>
              </div>

              <span>Informe o <b>SuitID</b>: </span>
              <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

              <div class="row" v-if="!codeValid">
                <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
              </div>

              <div style="text-align: center">
                <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                          class="btn btn-danger" >
                  <i class="fas fa-chevron-left"/> Voltar
                </p-button>
                <p-button @click="()=>{validateCode()}"
                          class="btn btn-primary"
                          :disabled="totpConfirmBtnDisabled"
                >
                  <i class="fas fa-chevron-right"/> Confirmar
                </p-button>
              </div>
            </card>
          </card>
        </div>

        <div class="col-xl-9" v-show="hideTOTP">
          <card style="height: 100%">
            <div class="row">
              <div class="col-xl-2">
                <label class="control-label">Número do Cartão</label>
                <fg-input v-model="searchFilter.number" style="width: 100%"/>
              </div>
              <div class="col-xl-3">
                <label class="control-label">Nome do Cartão</label>
                <fg-input v-model="searchFilter.nickname" style="width: 100%"/>
              </div>
              <div class="col-xl-2">
                <label class="control-label">Status</label>
                <br>
                <el-select class="select-info"
                           placeholder="Status"
                           v-model="searchFilter.status">
                  <el-option v-for="option in searchStatus"
                             class="select-info"
                             :value="option.id"
                             :label="option.name"
                             :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-xl-3">
                <label class="control-label">Centro de Custo</label>
                <br>
                <el-select class="select-info"
                           placeholder="Status"
                           v-model="searchFilter.costCenterId">
                  <el-option v-for="option in costCenters"
                             class="select-info"
                             :value="option.id"
                             :label="option.name"
                             :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-xl-2">
                <p-button @click.prevent="loadTableData()" style="margin-top: 24px" type="info">
                  <i class="fas fa-search-dollar"></i> BUSCAR
                </p-button>
              </div>
            </div>
            <div>
              <hr>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <el-table class="table-striped"
                          :data="tableData"
                          style="width: 100%;"
                          header-cell-class-name="normal-word-break"
                          cell-class-name="normal-word-break"
                          ref="cardTable"
                          @row-click="(row, column, event) => expandRow(row)"
                          row-key="cardId">
                  <el-table-column :min-width="100" label="Cartão">
                    <template slot-scope="props">
                      <span>
                        <span style="margin-left: 10px">@{{props.row.username}}</span>
                        <br>
                        <span style="margin-left: 10px">**** {{props.row.number.slice(-4)}}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Nome do Cartão">
                    <template slot-scope="props">
                      <span>
                        <span>{{props.row.nickname}}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Responsável">
                    <template slot-scope="props">
                      <span>
                        <span>{{props.row.cardUserName}}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Controle de Gastos">
                    <template slot-scope="props">
                      <span>
                        <span v-if="props.row.maxFundAlert != null && props.row.maxFundAlert > 0">
                          <span style="font-size: xx-small; font-weight: lighter;">
                            Limite Máximo: R$ {{toMoney(props.row.maxFundAlert)}}
                          </span>
                          <p-progress :value="getProportionalPercent(props.row.maxFundAlert, props.row.availableFunds)" type="primary"></p-progress>
                        </span>
                        <span v-if="props.row.maxFundAlert == null || props.row.maxFundAlert === 0" style="font-size: xx-small; font-weight: lighter; font-style: italic">
                          Este cartão não possui controle de gastos
                        </span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="50" label="Saldo">
                    <template slot-scope="props">
                      <span>
                        <span>R$ {{toMoney(props.row.availableFunds)}}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="50" label="Status">
                    <template slot-scope="props">
                      <span>
                        <span class="status-text"><i :class="'fa-solid fa-circle ' + statusToColor(props.row.status)"></i> {{statusToText(props.row.status)}}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <div class="row">
                        <div class="col-sm-12">
                          <card style="width: 90%" class="mx-auto d-block">
                            <div class="row">
                              <div class="col-xl-4" v-if="props.row.username === getValidUsername(username) && props.row.status === 'ACTIVE'">
                                <div class="container-fluid d-flex h-100 flex-column">
                                  <div class="row">
                                    <div class="col-lg-12 pl-4 pr-4">
                                      <label class="control-label">Valor</label>
                                      <money class="form-control input-lg" v-model="value" v-bind="money"> </money>
                                      <label class="control-label" style="float: left; font-size: xx-small">
                                        Saldo Corrente: <b>R$ {{toMoney(accountBalance)}}</b>
                                        <br>
                                        Saldo disponível para recarga: <b>R$ {{toMoney(accountAvailableBalance)}}</b>
                                      </label>
                                      <br>
                                      <label class="control-label" style="float: left; font-size: xx-small">
                                        Saldo no Cartão: <b>R$ {{toMoney(props.row.availableFunds)}}</b>
                                      </label>
                                    </div>
                                  </div>
                                  <div class="row d-flex" v-if="props.row.cardType === 'VIRTUAL' || (props.row.cardType === 'PHYSICAL' && props.row.shipmentStatus === 'DELIVERED' && props.row.pinDefined)">
                                    <div class="col-xl-6 box-xl-6">
                                      <p-button type="primary" class="mx-auto p-2 w-100" style="min-width: 103px" @click="addFunds(props.row)">
                                        Recarregar
                                      </p-button>
                                    </div>
                                    <div class="col-xl-6 box-xl-6">
                                      <p-button type="danger" class="mx-auto p-2 w-100" tyle="min-width: 103px" @click="withdraw(props.row)">
                                        Sacar
                                      </p-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div :class="props.row.status === 'ACTIVE' && props.row.username === getValidUsername(username) ? 'col-xl-8' : 'col-xl-12'">
                                <div class="row">
                                  <div class="col-sm-4">
                                    <label class="control-label">Número do Cartão</label>
                                    <br>
                                    {{props.row.number}}&nbsp;&nbsp;
                                    <span v-if="cardType==='virtual'">
                                      <i class="fa-regular fa-eye-slash" v-if="!props.row.expandedCardInfo" @click="expandCardInfo(props.row.cardId)"></i>
                                      <i class="fa-regular fa-eye" v-if="props.row.expandedCardInfo" @click="expandCardInfo(props.row.cardId)"></i>
                                    </span>
                                  </div>
                                  <div class="col-sm-4">
                                    <label class="control-label">Titular do Cartão</label>
                                    <br>
                                    <span v-if="!editing || cardType==='physical'">
                                      {{props.row.cardUserName}}
                                    </span>
                                    <span v-if="editing && cardType!=='physical'">
                                      <div style="float: left; display: flex">
                                        <fg-input v-model="editingCardUser" style="width: 100%">
                                          <template slot="addonLeft">@</template>
                                        </fg-input>
                                        <el-popover
                                          placement="left-start"
                                          width="260"
                                          v-model="userInfoVisible"
                                          v-on:hide="userInfoHidden(props.row)">
                                           <div class="col-md-12" v-if="!isNullOrEmpty(searchUser.destinationName)" style="text-align: center">
                                              <div class="col-sm-12">
                                                <label class="control-label">Nome Destino</label>
                                                <p class="form-control-static">{{searchUser.destinationName}}</p>
                                              </div>
                                            </div>
                                            <div class="col-md-12" v-if="!isNullOrEmpty(searchUser.destinationSocialNumber)" style="text-align: center">
                                              <div class="col-sm-12">
                                                <label class="control-label">CPF/CNPJ</label>
                                                <p class="form-control-static">{{searchUser.destinationSocialNumber}}</p>
                                                <input v-mask="['###.***.***-##', '##.###.###/####-##']" v-model="searchUser.destinationSocialNumber"
                                                       style="display: none"/>
                                              </div>
                                            </div>
                                            <div class="col-md-12" v-if="!isNullOrEmpty(searchUser.email)" style="text-align: center">
                                              <div class="col-sm-12">
                                                <label class="control-label">E-mail Destinatário</label>
                                                <p class="form-control-static">{{searchUser.email}}</p>
                                              </div>
                                            </div>
                                            <div style="text-align: right; margin: 0">
                                              <p-button size="sm" type="primary" @click="selectUserEditing()">
                                                Confirmar
                                              </p-button>
                                              <p-button size="sm" type="danger" @click="userInfoVisible = false">
                                                Cancelar
                                              </p-button>
                                            </div>
                                            <p-button slot="reference" class="search mb-auto pl-2 pr-2" @click="search(editingCardUser)">
                                              <i slot="label" class="fas fa-search"></i>
                                            </p-button>
                                        </el-popover>
                                      </div>
                                    </span>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="row">
                                      <div class="col-sm-8">
                                        <label class="control-label">Data de Venc.</label>
                                        <br>
                                        {{props.row.expire}}
                                      </div>
                                      <div class="col-sm-4">
                                        <label class="control-label">CCV</label>
                                        <br>
                                        {{props.row.cvv}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-4">
                                    <label class="control-label">Limite Mínimo</label>
                                    <br>
                                    <span v-if="!editing">
                                      R$ {{toMoney(props.row.minFundAlert)}}
                                    </span>
                                    <span v-else>
                                      <money class="form-control input-lg mb-2" v-model="editingMin" v-bind="money"> </money>
                                    </span>
                                  </div>
                                  <div class="col-sm-4">
                                    <label class="control-label">Limite Máximo</label>
                                    <br>
                                    <span v-if="!editing">
                                      R$ {{toMoney(props.row.maxFundAlert)}}
                                    </span>
                                    <span v-else>
                                      <money class="form-control input-lg mb-2" v-model="editingMax" v-bind="money"> </money>
                                    </span>
                                  </div>
                                  <div class="col-sm-4">
                                    <label class="control-label">Centro de Custo</label>
                                    <br>
                                    <span v-if="!editing" >
                                          {{props.row.costCenter != null ? props.row.costCenter.name : "Sem Categoria"}}
                                    </span>
                                    <span v-if="editing" >
                                      <el-select class="select-success"
                                                 placeholder="Centro de Custo"
                                                 size="small"
                                                 v-model="editingCostCenter">
                                        <el-option v-for="option in costCenters"
                                                   class="select-primary"
                                                   :value="option.id"
                                                   :label="option.name"
                                                   :key="option.id">
                                        </el-option>
                                      </el-select>
                                    </span>
                                  </div>
                                </div>
                                <div v-if="props.row.pinDefined || props.row.cardType === 'VIRTUAL'" class="row mt-3">
                                  <div class="col-md-4 mt-auto mb-auto p-1">
                                    <span v-if="props.row.username === getValidUsername(username) && !editing && props.row.status !== 'CANCELED'">
                                        <p-button type="primary" class="mx-auto d-block p-2 w-100" outline=true @click="editRow(props.row)">
                                          Personalizar
                                        </p-button>
                                    </span>
                                    <span v-if="editing">
                                      <fg-input type="tel"
                                                label="Nome do Cartão"
                                                required="true"
                                                :disabled="cardType==='physical'"
                                                v-model="editingNickname"
                                                maxlength="150"
                                                name="Nome do Cartão"
                                                key="nickname">
                                      </fg-input>
                                    </span>
                                  </div>
                                  <div class="col-md-4 mt-auto mb-auto p-1">
                                    <span v-if="!editing && props.row.status !== 'CANCELED'">
                                      <span v-if="props.row.username === getValidUsername(username)">
                                        <span v-if="!props.row.blocked">
                                          <p-button type="warning" class="mx-auto d-block p-2 w-100" outline=true @click="blockUnblock(props.row, true)">
                                            Bloquear
                                          </p-button>
                                        </span>
                                        <span v-if="props.row.blocked">
                                          <p-button type="warning" class="mx-auto d-block p-2 w-100" outline=true @click="blockUnblock(props.row, false)">
                                            Desbloquear
                                          </p-button>
                                        </span>
                                      </span>
                                    </span>
                                    <span v-if="editing">
                                      <p-button type="success" class="mx-auto d-block p-2 mt-3 w-100" outline=true @click="save(props.row)">
                                        Salvar
                                      </p-button>
                                    </span>
                                  </div>
                                  <div class="col-md-4 mt-auto mb-auto p-1">
                                    <span v-if="props.row.username !== getValidUsername(username)">
                                      <span v-if="!props.row.blocked">
                                        <p-button type="warning" class="mx-auto d-block p-2 w-100" outline=true @click="blockUnblock(props.row, true)">
                                          Bloquear
                                        </p-button>
                                      </span>
                                      <span v-if="props.row.blocked">
                                        <p-button type="warning" class="mx-auto d-block p-2 w-100" outline=true @click="blockUnblock(props.row, false)">
                                          Desbloquear
                                        </p-button>
                                      </span>
                                    </span>
                                    <span v-if="props.row.username === getValidUsername(username) && !editing && props.row.status !== 'CANCELED'">
                                      <p-button type="danger" class="mx-auto d-block p-2 w-100" outline=true @click="cancel(props.row)">
                                        Cancelar
                                      </p-button>
                                    </span>
                                    <span v-if="editing">
                                      <p-button type="danger" class="mx-auto d-block p-2 mt-3 w-100" outline=true @click="editRow(null, true)">
                                        Voltar
                                      </p-button>
                                    </span>
                                  </div>
                                </div>
                                <div v-if="props.row.cardType === 'PHYSICAL' && props.row.shipmentStatus !== 'DELIVERED'" class="row mt-3">
                                  <div class="col-md-12 d-flex mt-auto mb-auto p-1">
                                    <p-button type="primary" class="mx-auto d-block p-2 mt-3 w-100"  @click="showTrackingInfo(props.row)">
                                      Rastrear entrega
                                    </p-button>
                                    <span v-if="props.row.username === getValidUsername(username) && !editing && props.row.status !== 'CANCELED'" class="mx-auto d-block pl-2 mt-3 w-100">
                                      <p-button type="danger" class="mx-auto d-block p-2 w-100" outline=true @click="cancel(props.row)">
                                        Cancelar
                                      </p-button>
                                    </span>
                                  </div>
                                </div>
                                <div v-if="props.row.cardType === 'PHYSICAL' && props.row.shipmentStatus === 'DELIVERED' && !props.row.pinDefined" class="row mt-3">
                                  <div class="col-md-12 d-flex mt-auto mb-auto p-1">
                                    <p-button type="primary" class="mx-auto d-block p-2 mt-3 w-50"  @click="setPin(props.row)">
                                      Definir Senha
                                    </p-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </card>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="col-sm-6 pagination-success">
                  <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
                    {{pagination.totalNumber}} REGISTROS.</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination class="pull-right"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.totalNumber"
                                :click="loadData">
                  </p-pagination>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <modal :show.sync="displayTrackingModal" footerClasses="justify-content-center" type="notice">
      <h6 style="text-align: center"><i class="fa-solid fa-truck-fast text-primary"></i></h6>
      <h6 style="text-align: center"><b>Dados de Rastreamento</b></h6>
      <hr>
      <span v-if="tracking != null">
        <card>
          <p> <i class="fa-regular fa-circle-dot text-primary"></i> <span  class="text-muted"> Status: </span>{{getTrackingStatus()}}</p>
          <span v-if="tracking.shippingStatus != null && tracking.shippingStatus !== 'RETURNED' && tracking.shippingStatus !== 'failed' && tracking.shippingEta != null">
            <p><i class="fa-solid fa-hourglass-half text-primary"></i> <span class="text-muted"> Previsão de Entrega: </span> {{tracking.shippingEta.substring(0,10)}}</p>
          </span>
        </card>
        <span v-if="tracking.shippingStatus != null && tracking.shippingStatus === 'DELIVERED'">
          <p>Para definir sua senha, clique no botão <span style="font-weight: bold; background: #009E74; color: white;padding-left: 5px;padding-right: 5px;word-wrap: revert;white-space: pre;">DEFINIR SENHA</span>, e comece a utilizar seu <span class="text-primary">Suit</span>Card o quanto antes.</p>
          <p>Lembre-se: para ativar o recurso de compras por aproximação a primeira compra deve ser feita utilizando o chip.</p>
        </span>
        <div v-if="showTrackingInfoCorreios">
          <h6 style="text-align: center">Rastrear pelos Correios: </h6>
          <hr>
          <card style="text-align: center" >
            <p>Seu cartão será entregue pelos correios. Segue o código de rastreio:</p>
            <p style="font-weight: bold">{{ this.trackingNumberCorreios }}</p>
            <p-button
              style="text-align: center"
              type="warning"
              @click="searchCorreios()">
                  <i slot="label" class="nc-icon nc-zoom-split"></i>
                  Rastrear nos Correios
            </p-button>
          </card>
        </div>
        <span v-if="tracking.shippingHistory != null">
          <h6 style="text-align: center">Movimentação</h6>
          <hr>
          <card>
            <div v-for="hist in tracking.shippingHistory">
              <p><i class="fa-regular fa-calendar-days text-info"></i> <span class="text-muted">Data: </span>{{hist.ocorrencia.substring(0, 16)}}</p>
              <p><i class="fa-solid fa-dolly text-info"></i> <span class="text-muted">Movimentação: </span>{{hist.evento}}</p>
<!--              <p><i class="fa-solid fa-earth-americas text-info"></i> <span class="text-muted">Local: </span>{{hist.local}}</p>-->
              <hr>
            </div>
          </card>
        </span>
      </span>
    </modal>
  </div>
</template>
<script>
  import Vue from 'vue'
  import swal from 'sweetalert2'
  import {postWs, getWs, failWs} from 'src/ws.service'
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney, getValidUsername, containsOnlyNumbers} from 'src/util/core.utils'
  import {Progress} from 'src/components/UIComponents'
  import {Option, Select, Table, TableColumn, Popover} from "element-ui";
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {
      [Progress.name]: Progress,
      PPagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Popover.name]: Popover,
      Modal
    },
    data() {
      return {
        cardType: null,
        accountApproved: false,
        confirmButton: false,
        buttonDisabled: false,
        lastExpandedRow: null,
        requestNewCard: false,
        email: '',
        usernameDisplay: '',
        watching: false,
        viacep: {
          logradouro: '',
          bairro: ''
        },
        pagination: {
          perPage: 10,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        editing: false,
        editingRow: null,
        editingMin: null,
        editingMax: null,
        editingNickname: null,
        editingCostCenter: null,
        editingCardUser: null,
        showAddressFields: false,
        requestCardUser: null,
        value: 0,
        tableData: [],
        account: [],
        costCenters: [],
        accountBalance: 0,
        accountAvailableBalance: '0,00',
        username: '',
        userSelected: false,
        userRequestSelected: false,
        currentCardCount: 0,
        maxCardLimit: 0,
        userInfoVisible: false,
        userInfoRequestVisible: false,
        searchUser: {
          destination: null,
          destinationName: null,
          destinationSocialNumber: null,
          email: null
        },
        formRequest: {
          min:  0,
          max:  0,
          nickname:  null,
          costCenter:  null,
          deliveryAddress: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          mainAddressSameShippingAddress: true,
        },
        searchFilter: {
          number: null,
          nickname: null,
          status: 'ACTIVE',
          costCenterId: null
        },
        searchStatus: [
          {id: null, name: 'Todos'},
          {id:'ACTIVE', name: 'Ativos'},
          {id:'BLOCKED', name: 'Bloqueados'},
          {id:'CANCELED', name: 'Cancelados'}
        ],

        transactionAuthorizationEmail: 'false',
        transactionAuthorizationTotp: 'false',
        qrCode: '',
        validationCode: '',
        totpQrCodeUri : '',
        totpManualCode : '',
        totpConfirmBtnDisabled: false,
        codeValid: true,
        hideTOTP: true,
        verifCode: null,
        totpCallback: null,
        requestCostCenter: null,
        displayTrackingModal: false,
        showTrackingInfoCorreios: false,
        trackingNumberCorreios: '',
        tracking: null
      }
    },
    created() {
      this.cardType = this.$route.params.type
      if(!this.watching){
        this.watching = true;
        this.$watch( () => this.$route.params, (toParams, previousParams) => {
            this.cardType = this.$route.params.type
            this.pagination = {
              perPage: 10,
              currentPage: 1,
              pageMaxNumber: 1,
              fromNumber: 0,
              toNumber: 0,
              totalNumber: 0
            }
            this.loadData()
            this.hideTOTP = true
          })

        this.$watch(() => this.formRequest.mainAddressSameShippingAddress, (to, previous) =>{
          if(this.formRequest.mainAddressSameShippingAddress){
            this.setAddressFieldsToAccountAddress();
          } else {
            this.clearAddressFields()
          }
        })
        this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
        this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      }
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.requestCardUser = localStorage.getItem("usernameDisplay");
      this.account.name = localStorage.getItem("name")
      this.email = localStorage.getItem("email")
      this.getCurrentBalance();

      this.transactionAuthorizationEmail = notNull(localStorage.getItem('transactionAuthorizationEmail'), 'false')
      this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
      postWs("/acc/get-account", true, null, null,
         response => {
           this.loadAccount(response)
           this.loadData()
         }, function () {
      })
    },
    methods: {
      getValidUsername,
      isNullOrEmpty,
      toMoney,
      showTrackingInfo(row){
        postWs("/card/getShipmentHistory", true, {id: row.cardId},null,
          response => {
              this.displayTrackingModal = true
              this.tracking = response.data;
              if(this.tracking.shippingStatus === 'DELIVERED'){
                row.shipmentStatus = 'DELIVERED'
              }
              this.showTrackingInfoCorreios = this.validateCorreios()
            },
          error => {
            swal({
              title: 'Aviso!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
          }
        )
      },
      validateCorreios(){
        let rCorreio = ''
        this.trackingNumberCorreios = ''
        if(this.tracking.shippingHistory !== null){
          this.tracking.shippingHistory.some(function(obj) {
            if(obj.hasOwnProperty('arCorreios') && obj.eventoId === '2200'){
              rCorreio = obj.arCorreios
            }})
          this.trackingNumberCorreios = rCorreio
        }
        return this.trackingNumberCorreios !== ''
      },
      openWindow(url) {
        try {
          window.open(url)
        } catch (ex) {
          swal({
            title: 'Aviso!',
            text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      },
      searchCorreios(){
        navigator.clipboard.writeText(this.trackingNumberCorreios);
        swal({
          title:"Rastreamento Correios",
          type: "info",
          text: "Seu código de rastreio foi copiado para área de transferência e uma nova aba de rastreamento dos correios será aberta! ",
          confirmButtonText: "Seguir para Correios",
          confirmButtonColor: "#d2bb19",
          showConfirmButton: true
        }).then(() =>{
          this.openWindow('https://rastreamento.correios.com.br/app/index.php')
        })
      },
      getTrackingStatus(){
        if (this.tracking != null){
          if (this.tracking.shippingStatus != null){
            switch(this.tracking.shippingStatus){
              case "WAITING_SEND":
                return "Aguardando Envio"
              case "SENT":
                return "À Caminho"
              case "DELIVERED":
                return "Entregue"
              case "RETURNED":
                return "Falha na entrega. Devolvido."
              default:
                return "Status Desconhecido"
            }
          } else if(this.tracking.embossingStatus != null) {
            switch (this.tracking.embossingStatus){
              case "created":
                return "Pedido feito à embossadora"
              case "processing":
                return "Cartão em produção"
              case "success":
                return "Cartão produzido! Aguardando coleta!"
              case "failed":
                return "Falha ao produzir cartão."
            }
          }
        }
      },
      getProportionalPercent(max, current) {
        return (current * 100) / max;
      },
      statusToText(status) {
        switch (status) {
          case 'ACTIVE':
            return 'Ativo'
          case 'BLOCKED':
            return 'Bloqueado'
          case 'CANCELED':
            return 'Cancelado'
        }
      },
      statusToColor(status) {
        switch (status) {
          case 'ACTIVE':
            return 'text-success'
          case 'BLOCKED':
            return 'text-warning'
          case 'CANCELED':
            return 'text-danger'
        }
      },
      expandRow(row, forceHide) {
        if (this.lastExpandedRow != null || forceHide) {
          if (this.lastExpandedRow != null){
            this.$refs.cardTable.toggleRowExpansion(this.lastExpandedRow, false);
          }
          if(row != null){
            this.expandCardInfo(row.cardId, true)
          }
          this.editRow(null, true)
          this.value = 0;
        }
        if (this.lastExpandedRow != null && this.lastExpandedRow === row) {
          this.$refs.cardTable.toggleRowExpansion(row, false);
          this.lastExpandedRow = null
          this.expandCardInfo(row.cardId, true)
          this.editRow(null, true)
          this.value = 0;
        } else if(row != null){
          this.lastExpandedRow = row
          if(row.expire == null){
            row.expire = '**/****'
          }
          this.$refs.cardTable.toggleRowExpansion(row, true);
        }
      },
      backButtonRequest(){
        if(this.showAddressFields){
          this.showAddressFields = false
          return;
        }
        this.cancelRequest()
      },
      expandCardInfo(cardId, forceHideCardInfo) {
        this.tableData.forEach(value => {
          if(value.cardId === cardId){
            if(value.expandedCardInfo || forceHideCardInfo){
              value.expandedCardInfo = false;
              value.number = '**** **** **** ' + value.number.slice(-4)
              value.cvv = '***'
              value.expire = '**/****'
            } else {
              postWs("/card/get", true, {id: cardId, expanded: true},{verifCode: this.verifCode},
                response => {
                  if(!this.showTOTP(response, function(){ return this.expandCardInfo(cardId, forceHideCardInfo); })) {
                    value.expandedCardInfo = true;
                    value.number = response.data.number
                    value.cvv = response.data.cvv
                    value.expire = response.data.expire
                    this.clearTOTP()
                  }
                },
                error => {
                  swal({
                    title: 'Aviso!',
                    text: error.response.data.message,
                    buttonsStyling: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'warning'
                  })
                }
              )
            }
          }
        })
      },
      resetEditFields(editing, cardRequest, closeCardRequest){
        if(editing){
          this.editingRow = null
          this.editingMin = null
          this.editingMax = null
          this.editingNickname = null
          this.editingCostCenter = null
          this.editingCardUser = null
        }

        if(cardRequest){
          this.requestCardUser = localStorage.getItem("usernameDisplay");
          this.formRequest = {
            minFundAlert:  0,
            maxFundAlert:  0,
            nickname:  null,
            costCenter:  null,
            cardType: this.cardType,
            deliveryAddress: {
              codIbge: '',
              street: '',
              number: '',
              complement: '',
              zipCode: '',
              neighborhood: '',
              city: '',
              state: ''
            },
            mainAddressSameShippingAddress: true,
          }
        }

        if(closeCardRequest){
          this.requestNewCard = false
          this.showAddressFields = false
        }
      },
      setAddressFieldsToAccountAddress(){
        this.formRequest.deliveryAddress = {
          codIbge: '',
          street: this.account.address.street,
          number: this.account.address.number,
          complement: this.account.address.complement,
          zipCode: this.account.address.zipCode,
          neighborhood: this.account.address.neighborhood,
          city: this.account.address.city,
          state: this.account.address.state
        }
      },
      clearAddressFields(){
        this.$validator.reset({scope:'request'})
        this.formRequest.deliveryAddress = {
          codIbge: '',
          street: '',
          number: '',
          complement: '',
          zipCode: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      searchZipCode() {
        this.viacep = null
        this.formRequest.deliveryAddress.codIbge = ""
        this.formRequest.deliveryAddress.city = ""
        this.formRequest.deliveryAddress.state = ""
        getWs("/cep/get-cep",
          true, {cep: this.formRequest.deliveryAddress.zipCode},
          (response) => {
            this.viacep = response.data
            this.formRequest.deliveryAddress.codIbge = this.viacep.ibge
            if (this.viacep.logradouro.length > 40) {
              this.formRequest.deliveryAddress.street = this.viacep.logradouro.substr(0, 40)
            } else {
              this.formRequest.deliveryAddress.street = this.viacep.logradouro
            }
            if(this.viacep.bairro.length > 40) {
              this.formRequest.deliveryAddress.neighborhood = this.viacep.bairro.substr(0, 40)
            } else {
              this.formRequest.deliveryAddress.neighborhood = this.viacep.bairro
            }
            this.formRequest.deliveryAddress.city = this.viacep.localidade
            this.formRequest.deliveryAddress.state = this.viacep.uf
          },
          (e) => {
            swal({
              title: 'Aviso!',
              text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
              buttonsStyling: true,
              timer: 10000,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
            return
          })
      },
      editRow(row, forceExit){
        if(this.editing || forceExit){
          this.editing = false
          this.resetEditFields(true, false)
        } else {
          this.editing = true
          this.editingRow = row
          this.editingMin = row.minFundAlert
          this.editingMax = row.maxFundAlert
          this.editingNickname = row.nickname
          this.editingCostCenter = row.costCenter ? row.costCenter.id : null
          this.editingCardUser = row.cardUser
        }
      },
      save(row){
        let saveCostCenter;
        if(this.editingCostCenter != null){
          saveCostCenter = {
            id: this.editingCostCenter
          }
        } else {
          saveCostCenter = null
        }
        postWs("/card/update", true, null,
          {
            cardId: row.cardId,
            minFundAlert: this.editingMin,
            maxFundAlert: this.editingMax,
            nickname: this.editingNickname,
            cardUser: this.editingCardUser,
            costCenter: saveCostCenter,
            verifCode: this.verifCode
          },
          response => {
            this.editRow(null, true)
            row.cardUserName = response.data.cardUserName
            row.cardUser = response.data.cardUser
            row.minFundAlert = response.data.minFundAlert
            row.maxFundAlert = response.data.maxFundAlert
            row.nickname = response.data.nickname
            row.costCenter = response.data.costCenter
          },
          error => {
            swal({
              title: 'Aviso!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
          }
        )
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      openShippingAddress(){
        this.$validator.validate('request.nome').then(isValid => {
          this.showAddressFields = isValid
        })
      },
      requestCard(){
        this.$validator.validateAll('request').then(isValid => {
          if (!isValid) {
            return
          }
          if(this.cardType === 'physical'){
            let cardUser = this.requestCardUser == null || this.requestCardUser === '' ? this.username : this.requestCardUser
            swal({
              title: "Usuário do Cartão!",
              text: "O usuário @" + cardUser + " será atribuído como portador deste cartão físico. O nome deste usuário será impresso no cartão e não será possível alterar após a solicitação. Deseja continuar?",
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              cancelButtonClass: 'btn btn-danger btn-fill',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              type: 'warning',
              showCancelButton: true
            }).then(result => {
              if (result) {
                this.confirmDeliveryAddress()
              }
            })
          } else {
            this.doRequestCard()
          }
        })
      },
      confirmDeliveryAddress(){
        swal({
          title: "Confirmar Endereço!",
          text: "Confirme seu endereço para evitar problemas na entrega do cartão: " + this.formRequest.deliveryAddress.street + ", " + this.formRequest.deliveryAddress.number + ", " + this.formRequest.deliveryAddress.neighborhood + ", " + this.formRequest.deliveryAddress.complement + ", " + this.formRequest.deliveryAddress.city + ", " + this.formRequest.deliveryAddress.state + ", " + this.formRequest.deliveryAddress.zipCode,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning',
          showCancelButton: true
        }).then(result => {
          if (result) {
            this.doRequestCard()
          }
        })
      },
      doRequestCard(){
        if(this.requestCostCenter != null){
          this.formRequest.costCenter={id:this.requestCostCenter}
        }
        if(this.requestCardUser != null) {
          this.formRequest.cardUser = this.requestCardUser
        }
        this.formRequest.verifCode = this.verifCode
        this.doCallRequestCard();
      },
      doCallRequestCard(){
        if(this.verifCode != null){
          this.formRequest.verifCode = this.verifCode
        }
        postWs("/card/request", true, null,
          this.formRequest,
          response => {
            if(!this.showTOTP(response, function(){ this.doCallRequestCard() })){
              this.expandRow(null, true)
              this.resetEditFields(false, true, true)
              this.loadData()
              this.clearTOTP()
            }
          },
          error => {
            swal({
              title: 'Aviso!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
          }
        )
      },
      cancelRequest(){
        this.resetEditFields(false, true, true)
      },
      cancel(row){
        swal({
          title: "Cancelar Cartão!",
          text: "Atenção! Após cancelar o cartão, o saldo será devolvido para sua conta e o cartão não poderá mais ser utilizado ou reativado! Deseja continuar?",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning',
          showCancelButton: true
        }).then(result => {
          if(result){
            this.doCancel(row)
          }
        })
      },
      doCancel(row){
        postWs("/card/cancel", true, null,
          {
            cardId: row.cardId,
            verifCode: this.verifCode
          },
          response => {
            if(!this.showTOTP(response, function(){ return this.doCancel(row) })) {
              row.canceled = response.data.canceled
              row.status = response.data.status
              this.clearTOTP()
              this.loadLimits()
            }
          },
          error => {
            swal({
              title: 'Aviso!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
          }
        )
      },
      blockUnblock(row, block){
        let title = "Bloquear cartão!"
        let text = "Esta operação pode ser desfeita a qualquer momento, mas enquanto o cartão estiver bloqueado as compras não serão aprovadas!"
        if(!block) {
          title = "Desbloquear cartão!"
          text = "Após desbloqueado, as compras neste cartão voltarão a ser aprovadas automáticamente!"
        }
        swal({
          title: title,
          text: text,
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning',
          showCancelButton: true
        }).then(result => {
          if(result){
            postWs("/card/block", true, null,
              {
                cardId: row.cardId,
                blocked: block,
                verifCode: this.verifCode
              },
              response => {
                row.blocked= response.data.blocked
                row.status = response.data.status
              },
              error => {
                swal({
                  title: 'Aviso!',
                  text: error.response.data.message,
                  buttonsStyling: true,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'warning'
                })
              }
            )
          }
        })
      },
      search(username) {
        this.searchUser.destinationName = ''
        this.searchUser.destinationSocialNumber = ''
        postWs("/acc/find",
          true, {username: username, self: true}, null,
          (response) => {
            this.searchUser.destinationName = response.data.destinationName
            this.searchUser.destinationSocialNumber = response.data.destinationSocialNumber
            this.searchUser.email = response.data.email
          }, (error) => {
            this.searchUser.destinationName = ''
            this.searchUser.destinationSocialNumber = ''
            if (error.response.status == 404) {
              swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada para receber transações.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'warning'
              })
            }
          })
      },
      selectUserEditing(){
        this.userInfoVisible = false;
        this.userSelected = true
      },
      selectUserRequest(){
        this.userInfoRequestVisible = false;
        this.userRequestSelected = true
      },
      userInfoHidden(row){
        if(!this.userSelected){
          this.editingCardUser = row.cardUser
        } else {
           row.cardUser = this.editingCardUser
        }

        this.userSelected = false
      },
      userInfoRequestHidden(){
        if(!this.userRequestSelected){
          this.requestCardUser = null
        }

        this.userRequestSelected = false
      },
      getCurrentBalance(){
        postWs("/balance/get-current-available",true, null, {},
        response => {
          this.accountBalance = response.data.current
          this.accountAvailableBalance = response.data.available < 0 ? 0 : response.data.available
        })
      },
      addFunds(row){
        if(row.maxFundAlert != null && row.maxFundAlert > 0){
          if(this.value + row.availableFunds > row.maxFundAlert){
            swal({
              title: 'Aviso!',
              text: 'Aumente o limite máximo deste cartão para poder recarregar esse valor.',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
            return
          }
        }
        if(this.value <= 0){
          swal({
            title: 'Aviso!',
            text: 'O valor deve ser maior que zero!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'error'
          })
          return
        }
        if(this.value > this.accountBalance){
          swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
          return
        }

        swal({
          title: 'Aviso!',
          text: 'Deseja recarregar R$ ' + toMoney(this.value) + ' no cartão de final ' + row.number.slice(-4) + "?",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          type: 'warning'
        }).then(result => {
          if(result){
            postWs("/card/add-funds", true, null,
              {
                cardId: row.cardId,
                amount: this.value,
                verifCode: this.verifCode
              },
              response => {
                swal({
                  title: 'Sucesso!',
                  text: 'O cartão de final ' + row.number.slice(-4) + ' foi recarregado com sucesso no valor de R$ ' + toMoney(this.value) + "." ,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'success'
                })
                row.availableFunds = response.data.availableFunds
                this.value = 0;
                this.getCurrentBalance()
              },
              error => {
                swal({
                  title: 'Aviso!',
                  text: error.response.data.message,
                  buttonsStyling: true,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'warning'
                })
              }
            )
          }
        })
      },
      withdraw(row){
        if(this.value == null || this.value <= 0){
          swal({
            title: 'Aviso!',
            text: 'O valor deve ser maior que zero!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'error'
          })
          return
        }
        if(this.value > row.availableFunds){
          swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente neste cartão.',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'warning'
          })
          return
        }

        swal({
          title: 'Aviso!',
          text: 'Deseja sacar R$ ' + toMoney(this.value) + ' do cartão de final ' + row.number.slice(-4) + "?",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          type: 'warning'
        }).then(result => {
          if(result){
            postWs("/card/withdraw-funds", true, null,
              {
                cardId: row.cardId,
                amount: this.value,
                verifCode: this.verifCode
              },
              response => {
                swal({
                  title: 'Sucesso!',
                  text: 'O valor de R$ ' + toMoney(this.value) + ' foi removido do cartão de final ' + row.number.slice(-4) + ' e já se encontra em sua conta.' ,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'success'
                })
                row.availableFunds = response.data.availableFunds
                this.value = 0;
                this.getCurrentBalance()
              },
              error => {
                swal({
                  title: 'Aviso!',
                  text: error.response.data.message,
                  buttonsStyling: true,
                  confirmButtonClass: 'btn btn-success btn-fill',
                  type: 'warning'
                })
              }
            )
          }
        })
      },
      loadAccount(response) {
        this.account = response.data
        this.username = localStorage.getItem("usernameDisplay")
        localStorage.setItem('name', this.account.name)
        if (this.account.accountDocumentStatus != 'APPROVED') {
          this.accountApproved = false
        } else {
          this.accountApproved = true
        }
        if (!isNullOrEmpty(this.formRequest.deliveryAddress.codIbge)) {
          this.viacep.logradouro = this.formRequest.deliveryAddress.street
          this.viacep.bairro = this.formRequest.deliveryAddress.neighborhood
        }
      },
      loadData() {
        this.resetEditFields(true, true, true)
        this.loadLimits()
        this.loadTableData()
        this.loadCostCenters()
        this.setAddressFieldsToAccountAddress()
      },
      loadTableData() {
        let blocked = this.searchFilter.status === 'BLOCKED' ? true : this.searchFilter.status === 'ACTIVE' ? false : null
        let canceled = this.searchFilter.status === 'CANCELED' ? true : this.searchFilter.status === 'ACTIVE' ? false : null
        if(blocked ){
          canceled = false
        }
        postWs("/card/list", true, {full: true}, {
            type: this.cardType.toUpperCase(),
            number: this.searchFilter.number,
            nickname: this.searchFilter.nickname,
            costCenterId: this.searchFilter.costCenterId,
            canceled: canceled,
            blocked: blocked,
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage,
            verifCode: this.verifCode
          },
          response => {
            if(!this.showTOTP(response, function(){ return this.loadTableData; })) {
              this.tableData = response.data.list
              this.pagination.totalNumber = response.data.totalNumber
              this.pagination.pageMaxNumber = response.data.pageMaxNumber
              if (this.pagination.totalNumber > 0) {
                this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
                this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
              } else {
                this.pagination.fromNumber = 0
                this.pagination.toNumber = 0
              }
              this.clearTOTP()
            }
          },
          function () {
          })
      },
      clearTOTP(){
        this.qrCode = null
        this.totpQrCodeUri = null
        this.totpManualCode = null
        this.totpCallback = null
        this.verifCode = null
        this.validationCode = null
      },
      showTOTP(response, callback){
        if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.code)) {
          this.hideTOTP = false;
          this.qrCode = response.data.code
          this.totpQrCodeUri = response.data.totpQRCodeURI
          this.totpManualCode = response.data.totpManualCode
          this.totpCallback = callback
          return true
        }
        return false
      },
      validateCode(){
        this.totpConfirmBtnDisabled = true
        postWs("/verifcode/validate",
          true,
          null,
          {verifCode: this.qrCode, hashCode: this.validationCode},
          this.sucessValidateCode,
          this.failValidadeCode)
      },
      sucessValidateCode(response) {
        this.totpConfirmBtnDisabled = false
        this.verifCode = response.data.verifCode
        this.hideTOTP = true
        this.qrCode = ''
        this.totpCallback()
      },
      failValidadeCode() {
        this.totpConfirmBtnDisabled = false
        swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      },
      // confirmCode(response){
      //   this.verifCode = response.data.verifCode
      //   postWs("/verifcode/confirm",
      //     true,
      //     null,
      //     {verifCode: response.data.verifCode},
      //     (response) => {
      //       try{
      //         this.hideTOTP = true
      //         this.totpCallback()
      //       } catch (e) {
      //         console.log(e)
      //         throw e
      //       }
      //      },
      //     (error) => {
      //       this.qrCode = ''
      //       this.validationCode = ''
      //       this.hideTOTP = false
      //       swal({
      //         title: 'Erro!',
      //         text: 'Erro ao executar a operação, tente novamente!',
      //         buttonsStyling: true,
      //         confirmButtonClass: 'btn btn-warning btn-fill',
      //         type: 'warning'
      //       })
      //     }
      //   )
      // },
      loadCostCenters() {
        postWs("/costcenter/list", true, null, {
            enabled: true
          },
          response => {
            this.costCenters = [{id: null, name: "Sem Categoria"}].concat(response.data.list)
          },
          function () {
          })
      },
      loadLimits() {
        postWs("/card/limits", true, null, {
            cardType: this.cardType.toUpperCase()
          },
          response => {
            this.currentCardCount = response.data.currentCardCount
            this.maxCardLimit = response.data.maxCardLimit
          },
          function () {
          })
      },
      setPin(card){
        swal({
          title: 'Definir Senha',
          type: 'info',
          html:
            '<div style="text-align: center">' +
            '  <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A;">Informe uma senha numérica de 4 digitos para o cartão:</span>' +
            '  <input type="password" id="pin" maxlength="4"/>' +
            '  </br>' +
            '  <span style="font-size: 0.8571em;margin-bottom: 5px;color: #9A9A9A;">Confirme a senha: </span>' +
            '  </br>' +
            '  <input type="password" id="pin2" maxlength="4"/>' +
            '</div>'
          ,
          buttonsStyling: true,
          confirmButtonClass: 'btn-success btn-fill',
          showCancelButton: true,
          confirmButtonColor: '#00c390',
          cancelButtonColor: '#ef8157',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        }).then( (result) => {
          if(result){
            let pin = document.getElementById('pin').value
            let pin2 = document.getElementById('pin2').value
            if(isNullOrEmpty(pin) || pin.length !== 4 || !containsOnlyNumbers(pin)){
              swal({
                title: 'Atenção!',
                text: 'A senha deve ser numérica e possuir 4 digitos.',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'warning'
              })
            } else if(pin !== pin2){
              swal({
                title: 'Atenção!',
                text: 'As senhas não conferem! Tente novamente',
                buttonsStyling: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: 'warning'
              })
            } else {
              card.pin = document.getElementById('pin').value;
              this.doSetPin(card)
            }
          }
        })
      },
      doSetPin(card){
        if(this.verifCode != null){
          card.verifCode = this.verifCode
        }
        postWs("/card/setPin", true, null,
          card,
          response => {
            if(!this.showTOTP(response, function(){ this.doSetPin(card) })){
              this.expandRow(null, true)
              this.resetEditFields(true, true, true)
              this.loadData()
              this.clearTOTP()
            }
          },
          error => {
            swal({
              title: 'Aviso!',
              text: error.response.data.message,
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-success btn-fill',
              type: 'warning'
            })
          }
        )
      },
    }
  }
</script>
<style lang="scss" scoped>
  .dzs-card {
    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }
    ::placeholder {
      color: #000000;
    }

  }

  .search {
    background-color: #17a2b8 !important;
  }

  .search:hover {
    background-color: #0a7282 !important;
  }

  .col-xl-2 {
    @media (min-width: 1200px) and (max-width: 1400px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .box-xl-6 {
    @media (min-width: 992px) and (max-width: 1500px){
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin-top: 5px;
    }
  }

  .status-text{
    @media (max-width: 1300px) {
      font-size: xx-small
    }
    @media (max-width: 1500px) {
      font-size: smaller;
    }
  }


</style>

<style lang="scss">
  .normal-word-break .cell{
    word-break: normal !important;
    min-width: 55px;
  }
</style>
