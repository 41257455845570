<template>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <label class="control-label">Destino</label>
            <div style="width: 100% !important"><p class="form-control-static">
                <b>{{transfer.destinationName}}</b><span style="color: #9A9A9A"> - @{{transfer.destination}}</span>
            </p></div>
        </div>
        <div class="col-md-12">
            <label class="control-label">CPF/CNPJ</label>
            <p class="form-control-static">{{transfer.destinationSocialNumber}}</p>
        </div>
        <div class="col-md-12">
            <label class="control-label">Valor</label>
            <p class="form-control-static">R$ {{transfer.value}}</p>
        </div>
        <div class="col-md-12">
            <label class="control-label">Descrição</label>
            <p class="form-control-static">{{transfer.description}}</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'dzs-tev-transfer-details',
        props: {
            transfer: {}
        }
    }
</script>
