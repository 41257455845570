<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Cadastro de IPs</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label class="control-label">Usuário</label>
                <fg-input v-model="search.username">
                  <span slot="addonLeft">@</span>
                </fg-input>
              </div>
              <div class="col-md-4">
                <p-button style="margin-top: 25px;"
                          @click="searchUser" type="info">
                  Buscar
                </p-button>
              </div>
            </div>
            <div class="row">
            <div class="col">
              <div class="row-md-6">
                <div class="card">
                  <div class="card-header">
                    <h3>IPs Cadastrados</h3>
                  </div>
                  <div class="card-body">
                    <div class="row col-md-12">
                      <el-table class="table-striped" :data="registedIps" style="margin: auto; width: 100%">
                        <el-table-column :min-width="90" label="Usuário">
                          <template v-slot="props">
                            <span>{{props.row.userUsername}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Data de Inclusão">
                          <template v-slot="props">
                            <span>{{props.row.createdDate}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="IP">
                          <template v-slot="props">
                            <span>{{props.row.ip}}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row-md-6">
                <div class="card">
                  <div class="card-header">
                    <h3>Histórico de Cadastros de IPs</h3>
                  </div>
                  <div class="card-body">
                    <div class="row col-md-12">
                      <el-table class="table-striped" :data="historyIps" style="margin: auto; width: 100%">
                        <el-table-column :min-width="90" label="Usuário">
                          <template v-slot="props">
                            <span>{{props.row.userUsername}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Ativo">
                          <template v-slot="props">
                            <i class="fas fa-circle" :style="'color: #'+(validadeIp(props.row.ip, props.row.deleteDate)?'6bd098':'ef8157')"></i>
                            <span> {{validadeIp(props.row.ip, props.row.deleteDate)?'Sim':'Não'}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column :min-width="90" label="Data de Modificação">
                          <template v-slot="props">
                            <span>{{props.row.lastModifiedDate}}</span>
                          </template>
                        </el-table-column><el-table-column :min-width="90" label="IP">
                          <template v-slot="props">
                            <span>{{props.row.ip}}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {failWs, postWs, getWs} from "src/ws.service"
import swal from 'sweetalert2'
export default {
  data() {
    return {
      search: {
        username: '',
        salePersonUsername: ''
      },
      historyIps: [],
      registedIps: []
    }
  },
  methods: {
    resetDefaultValues(){
      this.search.username = ''
    },
    searchUser() {
      this.search.salePersonUsername = localStorage.getItem("username")
      postWs("/bo/gateway/list-client-ips",
        true, {username: this.search.name},
        {
          username: this.search.username,
          salePersonUsername: this.search.salePersonUsername
        },
        (response) => {
          if(response.status === 204){
            swal({
              title: 'Aviso!',
              text: 'Sem cadastros ou o usuário não pertence a sua carteira',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
          this.registedIps = response.data.list
        }, (error) => {
          if(error.response.status === 404){
            swal({
              title: 'Aviso!',
              text: 'Cliente não encontrado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          } else{
            swal({
              title: 'Aviso!',
              text: 'Erro ao buscar registros de IP',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        })
      postWs("/bo/gateway/list-client-ips-history",
        true, null,
        {
          username: this.search.username,
          salePersonUsername: this.search.salePersonUsername
        },
        (response) => {
          if(response.status === 204){
            swal({
              title: 'Aviso!',
              text: 'Sem cadastros ou o usuário não pertence a sua carteira',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
          this.historyIps = response.data.list
        }, (error) => {
          if(error.response.status === 404){
            swal({
              title: 'Aviso!',
              text: 'Cliente não encontrado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }else if(error.response.status === 400){
            swal({
              title: 'Aviso!',
              text: 'O usuário deve ser informado!',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          } else{
            swal({
              title: 'Aviso!',
              text: 'Erro ao buscar registros de IP',
              buttonsStyling: true,
              confirmButtonClass: 'btn btn-warning btn-fill',
              type: 'warning'
            })
          }
        }
      )
    },
    validadeIp(historyIp, deleteDate){
      if(deleteDate === ""){
        for(let i = 0; i < this.registedIps.length; i++){
          if(this.registedIps[i].ip === historyIp){
            return true;
          }
        }
        return false;
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
